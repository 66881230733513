import React from "react";
import { Bar } from "react-chartjs-2";

const StackedBarChart = ({ chartData }) => {
  let options = {
    plugins: {
      legend: { display: false },
      title: {
        display: false,
        text: "Chart.js Bar Chart - Stacked",
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default StackedBarChart;
