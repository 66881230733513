import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
// import { FaEdit, FaTrash } from "react-icons/fa";
import { useSelector } from "react-redux";
import useScrollbarSize from "react-scrollbar-size";

import { Loading, NoRecords } from "../../../components";
import { staticTranslate } from "../../../features/translate/translateSlice";
import Logouts from "../../../components/logout"; 

const keyAuth = global.config.typecode[10] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const TestPage = () => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector(state => state.translate.lang)
  const { height } = useScrollbarSize();
  const [isChecked, setChecked] = useState([]);
  const [pending, setPending] = useState(true);
  const [listMarket, setListMarket] = useState([]);
  
  useEffect(() => {
    getMarketList();
  }, []);

  const updtlistStat = (row) => {
    let checkarr = {};
    row.forEach((records) => {
      checkarr[records.market_id] = records.status;
    });
    setChecked(checkarr);
  };

  const getMarketList = () => {
    var hosturl = "https://distributor.asia.veolia.com/app";
    let fetchUrl = hosturl + "/listmarket";
    console.log(fetchUrl);
    headersParams.Authorization = keyAuth;
    const dataText = { from_id: "list-market", lang: chooseLang };
    fetch(fetchUrl, {
      method: "POST",
      headers: headersParams,
      body: JSON.stringify(dataText),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
      })
      .then(async (res) => {
        console.log(res);
        if (res.status === "200") {
          setListMarket(res.values);
          updtlistStat(res.values);
        } else if(res.status === global.config.errcodesess) {
          var resultSO = await Logouts();
          if(resultSO.status === "200") {
            window.location.reload();
          }
        }
        setPending(false);
      });
  };

  const ActionCols = (row) => (
    <div className="flex flex-1 gap-4 hover:fill-red-500">
      
    </div>
  );

  const StatusCols = (row) => (
    <div className="flex gap-x-10 items-center">
      {(row.status === true || row.status === 1)? global.config.generalstatus[0] : global.config.generalstatus[1] }
      {/* <ToggleSwitch
        id={row.market_id}
        checked={isChecked[row.market_id] || ""}
        onClick={() => handleToggle(row.market_id, isChecked[row.market_id])}
      /> */}
    </div>
  );

  const columns = [
    {
      name: translate.market_list.column_1,
      selector: (row) => row.market_name,
    },
    {
      name: translate.general.column_status,
      width: "180px",
      cell: StatusCols,
    }
  ];
  if(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1]) {
    columns.push({name: translate.general.column_action, width: "180px", cell: ActionCols});
  }
  return (
    <>
      <div className=" bg-gray-100 h-screen flex-1 p-8 overflow-y-auto">
        <div className="flex items-center mb-8">
          <h2 className=" text-2xl">{translate.market_list.title}</h2>
         
        </div>
        <div>
          <DataTable
            columns={columns}
            data={listMarket}
            fixedHeader
            highlightOnHover
            defaultSortFieldId="id"
            progressPending={pending}
            progressComponent={<Loading className="h-10 w-10 mt-10 mb-10"/>}
            pointerOnHover
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
            noDataComponent={<NoRecords />}
            customStyles={{
              headRow: {
                style: {
                  borderBottomWidth: "3px",
                  borderBottomStyle: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#F5F6FA",
                  color: "#8A92A6",
                },
                denseStyle: {
                  minHeight: "32px",
                },
              },
              table: {
                style: {
                  height: `calc(100vh - 180px - ${height}px)`,
                },
              },
            }}
          />
        </div>
      </div>
      
    </>
  );
};

export default TestPage;
