import React, { useState } from "react";
import { Button, Modal, TextInput } from "../../../../components";
import { ICSuccess } from '../../../../assets';
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../../features/translate/translateSlice";

const keyAuth = global.config.typecode[0]+global.config.authBearerKey;
const headersParams = global.config.headersparam;

const ForgotPasswordModal = ({ onClick, onBackgroundClick }) => {
  const translate = useSelector(staticTranslate);
  const [form, setForm] = useState({'useremail': ''});
  const [formerrors, setFErrors] = useState({'useremail': ''});
  const validateField = (fieldName, value, type) => {
    var ferrors = "";
    switch(fieldName) {
        case 'useremail': 
            if(value.trim().length > 0) { ferrors = ""; }
            else { if(type === "callback") { ferrors = translate.login.modal_error1; } else { ferrors = translate.login.modal_error2; } }
            break;
        default:
            break;
    }
    setFErrors(prevState => ({ ...prevState, [fieldName]: ferrors }));
  }
  const onChangeHandler = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value, "form");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if(form.useremail !== "") {
      document.getElementById("loading-screen").style.display = "flex";
      var urlFetch = global.config.urlLink+"/forgotpass";
      var dataJson = form;
      dataJson.from_id="forgot-password";
      headersParams.Authorization = keyAuth;
      
      fetch(urlFetch, {
        method: 'POST',
        headers:headersParams, 
        body : JSON.stringify(dataJson)
      }).then(res => {
          if(res.status === 200) 
              return res.json() 
      }).then( resJson => {
          document.getElementById("loading-screen").style.display = "none";
          if(resJson.status === "200") { 
            document.getElementById("form").style.display = "none";
            document.getElementById("smessage").style.display = "block";
            // window.location.href='/';
          } else {
            validateField("useremail", "", "callback");
          }
          return resJson;
      }).catch((err) => {
          return "Error";
      });    
    } else {
      var formField = [];
      if(form.useremail === "") { formField.push("useremail"); }
      for ( var i = 0; i < formField.length; i++ ) { 
        var fieldname = formField[i];
        var fieldvalue = form[fieldname];
        validateField(fieldname, fieldvalue, "form"); 
      }
    }
  };
  return (
    <Modal
      // title="Forgot Password"
      title={translate.login.modal_title1}
      onClick={onClick}
      onBackgroundClick={onBackgroundClick}
    >
      <div id="smessage"  className="hidden">
        <div className=" p-5 overflow-y-auto flex-1 justify-center items-center flex">
          <div className="md:flex self-center justify-center items-center">
            <img src={ICSuccess} width={80} className="m-auto" alt=""/>
            <div className=" text-ellipsis text-center sm:text-justify ml-8">
              <h2 className=" text-3xl mb-2">{translate.login.modal_desc1}</h2>
              <p className=" text-gray-400">
                {translate.login.modal_desc2}<br />{translate.login.modal_desc3}
                {/* Your password has been successfully sent to your Email.<br/>Please check you email. */}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id="form">
        <div className="mb-8">
          <TextInput 
            type="text"
            // title="Email"
            title={translate.login.modal_textInputLabel1}
            variant="base"
            name="useremail"
            id="useremail"
            value={form.useremail}
            onChange={onChangeHandler}
            errmsg={formerrors.useremail}
          />
        </div>
        <div className=" justify-end flex">
          {/* <Button
            title="Reset Password"
            variant="base"
            type="button"
            className="bg-red-500 text-white" 
          /> */}
          <Button
            variant="base"
            // title="Request Password"
            title={translate.login.modal_button1}
            className="bg-red-500 text-white"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ForgotPasswordModal;
