import React, { useEffect, useState } from "react";
import { FaDownload, FaTrash, FaEdit } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, ReplaceAllContent } from "../../../components";
import ImgFullScreenModal from "./Modal";
import { ReactComponent as IconDOC } from "../../../assets/svg/icon-doc.svg";
import { ReactComponent as IconPDF } from "../../../assets/svg/icon-pdf.svg";
import { ReactComponent as IconPPT } from "../../../assets/svg/icon-ppt.svg";
import { ReactComponent as IconTXT } from "../../../assets/svg/icon-txt.svg";
import { ReactComponent as IconXLS } from "../../../assets/svg/icon-xls.svg";
import { ReactComponent as IconUnknownFile } from "../../../assets/svg/unknown-file.svg";
import { ReactComponent as IconVideo } from "../../../assets/svg/icon-video.svg";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../features/translate/translateSlice";
import openFiles from "../../../components/downloadurl";

const keyAuth = global.config.typecode[8] + global.config.authBearerKey;
const keyAuth2 = global.config.typecode[3] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const MarketingDetail = () => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector(state => state.translate.lang)
  const paramsLoc = useLocation();
  const data = paramsLoc.state.item;
  const navigate = useNavigate();
  const [listMaterial, setListMaterial] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [isImgModal, setImgModal] = useState(false);
  const [isContent, setContent] = useState(null);

  useEffect(() => {
    document.getElementById("loading-screen").style.display = "flex";
    var idDist = [], idCntry="";
    if(localStorage.getItem("dulv")===global.config.conflevel[3]) { idDist = [parseInt(localStorage.getItem("dudst"))]; } 
    else if(localStorage.getItem("dulv")===global.config.conflevel[2]) { idCntry = localStorage.getItem("ducr"); }
    
    if(idCntry !== "") {
      loadData(idCntry);
    } else {
      getMarketingItems(idDist);
    }
  }, []);
  const handleOpenFile = async (url) => {
    let [resultUrl] = await Promise.all([openFiles(url,keyAuth)]);
    if(resultUrl !== "" & resultUrl !== undefined) {
      window.open(resultUrl,'_blank');
    }
  }
  const loadData = async (countryid) => {
    var distributorArr = await loadListDistributor(countryid);
    getMarketingItems(distributorArr);
  }
  const loadListDistributor = (country) => {
    return new Promise((resolve) => {
      var datatext = '{';
      datatext += '"from_id":"list-distributor"';
      if(country !== "") { datatext += ',"country_id":"'+country+'"'; }
      datatext += '}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth2;
      var fetchUrl = global.config.urlLink + "/listdist";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            var arrDistId = [];
            if(resJson.values.length > 0) {
              for(var d=0; d<resJson.values.length; d++) {
                arrDistId.push(resJson.values[d].distributor_id);
              };
            } 
            resolve(arrDistId);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const getMarketingItems = (distributor) => {
    // setLoading(true);
    let fetchUrl = global.config.urlLink + "/listmarketing";
    let dataText = {
      product_id: data?.product_id,
      from_id: "list-marketing-product",
      lang: chooseLang
    };
    if(localStorage.getItem("dulv")===global.config.conflevel[2] || localStorage.getItem("dulv")===global.config.conflevel[3]) {
      dataText.stat_id = 1;
    }
    headersParams.Authorization = keyAuth;
    fetch(fetchUrl, {
      method: "POST",
      headers: headersParams,
      body: JSON.stringify(dataText),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
      })
      .then((res) => {
        if (res.status === "200") {
          var sendResult = [];
          if(res.values.length>0) {
            var cathId = "";
            var resultItem = [];
            for(var a=0; a<res.values.length; a++) {
              var cathIdval = res.values[a].material_cath;
              if(cathId === "") { cathId = cathIdval; }
              if(cathIdval !== cathId) { 
                if(resultItem.length > 0) {
                  sendResult.push({
                    category: cathId,
                    item: resultItem
                  });
                }
                resultItem = [];
                cathId = cathIdval;
              }
              if(cathIdval === global.config.cathmaterial[0] && 
                  (localStorage.getItem("dulv")===global.config.conflevel[2] || localStorage.getItem("dulv")===global.config.conflevel[3])
                ) { 
                if(distributor.indexOf(parseInt(res.values[a].id_distributor)) > -1) {
                  resultItem.push(res.values[a]);  
                }
              } else { 
                resultItem.push(res.values[a]);
              }
            }
            if(resultItem.length > 0) {
              sendResult.push({
                category: cathId,
                item: resultItem
              });
            }
          }
          setListMaterial(sendResult);
          document.getElementById("loading-screen").style.display = "none";
        }
      })
      .catch((err) => { 
        console.log(err);
        document.getElementById("loading-screen").style.display = "none";
      });
  };

  const removeItemsMaterial = (id) => {
    if (
      window.confirm(`${translate.marketing_material_detail.alert_delete}`) === true
    ) {
      document.getElementById("loading-screen").style.display = "flex";
      let fetchUrl = global.config.urlLink + "/deletemarketing/" + id;

      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "GET",
        headers: headersParams,
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then((res) => {
          document.getElementById("loading-screen").style.display = "none";
          window.location.href = "/marketing/detail";
        })
        .catch((err) => { 
          console.log(err);
          document.getElementById("loading-screen").style.display = "none";
        });
    } 
  };

  const handlePreviewImgFullScreen = (img) => {
    if (img.material_filetype === "application/pdf") {
      return "/images/icons/pdf-icon.png";
    } else if (img.material_url !== null && img.material_url !== "") {
      return img.material_url;
    } else {
      return "/images/icons/sphere-min.png";
    }
  };

  const handleModalImgFullScrn = async (item) => {
    if(item.material_filetype.includes("image") === true) {
      setContent(handlePreviewImgFullScreen(item));
      setImgModal(!isImgModal);
    } else { 
      if(item.material_url !== undefined && item.material_url !== "") {
        let [resultUrl] = await Promise.all([openFiles(item.material_url,keyAuth)]);
        if(resultUrl !== "" & resultUrl !== undefined) {
          window.open(resultUrl,'_blank');
        }
      } 
    }
  };

  const contentInsideMaterial = (contentItem) => {
    var itemMaterial = [];
    if(contentItem.length > 0) {
      var n=0;
      contentItem.forEach((items) => {
        var fileType = items.material_filetype;
        n++;
        itemMaterial.push(<div
          key={n}
          className="shadow-3xl rounded-lg p-2 border border-gray-200 flex flex-col justify-between items-center"
        >
          <div className="flex flex-col justify-between">
            <div
              className="rounded-lg cursor-pointer h-24 flex items-center justify-center"
              onClick={() => handleModalImgFullScrn(items)}
            >
              { 
                (fileType === "application/pdf")? <IconPDF width={80} />
                : (fileType === "application/msword" || fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")? <IconDOC width={80} />
                : (fileType === "application/vnd.ms-powerpoint" || fileType === "application/vnd.openxmlformats-officedocument.presentationml.presentation")? <IconPPT width={80}/>
                : (fileType === "text/plain")? <IconTXT width={80}/>
                : (fileType === "application/vnd.ms-excel" || fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")? <IconXLS width={80}/>
                : (fileType.split("/")[0] === "video")? <IconVideo width={80}/>
                : (fileType.split("/")[0] === "image" && items.material_url !== null && items.material_url !== "")? 
                  <img src={items.material_url} className="rounded-lg" alt="" style={{maxWidth: "6rem", maxHeight: "6rem"}} />
                : <IconUnknownFile width={80}/>
                
              }
              {/* <img
                src={
                  items.material_filetype === "application/pdf"
                    ? "/images/icons/pdf-icon.png"
                    : (items.material_url !== null && items.material_url !== "")? 
                    items.material_url : "/images/icons/sphere-min.png"
                }
                className="w-24 rounded-lg"
                alt=""
              /> */}
            </div>
            <div className="py-2 w-24">
              <p className="text-center text-xs text-gray-500 italic line-clamp-1">
              <ReplaceAllContent content={items.material_filename} before={["_","-"]} after={[" "," "]}/>
                {/*items.material_cath*/}
              </p>
              {/*<p className="text-center text-xs text-gray-500 italic line-clamp-1">
                {items.product_description}
              </p> */}
            </div>
          </div>
          <div className="flex items-center justify-around gap-1 w-full">
          {(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2])?
          <>
            <a
              onClick={()=> handleOpenFile(items.material_url)}
            >
              <FaDownload className=" hover:fill-red-500 cursor-pointer fasize" />
            </a>
              <FaEdit 
                onClick={() =>
                  navigate("/marketing/create", {
                    state: { type: "edit", data: items },
                  })
                }
                className="hover:fill-red-500 cursor-pointer fasize"
              />
              <FaTrash onClick={()=>removeItemsMaterial(items?.marketing_id)}
              className=" hover:fill-red-500 cursor-pointer fasize"/>
          </>
          : 
          <>
           <button
            onClick={()=> handleOpenFile(items.material_url)}
            className="bg-red-500 p-2 rounded-lg text-white font-semibold mt-5 hover:shadow-3xl duration-150 flex flex-1 justify-center items-center text-xs">
              {translate.marketing_material_detail.button1}
          </button>
          </> 
          }  
          </div>
        </div>)
      })
    }
    return itemMaterial;
  }

  var contentMaterial = [];
  if(listMaterial.length > 0) {
    for(var m=0; m<listMaterial.length; m++) {
      var cathname = listMaterial[m].category;
      if(chooseLang === 'cn') {
        var idx = global.config.cathmaterial.indexOf(listMaterial[m].category);
        cathname = translate.marketing_material_create.cathMaterial[idx];
      }
      contentMaterial.push(
        <div key={m} className="mb-5">
          <div className="bg-slate-200 h-30 p-4 text-base text-gray-700 border-b-2 rounded-t-lg font-medium">{cathname}</div>
          <div className="flex flex-1 gap-5 flex-wrap p-4 bg-white">{contentInsideMaterial(listMaterial[m].item) }
          </div>
        </div>
      )
    }
  }
  return (
    <>
    <div className=" bg-gray-100 h-screen overflow-y-auto p-8">
      <div className="mb-8 flex items-center">
        <Button
          variant="icon"
          icon="left"
          onClick={() => navigate(-1)}
          className="mr-5"
        />
        <h2 className=" text-2xl">{data.product_name}</h2>
      </div>
      
      <Card className="p-8">
        <div>{contentMaterial}</div>
      </Card>
    </div>
    {isImgModal && (
      <ImgFullScreenModal
        content={isContent}
        onBackgroundClick={() => setImgModal(!isImgModal)}
      />
    )}
    </>
  );
};

export default MarketingDetail;
