import React from "react";
import { Modal } from "../../../../components";

const ImgFullScreenModal = ({ onBackgroundClick, onClick, content }) => {
  return (
    <Modal
      onBackgroundClick={onBackgroundClick}
    >
      <img src={content} className="h-1/2 w-full" alt="" />
    </Modal>
  );
};

export default ImgFullScreenModal;
