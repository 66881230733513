import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import useScrollbarSize from "react-scrollbar-size";
import { FaEdit, FaTrash } from "react-icons/fa";

import { useNavigate } from "react-router-dom";
import { Button, ToggleSwitch, Loading, NoRecords } from "../../../components";
import { ReactComponent as ThumbnailIcon } from "../../../assets/svg/thumb-icon-grey.svg";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../features/translate/translateSlice";
import Logouts from "../../../components/logout"; 

const keyAuth = global.config.typecode[2] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const ProductList = () => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector(state => state.translate.lang)
  const navigate = useNavigate();
  const { height } = useScrollbarSize();
  const [productList, setProductList] = useState([]);
  const [isChecked, setChecked] = useState([]);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    getProductList();
  }, []);

  const updtlistStat = (row) => {
    let checkarr = {};
    row.forEach((records) => {
      checkarr[records.product_id] = records.status;
    });
    setChecked(checkarr);
  };

  const getProductList = () => {
    let fetchUrl = global.config.urlLink + "/listproduct";
    let dataText = { from_id: "list-all-product", lang: chooseLang };
    headersParams.Authorization = keyAuth;
    fetch(fetchUrl, {
      method: "POST",
      headers: headersParams,
      body: JSON.stringify(dataText),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
      })
      .then(async (res) => {
        setPending(false);
        if (res.status === "200") {
          setProductList(res.values);
          updtlistStat(res.values);
        } else if(res.status === global.config.errcodesess) {
          var resultSO = await Logouts();
          if(resultSO.status === "200") {
            window.location.reload();
          }
        }
        
      })
      .catch((err) => console.log(err));
  };
  const handleDetailClick = (resList,type) => {
    navigate("/admin/product/create", {
      state: { data: resList, type: type },
    })
  }
  const handleToggle = (id, stat) => {
    let statcheck = 1,
      statsend = true;
    if (stat === 1) {
      statcheck = 0;
      statsend = false;
    } else {
      statcheck = 1;
      statsend = true;
    }
    setChecked((prevState) => ({ ...prevState, [id]: statcheck }));

    let dataText =
      '{"doc_id":"' +
      id +
      '","product_status": "' +
      statsend +
      '","lang": "' +
      chooseLang +
      '","from_id":"edit-status-product"}';

    let data = JSON.parse(dataText);
    let urlFetch = global.config.urlLink + "/editproduct";
    headersParams.Authorization = keyAuth;

    fetch(urlFetch, {
      method: "POST",
      headers: headersParams,
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
      })
      .then((resJson) => {
        return false;
      })
      .catch((err) => {
        return false;
      });
  };

  const handleDeletProduct = (id_product) => {
    let fetchUrl = global.config.urlLink;
    if (window.confirm(`${translate.product_list.alert_delete}`)) {
      fetchUrl += "/deleteproduct/" + id_product;
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "GET",
        headers: headersParams,
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then((res) => {
          if (res.status === "200") {
            window.location.href = "/admin/product";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
    }
  };

  const AvatarList = ({ item }) => (
    <div className="flex flex-1 items-center gap-5 py-3">
      {/* <img src={(item.pic_thumbnail !== "" && item.pic_thumbnail !== null)? item.pic_thumbnail : '/images/icons/cone-min.png'} className="w-10" alt="" /> */}
      {(item.pic_thumbnail !== "" && item.pic_thumbnail !== null)?
        <img src={item.pic_thumbnail} className="w-10 rounded-md" alt="" />
      : <ThumbnailIcon className=" w-10 h-10" />}
      <p>{item.product_name}</p>
    </div>
  );

  const ActionCols = (row) => (
    <div className="flex flex-1 gap-4 hover:fill-red-500">
      {/* <FaInfo
        onClick={() => handleDetailClick(row,"detail")}
        className="hover:fill-red-500 cursor-pointer fasize"
      /> */}
      <FaEdit
        onClick={() => handleDetailClick(row,"edit")}
        className="hover:fill-red-500 cursor-pointer fasize"
      />
      {(localStorage.getItem("dulv")===global.config.conflevel[0] && <FaTrash
        onClick={() => handleDeletProduct(row.product_id)}
        className="hover:fill-red-500 cursor-pointer fasize"
      />)}
    </div>
  );

  const StatusCols = (row) => (
    <div className="flex gap-x-10 items-center">
      {(row.status === true || row.status === 1)? global.config.generalstatus[0] : global.config.generalstatus[1] }
      {/* <ToggleSwitch
        id={row.product_id}
        checked={isChecked[row.product_id] || ""}
        onClick={() => handleToggle(row.product_id, isChecked[row.product_id])}
      /> */}
    </div>
  );

  const columns = [
    {
      name: translate.product_list.column_1,
      
      selector: (row) => <AvatarList item={row} />,
    },
    {
      name: translate.general.column_status,
      width: "180px",
      cell: StatusCols,
    }
  ];
  if(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1]) {
    columns.push({ name: translate.general.column_action, width: "180px", cell: ActionCols});
  }
  return (
    <div className=" bg-gray-100 h-screen flex-1 p-8 overflow-y-auto">
      <div className="flex mb-8 gap-4">
        <h2 className=" text-2xl">{translate.product_list.title}</h2>
        {(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1]) &&
        <Button
          variant="icon"
          icon="plus"
          onClick={() =>
            navigate("/admin/product/create", { state: { type: "create" } })
          }
        />
        }
      </div>
      <div>
        <DataTable
          columns={columns}
          data={productList}
          fixedHeader
          highlightOnHover
          defaultSortFieldId="id"
          progressPending={pending}
          progressComponent={<Loading className="h-10 w-10 mt-10 mb-10"/>}
          pointerOnHover
          pagination
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
          onRowClicked={(row) => handleDetailClick(row,"detail")}
          noDataComponent={<NoRecords />}
          customStyles={{
            headRow: {
              style: {
                borderBottomWidth: "3px",
                borderBottomStyle: "solid",
                fontWeight: "bold",
                backgroundColor: "#F5F6FA",
                color: "#8A92A6",
              },
              denseStyle: {
                minHeight: "32px",
              },
            },
            table: {
              style: {
                height: `calc(100vh - 20px - ${height}px)`,
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default ProductList;
