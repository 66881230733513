import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { IoMdLogOut } from "react-icons/io";
import { staticTranslate } from "../../features/translate/translateSlice";
import { Button } from "../../components";
import Logouts from "../logout"; 
import openFiles from "../downloadurl";

const keyAuth = global.config.typecode[17] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const ComplianceScreen = ({ id = "", className = "", style = {} }) => {
  const translate = useSelector(staticTranslate);
  const [loading, setLoading] = useState(false);
  const [loadscreen, setLoadScreen] = useState(false);
  const [linkfile, setLinkFile] = useState("");
  
  const handleClick = (link,target) => {
    window.open(link , target);
  }
  const downloadURL = async (url) => {
    let [resultUrl] = await Promise.all([openFiles(url,keyAuth)]);
    if(resultUrl !== "" & resultUrl !== undefined) {
      window.open(resultUrl,'_blank');
    }
  }
  const handleDownload = () => {
    var idDist = "", idCntry="";
    if(localStorage.getItem("dulv")===global.config.conflevel[3]) { idDist = localStorage.getItem("dudst"); } 
    else if(localStorage.getItem("dulv")===global.config.conflevel[2]) { idCntry = localStorage.getItem("ducr"); }

    let data = {
      dist_id: idDist,
      action: global.config.complianceaction[0],
      from_id: "download_compliance_file"
    };
    headersParams.Authorization = keyAuth;
    let fetchUrl = global.config.urlLink+"/downloadcompliance";
    setLoading(true); 
    fetch(fetchUrl, {
      method: "POST",
      headers: headersParams,
      body: JSON.stringify(data),
    }).then((res) => {
        if (res.status === 200) return res.json();
    }).then((res) => {
        if (res.status === "200") {
          var linkFileDownload = res.values.downloadurl;
          localStorage.setItem('dstatc', res.values.status);
          setLoading(false); 
          setLinkFile(linkFileDownload);
          downloadURL(linkFileDownload);
        } else if(res.status === global.config.errcodesess) {
          setLoading(false); 
          alert(translate.compliance.error1);
        } 
    }).catch((err) => {
      setLoading(false); 
      alert(translate.compliance.error1);
    });
  }
  const handleLogout = async () => {
    var resultSO = await Logouts();
    if(resultSO.status === "200") {
      window.location.reload();
    }
  }
  useEffect(() => {
    if(translate === undefined || translate.compliance === undefined || translate.compliance_list === undefined) {
      handleLogout();
    } else {
      setLoadScreen(true);
    }
  }, []);
  return (
    <div
      id={id}
      className={`absolute bg-zinc-900 bg-opacity-90 z-[999999] h-full w-full flex items-center justify-center ${className}`}
      style={style}
    >
      {loadscreen === true && <div className="flex items-center">
        <div className="flex-1 p-8 bg-white w-2/4 overflow-y-scroll no-scrollbar"
            style={{color: "#666666",border:"0px solid #CCC", paddingTop:"20px", paddingBottom:"20px", paddingLeft:"20px", borderRadius: "10px"}}
        >
            <div className="mb-8">
              <div className="w-1/2 ">
                <img
                    className={`w-40 my-1`}
                    src="/images/main-logo.png"
                    alt="veolia-logo"
                  />
                </div>
                <div className="flex justify-end w-1/2 ">
                  <button
                    onClick={handleLogout}
                  >
                    <IoMdLogOut color="grey" size={25} />
                  </button>
                </div>
            </div>
           <div className="text-center mb-8" style={{height:"100%", width:"100%", overflowX: "hidden", overflowY: "auto"}}>
              {translate.compliance.label1}<br/>{translate.compliance.label2}
           </div>
          <div className="mb-8 flex">
            <div  className="flex flex-1 gap-4 w-full">
                <div className={`${
                  (localStorage.getItem("dstatc") !== undefined && localStorage.getItem("dstatc") !== null && localStorage.getItem("dstatc") !== "") ? "w-1/2" : "w-full flex items-center justify-center"
                } flex `}
               >
                    <Button
                        type="submit"
                        variant="icon-base"
                        // title="Download Compliance Declaration Form"
                        title={translate.compliance.button1}
                        className="bg-red-500 text-white w-96"
                        icon={(loading===true)? "loading" : ""}
                        onClick={() => (linkfile !== "")? downloadURL(linkfile): handleDownload()}
                        disables={loading}
                      />  
                      <div id="hiddenDownloadID" style={{display:"none"}}></div>
                </div>
                {(localStorage.getItem("dstatc") !== undefined && localStorage.getItem("dstatc") !== null && localStorage.getItem("dstatc") !== "")?
                <div className="flex justify-end w-1/2">
                   <Button
                    type="submit"
                    variant="base"
                    title={translate.compliance.button2}
                    className="bg-red-500 text-white w-96"
                    onClick={() => handleClick("/compliance","_self")}
                  />
                </div>
                 : <></>
                }
            </div>
          </div>
          
        </div>
      </div> }
    </div>
  );
};
export default ComplianceScreen;
