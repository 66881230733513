import React from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FormErrors } from "../../components";
import './ckeditor.css'
import { useSelector } from "react-redux";
import { staticTranslate } from "../../features/translate/translateSlice";

const TextInput = ({
  type = "",
  variant = "",
  title = "",
  className = "",
  placeholder = "",
  name = "",
  id = "",
  value = "",
  onChange = () => {},
  disabled,
  errmsg = "",
  height="",
  addon
}) => {
  const translate = useSelector(staticTranslate)
  const editorConfiguration = {
    toolbar: [
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
      "undo",
      "redo",
    ],
  };
  const TextInputItem = () => {
    switch (variant) {
      case "base":
        return (
          <div className={`${className} mb-4 mx-1`}>
            <div>
              <div className="flex flex-row">
                <div><label className="block mb-2 text-gray-500">{title}</label></div>
                <div className="px-3 py-1">{addon}</div>
              </div>
              <input
                type={type}
                placeholder={`${translate.component.input_placeholder} ${title.toLowerCase()}`}
                // placeholder={`insert ${title.toLowerCase()}`}
                name={name}
                id={id}
                value={value}
                onChange={onChange}
                disabled={disabled}
                className={`${
                  disabled ? "bg-gray-50" : "hover:border-gray-400"
                } bg-white p-2 rounded-lg border text-gray-500 w-full focus:outline-blue-500 placeholder:text-gray-300 placeholder:text-sm`}
              />
            <div><FormErrors formErrors={errmsg}/></div>
            </div>
          </div>
        );

      case "textarea":
        return (
          <div className={`${className} flex flex-col mb-4 mx-1`}>
            <label className="mb-2 text-gray-500">{title}</label>
            <textarea
              className={`${
                disabled ? "bg-gray-50" : "hover:border-gray-400"
              } bg-white p-2 rounded-lg border text-gray-500 w-full focus:outline-blue-500 h-40 placeholder:text-gray-300 placeholder:text-sm`}
              placeholder={`${translate.component.input_placeholder} ${title.toLowerCase()}`}
              // placeholder={`insert ${title.toLowerCase()}`}
              name={name}
              id={id}
              value={value}
              onChange={onChange}
              disabled={disabled}
              style={{height: `${height}`}}
            />
            <div><FormErrors formErrors={errmsg}/></div>
          </div>
        );

      case "ckeditor":
        return (
          <div className={`${className} flex flex-col mb-4 mx-1`}>
            <label className="mb-2 text-gray-500">{title}</label>
            <div>
              <CKEditor
                className="ck-content"
                config={editorConfiguration}
                editor={ClassicEditor}
                data={value}
                onChange={onChange}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  // console.log("Editor is ready to use!", editor);
                }}
                onBlur={(event, editor) => {
                  // console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  // console.log("Focus.", editor);
                }}
              />
            </div>
            <div><FormErrors formErrors={errmsg}/></div>
          </div>
        );

      default:
        break;
    }
  };

  return <>{TextInputItem()}</>;
};

export default TextInput;
