const DateTimeFormats = ({datez,type}) => {
    var timezone = 'en-US';
    // var datetimeoption = '{"year":"numeric", "month": "long", "day": "2-digit", "hour": "numeric", "minute": "numeric", "second": "numeric", "hour12": false, "timeZone": "Asia/Singapore"}';
    var datetimeoption = '{';
    if(type === "datetime-short") { 
        // datetimeoption = '{"year":"numeric", "month": "short", "day": "2-digit", "hour": "numeric", "minute": "numeric", "second": "numeric", "hour12": false, "timeZone": "'+timezoneFormat+'"}';
        datetimeoption += '"year":"numeric", "month": "short", "day": "2-digit", "hour": "numeric", "minute": "numeric", "second": "numeric", "hour12": false';
    } else if(type === "datetime-long") { 
        datetimeoption += '"year":"numeric", "month": "long", "day": "2-digit", "hour": "numeric", "minute": "numeric", "second": "numeric", "hour12": false';
    } else if(type === "date-long") { 
        datetimeoption += '"year":"numeric", "month": "long", "day": "2-digit", "hour12": false';
    } else if(type === "date-short") { 
        datetimeoption += '"year":"numeric", "month": "short", "day": "2-digit", "hour12": false';
    } else if(type === "time-24") { 
        datetimeoption += '"hour": "numeric", "minute": "numeric", "hour12": false';
    } else if(type === "time-24-second") { 
        datetimeoption += '"hour": "numeric", "minute": "numeric", "second": "numeric", "hour12": false';
    } else if(type === "time-12") { 
        datetimeoption += '"hour": "numeric", "minute": "numeric", "hour12": true';
    } else if(type === "time-12-second") { 
        datetimeoption += '"hour": "numeric", "minute": "numeric", "second": "numeric", "hour12": true';
    } else if(type === "month-short") { 
        datetimeoption += '"month": "short", "hour12": false';
    } else if(type === "month-long") { 
        datetimeoption += '"month": "long", "hour12": false';
    } else if(type === "year-long") { 
        datetimeoption += '"year":"numeric", "hour12": false';
    } else if(type === "yearmonth-short") { 
        datetimeoption += '"year":"numeric", "month": "short", "hour12": false';
    } else {
        datetimeoption += '"year":"numeric", "month": "long", "day": "2-digit", "hour": "numeric", "minute": "numeric", "second": "numeric", "hour12": false';
    }

    // datetimeoption += ', "timeZone": "Asia/Singapore"';
    datetimeoption += '}';

    var date_ob = datez;
    var resDateTimeFormat = new Intl.DateTimeFormat(timezone, JSON.parse(datetimeoption)).format(new Date(date_ob));
    return resDateTimeFormat;
}
export default DateTimeFormats;