import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setLoading } from "../utils/utilSlice";
import '../../components/config';

const headersParams = global.config.headersparam;
const keyAuth = global.config.typecode[0]+global.config.authBearerKey;
let fetchUrl = `${global.config.urlLink}/translatetochinese`;
export const translatingWords = createAsyncThunk(
  "translate",
  async (data, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    headersParams.Authorization = keyAuth;
    const response = await fetch(fetchUrl, {
      method: "POST",
      headers: headersParams,
      body: JSON.stringify(data),
    });
    const jsonData = await response.json();
    if (response.status < 200 || response.status >= 300) {
      dispatch(setLoading(false));
      return rejectWithValue(jsonData);
    } else {
      dispatch(setLoading(false));
      return jsonData;
    }
  }
);

export const cnTranslateResult = createAsyncThunk("cntranslate", async () => {
  return {};
});

const initialState = {
  lang: "en",
  cnTranslate: null,
  wordtranslate: {
    sidebar: {
      category1: "Access Management",
      submenu1_1: "Distributor List",
      submenu1_Id1: "Distributor ID",
      submenu1_2: "Messages From Distributor",
      submenu1_3: "Product List",
      submenu1_4: "Market List",
      submenu1_5: "Users List",
      submenu1_6: "Banner List",
      submenu1_7: "Country List",
      submenu1_8: "User Login Report",
      submenu1_9: "Compliance Declaration",
      category2: "Distributor Management",
      submenu2_1: "Data Analysis",
      submenu2_2: "Objective & KPIs",
      submenu2_3: "Marketing Material",
      submenu2_4: "Trainings",
      submenu2_5: "Key Contact",
      submenu2_6: "Pipeline Review",
      submenu2_7: "Business Plan",
      submenu2_8: "Distributor Map (A.P.A.C)",
      submenu2_9: "Training Participants",
      submenu2_10: "Marketing Campaign",
      button_mail: "Email Veolia's Team",
      button_profile: "Edit Profile",
      logout: "Logout",
    },
    login: {
      title: "Welcome to the Distributor Portal",
      subtitle: "Sign in to stay connected.",
      label_textInput1: "Email",
      label1: "Account Verification",
      label2:
        "Please enter the verification code that has just been sent to the following email address",
      label4: "You are not successfully login.",
      label5: "Please contact administrator for more information",
      label_error1: "email need to be filled",
      placeholder1: "insert email",
      label_textInput2: "Password",
      label_error2: "password need to be filled",
      label_textInput3: "Verification code",
      label_error3: "code need to be filled",
      forgot_password: "Forgot Password",
      modal_title1: "Forgot Password",
      modal_textInputLabel1: "Email",
      modal_button1: "Request Password",
      modal_desc1: "Successfully Sent",
      modal_desc2: "Your password has been successfully sent to your Email.",
      modal_desc3: "Please check you email.",
      modal_error1: "invalid email addrress",
      modal_error2: "email need to be filled",
      button1: "Sign In",
      button2: "Submit",
      userlevel_name: ["Administrator","Veolia's Admin","Distributor Manager","Distributor"]
    },
    profile: {
      title: "My Profile",
      label1: "Full Name",
      label2: "Position",
      label3: "Phone",
      label4: "Current Password",
      label5: "New Password",
      label6: "Confirm New Password",
      label7: "Company",
      label8: "Country",
      label9: "Email",
      error: "full name need to be filled",
      error1: "current password need to be filled",
      error2: "new password need to be filled",
      error3: "confirm new password need to be filled",
      error4:
        "the new password do not match with the confirmation new password",
      error5:
        "the new password do not match with the confirmation new password",
      error6: "password should be at least 6 characters",
      error7: "incorrect password",
      error8: "user not found",
      button1: "Edit Password",
      button2: "Edit Profile",
      button3: "Logout",
    },
    distributor_list: {
      title: "Distributor List",
      column_1: "Distributor Name",
      column_2: "Country",
      column_3: "Territory",
      column_4: "Region",
      button1: "Mail",
      alert_delete: "Are you sure you want to delete this distributor ?"
    },
    distributor_create: {
      title: "Create Distributor",
      button1: "Create Distributor",
      text_input1: "Distributor Name",
      text_input2: "Country",
      text_input3: "Territory",
      text_input4: "Market(s)",
      text_input5: "Product(s)",
      text_input6: "Contact Name",
      text_input7: "Contact Email",
      text_input8: "Position",
      text_input9: "Contact Number",
      text_input10: "Website",
      text_input11: "Distribution Case",
      text_input12: "Distribution Manager",
      text_input13: "Distribution Case Remarks",
      text_input14: "Region",
      text_input15: "Distribution Agreement",
      text_input16: "NDA",
      text_input17: "Effective Date of DA",
      text_input18: "Key Contacts",
      text_input19: "Address",
      text_input20: "Compliance Declaration",
      error1: "distributor name need to be filled", 
      error2: "country need to be filled", 
      error3: "market need to be filled", 
      error4: "product need to be filled",
      error5: "region need to be filled",
      error6: "territory need to be filled",
      label1: "Add Files",
      label2: "Local",
      label3: "Remove",
      label4: "Eng",
      label5: "Estimated Unit Volume",
      column_1: "Standard Product",
    },
    contact_us: {
      title: "Messages From Distributor",
      column_1: "Date Request",
      column_2: "Contact Information",
      column_3: "Distributor",
      column_detail_1: "Name",
      column_detail_2: "Email",
      column_detail_3: "Phone",
      modal_title_1: "Message",
      modal_label_1: "Distributor",
      modal_label_2: "Name",
      modal_label_3: "Email",
      modal_label_4: "Phone",
      modal_label_5: "Message",
    },
    contact_us_distributor:{
      title: "Email Veolia's Team",
      text_input1: "Name",
      text_input2: "Email",
      text_input3: "Phone Number",
      text_input4: "Message",
      text_input5: "For Distributor",
      label1: "Successfully Sent",
      label2: "Your message has been successfully sent to Veolia's Team.",
      button1: "Send Message",
      error1: "name need to be filled",
      error2: "email need to be filled",
      error3: "message need to be filled",
      error4: "distributor need to be filled",
    },
    product_list: {
      title: "Product List",
      column_1: "Product Name",
      alert_delete: "Are you sure, you want to delete this product ?"
    },
    product_detail: {
      title1: "Create Product",
      title2: "Edit Product",
      title3: "Product",
      button1: "Create Product",
      button2: "Upload Product Image",
      text_input1: "Product Name",
      text_input2: "Product Description",
      placeholder2: "Specification Product",
      label1: "Product Thumbnail",
      error1: "product name need to be filled",
    },
    market_list: {
      title: "Market List",
      column_1: "Market Name",
      alert_delete: "Are you sure, you want to delete this market ?"
    },
    market_detail: {
      title1: "Create Market",
      title2: "Edit Market",
      title3: "Market",
      text_input1: "Market Name",
      button1: "Create Market",
      error1: "market name need to be filled",
    },
    user_role: {
      superadmin: "Super Admin",
      admin: "Veolia Admin",
      manager: "Distributor Manager",
      user: "Distributor"
    },
    user_list: {
      title: "User List",
      filter_role: "All Role",
      column_1: "Name",
      column_2: "Email",
      column_3: "Country",
      column_4: "Role",
      alert_delete: "Are you sure, you want to delete this user?"
    },
    user_detail: {
      title1: "Add User",
      title2: "Edit User",
      title3: "User",
      text_input1: "Full Name",
      text_input2: "Phone",
      text_input3: "Position",
      text_input4: "Email",
      text_input5: "Role",
      text_input6: "Country",
      text_input7: "Distributor",
      error1: "full name need to be filled",
      error2:  "email need to be filled",
      error3:  "role need to be filled",
      error4:  "country need to be filled",
      error5: "distributor need to be filled",
      label1: "Access Status",
      label2: "Join Since",
      label3: "Last Login",
      label4: "Created By",
    },
    data_analysis: {
      title: "Data Analysis",
      title_card1: "Total Quotes",
      title_card2: "Total Units",
      title_card3: "Total Weighted Unit",
      title_card4: "Total Selling Price",
      title_graph1: "Selling Price Vs Product",
      title_graph2: "Selling Price Vs Market",
      title_dataset1: "Product vs Selling Price",
      title_dataset2: "Market vs Selling Price",
    },
    objective_kpi: {
      title: "Objective & KPI",
      subtitle: "Select Distributor",
      column_1: "Distributor Name",
    },
    objective_kpi_details: {
      label1: "KPIs",
      label2: "Target Total Unit",
      label3: "Standard Product",
      alert_delete1: "Are you sure, you want to delete this KPI item ?",
      alert_delete2: "Are you sure, you want to delete this target item ?"
      
    },
    objective_kpi_modal: {
      title_kpi1: "Create KPI",
      title_kpi2: "Edit KPI",
      title_target1: "Create Target",
      title_target2: "Edit Target",
      text_input1: "Title KPI",
      text_input2: "Description",
      text_input3: "Sort Number",
      text_input4: "Product",
      text_input5: "Year",
      text_input6: "Target Unit",
      text_input7: "Start Date",
      text_input8: "End Date",
      label_tag1: "Target",
      label_tag2: "KPI",
      button1: "Create Target",
      button2: "Create KPI",
      error1: "description need to be filled",
      error2: "sort number need to be filled",
      error3: "start date need to be filled",
      error4: "end date need to be filled",
      error5: "target unit need to be filled",
      error6: "product need to be filled",
      error7: "year need to be filled"
    },
    marketing_material: {
      title: "Marketing Material",
      subtitle: "Products",
      label1: "Showing",
      label2: "product",
      label3: "products",
      label4: "no product",
      label_error: "There is no material for marketing",
    },
    marketing_material_detail: {
      title: "Marketing Material",
      button1: "download",
      alert_delete: "Are you sure you want to remove this file ?"
    },
    marketing_material_create: {
      title: "Create Material",
      text_input1: "Files",
      text_input2: "Upload your file",
      text_input3: "Product Name",
      text_input4: "Category",
      text_input5: "Distributor",
      button1: "Create Material",
      button2: "Upload File",
      cathMaterial: global.config.cathmaterial
    },
    training: {
      title: "Training",
      column_1: "Training Name",
      column_2: "Training URL",
      column_3: "Distributor",
      button1: "Link",
      alert_delete: "Are you sure, you want to delete this training ?"
    },
    training_detail: {
      column_1: "No",
      column_2: "Name",
      column_3: "Email",
      column_4: "Position",
      column_5: "Action",
      title: "Create Training",
      button1: "Create Training",
      text_input1: "Distributor",
      text_input2: "Training Name",
      text_input3: "Link URL",
      text_input4: "Description",
      label1: "Participant(s)",
      error1: "training name need to be filled",
      error2: "distributor need to be filled",
      alert_delete: "Are you sure, you want to delete this training user ?"
    },
    training_participant: {
      title1: "Add Participant",
      title2: "Edit Participant",
      title3: "Participant",
      text_input1: "Name",
      text_input2: "Email",
      text_input3: "Position",
      button1: "Add Participant",
      error1: "name need to be filled",
      error2: "email need to be filled"
    },
    key_contact: {
      title: "Key Contact",
      column_1: "Name",
      column_2: "Email Address",
      column_3: "Role",
      column_4: "Product",
      column_5: "Distributor",
      label1: "Distributor",
      label2: "Veolia",
      alert_delete: "Are you sure, you want to delete this contact ?"
    },
    key_contact_modal: {
      title1: "Add Key Contact",
      title2: "Key Contact",
      title3: "Edit Key Contact",
      text_input1: "Contact Name",
      text_input2: "Distributor",
      text_input3: "Contact Email",
      text_input4: "Product(s)",
      text_input5: "Contact Phone",
      text_input6: "Role",
      error1: "contact name need to be filled",
      error2: "contact email need to be filled",
      error3: "product need to be filled",
      error4: "distributor need to be filled",
      button1: "Insert",
    },
    pipeline: {
      title1: "Pipeline Review",
      filter_year: "All Year",
      label1: "KPI Year",
      label2: "All",
      label3: "the Year",
      label4: "Pipeline Status",
      label5: "KPI",
      graph_label1: "Target",
      graph_label2: "Won",
      graph_label3: "Remaining",
      graph_label4: "# of Project(s)",
      graph_label5: "# of Unit Product(s)",
      column_1: "Project Name",
      column_2: "Client",
      column_3: "Market",
      column_4: "Product", 
      column_5: "Model",
      column_6: "Price",
      column_7: "Probability (%)", 
      column_8: "Status",
      column_9: "Qty",
      column_10: "Close Date",
      alert_delete: "Are you sure, you want to delete this pipeline?",
      pipeline_status: global.config.pipelinestatus,
      filter_year_create: "All Date Created",
      filter_year_close: "All Close Date"
    },
    pipeline_detail: {
      title: "Create Pipeline Review",
      button1: "Create Pipeline",
      text_input1: "Distributor",
      text_input2: "Project Name",
      text_input3: "Project Country",
      text_input4: "Market",
      text_input5: "Client",
      text_input6: "Product",
      text_input7: "Model",
      text_input8: "Quantity",
      text_input9: "Selling Price",
      text_input10: "Probability (%)",
      text_input11: "Weighed Value",
      text_input12: "Expect PO Date",
      text_input13: "PO Date",
      text_input14: "Application",
      text_input15: "Status",
      text_input16: "Remarks",
      text_input17: "URL Skywater",
      text_input18: "Comment",
      text_input19: "Currency",
      text_input20: "Expected Close Date",
      error1: "project name need to be filled",
      error2: "country need to be filled",
      error3: "market need to be filled",
      error4: "product need to be filled",
      error5: "status need to be filled",
      error6: "distributor need to be filled",
      error7: "expected close date need to be filled",
    },
    business_plan: {
      title: "Business Plan",
      label1: "created on :",
      label2: "for distributor",
      button1: "Read more...",
      alert_delete: "Are you sure you want to delete the business plan item ?"
    },
    business_plan_create: {
      title1: "Create Business Plan",
      title2: "Edit",
      button1: "Create Plan",
      text_input1: "Distributor",
      text_input2: "Title",
      text_input3: "Content",
      error1: "title need to be filled",
      error2: "content need to be filled",
      error3: "distributor need to be filled",
    },
    business_plan_detail: {
      title: "Business Plan",
      label1: "created on",
      label2: "for",
    },
    country_list: {
      title: "Country List",
      column_1: "Country Name",
      column_2: "Region",
      column_3: "Currency",
      column_4: "Symbol",
      alert_delete: "Are you sure, you want to delete this country ?"
    },
    country_detail: {
      title1: "Create Country",
      title2: "Edit Country",
      title3: "Country",
      text_input1: "Country Name",
      text_input2: "Region",
      text_input3: "Currency",
      text_input4: "Symbol Currency",
      text_input5: "Veolia's Entity",
      text_input6: "Veolia's Address",
      button1: "Create Country",
      error1: "country name need to be filled",
      error2: "region need to be filled",
      error3: "currency need to be filled",
      error4: "symbol currency need to be filled",
      error5: "entity need to be filled",
      error6: "address need to be filled",      
    },
    banner_list: {
      title: "Banner List",
      column_1: "File Name",
      column_2: "URL",
      column_3: "Sort Id",
      alert_delete: "Are you sure, you want to delete this banner ?"
    },
    banner_detail: {
      label1: "Create Banner",
      label2: "Edit Banner",
      label3: "Banner",
      label4: "Picture dimensions is 1200 x 560",
      text_input1: "Picture",
      text_input2: "Upload Image",
      text_input3: "Link Url",
      text_input4: "Sort Number",
      button1: "Create Banner",
      error1: "link url need to be filled",
      error2: "target link need to be filled",
      error3: "sort number need to be filled",
      error4: "file need to be uploaded",
    },
    marketing_campaign: {
      title: "Marketing Campaign",
      column_1: "Campaign Name",
      column_2: "Product",
      column_3: "Type",
      column_4: "Distributor",
      alert_delete: "Are you sure, you want to delete this campaign ?"
    },
    marketing_campaign_detail: {
      title: "Create Campaign ",
      text_input1: "Distributor",
      text_input2: "Campaign Name",
      text_input3: "Industry",
      text_input4: "Contact Name",
      text_input5: "Contact Email",
      text_input6: "Contact Number",
      text_input7: "Material Files",
      text_input8: "Campaign Date",
      text_input9: "Product",
      text_input10: "Type",
      text_input10_check1: "EDM",
      text_input10_check2: "Social Media",
      text_input11: "Campaign Purposes",
      text_input12: ["Content (ms.docs)", "Photo (jpeg/png)", "Video (mp4)", "Others"],
      text_input13: "Delivery Date",
      section_title1: "Contact Person Information",
      section_title2: "Campaign Information",
      button1: 'Create Campaign',
      button2: 'Mark as Complete',
      label_notes1: "All marketing collaterals need to be requested minimum 3 weeks before actual campaign date",
      error1: "distributor need to be filled",
      error2: "campaign name need to be filled",
      error3: "industry need to be filled",
      error4: "contact name need to be filled",
      error5: "contact email need to be filled",
      error6: "contact number need to be filled",
      error7: "material files need to be filled",
      error8: "date need to be filled",
      error9: "product need to be filled",
      error10: "type need to be filled",
      error11: "purpose need to be filled",
      error12: "the selected date has been full booked. please choose another date",
      error13: "please choose minimum 3 weeks before actual campaign date",
    },
    compliance: {
      label1: "Please complete the annual compliance declaration form to comply with the Distributor Agreement.",
      label2: "Arrange for the company's authorized signatory to sign the compliance declaration form.",
      button1: "Download Compliance Declaration Form",
      button2: "Upload Compliance Declaration Form",
      error1: "Download failed"
    },
    compliance_form: {
      title: "Compliance Declaration ",
      text_input1: "Upload the signed document of compliance declaration",
      text_input2: ["File 1", "File 2", "File 3", "File 4"],
      text_input1b: "The signed document of compliance declaration",
      text_input3: "Waiting Approval",
      label1: "Supporting Document",
      label2: "Download Unsigned Compliance Declaration Form",
      label3a: "You have submitted the compliance declaration form.",
      label3b: "Your assigned Distributor Manager from Veolia will review the document and provide an update via email after the approval.",
      button1: "Upload Compliance Declaration Form",
      button2: "Click to Download",
      error1: "distributor need to be filled",
      error2: "file need to be uploaded",
    },
    compliance_list: {
      title: "Compliance Declaration",
      title_pop: "Reject Compliance Declaration",
      subtitle: "Select Distributor",
      column_1: "Distributor Name",
      column_2: "Year",
      column_3: "File",
      button1: "Approve",
      button2: "Reject",
      alert_delete: "Are you sure you want to delete this compliance declaration ?",
      alert_approve: "Are you sure you want to approve this compliance declaration ?",
      alert_reject: "Are you sure you want to reject this compliance declaration ?",
      status_compliance: global.config.compliancestatus,
      text_input1: "Reason to reject",
      label1: "For Distributor",
      error1: "reason need to be filled",
    },
    general: {
      button_save: "Save Changes",
      button_cancel: "Cancel",
      button_upload: "Upload File",
      button_submit: 'Submit',
      filter: "Filters",
      filter_status: "All Status",
      filter_distributor: "All Distributor",
      filter_country: "All Country",
      filter_market: "All Market",
      filter_region: "All Region",
      filter_product: "All Product",
      filter_year: "All Year",
      column_status: "Status",
      column_action: "Action",
      label_allow_img: "Browse your files .jpeg / .jpg / .png / .gif extensions.",
      label_nodata: "there is no records to display",
      region_list: global.config.regions
    },
    component: {
      input_placeholder: "insert",
      select_placeholder: "select"
    },
  },
  isLoading: false,
  errorMassage: null,
};

export const translateSlice = createSlice({
  name: "translate",
  initialState,
  reducers: {
    enLang: (state) => {
      state.lang = "en";
    },
    cnLang: (state) => {
      state.lang = "cn";
    },
    translatingToEn: (prevState) => ({
      ...prevState,
      wordtranslate: initialState.wordtranslate,
    }),
    clearTranslateCN: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(translatingWords.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(translatingWords.fulfilled, (state, action) => {
      state.isLoading = false;
      state.wordtranslate = action.payload.values;
      state.cnTranslate = action.payload.values;
    });
    builder.addCase(translatingWords.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(cnTranslateResult.fulfilled, (state, action) => {
      state.wordtranslate = state.cnTranslate;
    });
  },
});

// Action creators are generated for each case reducer function
export const { enLang, cnLang, translatingToEn, clearTranslateCN } = translateSlice.actions;

// Select state currentUser from slice
export const staticTranslate = (state) => state.translate.wordtranslate;
export const staticLoading = (state) => state.translate.isLoading;

export default translateSlice.reducer;
