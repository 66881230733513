import React from "react";

import { ILError404 } from "../../../assets";

const ErrorScreen404 = () => {
  return (
    <div className=" bg-gray-100 p-8 h-screen overflow-y-auto flex-1 justify-center items-center flex">
      <div className="self-center justify-center items-center gap-5 w-1/2">
        <img src={ILError404} width={150} className="mb-4 sm:mb-0 m-auto" />
        <div className=" text-ellipsis text-center">
          <h2 className=" text-3xl mb-2">Not Found</h2>
          <p className=" text-gray-400">
           We are sorry the page you're looking couldn't be found.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ErrorScreen404;
