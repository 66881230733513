import React, { useState, useEffect, useRef, useCallback } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../features/translate/translateSlice";
import Logouts from "../../../components/logout"; 
import openFiles from "../../../components/downloadurl";
import {
  Button,
  CustomSelect,
  TextInput,
  ToggleSwitch,
  CustomCheckbox,
  FormErrors,
  NoRecords
} from "../../../components";

import { ReactComponent as Loading } from "../../../assets/svg/loading.svg";
import { ReactComponent as LinkIcon } from "../../../assets/svg/popup-link.svg";
import { ReactComponent as Close } from "../../../assets/svg/close-icon.svg";
import { ReactComponent as CheckMark } from "../../../assets/svg/check-mark.svg";

const keyAuth = global.config.typecode[16] + global.config.authBearerKey;
const keyAuth2 = global.config.typecode[3] + global.config.authBearerKey;
const keyAuth3 = global.config.typecode[2] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const convertDate = (dt) => {
  const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
  return `${dt.getFullYear()}-${padL(dt.getMonth() + 1)}-${padL(
    dt.getDate()
  )} ${padL(dt.getHours())}:${padL(dt.getMinutes())}:${padL(
    dt.getSeconds()
  )}`;
};
const checkvalue = ["EDM","Social Media"];

const CampaignDetail = () => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector(state => state.translate.lang)
  const navigate = useNavigate();
  const paramsLoc = useLocation();
  const [type, setType] = useState("");
  const [detaildata, setDetailData] = useState({});
  const [listDistributor, setListDistributor] = useState([]);
  const [listProduct, setListProduct] = useState([]);
  const [distributorDropdown, setDistributorDropdown] = useState([]);
  const [productDropdown, setProductDropdown] = useState([]);
  const [selected, setSelected] = useState({
    distributor_id: "",
    product_id: "",
  });
  const [campaigntype, setCampaignType] = useState({
    "campaign_type_1" : false,
    "campaign_type_2" : false,
  });
  
  const [formDisabled, setFormDisabled] = useState(true);

  const [dateInput, setDateInput] = useState(new Date());
  const [date2Input, setDate2Input] = useState(new Date());
  const [formErrors, setFormErrors] = useState({
    campaign_name: "",
    campaign_purpose: "",
    campaign_date: "",
    campaign_delivery_date: "",
    campaign_type: "",
    contact_fullname: "",
    contact_email: "",
    contact_phone_number: "",
    product_id: "",
    distributor_id: "",
    distributor_name:"",
    distributor_industry: ""
  });
  const [form, setForm] = useState({
    doc_id: 0,
    campaign_name: "",
    campaign_purpose: "",
    campaign_date: convertDate(new Date()),
    campaign_delivery_date: convertDate(new Date()),
    campaign_type: "",
    contact_fullname: "",
    contact_email: "",
    contact_phone_number: "",
    product_id: "",
    distributor_id: "",
    distributor_name:"",
    distributor_industry: "",
    campaign_status: global.config.campaignstatus[0],
    status: true,
    file_materi_1: "",
    file_materi_2: "",
    file_materi_3: "",
    file_materi_4: "",
  });
  const hiddenFileMateri_1 = useRef(null);
  const hiddenFileMateri_2 = useRef(null);
  const hiddenFileMateri_3 = useRef(null);
  const hiddenFileMateri_4 = useRef(null);

  const [fileMateri_1, setMateriFile_1] = useState({});
  const [fileMateri_2, setMateriFile_2] = useState({});
  const [fileMateri_3, setMateriFile_3] = useState({});
  const [fileMateri_4, setMateriFile_4] = useState({});

  const [loading, setLoading] = useState({
    file_materi_1: false,
    file_materi_2: false,
    file_materi_3: false,
    file_materi_4: false
  });
  const handleOpenFile = async (url) => {
    let [resultUrl] = await Promise.all([openFiles(url,keyAuth)]);
    if(resultUrl !== "" & resultUrl !== undefined) {
      window.open(resultUrl,'_blank');
    }
  }
  const handleBack = () => {
    navigate("/campaign", {
      state: { dataref: paramsLoc.state.filter },
    })
  }
  const checkCampaignDate = (datez, docid) => {
    return new Promise((resolve) => {
      if(datez !== "") {
        var jsonData = {from_id: "cek-campaign-date", campaign_date: datez};
        if(docid !== "") { jsonData.doc_id = docid; }
        headersParams.Authorization = keyAuth;
        fetch(global.config.urlLink+"/cekcampaign", {
          method: "POST",
          headers: headersParams,
          body: JSON.stringify(jsonData),
        }).then((res) => {
            if (res.status === 200) return res.json();
        }).then((res) => {
            resolve(res.values);
        });
      } else {
        resolve([]);
      }
    })
  }
  const checkDeliveryDate  = (campaignDate, deliveryDate) => {
    return new Promise((resolve) => {
      var Difference_In_Time = campaignDate.getTime() - deliveryDate.getTime(); 
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
      if(Difference_In_Days < (7*3)) {
        setFormErrors((prevState) => ({ ...prevState, campaign_delivery_date : translate.marketing_campaign_detail.error13 }));
        resolve(false);
      } else {
        setFormErrors((prevState) => ({ ...prevState, campaign_delivery_date : "" }));
        resolve(true);
      }
    })
  }
  const toggleHandler = () => {
    if(formDisabled === false) {
      setForm((prevState) => ({
        ...prevState,
        status: form.status === 0 ? 1 : 0,
        campaign_status: form.status === 0 ? global.config.campaignstatus[0] : global.config.campaignstatus[1],
      }));
    }
  };
  const onChangeCheckbox = (e) => {
   var name = e.target.name;
   var inputid = e.target.id;
   var value = e.target.value;
   var status = e.target.checked;
   
   setCampaignType((prevState) => ({
      ...prevState,
      [inputid]: status,
    }));
    if(name === "campaign_type") {
      var strCampaignType = "";
      if(inputid === "campaign_type_1") { 
        if(status === true) { strCampaignType = value; }
        if(campaigntype.campaign_type_2 === true) { 
          if(strCampaignType !== "") { strCampaignType += ","; }
          strCampaignType += checkvalue[1]; 
        }
      } else if(inputid === "campaign_type_2") {
        if(campaigntype.campaign_type_1 === true) { strCampaignType = checkvalue[0]; }
        if(status === true) { 
          if(strCampaignType !== "") { strCampaignType += ","; }
          strCampaignType += value; 
        }
      }
      setForm((prevState) => ({
        ...prevState,
        [name]: strCampaignType,
      }));
      validateField(name, strCampaignType);
    }
  };
  const onChangeHandler = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };
  const onChangeSelect = (e, name, sel) => {
    var value = e.value; if(value === undefined) { value = e; }

    if(name=== "distributor_id") { 
      var distname = form.distributor_name;
      if(listDistributor.length > 0) {
        let getDistributor = listDistributor?.filter(
          (dist) => dist?.distributor_id === value
        );
        distname = getDistributor[0].distributor_name;
        setProductDropdown(JSON.parse(getDistributor[0].product_application));
      }
      setSelected((prevState) => ({
        ...prevState,
        [name]: e,
        product_id: ""
      }));
      setForm((prevState) => ({
        ...prevState,
        [name]: value,
        product_id: "",
        distributor_name: distname
      }));
    } else {
      setSelected((prevState) => ({
        ...prevState,
        [name]: e,
      }));
      setForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    validateField(name, value);
  };
  
  const onChangeDateHandler = async (val, name) => {
    if(val !== "" && val !== null) {
      if(name === "campaign_date") {
        document.getElementById("loading-screen").style.display = "block";
        await checkCampaignDate(val,form.doc_id)
        .then((res) => {
          document.getElementById("loading-screen").style.display = "none";
          if(res.length < 2) {
            setDateInput(val);
            var errDeliveryDate = checkDeliveryDate(val,new Date(form.campaign_delivery_date));
            if(errDeliveryDate === false) {
              setForm((prevState) => ({
                ...prevState,
                campaign_date: form.campaign_date
              }));
            } else {
              setForm((prevState) => ({
                ...prevState,
                campaign_date: convertDate(val),
              }));
            }
          } else {
            setDateInput(val);
            setForm((prevState) => ({
              ...prevState,
              campaign_date: "",
            }));
            setFormErrors((prevState) => ({ ...prevState, campaign_date : translate.marketing_campaign_detail.error12 }));
          }
        });
        
      } else if(name === "campaign_delivery_date") {
        setDate2Input(val);
        var errDeliveryDate = checkDeliveryDate(new Date(form.campaign_date),val);
        if(errDeliveryDate === false) {
          setForm((prevState) => ({
            ...prevState,
            campaign_delivery_date: form.campaign_delivery_date
          }));
        } else {
          setForm((prevState) => ({
            ...prevState,
            campaign_delivery_date: convertDate(val),
          }));
        }
      }
      
    }
    
  };
  
  const handleAction = async (campaignstat) => {
    if(formDisabled === true) {
      document.getElementById("loading-screen").style.display = "flex";
      var fetchUrl = global.config.urlLink+"/editcampaign";
      var dataJson = {};
      dataJson.doc_id=form.doc_id;
      dataJson.status=form.status;
      dataJson.campaign_status=campaignstat;
      dataJson.from_id="edit-status-campaign";
      dataJson.lang = chooseLang;
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(dataJson),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((res) => {
          document.getElementById("loading-screen").style.display = "none";
          if (res.status === "200") {
            setForm((prevState) => ({
              ...prevState,
              status: form.status,
              campaign_status: campaignstat,
            }));
            // navigate("/campaign", {
            //   state: { dataref: paramsLoc.state.filter },
            // })
          }
      }).catch((err) => { 
        document.getElementById("loading-screen").style.display = "none";
      });

      
    }
  }
  const handleUploadFileChange = async (e) => {
    if(e.target !== undefined) {
      var fieldname = e.target.name;
      var loadname = fieldname;
      var pdfFile = e.target.files[0];
      var fileName = e.target.files[0].name;
      
      if (!e) return;
      setLoading((prev) => ({ ...prev, [loadname]: true }));
      let fetchUrl = global.config.urlLink;
      headersParams.Authorization = keyAuth;
      const dataText = {
        fileName: fileName,
      };
      await fetch(fetchUrl + "/getsignedurl", {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(dataText),
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then(async (res) => {
          if (res.status === "200") {
            const urlUploadFile = res.values;
            await fetch(urlUploadFile, {
              method: "PUT",
              headers: {
                "content-type": "application/octet-stream",
              },
              body: pdfFile,
            }).then(async (res) => {
              let urlPDFFile = res.url.split("?")[0];
              var current_materi_file = {
                fileName: pdfFile.name,
                fileType: pdfFile.type,
                fileUrl: urlPDFFile,
                fileSize: pdfFile.size,
                fileTitle: "",
                fileId: ""
              };
              if(fieldname === "file_materi_1") {
                setMateriFile_1(current_materi_file);
              } else if(fieldname === "file_materi_2") {
                setMateriFile_2(current_materi_file);
              } else if(fieldname === "file_materi_3") {
                setMateriFile_3(current_materi_file);
              } else if(fieldname === "file_materi_4") {
                setMateriFile_4(current_materi_file);
              } 
              setForm((prevState) => ({
                ...prevState,
                // [fieldname]: urlPDFFile,
                [fieldname]: JSON.stringify(current_materi_file),
              }));
              setLoading((prev) => ({ ...prev, [loadname]: false }));
            });
          }
        });
        
    }
  };
  
  const removeFile = (type) => {
    if (type === "file1") {
      hiddenFileMateri_1.current.value = null;
      setMateriFile_1({});
      setForm((prevState) => ({
        ...prevState,
        file_materi_1: ""
      }));
    } else if (type === "file2") {
      hiddenFileMateri_2.current.value = null;
      setMateriFile_2({});
      setForm((prevState) => ({
        ...prevState,
        file_materi_2: ""
      }));
    } else if (type === "file3") {
      hiddenFileMateri_3.current.value = null;
      setMateriFile_3({});
      setForm((prevState) => ({
        ...prevState,
        file_materi_3: ""
      }));
    } else if (type === "file4") {
      hiddenFileMateri_4.current.value = null;
      setMateriFile_4({});
      setForm((prevState) => ({
        ...prevState,
        file_materi_4: ""
      }));
    }
  };
  const handleClickUpload = (cat) => {
    if (cat === "file1") {
      hiddenFileMateri_1.current.click();
    } else if (cat === "file2") {
      hiddenFileMateri_2.current.click();
    } else if (cat === "file3") {
      hiddenFileMateri_3.current.click();
    } else if (cat === "file4") {
      hiddenFileMateri_4.current.click();
    } 
  };
  const validateField = (fieldName, value, dtFErrors) => {
    let ferrors = "";
    switch (fieldName) {
      case "distributor_id":
        if (value > 0) { ferrors = ""; } 
        else { ferrors = translate.marketing_campaign_detail.error1; }
        break;
      case "campaign_name":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.marketing_campaign_detail.error2; }
        break;
      case "distributor_industry":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.marketing_campaign_detail.error3; }
        break;
      case "contact_fullname":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.marketing_campaign_detail.error4; }
        break;
      case "contact_email":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.marketing_campaign_detail.error5; }
        break;
      case "contact_phone_number":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.marketing_campaign_detail.error6; }
        break;
      case "campaign_date":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.marketing_campaign_detail.error8; }
        if(dtFErrors !== "") { ferrors = dtFErrors; }
        break;
      case "campaign_delivery_date":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.marketing_campaign_detail.error8; }
        if(dtFErrors !== "") { ferrors = dtFErrors; }
        break;
      case "product_id":
        if (value > 0) { ferrors = ""; } 
        else { ferrors = translate.marketing_campaign_detail.error9; }
        break;
      case "campaign_type":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.marketing_campaign_detail.error10; }
        break;
      case "campaign_purpose":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.marketing_campaign_detail.error11; }
        break;
      default:
        break;
    }
    setFormErrors((prevState) => ({ ...prevState, [fieldName]: ferrors }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if(form.distributor_id !== "" && form.campaign_name !== "" && form.distributor_industry !== "" && form.product_id !== "" && formErrors.campaign_date === "" && formErrors.campaign_delivery_date === ""  && form.campaign_type !== ""  && form.campaign_purpose !== ""  && form.contact_fullname !== ""  && form.contact_email !== ""  && form.contact_phone_number !== "") {
      document.getElementById("loading-screen").style.display = "flex";
      var dataJson = form;
      var fetchUrl = global.config.urlLink;
      if (type === "create") {
        fetchUrl += "/addcampaign";
        dataJson.from_id="add-campaign";
      } else {
        fetchUrl += "/editcampaign";
        dataJson.from_id="edit-campaign";
      }
      dataJson.lang = chooseLang;
      headersParams.Authorization = keyAuth;
      
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(dataJson),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((res) => {
          document.getElementById("loading-screen").style.display = "none";
          if (res.status === "200") {
            navigate("/campaign", {
              state: { dataref: paramsLoc.state.filter },
            })
          }
      }).catch((err) => { 
        document.getElementById("loading-screen").style.display = "none";
      });
    } else {
      var formField = [], ferrors = "";
      if(form.distributor_id === "") { formField.push("distributor_id"); }
      if(form.campaign_name === "") { formField.push("campaign_name"); }
      if(form.distributor_industry === "") { formField.push("distributor_industry"); }
      if(form.product_id === "") { formField.push("product_id"); }
      if(form.campaign_date === "") { formField.push("campaign_date"); ferrors = formErrors.campaign_date; }
      if(form.campaign_delivery_date === "") { formField.push("campaign_delivery_date"); ferrors = formErrors.campaign_delivery_date; }
      if(form.campaign_type === "") { formField.push("campaign_type"); }
      if(form.campaign_purpose === "") { formField.push("campaign_purpose"); }
      if(form.contact_fullname === "") { formField.push("contact_fullname"); }
      if(form.contact_email === "") { formField.push("contact_email"); }
      if(form.contact_phone_number === "") { formField.push("contact_phone_number"); }

      for ( var i = 0; i < formField.length; i++ ) { 
        var fieldname = formField[i];
        var fieldvalue = form[fieldname];
        validateField(fieldname, fieldvalue, ferrors); 
      }
    }
  }
  const getListId = (list,lid,name) => {
    let updListId = list?.filter(
      (listed) => listed?.value === Number(lid)
    );
    var distributor_name = "";
    if(updListId.length > 0) {
      setSelected((prevState) => ({
        ...prevState,
        [name]: updListId[0],
      }));
      distributor_name = updListId[0].label;
    } 
    if(name === "distributor_id") {
      setForm((prevState) => ({
        ...prevState,
        distributor_id: lid,
        distributor_name: distributor_name,
      }));
    } else {
      setForm((prevState) => ({
        ...prevState,
        [name]: lid,
      }));
    }
  };
  
  const loadListProduct = (pgtype) => {
    return new Promise((resolve) => {
      var datatext = '{';
      datatext += '"from_id":"list-all-product","stat_id":1';
      if(pgtype === "detail") { datatext += ',"lang":"'+chooseLang+'"'; }
      datatext += '}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth3;
      var fetchUrl = global.config.urlLink + "/listproduct";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            var updProduct = { };
            var updProductOthers = { };
            if(resJson.values.length > 0) {
              updProduct = resJson.values.map((prod) => ({
                value: prod.product_id,
                label: prod.product_name,
              }));
              var filterProductOthers = resJson.values.filter(
                (pr) => pr.product_name === "Others"
              );
              if(filterProductOthers.length > 0) {
                updProductOthers = {
                  value: filterProductOthers[0].product_id,
                  label: filterProductOthers[0].product_name,
                }
              }
            } 
            resolve([updProduct,updProductOthers]);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadListDistributor = (country,pgtype) => {
    return new Promise((resolve) => {
      
      var datatext = '{';
      datatext += '"from_id":"list-distributor"';
      if(pgtype === "detail") { datatext += ',"lang":"'+chooseLang+'"'; }
      else { datatext += ',"stat_id":1'; }
      if(country !== "") { datatext += ',"country_id":"'+country+'"'; }
      datatext += '}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth2;
      var fetchUrl = global.config.urlLink + "/listdist";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            var updDistributor = { };
            if(resJson.values.length > 0) {
              updDistributor = resJson.values.map((dist) => ({
                value: dist.distributor_id,
                label: dist.distributor_name,
              }));
            } 
            resolve([updDistributor,resJson.values]);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadDataCampaign = (id,pgtype) => {
    return new Promise((resolve) => {
      let datatext = '{';
      datatext += '"from_id":"detail-campaign"'
      datatext += ',"doc_id":'+id;
      if(pgtype !== "edit" && pgtype !== "create") { datatext += ',"lang":"'+chooseLang+'"'; }
      datatext += '}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth;
      var fetchUrl = global.config.urlLink + "/detailcampaign";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            resolve(resJson.values);
          } else if(resJson.status === global.config.errcodesess) {
            resolve([resJson.status]);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadData = async (id, countryid, distributorid,pgtype,campaigndate,campaigndeliverydate) => {
    document.getElementById("loading-screen").style.display = "flex";
    var productid = "", campaigntype="", filemateri1="", filemateri2="", filemateri3="", filemateri4="", distributorname="";
    var datastatus = true;
    var campaignstatus = "";
    var datauserstr = {};
    if(id !== undefined) {
      let [result1, result2, result3, result4] = await Promise.all([loadDataCampaign(id,pgtype), loadListDistributor(countryid,pgtype), loadListProduct(pgtype), checkCampaignDate(campaigndate,id)]);
      if(result1.length > 0) {
        if(result1[0] === global.config.errcodesess) { 
          var resultSO = await Logouts();
          if(resultSO.status === "200") {
            window.location.reload();
          }
        } else {
          if(result1[0].status === 1) { datastatus =true; } else { datastatus =false; } 
          
          distributorid = result1[0].id_distributor;
          distributorname = result1[0].distributor_name;
          productid = result1[0].id_product;
          
          if(result1[0].campaign_startdate !== null && result1[0].campaign_startdate !== "") { campaigndate = result1[0].campaign_startdate; }
          if(result1[0].campaign_deliverydate !== null && result1[0].campaign_deliverydate !== "") { campaigndeliverydate = result1[0].campaign_deliverydate; }
          if(result1[0].campaign_type_of_content !== null && result1[0].campaign_type_of_content !== "") { 
            campaigntype = result1[0].campaign_type_of_content; 
            setCampaignType({
              "campaign_type_1" : campaigntype.includes(checkvalue[0]),
              "campaign_type_2" : campaigntype.includes(checkvalue[1]),
            });
          }
          if(result1[0].campaign_status !== null && result1[0].campaign_status !== "") { campaignstatus = result1[0].campaign_status; }
          for(var l=0; l<result1.length; l++) {
            var filedata = {
              fileName: result1[l].campaign_filename,
              fileType: result1[l].campaign_filetype,
              fileUrl: result1[l].campaign_fileurl,
              fileSize: "",
              fileTitle: result1[l].campaign_filetitle,
              fileId: result1[l].campaign_fileid
            }
            if(result1[l].campaign_fileid !== null) {
              if(result1[l].campaign_filetype !== null && result1[l].campaign_filetype !== undefined && result1[l].campaign_filetype !== "") {
                if(result1[l].campaign_filetype === "application/msword" || result1[l].campaign_filetype === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                  setMateriFile_1(filedata); filemateri1 = JSON.stringify(filedata);
                } else if(result1[l].campaign_filetype.includes("image")) {
                  setMateriFile_2(filedata); filemateri2 = JSON.stringify(filedata);
                } else if(result1[l].campaign_filetype === "video/mp4") { 
                  setMateriFile_3(filedata); filemateri3 = JSON.stringify(filedata);
                } else {
                  setMateriFile_4(filedata); filemateri4 = JSON.stringify(filedata);
                }
              } else {
                if(l===0) { setMateriFile_1(filedata); filemateri1 = JSON.stringify(filedata); } 
                else if(l===1) { setMateriFile_2(filedata); filemateri2 = JSON.stringify(filedata); } 
                else if(l===2) { setMateriFile_3(filedata); filemateri3 = JSON.stringify(filedata); } 
                else if(l===3) { setMateriFile_4(filedata); filemateri4 = JSON.stringify(filedata); }
              } 
            }
          }

          datauserstr = {
            doc_id: result1[0].campaign_id,
            contact_fullname: result1[0].pic_name,
            contact_email: result1[0].pic_email,
            contact_phone_number: result1[0].pic_phone,
            distributor_industry: result1[0].distributor_industry,
            campaign_name: result1[0].campaign_name,
            campaign_purpose: result1[0].campaign_purpose,
            campaign_date: convertDate(new Date (campaigndate)),
            campaign_delivery_date: convertDate(new Date (campaigndeliverydate)),
            campaign_type: campaigntype,
            campaign_status: campaignstatus,
            product_id: productid,
            distributor_id: distributorid,
            distributor_name: distributorname,
            status: datastatus,
            file_materi_1: filemateri1,
            file_materi_2: filemateri2,
            file_materi_3: filemateri3,
            file_materi_4: filemateri4
          };
          setDetailData((prevState) => ({
            ...prevState,
            doc_id: result1[0].campaign_id,
            campaign_name: result1[0].campaign_name,
          }));
          
          
        }
        document.getElementById("loading-screen").style.display = "none";
      } else {
        datauserstr = {
          doc_id: 0,
          campaign_name: "",
          status: true,
          distributor_id: distributorid,
        };
        document.getElementById("loading-screen").style.display = "none";
      }
      var listOptProduct=[];
      if(result2[1].length > 0) {
        var sel_distributor = result2[1].filter(
          (dist) => dist.distributor_id === distributorid
        );
        listOptProduct = JSON.parse(sel_distributor[0].product_application);
      } else {
        listOptProduct = result3[0];
      }
      if(result4.length < 2) {
        setFormErrors((prevState) => ({ ...prevState, campaign_date : "" }));
      } else {
        setFormErrors((prevState) => ({ ...prevState, campaign_date : translate.marketing_campaign_detail.error12 }));
      }
      setForm(datauserstr);      
      setListProduct(result3[1]);
      setProductDropdown(listOptProduct);
      setListDistributor(result2[1]);
      setDistributorDropdown(result2[0]);
      if(distributorid !== "") { getListId(result2[0],distributorid,"distributor_id"); }
      if(productid !== "") { getListId(result3[0],productid,"product_id"); }
      if(campaigndate !== "") { setDateInput(new Date(campaigndate)); }
      else { setDateInput(new Date()); }
      if(campaigndeliverydate !== "") { setDate2Input(new Date(campaigndeliverydate)); }
      else { setDate2Input(new Date()); }

      checkDeliveryDate(new Date(campaigndate),new Date(campaigndeliverydate));
      
    } else {
      let [result2,result3,result4] = await Promise.all([loadListDistributor(countryid,pgtype), loadListProduct(pgtype), checkCampaignDate(campaigndate)]);
      if(result4.length < 2) {
        setFormErrors((prevState) => ({ ...prevState, campaign_date : "" }));
      } else {
        setFormErrors((prevState) => ({ ...prevState, campaign_date : translate.marketing_campaign_detail.error12 }));
      }
      setListProduct(result3[1]);
      setProductDropdown(result3[0]);
      setListDistributor(result2[1]);
      setDistributorDropdown(result2[0]);
      if(distributorid !== "") { getListId(result2[0],distributorid,"distributor_id"); }
      if(productid !== "") { getListId(result3[0],productid,"product_id"); }
      setDateInput(new Date());
      setDate2Input(new Date());
      checkDeliveryDate(new Date(),new Date());
      document.getElementById("loading-screen").style.display = "none";
    }
  }
  useEffect(() => {
    var docId = "",campaignName="", campaignDate=new Date(), campaignDeliveryDate=new Date(), pageType="detail",idDist="",idCntry="";
    if(localStorage.getItem("dulv")===global.config.conflevel[3]) { idDist = localStorage.getItem("dudst"); } 
    else if(localStorage.getItem("dulv")===global.config.conflevel[2]) { idCntry = localStorage.getItem("ducr"); }
    if(paramsLoc.state !== null) {
      docId = paramsLoc.state.data?.campaign_id;
      if(paramsLoc.state.data.campaign_name !== undefined) {campaignName = paramsLoc.state.data?.campaign_name; }
      if(paramsLoc.state.data.campaign_startdate !== undefined && paramsLoc.state.data.campaign_startdate !== null) {campaignDate = paramsLoc.state.data?.campaign_startdate; }
      if(paramsLoc.state.data.campaign_deliverydate !== undefined && paramsLoc.state.data.campaign_deliverydate !== null) {campaignDeliveryDate = paramsLoc.state.data?.campaign_deliverydate; }
      pageType = paramsLoc.state?.type;
    } 
    if(docId !== "") {
      var detaildata = {
        doc_id: docId,
        campaign_name: campaignName,
        campaign_date: campaignDate,
        campaign_delivery_date: campaignDeliveryDate,
        page_from: "campaign"
      }
      setDetailData(detaildata);
      setType(pageType);
      loadData(docId,idCntry,idDist,pageType,campaignDate,campaignDeliveryDate);
      var inpdisabled = true;
      if(pageType === "edit") {   
        if(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2]) { 
          inpdisabled = false;
        } 
      } else if(pageType === "create") {
        if(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[3]) { 
          inpdisabled = false;
        } 
      }
      setFormDisabled(inpdisabled);
     
    } else {
      // window.location.href = "/campaign";
    }
  }, []);
  
  return (
    <div className=" bg-gray-100 h-screen flex-1 p-8 overflow-y-auto">
      <div className="flex items-center gap-10 mb-8">
        <div className="flex items-center">
          <Button
            variant="icon"
            className="mr-5"
            icon="left"
            onClick={() => handleBack()}
            // onClick={() => navigate(-1)}
          />
          {type === "create" ? (
            <h2 className=" text-2xl">{translate.marketing_campaign_detail.title}</h2>
          ) : (
            <h2 className=" text-2xl">{detaildata.campaign_name}</h2>
          )}
        </div>
        {/* {(localStorage.getItem("dulv")!==global.config.conflevel[3])? */}
        <div className="flex items-center gap-x-4">
          <label
            className={`text-sm text-gray-400 ${
              !form.dist_status && "text-red-500"
            }`}
          >
            {global.config.campaignstatus[1]}
          </label>
          <ToggleSwitch
            id="status"
            checked={form.status}
            onClick={formDisabled ? null : toggleHandler}
          />
          <label
            className={`text-sm text-gray-400 ${
              form.status && "text-red-500"
            }`}
          >
            {global.config.campaignstatus[0]}
            {/* {global.config.generalstatus[0]} */}
          </label>
        </div>
        {/* : '' } */}
        {(form.campaign_status === global.config.campaignstatus[2]) && <div>
          <div className="flex flex-1 bg-emerald-600 rounded-md p-1  px-4">
            <div className="w-2/12 mr-2"><CheckMark className="w-4 h-4 fill-white" /></div>
            <div className="w-10/12 text-white text-xs " >{global.config.campaignstatus[2]}</div>
          </div>
        </div> 
        }
      </div>

      <form onSubmit={handleSubmit}>
        
        <div className="flex flex-1 justify-end gap-4 mb-8">
            {
            formDisabled === false? (
              <Button
                type="submit"
                variant="base"
                title={type === "create" ? translate.marketing_campaign_detail.button1 : translate.general.button_save}
                className="bg-red-500 text-white"
              />
            ) : (form.campaign_status === global.config.campaignstatus[0] && localStorage.getItem("dulv")!==global.config.conflevel[3])? (
              <Button
                type="button"
                variant="icon-base"
                title={translate.marketing_campaign_detail.button2}
                className="bg-green-800 text-white"
                icon="checkmark"
                onClick={() => handleAction(global.config.compliancestatus[2])}
              />
            )
            : <></>
          }
        </div>
        
        {/* -------- start - row 1 ------ */}
        {localStorage.getItem("dulv")!==global.config.conflevel[3] && 
          (<div>
              <CustomSelect
                  // title="Distributor"
                  title={translate.marketing_campaign_detail.text_input1}
                  name="distributor_id"
                  value={selected.distributor_id}
                  options={distributorDropdown}
                  onChange={(e) => onChangeSelect(e,"distributor_id", distributorDropdown)}
                  defaultValue={form.distributor_id}
                  disabled={formDisabled}
                  errmsg={formErrors.distributor_id}
                />
          
          </div>)
          }
        {/* -------- end - row 1 ------ */}
        {/* -------- start - row 2 ------ */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 ">
          <div>
            <div className="p-1 text-gray-500 border-x-0 border-t-0 border-b-2 mb-5 mt-4">{translate.marketing_campaign_detail.section_title2}</div>
            <TextInput
              variant="base"
              type="text"
              // title="Distributor Name"
              title={translate.marketing_campaign_detail.text_input2}
              name="campaign_name"
              id="campaign_name"
              value={form.campaign_name}
              onChange={onChangeHandler}
              disabled={formDisabled}
              errmsg={formErrors.campaign_name}
            />
            <div className="mt-6 mb-5 grid grid-cols-3 gap-x-2">
              <label className="block text-gray-500 mt-2">
                {translate.marketing_campaign_detail.text_input8}
              </label>
              <div className="col-start-2 col-end-6">
              <DatePicker
                wrapperClassName=" rounded-md"
                selected={"" || dateInput}
                // onChange={onChangeDateHandler}
                onChange={(e) => onChangeDateHandler(e,"campaign_date")}
                className={`w-32 p-2 rounded-md border text-gray-500`}
                disabled={formDisabled}
                name="campaign_date"
              />
              <div className="w-52"><FormErrors formErrors={formErrors.campaign_date}/></div>
              </div>
            </div>
            <TextInput
              variant="base"
              type="text"
              // title="Distributor Name"
              title={translate.marketing_campaign_detail.text_input3}
              name="distributor_industry"
              id="distributor_industry"
              value={form.distributor_industry}
              onChange={onChangeHandler}
              disabled={formDisabled}
              errmsg={formErrors.distributor_industry}
            />
            <CustomSelect
                // title="Product"
                title={translate.marketing_campaign_detail.text_input9}
                name="product_id"
                value={selected.product_id}
                options={productDropdown}
                onChange={(e) => onChangeSelect(e,"product_id", productDropdown)}
                disabled={formDisabled}
                errmsg={formErrors.product_id}
              />
            <div className="mt-6 mb-6 grid grid-cols-4 gap-x-2 ">
              <label className="block text-gray-500 mb-2">
                {translate.marketing_campaign_detail.text_input10}
              </label>
              <div className="col-start-2 col-end-6">
                <div className="flex flex-row gap-x-5 items-center">
                  <CustomCheckbox 
                    id="campaign_type_1"
                    name="campaign_type"
                    label={translate.marketing_campaign_detail.text_input10_check1}
                    onChange={(e) => onChangeCheckbox(e)}
                    value={checkvalue[0]}
                    checked={campaigntype.campaign_type_1}
                    disabled={formDisabled}
                    errmsg=""
                  />
                  <CustomCheckbox 
                    id="campaign_type_2"
                    name="campaign_type"
                    label={translate.marketing_campaign_detail.text_input10_check2}
                    onChange={(e) => onChangeCheckbox(e)}
                    value={checkvalue[1]}
                    checked={campaigntype.campaign_type_2}
                    disabled={formDisabled}
                    errmsg=""
                  />
                </div>
                <div><FormErrors formErrors={formErrors.campaign_type}/></div>
              </div>
              
            </div>
            <div className="mt-6 grid grid-cols-3 gap-x-2">
              <label className="block text-gray-500 mt-2">
                {translate.marketing_campaign_detail.text_input13}
              </label>
              <div className="col-start-2 col-end-6">
              <DatePicker
                wrapperClassName=" rounded-md"
                selected={"" || date2Input}
                // onChange={onChangeDateHandler}
                onChange={(e) => onChangeDateHandler(e,"campaign_delivery_date")}
                className={`w-32 p-2 rounded-md border text-gray-500`}
                disabled={formDisabled}
              />
              <div className="w-full">
                <FormErrors formErrors={formErrors.campaign_delivery_date}/>
                <p className="text-xs text-gray-500 italic mt-2 mb-8">{translate.marketing_campaign_detail.label_notes1}</p>
              </div>
              </div>
            </div>
            <TextInput
              type="text"
              variant="textarea"
              // title="Address"
              title={translate.marketing_campaign_detail.text_input11}
              name="campaign_purpose"
              id="campaign_purpose"
              value={form.campaign_purpose}
              onChange={onChangeHandler}
              disabled={formDisabled}
              height="100px"
              errmsg={formErrors.campaign_purpose}
            />
          </div>
          <div>  
            <div className="p-1 text-gray-500 border-x-0 border-t-0 border-b-2 mb-5 mt-4">{translate.marketing_campaign_detail.section_title1}</div>
            <TextInput
              type="text"
              variant="base"
              // title="Contact Name"
              title={translate.marketing_campaign_detail.text_input4}
              name="contact_fullname"
              id="contact_fullname"
              value={form.contact_fullname}
              onChange={onChangeHandler}
              disabled={formDisabled}
              // className="ml-5"
              errmsg={formErrors.contact_fullname}
            />
            <TextInput
              type="text"
              variant="base"
              // title="Contact Email"
              title={translate.marketing_campaign_detail.text_input5}
              name="contact_email"
              id="contact_email"
              value={form.contact_email}
              onChange={onChangeHandler}
              disabled={formDisabled}
              // className="ml-5"
              errmsg={formErrors.contact_email}
            />
            <TextInput
              type="text"
              variant="base"
              // title="Contact Number"
              title={translate.marketing_campaign_detail.text_input6}
              name="contact_phone_number"
              id="contact_phone_number"
              value={form.contact_phone_number}
              onChange={onChangeHandler}
              disabled={formDisabled}
              // className="ml-5"
              errmsg={formErrors.contact_phone_number}
            />
          </div>

        </div>
        {/* -------- end - row 2 ------ */}
        {/* -------- start - row 3 ------ */}
        <div>
          <div className="p-1 text-gray-500 border-x-0 border-t-0 border-b-2 mb-5 mt-4">{translate.marketing_campaign_detail.text_input7}</div>
            <div className="flex flex-col mb-4">
              {(type === "detail" && form.file_materi_1 === "" && form.file_materi_2 === "" && form.file_materi_3 === "" && form.file_materi_4 === "")?
              <div className="mt-4 mb-4 w-full items-center justify-center text-gray-500"> <NoRecords /></div>
                : <></>
              }
              {/* START - FILE 1  */}
              {((fileMateri_1.fileName === undefined || fileMateri_1.fileName === null || fileMateri_1.fileName === "") && type === "detail")?
               <></>
              :
              <div className="flex flex-col items-center bg-gray-200 rounded-lg border border-gray-300 justify-around w-full">
                <div className="w-full self-center flex items-center justify-center">
                  <label className="w-1/6 text-gray-500 p-3 mt-1">{translate.marketing_campaign_detail.text_input12[0]}</label>
                  {!fileMateri_1?.fileName ? (
                    <div className="w-4/6">
                      {loading.file_materi_1 ? (
                        <div className="justify-center items-center"><Loading className="w-10 h-10 animate-spin "/></div>
                      ) : (
                        <div className="flex p-2 w-full">
                          {type !== "detail" && (
                            
                            <div className="mr-6">
                              <Button
                                type="button"
                                variant="base"
                                // title="Upload Image"
                                title={translate.general.button_upload}
                                className="bg-gray-500 text-white"
                                onClick={() =>
                                  handleClickUpload("file1")
                                }
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : fileMateri_1?.fileName ? (
                    <div className="w-4/6">
                      {loading.file_materi_1 ? (
                        <div className="justify-center items-center"><Loading className="w-10 h-10 animate-spin "/></div>
                      ) : (
                        <div className="flex p-2 w-full ">
                          
                          {(fileMateri_1.fileName !== null && fileMateri_1.fileName !== "")?
                            <div className="mt-1 text-gray-500 cursor-pointer flex" onClick={() => handleOpenFile(fileMateri_1.fileUrl)}>
                              {fileMateri_1?.fileName}
                              <LinkIcon className=" w-4 h-4 ml-3 mt-1" />
                            
                            </div>
                          : <></>
                          }
                          {type !== "detail" && (
                            
                            <div className="mr-6 w-5 justify-items-end items-end">
                              
                              {/* <Button
                                type="button"
                                variant="icon"
                                icon="close"
                                onClick={() => removeFile("file1")}
                                // className=" absolute z-[999999] ml-1 mt-1"
                                style={
                                  type === "detail" ? { display: "none" } : null
                                }
                              /> */}
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                  ) : null}
                  <div className="w-1/6 flex md:flex md:flex-grow flex-row justify-end space-x-1 p-2">
                  {(fileMateri_1?.fileName && type !== "detail")?
                    <Close className="w-6 h-6 bg-gray-500 rounded-full cursor-pointer" 
                      onClick={() => removeFile("file1")}
                      />
                    : <></> 
                  }
                  </div>
                </div>
                <div><FormErrors formErrors={formErrors.file_url}/></div>
                <input
                  type="file"
                  ref={hiddenFileMateri_1}
                  name="file_materi_1"
                  onChange={handleUploadFileChange}
                  style={{ display: "none" }}
                  hidden
                  accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  // accept="application/pdf, image/png, image/gif, image/jpeg, image/jpg, image/tiff, application/msword, application/vnd.ms-powerpoint, text/plain, application/vnd.ms-excel, video/mp4, text/csv, application/zip"
                />
              </div>
              }
              {/* END - FILE 1  */}

              {/* START - FILE 2  */}
              {((fileMateri_2.fileName === undefined || fileMateri_2.fileName === null || fileMateri_2.fileName === "") && type === "detail")?
               <></>
              :
              <div className="flex flex-col items-center bg-gray-200 rounded-lg border border-gray-300 justify-around w-full mt-6">
                <div className="w-full self-center flex items-center justify-center">
                  <label className="w-1/6 text-gray-500 p-3 mt-1">{translate.marketing_campaign_detail.text_input12[1]}</label>
                  {!fileMateri_2?.fileName ? (
                    <div className="w-4/6">
                      {loading.file_materi_2 ? (
                        <div className="justify-center items-center"><Loading className="w-10 h-10 animate-spin "/></div>
                      ) : (
                        <div className="flex p-2 w-full">
                          {type !== "detail" && (
                            
                            <div className="mr-6">
                              <Button
                                type="button"
                                variant="base"
                                // title="Upload Image"
                                title={translate.general.button_upload}
                                className="bg-gray-500 text-white"
                                onClick={() =>
                                  handleClickUpload("file2")
                                }
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : fileMateri_2?.fileName ? (
                    <div className="w-4/6">
                      {loading.file_materi_2 ? (
                        <div className="justify-center items-center"><Loading className="w-10 h-10 animate-spin "/></div>
                      ) : (
                        <div className="flex p-2 w-full ">
                          
                          {(fileMateri_2.fileName !== null && fileMateri_2.fileName !== "")?
                            <div className="mt-1 text-gray-500 cursor-pointer flex" onClick={() => handleOpenFile(fileMateri_2.fileUrl)}>
                              {fileMateri_2?.fileName}
                              <LinkIcon className=" w-4 h-4 ml-3 mt-1" />
                            
                            </div>
                          : <></>
                          }
                        </div>
                      )}
                    </div>

                  ) : null}
                  <div className="w-1/6 flex md:flex md:flex-grow flex-row justify-end space-x-1 p-2">
                  {(fileMateri_2?.fileName && type !== "detail")?
                    <Close className="w-6 h-6 bg-gray-500 rounded-full cursor-pointer" 
                      onClick={() => removeFile("file2")}
                      />
                    : <></> 
                  }
                  </div>
                </div>
                <div><FormErrors formErrors={formErrors.file_url}/></div>
                <input
                  type="file"
                  ref={hiddenFileMateri_2}
                  name="file_materi_2"
                  onChange={handleUploadFileChange}
                  style={{ display: "none" }}
                  hidden
                  accept="image/png, image/gif, image/jpeg, image/jpg, image/tiff"
                  // accept="application/pdf, image/png, image/gif, image/jpeg, image/jpg, image/tiff, application/msword, application/vnd.ms-powerpoint, text/plain, application/vnd.ms-excel, video/mp4, text/csv, application/zip"
                />
              </div>
              }
              {/* END - FILE 2  */}

              {/* START - FILE 3  */}
              {((fileMateri_3.fileName === undefined || fileMateri_3.fileName === null || fileMateri_3.fileName === "") && type === "detail")?
               <></>
              :
              <div className="flex flex-col items-center bg-gray-200 rounded-lg border border-gray-300 justify-around w-full mt-6">
                <div className="w-full self-center flex items-center justify-center">
                  <label className="w-1/6 text-gray-500 p-3 mt-1">{translate.marketing_campaign_detail.text_input12[2]}</label>
                  {!fileMateri_3?.fileName ? (
                    <div className="w-4/6">
                      {loading.file_materi_3 ? (
                        <div className="justify-center items-center"><Loading className="w-10 h-10 animate-spin "/></div>
                      ) : (
                        <div className="flex p-2 w-full">
                          {type !== "detail" && (
                            
                            <div className="mr-6">
                              <Button
                                type="button"
                                variant="base"
                                // title="Upload Image"
                                title={translate.general.button_upload}
                                className="bg-gray-500 text-white"
                                onClick={() =>
                                  handleClickUpload("file3")
                                }
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : fileMateri_3?.fileName ? (
                    <div className="w-4/6">
                      {loading.file_materi_3 ? (
                        <div className="justify-center items-center"><Loading className="w-10 h-10 animate-spin "/></div>
                      ) : (
                        <div className="flex p-2 w-full ">
                          
                          {(fileMateri_3.fileName !== null && fileMateri_3.fileName !== "")?
                            <div className="mt-1 text-gray-500 cursor-pointer flex" onClick={() => handleOpenFile(fileMateri_3.fileUrl)}>
                              {fileMateri_3?.fileName}
                              <LinkIcon className=" w-4 h-4 ml-3 mt-1" />
                            
                            </div>
                          : <></>
                          }
                        </div>
                      )}
                    </div>

                  ) : null}
                  <div className="w-1/6 flex md:flex md:flex-grow flex-row justify-end space-x-1 p-2">
                  {(fileMateri_3?.fileName && type !== "detail")?
                    <Close className="w-6 h-6 bg-gray-500 rounded-full cursor-pointer" 
                      onClick={() => removeFile("file3")}
                      />
                    : <></> 
                  }
                  </div>
                </div>
                <div><FormErrors formErrors={formErrors.file_url}/></div>
                <input
                  type="file"
                  ref={hiddenFileMateri_3}
                  name="file_materi_3"
                  onChange={handleUploadFileChange}
                  style={{ display: "none" }}
                  hidden
                  accept="video/mp4"
                  // accept="application/pdf, image/png, image/gif, image/jpeg, image/jpg, image/tiff, application/msword, application/vnd.ms-powerpoint, text/plain, application/vnd.ms-excel, video/mp4, text/csv, application/zip"
                />
              </div>
              
              }
              {/* END - FILE 3  */}

              {/* START - FILE 4  */}
              {((fileMateri_4.fileName === undefined || fileMateri_4.fileName === null || fileMateri_4.fileName === "") && type === "detail")?
               <></>
              :
              <div className="flex flex-col items-center bg-gray-200 rounded-lg border border-gray-300 justify-around w-full mt-6">
                <div className="w-full self-center flex items-center justify-center">
                  <label className="w-1/6 text-gray-500 p-3 mt-1">{translate.marketing_campaign_detail.text_input12[3]}</label>
                  {!fileMateri_4?.fileName ? (
                    <div className="w-4/6">
                      {loading.file_materi_4 ? (
                        <div className="justify-center items-center"><Loading className="w-10 h-10 animate-spin "/></div>
                      ) : (
                        <div className="flex p-2 w-full">
                          {type !== "detail" && (
                            
                            <div className="mr-6">
                              <Button
                                type="button"
                                variant="base"
                                // title="Upload Image"
                                title={translate.general.button_upload}
                                className="bg-gray-500 text-white"
                                onClick={() =>
                                  handleClickUpload("file4")
                                }
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : fileMateri_4?.fileName ? (
                    <div className="w-4/6">
                      {loading.file_materi_4 ? (
                        <div className="justify-center items-center"><Loading className="w-10 h-10 animate-spin "/></div>
                      ) : (
                        <div className="flex p-2 w-full ">
                          
                          {(fileMateri_4.fileName !== null && fileMateri_4.fileName !== "")?
                            <div className="mt-1 text-gray-500 cursor-pointer flex" onClick={() => handleOpenFile(fileMateri_4.fileUrl)}>
                              {fileMateri_4?.fileName}
                              <LinkIcon className=" w-4 h-4 ml-3 mt-1" />
                            
                            </div>
                          : <></>
                          }
                        </div>
                      )}
                    </div>

                  ) : null}
                  <div className="w-1/6 flex md:flex md:flex-grow flex-row justify-end space-x-1 p-2">
                  {(fileMateri_4?.fileName && type !== "detail")?
                    <Close className="w-6 h-6 bg-gray-500 rounded-full cursor-pointer" 
                      onClick={() => removeFile("file4")}
                      />
                    : <></> 
                  }
                  </div>
                </div>
                <div><FormErrors formErrors={formErrors.file_url}/></div>
                <input
                  type="file"
                  ref={hiddenFileMateri_4}
                  name="file_materi_4"
                  onChange={handleUploadFileChange}
                  style={{ display: "none" }}
                  hidden
                  accept="application/pdf, image/png, image/gif, image/jpeg, image/jpg, image/tiff, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, text/plain, application/vnd.ms-excel, video/mp4, text/csv, application/zip"
                />
              </div>
              
              }
              {/* END - FILE 4  */}

            </div>
            
          </div>
          {/* -------- end - row 3 ------ */}
      </form>

    </div>
    
  );
};

export default CampaignDetail;
