import React from "react";

const Card = ({ children, className, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`${className} bg-white rounded-lg shadow-3xl ${
        onClick && "cursor-pointer"
      }`}
    >
      {children}
    </div>
  );
};

export default Card;
