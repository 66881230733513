let sessionUToken = "";
if(localStorage.getItem('dutk') !== null && localStorage.getItem('dutk') !== undefined) { sessionUToken = localStorage.getItem('dutk'); }
module.exports = global.config = {
    /* ##### LIVE ######## */
    // urlLink: 'https://bdistributor.asia.veolia.com',
    urlLink: 'https://dstp-fn-67brten4ra-as.a.run.app',
    apikey: "AIzaSyCVRa9ygPDPYzG9WSSecwbEOnpfvFQ--Sg",
    authdomain: "my-ist-vwt-dbp.firebaseapp.com", 
    asseturl: "https://adistributor.asia.veolia.com",
    authBearerKey: "kf2109y2991nv3re8e5v7s542w158u206f46qp61"+sessionUToken,
    
    timezone: 'en-US',
    datetimeoption: '{"year":"numeric", "month": "short", "day": "2-digit", "hour": "numeric", "minute": "numeric", "second": "numeric", "hour12": false, "timeZone": "Asia/Singapore"}',
    headersparam: {'Accept': 'application/json','Content-Type': 'application/json','Access-Control-Allow-Origin': '*'},
    webtitle: "Distributor Portal",
    conflevel: ['K82JG','J10CFD','H29MZD','C74MGW'],
    typecode: [
        "U79WF1","W41MK1","R23KL1","Q99GH1","N27DW1","M38SI1","X53FZ1","T64RC1","P83VD1","J10KB1",
        "K29HQ1","D53JB1","Y34KQ1","F93KW1","U37DR1","R32YT1","S53HJ1","C07LA1"
    ],
    pipelinestatus: ["Won", "Lost", "Ongoing", "No go", "Abandoned"],
    generalstatus: ["Active","Inactive"],
    regions: ["ANZ", "China", "Southeast Asia"],
    cathmaterial: ["Others","Service Package","Reference Lists","Questionnaires","Photo","Hydrex","Hubgrade","Webinar","Presentation","Video","Case Studies","Brochure","Datasheet","Logo"],
    errcodesess: "440",
    compliancestatus: ["Pending", "Pending Approval", "Completed", "Rejected", "Not Completed"],
    complianceaction: ["download", "upload", "approved", "rejected"],
    campaignstatus: ["Active", "Inactive", "Completed"]
}
