import { useEffect, useState } from "react";
import { Card } from "../../components";
import { StackedBarChart } from "../../components/Chart";
import "react-circular-progressbar/dist/styles.css";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../features/translate/translateSlice";
import Logouts from "../../components/logout"; 

// import { ReactComponent as Accent } from "../../assets/svg/accent.svg";
// import { ReactComponent as Work } from "../../assets/svg/work.svg";
// import { ReactComponent as Filter } from "../../assets/svg/filter.svg";
// import { ReactComponent as Market } from "../../assets/svg/folder-market.svg";

const keyAuth = global.config.typecode[7]+global.config.authBearerKey;
const keyAuth2 = global.config.typecode[3]+global.config.authBearerKey;
const headersParams = global.config.headersparam;

const DataAnalysis = () => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector((state) => state.translate.lang)
  const [progressData, setProgressData] = useState([{id: 0, title: "", persentg: 0, value: 0, strokeColor: "", iconCircle: ""}]);
  const [stackedData, setStackedData] = useState({labels: "", datasets: []});
  const [stacked2Data, setStacked2Data] = useState({labels: "", datasets: []});
  const [listDistributor, setListDistributor] = useState([]);
  const [filterData, setFilterData] = useState({statid:"",distributorid:"",countryid:""});  

  const handleSelect = async (e) => {
    document.getElementById("loading-screen").style.display = "flex";
    var name = e.target.name;
    var value = e.target.value;
    if(name === "distributorid") {
      let [result1a,result2a] = await Promise.all([loadPipelineReport(filterData.statid,value,filterData.countryid),loadPipelineBar(filterData.statid,value,filterData.countryid)]);
      calculateReport(result1a,result2a);
    } else if(name === "statid") {
      let [result1b,result2b] = await Promise.all([loadPipelineReport(value,filterData.distributorid,filterData.countryid),loadPipelineBar(value,filterData.distributorid,filterData.countryid)]);
      calculateReport(result1b,result2b);
    }
    setFilterData({ ...filterData, [name]: value });
    document.getElementById("loading-screen").style.display = "none";
    
  }

  const loadDistributor = (country) => {
    return new Promise(resolve => {
      if(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2]) {
        var datatext ='{';
        datatext += '"from_id":"list-distributor","stat_id":1';
        datatext += ',"lang":"'+chooseLang+'"'
        if(country !== "") { datatext += ',"country_id":"'+country+'"'; }
        datatext += '}';
        var data = JSON.parse(datatext); 
        headersParams.Authorization = keyAuth2;
        
        fetch(global.config.urlLink+"/listdist", {
          method: 'POST',
          headers: headersParams, 
          body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJson => {
            if(resJson.status === "200") {
              resolve(resJson.values);
            } else if(resJson.status === global.config.errcodesess) {
              resolve([resJson.status]);
            } else {
              resolve([]);
            }
        }).catch((err) => {
            resolve([]);
        });
      } else { resolve([]); }
    });
  }
  const loadPipelineBar = (statid,distid,cntryid) => {
    return new Promise(resolve => {
      var datatext ='{';
      datatext += '"from_id":"graph-analysis-prod", "status_id":1';
      datatext += ',"lang":"'+chooseLang+'"'
      if(distid !== "") { datatext += ',"dist_id":"'+distid+'"'; }
      if(cntryid !== "") { datatext += ',"country_id":"'+cntryid+'"'; }
      if(statid !== "") { datatext += ',"projectstat_id":"'+statid+'"'; }
      datatext += '}';
      var data = JSON.parse(datatext); 
      headersParams.Authorization = keyAuth;
      fetch(global.config.urlLink+"/listanalysis", {
        method: 'POST',
        headers: headersParams, 
        body : JSON.stringify(data)
      }).then(res => {
          if(res.status === 200) 
              return res.json() 
      }).then( resJson => {
          if(resJson.status === "200") {
            resolve(resJson.values);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  }

  const loadPipelineReport = (statid,distid,cntryid) => {
    return new Promise(resolve => {
      var datatext ='{';
      datatext += '"from_id":"count-analysis", "status_id":1';
      datatext += ',"lang":"'+chooseLang+'"'
      if(distid !== "") { datatext += ',"dist_id":"'+distid+'"'; }
      if(cntryid !== "") { datatext += ',"country_id":"'+cntryid+'"'; }
      if(statid !== "") { datatext += ',"projectstat_id":"'+statid+'"'; }
      datatext += '}';

      var data = JSON.parse(datatext); 
      headersParams.Authorization = keyAuth;
      fetch(global.config.urlLink+"/listanalysis", {
        method: 'POST',
        headers: headersParams, 
        body : JSON.stringify(data)
      }).then(res => {
          if(res.status === 200) 
              return res.json() 
      }).then( resJson => {
          if(resJson.status === "200") {
            resolve(resJson.values);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  }
  const calculateReport = (result1,result2) => {
    if(result1.total.length > 0) {
      var currencyProject = result1.total[0].projectCurrency; if(currencyProject === null) { currencyProject = ""; }
      var totalQuote = result1.total[0].totalQuote; if(result1.total[0].totalQuote === null) { totalQuote = 0; }
      var totalUnits = result1.total[0].totalUnits; if(result1.total[0].totalUnits === null) { totalUnits = 0; }
      var totalWeight = result1.total[0].totalWeight; if(result1.total[0].totalWeight === null) { totalWeight = 0; }
      var totalSellPrice = result1.total[0].totalSellingPrice; if(result1.total[0].totalSellingPrice === null) { totalSellPrice = 0; }

      var alltotalQuote = result1.alltotal[0].alltotalQuote; if(result1.alltotal[0].alltotalQuote === null) { alltotalQuote = 0; }
      var alltotalUnits = result1.alltotal[0].alltotalUnits; if(result1.alltotal[0].alltotalUnits === null) { alltotalUnits = 0; }
      var alltotalWeight = result1.alltotal[0].alltotalWeight; if(result1.alltotal[0].alltotalWeight === null) { alltotalWeight = 0; }
      var alltotalSellPrice = result1.alltotal[0].alltotalSellingPrice; if(result1.alltotal[0].alltotalSellingPrice === null) { alltotalSellPrice = 0; }

      var persentageQuote = Math.round((totalQuote/alltotalQuote)*100);
      var persentageUnits = Math.round((totalUnits/alltotalUnits)*100);
      var persentageWeight = Math.round((totalWeight/alltotalWeight)*100);
      var persentageSellPrice = Math.round((totalSellPrice/alltotalSellPrice)*100);

      // var progressTitle = ["Total Quotes","Total Units","Total Weighted Units","Total Selling Price"];
      var progressTitle = [`${translate.data_analysis.title_card1}`,`${translate.data_analysis.title_card2}`,`${translate.data_analysis.title_card3}`,`${translate.data_analysis.title_card4}`];
      var progressPersen = [persentageQuote,persentageUnits,persentageWeight,persentageSellPrice];
      var progressValue = [totalQuote, totalUnits, parseFloat(totalWeight).toFixed(2), currencyProject+" "+totalSellPrice.toLocaleString('en-US')];
      var progressColor = ["#3e98c7","#2a9601","#9605a0","#c83205"];
      var progressImage = ["Accent","icon2","icon3","icon4"];
      var progressdatastr = []; 
      for(var p=0; p<progressTitle.length; p++) {
        progressdatastr.push({id: p, title: progressTitle[p], persentg: progressPersen[p], value: progressValue[p], strokeColor: progressColor[p], iconCircle: progressImage[p]});
      }
      setProgressData(progressdatastr);
    }
      var dtset1 = {
        labels: result2.sellproduct.map((data) => data.product_name),
        datasets: [
          {
            label: translate.data_analysis.title_dataset1,
            data: result2.sellproduct.map((data) => data.totalSellingPrice),
            backgroundColor: "rgb(255, 99, 132)",
            barThickness: 30,
            maxBarThickness: 100,
          }
        ],
      };
      setStackedData(dtset1);
    
      var dtset2 = {
        labels: result2.sellmarket.map((data) => data.market_name),
        datasets: [
          {
            label: translate.data_analysis.title_dataset2,
            data: result2.sellmarket.map((data) => data.totalSellingPrice),
            backgroundColor: "rgb(75, 192, 192)",
            barThickness: 30,
            maxBarThickness: 100,
          }
        ],
      };
      setStacked2Data(dtset2);
  }
  const loadData2 = async (statid,distid,cntryid) => {
    // document.getElementById("loading-screen").style.display = "flex";
    // let [result1,result2,result3] = await Promise.all([loadPipelineReport(statid,distid,cntryid),loadPipelineBar(statid,distid,cntryid),loadDistributor(cntryid)]);
    let [result1,result2] = await Promise.all([loadPipelineReport(statid,distid,cntryid),loadPipelineBar(statid,distid,cntryid)]);
    if(result2) {
      calculateReport(result1,result2);
      setFilterData({ ...filterData, distributorid: distid, countryid: cntryid });
      // document.getElementById("loading-screen").style.display = "none";
    }
  }
  const loadData = async (statid,distid,cntryid) => {
    document.getElementById("loading-screen").style.display = "flex";
    let [result3] = await Promise.all([loadDistributor(cntryid)]);
    if(result3) {
      if(result3.length > 0 && result3[0] === global.config.errcodesess) { 
        var resultSO = await Logouts();
        if(resultSO.status === "200") {
          window.location.reload();
        }
      } else {
        setListDistributor(result3);
        if(distid === "" && result3.length > 0) {
          distid = result3[0].distributor_id;
        }
        loadData2(statid,distid,cntryid);
        
        // setFilterData({ ...filterData, distributorid: distid, countryid: cntryid });
        // document.getElementById("loading-screen").style.display = "none";
      }
    }
    document.getElementById("loading-screen").style.display = "none";
  }
  useEffect(() => {
    var idDist = "", idCntry="";
    if(localStorage.getItem("dulv")===global.config.conflevel[3]) { idDist = localStorage.getItem("dudst"); } 
    else if(localStorage.getItem("dulv")===global.config.conflevel[2]) { idCntry = localStorage.getItem("ducr"); }
    loadData("",idDist,idCntry);
  }, []);

  const dataProgressBar = progressData;
  var optDistributor = [];
  if(listDistributor !== undefined) {
    for(var c=0; c<listDistributor.length; c++) {
      optDistributor.push(<option value={listDistributor[c].distributor_id} key={listDistributor[c].distributor_id}>{listDistributor[c].distributor_name}</option>)
    }
  }
  var optProjectStatus = [];
  if(global.config.pipelinestatus?.length > 0) {
    for(var d=0; d<global.config.pipelinestatus.length; d++) {
      if(d<3) {
      optProjectStatus.push(<option value={global.config.pipelinestatus[d]} key={global.config.pipelinestatus[d]}>{translate.pipeline.pipeline_status[d]}</option>)
      }
    }
  }
  return (
    <div className="flex-1 p-8 bg-gray-100 h-screen overflow-y-scroll no-scrollbar">
      <div className=" mb-8">
        <h2 className="text-2xl">{translate.data_analysis.title}</h2>
      </div>
      <div className="bg-white shadow-3xl flex flex-1 p-4 gap-x-4 rounded-lg mb-2">
        <p className=" text-gray-400 font-bold">{translate.general.filter}: </p>
        {(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2])? 
        <select name="distributorid" id="distributorid" className="bg-white text-gray-400 outline-none" defaultValue=""
            value={filterData.distributorid} 
            onChange={handleSelect}
            
          >
            {/* <option value="">{translate.general.filter_distributor}</option> */}
            {optDistributor}
        </select>
        : '' }
        <select name="statid" id="statid" className="bg-white text-gray-400 outline-none" defaultValue=""
          value={filterData.statid} 
          onChange={handleSelect}
        >
          <option value="">{translate.general.filter_status}</option>
          {optProjectStatus}
        </select>
      </div>
      
      <div className="gap-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mb-8">
        { dataProgressBar !== undefined?
            dataProgressBar.map((progress, index) => (
              <Card
                key={index}
                className={`flex items-center justify-start  p-4`}
              >
                <div className="flex flex-col">
                  <label className="text-xs text-gray-400 mb-2">
                    {progress.title}
                  </label>
                  <p className="text-2xl">{progress.value}</p>
                </div>
              </Card>
            )) : ''
        }
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8">
      { stackedData !== undefined?
        <Card>
          <div className="p-5">
            <h4 className="text-lg">{translate.data_analysis.title_graph1}</h4>
          </div>
          <div className="p-10">
            <StackedBarChart chartData={stackedData} />
          </div>
        </Card>
       : '' }
      { stacked2Data !== undefined?
        <Card>
          <div className="p-5">
            <h4 className="text-lg">{translate.data_analysis.title_graph2}</h4>
          </div>
          <div className="p-10">
            <StackedBarChart chartData={stacked2Data} />
          </div>
        </Card> 
      : '' }
      </div>
    
    </div>
  );
};

export default DataAnalysis;
