import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  distributorFilter: { statid: "", countryid: "", marketid: "", regionid: "" },
  userFilter: { statid: "", countryid: "", distributorid: "", levelid: "" },
  trainingFilter: { statid: "", distributorid:"", countryid: "" },
  businessPlanFilter: { statid: "", distributorid:"", countryid:"" },
  dataAnalysFilter: { statid: "", distributorid: "", countryid: "" },
  keyContactFilter: { statid: "", distributorid: "", countryid: "" },
  pipelineFilter: { statid: "", distributorid: "", countryid: "", idyears: "", marketid: "", prjstat: "" }
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    onChangeFilterDistributor: (state, action) => {
      state.distributorFilter = { ...action.payload };
    },
    onChangeFilterUser: (state, action) => {
      state.userFilter = { ...action.payload };
    },
    onChangeFilterTraining: (state, action) => {
      state.trainingFilter = { ...action.payload };
    },
    onChangeFilterDataAnalysis: (state, action) => {
      state.dataAnalysFilter = { ...action.payload }
    },
    onChangeFilterBusinessPlan: (state, action) => {
      state.businessPlanFilter = { ...action.payload }
    },
    onChangeFilterKeyContact: (state, action) => {
      state.keyContactFilter = { ...action.payload }
    },
    onChangeFilterPipeline: (state, action) => {
      state.pipelineFilter = { ...action.payload }
    },
    clearFilters: () => initialState,
  },
});

export const {
  onChangeFilterDistributor,
  onChangeFilterUser,
  onChangeFilterTraining,
  onChangeFilterDataAnalysis,
  onChangeFilterBusinessPlan,
  onChangeFilterKeyContact,
  onChangeFilterPipeline,
  clearFilters,
} = filterSlice.actions;

export default filterSlice.reducer;
