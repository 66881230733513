import React from "react";
import { AiOutlineClose } from "react-icons/ai";

const Modal = ({
  children,
  title,
  onClick,
  onBackgroundClick,
  tags,
  className,
}) => {
  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto no-scrollbar">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="fixed top-0 left-0 h-screen w-screen"
            onClick={onBackgroundClick}
          ></div>
          <div
            className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-3xl ${className}`}
          >
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              {(title || tags || onClick) && (
                <div className="flex flex-1 justify-between items-center mb-4">
                  <div className="flex items-center">
                    {title && (
                      <div className=" break-words">
                        <h3
                          className="text-lg font-bold leading-6 text-gray-900 mr-2"
                          id="modal-title"
                        >
                          {title}
                        </h3>
                      </div>
                    )}
                    {tags && (
                      <div style={{width:"80px"}}>
                        <div className="bg-red-200 inline-block p-1 rounded-md text-xs px-2">
                          <p>{tags}</p>
                        </div>
                      </div>
                    )}
                  </div>
                  {onClick && (
                    <AiOutlineClose
                      onClick={onClick}
                      className={`cursor-pointer`}
                    />
                  )}
                </div>
              )}
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
