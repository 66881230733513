import React, { useEffect, useState } from "react";
import { Button, Card /*, ToggleSwitch*/, Loading, NoRecords } from "../../../components";
import useScrollbarSize from "react-scrollbar-size";
import DataTable from "react-data-table-component";
import { FaEdit , FaTrash } from "react-icons/fa";
import EditUsersModal from "../Components/Modal";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../features/translate/translateSlice";
import Logouts from "../../../components/logout"; 

const keyAuth = global.config.typecode[0]+global.config.authBearerKey;
const keyAuth2 = global.config.typecode[1]+global.config.authBearerKey;
const keyAuth3 = global.config.typecode[3] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const UsersList = () => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector(state => state.translate.lang)
  const { height } = useScrollbarSize();
  const [isChecked, setChecked] = useState([]);
  const [isModal, setModal] = useState(false);
  const [listdata, setListData] = useState([]);
  const [listalldata, setListAllData] = useState([]);
  const [listCountry, setListCountry] = useState([]);
  const [listDistributor, setListDistributor] = useState([]);
  const [listAllDistributor, setListAllDistributor] = useState([]);
  const [popcontent, setPopContent] = useState([]);
  const [pending, setPending] = useState(true)
  const [filterData, setFilterData] = useState({statid:"",countryid:"",distributorid:"",levelid:""});
  /*
  const handleToggle = (id,stat) => {
    var statcheck = 1, statsend = true;
    if(stat===1) { statcheck = 0; statsend = false; }
    else { statcheck = 1; statsend = true;  }
    setChecked(prevState => ({ ...prevState, ["ustat_"+id]: statcheck }));
    var datatext = '{"doc_id":"'+id+'","u_status": '+statsend+',"from_id":"edit-status-user","lang":"'+chooseLang+'"}';
    var data = JSON.parse(datatext); 
    var urlFetch = global.config.urlLink+"/editusers";
    headersParams.Authorization = keyAuth;
    fetch(urlFetch, {
      method: 'POST',
      headers: headersParams, 
      body : JSON.stringify(data)
    }).then(res => { 
        if(res.status === 200) return res.json() 
    }).then( resJson => {
      return false;
    }).catch((err) => { 
        console.log(err);
        return false;
    });
  }
  */
  const handleDeleteClick = (resList,type) => {
    if (window.confirm(`${translate.user_list.alert_delete}`) === true) {
      document.getElementById("loading-screen").style.display = "flex";
      var urlFetch = global.config.urlLink+"/deleteusers/"+resList.user_id;
      headersParams.Authorization = keyAuth;
      fetch(urlFetch, {
        method: 'GET',
        headers: headersParams 
      }).then(res => { 
          if(res.status === 200) return res.json() 
      }).then( resJson => {
          document.getElementById("loading-screen").style.display = "none";
          // loadData();
        return false;
      }).catch((err) => { 
          console.log(err);
          document.getElementById("loading-screen").style.display = "none";
          return false;
      }); 
    } 
  }
  
  const handleSelect = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    var listuser = listalldata;
    listuser= listalldata.filter(function(item) {
      for (var key in filterData) {
        var valuedata = filterData[key];
        if(key === name) { valuedata = value; }
        
        if(valuedata !== "") { 
          var keydata = "";
          if(key === "countryid") {
            if (item["user_country"] === null || 
                item["user_country"] === '""' || 
                item["user_country"] === "" || 
                item["user_country"].toString().includes(valuedata.toString()) === false) {
              return false;
            }
          } else {
            if(key === "statid") { keydata = "user_status"; }
            else if(key === "levelid") { keydata = "user_level"; }
            else if(key === "countryid") { keydata = "user_country"; }
            else if(key === "distributorid") { keydata = "user_distributor"; }
            
            if (item[keydata].toString() !== valuedata.toString()) {
              return false;
            } 
          }
        } 
      }
      return true; 
    });
    setListData(listuser);
  
    if(name === "countryid") {
      var listdist = listAllDistributor;
      if(value !== "") { 
        listdist=[];
        listAllDistributor.forEach(dist => {
            var userCountryVal = JSON.parse(value);
            if(dist.id_country === userCountryVal.value){
              listdist.push(dist);
            } 
        })
      }
      setListDistributor(listdist);
    }
    setFilterData({ ...filterData, [name]: value });
  }
  const handleDetailClick = (resList,type) => {
    var sendContent = {};
    sendContent.type = type;
    sendContent.data = resList;
    setPopContent(JSON.stringify(sendContent));
    setModal(!isModal);
  }
  const updtlistStat = (row) => {
    var checkarr = {};
    row.forEach(records => {
      checkarr["ustat_"+records.user_id] = records.user_status;
    })
    setChecked(checkarr);
  }
  const loadCountry = () => {
    return new Promise(resolve => {
        var datatext ='{"from_id":"list-country","stat_id":1,"lang":"'+chooseLang+'"}';
        var data = JSON.parse(datatext); 
        headersParams.Authorization = keyAuth2;
        fetch(global.config.urlLink+"/listcountry", {
          method: 'POST',
          headers: headersParams, 
          body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJson => {
            if(resJson.status === "200") {
              resolve(resJson.values);
            } else {
              resolve([]);
            }
        }).catch((err) => {
            resolve([]);
        });
    });
  }
  const loadDistributor = (stat,country) => {
    return new Promise(resolve => {
      var fetchUrl = global.config.urlLink + "/listdist";
      var dataText = '{';
      dataText += '"from_id": "list-distributor"';
      dataText += ',"lang": "'+chooseLang+'"';      
      if(localStorage.getItem("dulv") ===global.config.conflevel[0] || localStorage.getItem("dulv") ===global.config.conflevel[1]) { 
        if(stat !== "") { dataText += ',"stat_id":'+parseInt(stat); }
      } else { dataText += ',"stat_id":1'}
      if(country !== "") { dataText += ',"country_id":"'+country+'"'; }
      dataText += "}";

      let data = JSON.parse(dataText);
      headersParams.Authorization = keyAuth3;
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((res) => {
          if (res.status === "200") {
            resolve(res.values);
          } else { resolve([]); }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadUser = (country) => {
    return new Promise(resolve => {
      if(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2]) {
        // var datatext ='{"from_id":"list-user","lang":"'+chooseLang+'"}';
        // var data = JSON.parse(datatext); 
        var data = {
          from_id: "list-user",
          lang: chooseLang
        }
        if(country !== "") { data.country_id = country; }
        // console.log(data);
        headersParams.Authorization = keyAuth;
        fetch(global.config.urlLink+"/listusers", {
          method: 'POST',
          headers: headersParams, 
          body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJson => {
            if(resJson.status === "200") {
              resolve(resJson.values);
            } else if(resJson.status === global.config.errcodesess) {
              resolve([resJson.status]);
            } else {
              console.log('Something happened wrong');
              resolve([]);
            }
        }).catch((err) => {
            console.log(err);
            resolve([]);
        });
      } else { resolve([]); }
    });
  }
  const loadData = async () => {
    var idCntry="";
    if(localStorage.getItem("dulv")===global.config.conflevel[2]) { idCntry = localStorage.getItem("ducr"); }
    let [result1, result2, result3] = await Promise.all([loadUser(idCntry),loadCountry(),loadDistributor("","")]);
    if(result1.length > 0 && result1[0] === global.config.errcodesess) {
      var resultSO = await Logouts();
      if(resultSO.status === "200") {
        window.location.reload();
      }
    } else {
      setPending(false)
      setListData(result1);
      setListAllData(result1);
      updtlistStat(result1);
      setListDistributor(result3);
      setListAllDistributor(result3);
      setListCountry(result2); 
    } 
  };
  var optCountry = [];
  if(listCountry !== undefined) {
    for(var d=0; d<listCountry.length; d++) {
      optCountry.push(<option value={'{"value":'+listCountry[d].country_id+',"label":"'+listCountry[d].country_name+'"}'} key={"D"+listCountry[d].country_id}>{listCountry[d].country_name}</option>)
    }
  }
  var optDistributor = [];
  if(listDistributor !== undefined) {
    for(var e=0; e<listDistributor.length; e++) {
      optDistributor.push(<option value={listDistributor[e].distributor_id} key={"E"+listDistributor[e].distributor_id}>{listDistributor[e].distributor_name}</option>)
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  const AvatarNameList = ({ item }) => (
    <div className="flex flex-1 items-center gap-5 py-3">
      <img src={(item.user_photo !== "" && item.user_photo !== null)? item.user_photo : '/images/Profile_Default.png'} className="w-10 h-10 rounded-full object-cover object-[50%_0px]" alt="" />
      <p>{item.user_fullname}</p>
    </div>
  );
  const AccessCols = (row) => (
    <div>{(row.user_status === true || row.user_status === 1)? global.config.generalstatus[0] : global.config.generalstatus[1] }</div>
  );
  const ActionCols = (row) => (
    <div className="flex flex-1 gap-4 hover:fill-red-400">
      {/* <FaInfo 
        onClick={() => handleDetailClick(row,"Detail")}
        className="hover:fill-red-600"
      /> */}
      <FaEdit onClick={() =>  handleDetailClick(row,"Edit")} className="hover:fill-red-600 fasize" />
      {(localStorage.getItem("dulv")===global.config.conflevel[0]) && <FaTrash
        onClick={() => handleDeleteClick(row,"Delete")}
        className="hover:fill-red-600 fasize"
      /> }
    </div>
  );
  const CountryData = (item) => {
    var items= JSON.parse(item);
    var senditem="";
    for(var a=0; a<items.length; a++) {
      if(senditem !== "") { senditem += `, ` }
      senditem+= `${items[a].label}` 
    }
    return senditem;
  }

  const columns = [
    {
      name: translate.user_list.column_1,
      sortable: true,
      selector: (row) => <AvatarNameList item={row} />,
      wrap: true 
    },
    {
      name: translate.user_list.column_2,
      selector: (row) => row.user_email,
      wrap: true 
    },
    {
      name: translate.user_list.column_3,
      // selector: (row) => (row.country_name !== null? row.country_name : ''),
      cell: (row) => <p>{`${
        row.user_country !== null && row.user_country !== '""' && row.user_country !== '“”' && row.user_country !== "" && JSON.parse(row.user_country).length > 0 ? 
          // CountryData(JSON.parse(row.user_country))
          CountryData(row.user_country)
        : "-"
      }`}</p>,
      width: "160px"
    },
    {
      name: translate.user_list.column_4,
      selector: (row) => row.ulevel,
      width: "180px"
    },
    {
      name: translate.general.column_status,
      cell: AccessCols,
      width: "120px"
    }
  ];
  if(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2]) {
    columns.push({name: translate.general.column_action, cell: ActionCols, width: "160px"});
  }
  return (
    <>
      <div className={`bg-gray-100 flex-1 p-8 h-screen overflow-y-auto ${isModal ? " overflow-hidden" : "" }`} >
        <div className="flex gap-4 items-center mb-8">
          <h1 className="text-2xl">{translate.user_list.title}</h1>
          {(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1]  || localStorage.getItem("dulv")===global.config.conflevel[2]) &&
          <Button
            variant="icon"
            icon="plus"
            onClick={() =>
              handleDetailClick(
                {
                  user_id: 0,
                  joined_date: null,
                  last_login: null,
                  user_fullname: "",
                  user_email: "",
                  user_phone: "",
                  user_position: "",
                  user_photo: null,
                  user_level: "ad",
                  user_country: "",
                  user_distributor: 0,
                  ulevel: "",
                },
                "Add"
              )
            }
          />
          }
        </div>
        <div className="bg-white shadow-3xl flex flex-1 p-4 gap-x-4 rounded-lg mb-2">
          <p className=" text-gray-400 font-bold">{translate.general.filter}: </p>
          <select name="statid" id="statid" className="bg-white text-gray-400 outline-none" defaultValue=""
            value={filterData.statid} 
            onChange={handleSelect}
          >
            <option value="" key="B0">{translate.general.filter_status}</option>
            <option value="1" key="B1">{global.config.generalstatus[0]}</option>
            <option value="0" key="B2">{global.config.generalstatus[1]}</option>
          </select>
          {(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1]) && 
          <select name="levelid" id="levelid" className="bg-white text-gray-400 outline-none" defaultValue=""
            value={filterData.levelid} 
            onChange={handleSelect}
          >
            <option value="" key="C0">{translate.user_list.filter_role}</option>
            <option value="ad" key="C1">{translate.user_role.admin}</option>
            <option value="cm" key="C2">{translate.user_role.manager}</option>
            <option value="dt" key="C3">{translate.user_role.user}</option>
          </select>
          }
          <select name="countryid" id="countryid" className="bg-white text-gray-400 outline-none" defaultValue=""
            value={filterData.countryid} 
            onChange={handleSelect}
          >
            <option value="" key="D0">{translate.general.filter_country}</option>
            {optCountry}
          </select>
          <select name="distributorid" id="distributorid" className="bg-white text-gray-400 outline-none " defaultValue="" style={{maxWidth: "500px"}}
            value={filterData.distributorid} 
            onChange={handleSelect}
          >
            <option value="" key="E0">{translate.general.filter_distributor}</option>
            {optDistributor}
          </select>
          
        </div>
        {/* Table */}
        <Card className={"h-auto w-auto"}>
          <DataTable 
            name="list"
            columns={columns}
            data={listdata}
            fixedHeader
            highlightOnHover
            // defaultSortFieldId="No."
            progressPending={pending}
            progressComponent={<Loading className="h-10 w-10 mt-10 mb-10"/>}
            pointerOnHover
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
            onRowClicked={(row) => handleDetailClick(row, "Detail")}
            noDataComponent={<NoRecords />}
            customStyles={{
              headRow: {
                style: {
                  borderBottomWidth: "3px",
                  borderBottomStyle: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#F5F6FA",
                  color: "#8A92A6",
                },
                denseStyle: {
                  minHeight: "32px",
                },
              },
              table: {
                style: {
                  height: `calc(100vh - 20px - ${height}px)`,
                },
              },
            }}
          />
        </Card>
        {isModal && (
            <EditUsersModal
              onClick={() => setModal(!isModal)}
              onBackgroundClick={() => setModal(!isModal)}
              content={popcontent}
            />
          )}
      </div>
    </>
  );
};

export default UsersList;
