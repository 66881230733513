import './config';
const openFiles = (fileurl,keyAuth) => {
    return new Promise(resolve => {
        const headersParams = global.config.headersparam;
        headersParams.Authorization = keyAuth;

        var arrFileUrl = fileurl.split("/")
        var filenames = arrFileUrl[arrFileUrl.length-1];
        filenames = decodeURI(filenames);     
        var dataText = {
            fileName: filenames,
            types: "1"
        };
        fetch(global.config.urlLink + "/getsignedurl", {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(dataText),
        }).then((res) => {
            if (res.status === 200) return res.json();
        }).then(async (res) => {
        if(res.status === "200") {
            resolve(res.values);  
        }
        });
    });
      
}

export default openFiles;
