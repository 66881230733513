import React from "react";

const ProgressBar = ({
  completed = "",
  className,
  placeholderColor,
  width,
}) => {
  return (
    <div className={`h-3.5 w-full ${placeholderColor} rounded-3xl my-2 `}>
      <div
        className={`h-full ${className} rounded-3xl text-right flex justify-end`}
        style={{ width: width }}
      >
        <span className="text-white font-bold text-xs">{completed}%</span>
      </div>
    </div>
  );
};

export default ProgressBar;
