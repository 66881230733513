import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../features/translate/translateSlice";
import { Button } from "../../../components";
import { ReactComponent as Loading } from "../../../assets/svg/loading.svg";
import openFiles from "../../../components/downloadurl";

const keyAuth = global.config.typecode[17] + global.config.authBearerKey;
const headersParams = global.config.headersparam;
const current_year = new Date().getFullYear();
      
const ComplianceView = () => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector(state => state.translate.lang);
  const [formDisabled, setFormDisabled] = useState(true);
  const [form, setForm] = useState({
    doc_id: "",
    distributor_id: "",
    distributor_name: "",
    current_year: current_year,
    file_compliance: {},
    download_file_url: "",
    status: ""
  });
  
  const hiddenFileCompliance = useRef(null);
  const [fileCompliance, setComplianceFile] = useState({});
  const [loading, setLoading] = useState({
    file_compliance: false,
  });
  const handleOpenFile = (url) => {
    window.open(url,'_blank');
  }
  const clickDownloadFile = async (link,target,filenames) => {
    if(link !== "") {
      let [resultUrl] = await Promise.all([openFiles(link,keyAuth)]);
      if(resultUrl !== "" & resultUrl !== undefined) {
        window.open(resultUrl,target);
      }
    } 
  }

  const handleClickUpload = (cat) => {
    if (cat === "file0") {
      hiddenFileCompliance.current.click();
    } 
  };
  const submitData = (link, filenames) => {
    return new Promise(resolve => {
      let data = {
        from_id: "edit-compliance",
        dist_id: form.distributor_id,
        distributor_name: form.distributor_name,
        curr_year: form.current_year,
        doc_id: form.doc_id,
        file_uploaded: link,
        file_name: filenames
      };
      headersParams.Authorization = keyAuth;
      var fetchUrl = global.config.urlLink+"/editcompliance";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((res) => {
          if (res.status === "200") {
            resolve(res.values);
          } else if(res.status === global.config.errcodesess) {
            resolve([res.status]);
          } else { resolve([])}
      }).catch((err) => {
        resolve([]);
      });
    });
  }
  const handleUploadFile = async (e) => {
    if(e.target !== undefined) {
      var fieldname = e.target.name;
      var loadname = fieldname;
      var fileName = e.target.files[0].name;
      fileName = fileName.split(' ').slice(0, 2).join('');
      fileName = fileName.substring(0, fileName.length-4)+"_fin"+fileName.substring(fileName.length-4, fileName.length);

      var dataFile = e.target.files[0];
      dataFile.filename = fileName;

      if (!e) return;
      setLoading((prev) => ({ ...prev, [loadname]: true }));

      let fetchUrl = global.config.urlLink;
      headersParams.Authorization = keyAuth;
      const dataText = {
        fileName: fileName,
      };
      await fetch(fetchUrl + "/getsignedurl", {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(dataText),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then(async (res) => {
          if (res.status === "200") {
            const urlUploadFile = res.values;
            await fetch(urlUploadFile, {
              method: "PUT",
              headers: {
                "content-type": "application/octet-stream",
              },
              body: dataFile,
            }).then(async (res2) => {
              let urlDataFile = res2.url.split("?")[0];
              
              var current_materi_file = {
                fileName: fileName,
                fileType: dataFile.type,
                fileUrl: urlDataFile,
                fileSize: dataFile.size,
                fileTitle: "",
                fileId: ""
              };
              
              let [result3] = await Promise.all([submitData(urlDataFile,fileName)]);
              if(result3) {
                localStorage.setItem('dstatc', result3);
                if(fieldname === "file_compliance") {
                  setComplianceFile(current_materi_file);
                } 
                setForm((prevState) => ({
                  ...prevState,
                  status: result3,
                  [fieldname]: current_materi_file,
                }));
                setLoading((prev) => ({ ...prev, [loadname]: false }));
              }
               
            });
          }
        });

    }
  }
  
  const loadDataCompliance = (distributor) => {
    return new Promise(resolve => {
      var fetchUrl = global.config.urlLink + "/listcompliance";
      var dataText = '{';
      dataText += '"from_id": "list-compliance"';
      dataText += ',"lang": "'+chooseLang+'"';
      if(distributor !== "") { dataText += ',"dist_id":"'+distributor+'"'; }
      if(current_year !== "") { dataText += ',"year_id":"'+current_year+'"'; }
      dataText += '}';
      let data = JSON.parse(dataText);
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((res) => {
          if (res.status === "200") {
            resolve(res.values);
          } else if(res.status === global.config.errcodesess) {
            resolve([res.status]);
          } else { resolve([])}
      }).catch((err) => {
        resolve([]);
      });
    });
  };
  const loadData = async (distributorid) => {
    let [result1] = await Promise.all([loadDataCompliance(distributorid)]);
    if(result1.length > 0) {
      var materi_file = {};
      if(result1[0].compliance_doc_name !== null && result1[0].compliance_doc_name !== "") { materi_file.fileName = result1[0].compliance_doc_name; }
      if(result1[0].compliance_doc_url !== null && result1[0].compliance_doc_url !== "" && result1[0].status !== global.config.compliancestatus[0]) {
        materi_file.fileUrl = result1[0].compliance_doc_url;
      }
      setComplianceFile(materi_file);
      var download_urlfiles = result1[0].download_fileurl;
      var datauserstr = {  
        doc_id: result1[0].compliance_id,
        distributor_id: result1[0].id_distributor,
        distributor_name: result1[0].distributor_name,
        current_year: current_year,
        file_compliance: materi_file,
        download_file_url: download_urlfiles,
        status: result1[0].status
      }
      setForm(datauserstr);
    }
    
  };
  useEffect(() => {
      var idDist="";
      if(localStorage.getItem("dulv")===global.config.conflevel[3]) { idDist = localStorage.getItem("dudst"); } 
      loadData(idDist);
      setFormDisabled(false);
  }, []);
  return (
    <div className=" bg-gray-100 h-screen flex-1 p-8 overflow-y-auto">
      <div className="flex items-center gap-10 mb-8">
        <div className="flex items-center">
          <h2 className=" text-2xl">{translate.compliance_form.title} {form.current_year}</h2>
        </div>
      </div>
      {/* -------- start - row 3 ------ */}
      {form.status === global.config.compliancestatus[1] && 
      <div>
        <div className="flex w-auto mt-10">
        <div className="p-1 text-gray-500">{translate.compliance_form.label3a}<br/>{translate.compliance_form.label3b}</div>
        </div>
      </div>
      }
      {/* -------- end - row 3 ------ */}
      {/* -------- start - row 4A ------ */}
      <div>
           {/* START - FILE 1  */}
           {((fileCompliance.fileName === undefined || fileCompliance.fileName === null || fileCompliance.fileName === "") && formDisabled === true)?
               <></>
              :
              <div className="flex flex-col rounded-lg border-gray-300 justify-around w-full">
                <div className="p-1 text-gray-500 border-x-0 border-t-0 border-b-2 mb-5 mt-10">{form.status === global.config.compliancestatus[0]? translate.compliance_form.text_input1 : translate.compliance_form.text_input1b}</div>
                <div className="w-full self-center items-center justify-center">
                  {!fileCompliance?.fileUrl ? (
                    <div className="w-4/6">
                      {loading.file_compliance ? (
                        <div className="justify-center items-center"><Loading className="w-10 h-10 animate-spin "/></div>
                      ) : (
                        <div className="flex p-2 w-full">
                          {formDisabled === false && (
                            
                            <div className="mr-6">
                              <Button
                                type="button"
                                variant="base"
                                // title="Upload Image"
                                title={translate.general.button_upload}
                                className="bg-gray-500 text-white"
                                onClick={() =>
                                  handleClickUpload("file0")
                                }
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : fileCompliance?.fileUrl ? (
                    <div className="w-4/6">
                      {loading.file_supporting_1 ? (
                        <div className="justify-center items-center"><Loading className="w-10 h-10 animate-spin "/></div>
                      ) : (
                        <div className="flex w-full">
                          
                          {(fileCompliance.fileUrl !== null && fileCompliance.fileUrl !== "")?
                          <div className="w-auto flex">
                            <Button
                              type="submit"
                              variant="icon-base"
                              title={translate.compliance_form.button2}
                              className="bg-red-500 text-white"
                              icon="download"
                              onClick={() => clickDownloadFile(fileCompliance.fileUrl,"_blank", fileCompliance.fileName)}
                            />
                          </div>
                          : <></>
                          }
                          {formDisabled === false && (
                            
                            <div className="mr-6 w-5 justify-items-end items-end">
                              
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                  ) : null}
                  
                 
                </div>
                <input
                  type="file"
                  ref={hiddenFileCompliance}
                  name="file_compliance"
                  onChange={handleUploadFile}
                  style={{ display: "none" }}
                  hidden
                  accept="application/pdf, image/png, image/gif, image/jpeg, image/jpg"
                />   
              </div>
            }
      </div>
      {/* -------- end - row 4A ------ */}

      {/* -------- start - row 4B ------ */}
      {form.status !== global.config.compliancestatus[2] && <div >
        <div className="p-1 text-gray-500 border-x-0 border-t-0 border-b-2 mb-5 mt-10">{translate.compliance_form.label2}</div>
        <div className="mt-4">
          <Button
            type="submit"
            variant="icon-base"
            title={translate.compliance_form.button2}
            className="bg-red-500 text-white"
            icon="download"
            onClick={() => clickDownloadFile(form.download_file_url,"_blank", fileCompliance.fileName)}
          />
          <div id="hiddenDownloadID" style={{display:"none"}}></div>
        </div>
      </div>
      }
      {/* -------- end - row 4B ------ */}
    </div>

  );
};

export default ComplianceView;
