import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

const DoughnutChart = ({ chartData }) => {
  let options = {
    // maintainAspectRatio: false,
    responsive: true,
    cutout: "85%",
    legend: {
      display: false,
    },
    layout: {
      // padding: 10,
    },
  };

  return (
    <Doughnut data={chartData} options={options} style={{ marginBottom: 5 }} />
  );
};

export default DoughnutChart;
