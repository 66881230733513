import React from "react";

import { ReactComponent as AddUser } from "../../assets/svg/add-user.svg";
import { ReactComponent as Send } from "../../assets/svg/send.svg";
import { ReactComponent as Plus } from "../../assets/svg/plus.svg";
import { ReactComponent as ArrowLeft } from "../../assets/svg/arrow-left.svg";
import { ReactComponent as Camera } from "../../assets/svg/camera.svg";
import { ReactComponent as Close } from "../../assets/svg/close-icon.svg";
import { ReactComponent as Download } from "../../assets/svg/download.svg";
import { ReactComponent as CheckMark } from "../../assets/svg/check-mark.svg";
import { Loading } from "../../components";
const Button = ({
  type = "",
  variant = "",
  title = "",
  src = "",
  icon = "",
  className = "",
  onClick = () => {},
  size = "",
  width,
  height,
  hidden,
  style = {},
  checked,
  onChange,
  disables
}) => {
  const ButtonItem = () => {
    switch (variant) {
      case "base":
        return (
          <button
            type={type}
            className={`${
              size === "small" ? "text-xs" : "text-sm "
            } py-2 px-7 rounded-md shadow-3xl ${className}`}
            onClick={onClick}
            hidden={hidden}
          >
            {title}
          </button>
        );
      case "icon":
        return (
          <div
            className={`${className} bg-red-500 p-2 inline-block rounded-full shadow-3xl cursor-pointer`}
            onClick={onClick}
            style={style}
          >
            {src && <img src={src} alt={`.${src}`} width={17} />}
            {icon === "add-user" && <AddUser className={`fill-white`} />}
            {icon === "camera" && <Camera className={` fill-white `} />}
            {icon === "plus" && <Plus className={`fill-white w-4 h-4`} />}
            {icon === "left" && <ArrowLeft className={`w-4 h-4 fill-white`} />}
            {icon === "close" && <Close className="w-4 h-4" />}
          </div>
        );

      case "icon-base":
        return (
          <button
            type={type}
            //className="flex items-center text-white bg-blue-500 px-4 rounded-lg py-2 gap-x-2 shadow-3xl"
            className={`${
              size === "small" ? "text-xs" : "text-sm "
            } py-2 px-7 rounded-md shadow-3xl flex items-center ${className}`}
            onClick={onClick}
            disabled={disables}
          >
            {/* {icon === "send" && <Send className="fill-white" width={13} />}
            {title} */}
            {icon === "send" && <div className="w-1/12"><Send className="fill-white" width={13} /></div>}
            {icon === "download" && <div className="w-1/12"><Download className="fill-white mr-2" width={13} /></div>}
            {icon === "checkmark" && <div className="w-1/12"><CheckMark className="w-4 h-4 fill-white mr-4 mt-0" width={13} /></div>}
            {icon === "close" && <div className="w-1/12"><Close className="w-4 h-4 fill-white mr-2" /></div>}
            <div className="w-11/12">{title}</div>
            {icon === "loading" && <div className="w-1/12"><Loading className="w-5 h-5" theme="white"/></div>}
          </button>
        );
        case "radio":
          return (
            <div className=" flex items-center w-9 h-9">
              <label
                className={`flex gap-2 ${className} p-1 rounded-full cursor-pointer`}
              >
                <input
                  type="radio"
                  checked={checked}
                  onChange={onChange}
                  hidden
                />{" "}
                {src && (
                  <img src={src} width={width} height={height} className="" alt=""/>
                )}
              </label>
            </div>
          );
      default:
        break;
    }
  };

  return <>{ButtonItem()}</>;
};

export default Button;
