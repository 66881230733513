import React from "react";

import { Line } from "react-chartjs-2";
import { UserData } from "../../data";

const LineChart = ({ chartData }) => {
  let options = {
    responsive: true,
    cutout: "85%",
    plugins: {
      legend: { display: false },
    },
    scales: {
      x: {
        ticks: { display: false },
        grid: { drawBorder: false, display: false },
      },
      y: {
        ticks: { display: false, beginAtZero: true },
        grid: { drawBorder: false, display: false },
      },
    },
    layout: {
      paddingTop: 10,
    },
  };

  return <Line data={chartData} options={options} style={{ marginTop: 25 }} />;
};

export default LineChart;
