import React, { useState, useEffect, useRef, useCallback } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../features/translate/translateSlice";
import Logouts from "../../../components/logout"; 
import openFiles from "../../../components/downloadurl";
import {
  Button,
  CreatableSelect,
  CustomSelect,
  TextInput,
  ToggleSwitch,
  DateTimeFormats,
  ReplaceAllContent,
  NoRecords
} from "../../../components";

import { ReactComponent as Loading } from "../../../assets/svg/loading.svg";
import { ReactComponent as Add } from "../../../assets/svg/add.svg";
import { ReactComponent as Document } from "../../../assets/svg/document.svg";
import { ReactComponent as Minus } from "../../../assets/svg/minus.svg";
import ObjectiveKPIModal from "../../Objective/Components/Modal";

const keyAuth = global.config.typecode[3] + global.config.authBearerKey;
const keyAuth2 = global.config.typecode[1] + global.config.authBearerKey;
const keyAuth3 = global.config.typecode[5] + global.config.authBearerKey;
const keyAuth4 = global.config.typecode[10] + global.config.authBearerKey;
const keyAuth5 = global.config.typecode[2] + global.config.authBearerKey;
const keyAuth6 = global.config.typecode[13]+global.config.authBearerKey; 

const headersParams = global.config.headersparam;
const sYear = parseInt(new Date().getFullYear());
const eYear = parseInt(sYear)+3; 

const convertDate = (dt) => {
  const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
  return `${dt.getFullYear()}-${padL(dt.getMonth() + 1)}-${padL(
    dt.getDate()
  )} ${padL(dt.getHours())}:${padL(dt.getMinutes())}:${padL(
    dt.getSeconds()
  )}`;
};

const DistributorDetail = () => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector(state => state.translate.lang)
  const navigate = useNavigate();
  const paramsLoc = useLocation();
  const [type, setType] = useState("");
  const [reff, setReff] = useState("");
  const [isModal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [detaildist, setDetailDist] = useState({});
  const [targetdata, setTargetData] = useState([]);
  const [targetheader, setTargetHeader] = useState([]);
  const [listCountry, setListCountry] = useState([]);
  const [listRegion, setListRegion] = useState([]);
  // const [selCountry, setSelectedCountry] = useState("");
  const [marketDropdown, setMarketDropdown] = useState([]);
  const [productDropdown, setProductDropdown] = useState([]);
  const [teritoryDropdown, setTeritoryDropdown] = useState([]);
  const [isSelected, setSelected] = useState({
    dist_country_id: "",
    dist_market_appl: [],
    dist_product_appl: [],
    dist_region_name: "",
    dist_teritory: []
  });
  const [formDisabled, setFormDisabled] = useState(true);

  const hiddenPDFInp_dist_agree_eng = useRef(null);
  const hiddenPDFInp_dist_agree_loc = useRef(null);
  const hiddenPDFInp_nda_eng = useRef(null);
  const hiddenPDFInp_nda_loc = useRef(null);

  const [fileDAEng, setDAEngFile] = useState({});
  const [fileDALocal, setDALocFile] = useState({});
  const [fileNDAEng, setNDAEngFile] = useState({});
  const [fileNDALoc, setNDALocFile] = useState({});

  const [loading, setLoading] = useState({
    da_eng: false,
    da_loc: false,
    nda_eng: false,
    nda_loc: false,

  });

  const [formErrors, setFormErrors] = useState({
    dist_name: "", 
    dist_country_id: "",
    dist_market_appl: "",
    dist_region_name: "",
    dist_teritory: ""
  });
  const [dateInput, setDateInput] = useState(new Date());
  const [form, setForm] = useState({
    dist_name: "",
    dist_teritory: [],
    dist_country_id: "",
    dist_region_name: "",
    dist_website: "",
    dist_contactname: "",
    dist_contactemail: "",
    dist_contactphone: "",
    dist_contactposition: "",
    dist_lead: "",
    dist_case: "",
    dist_case_remarks: "",
    dist_aggrement_eng: "",
    dist_aggrement_local: "",
    dist_efdate_da: convertDate(new Date()),
    dist_nda_eng: "",
    dist_nda_local: "",
    dist_market_appl: [],
    dist_product_appl: [],
    dist_product_appl_old: [],
    dist_status: 1,
    dist_address: "",
    file_compliance: ""
  });
  const handleBack = (reff) => {
    navigate("/admin/"+reff, {
      state: { dataref: paramsLoc.state.filter },
    })
  }
  const handlePopUpModal = (typeModal, category, details, product) => {
    let contentParams = {};
     if (category === "target") {
      contentParams.product = product;
      contentParams.data = details;
      contentParams.category = category;
      contentParams.type = typeModal;
      setModalContent(contentParams);
    }
    setModal(!isModal);
  };
  const cleaningContent = (content) => {
    var arrContentUrl = content.split("/");
    if(arrContentUrl.length > 0) {
      var filenames = arrContentUrl[arrContentUrl.length-1];
      filenames = decodeURI(filenames);
      return <ReplaceAllContent content={filenames} before={["_","-","%20",global.config.asseturl+"/distributor/"]} after={[" "," "," ",""]}/>
    } else {
      return content;
    }
  }
  const handleOpenFile = async (url, used) => {
    let [resultUrl] = await Promise.all([openFiles(url,keyAuth)]);
    if(resultUrl !== "" & resultUrl !== undefined) {
      if(used === "upload") { 
        return resultUrl;
      } else {
        window.open(resultUrl,'_blank');
      }
    }
  }
  const validateField = (fieldName, value) => {
    let ferrors = "";
    switch (fieldName) {
      case "dist_name":
        if (value.trim().length > 0) {
          ferrors = "";
        } else {
          ferrors = translate.distributor_create.error1;
        }
        break;
      case "dist_country_id":
        if (value > 0) {
          ferrors = "";
        } else {
          ferrors = translate.distributor_create.error2;
        }
        break;
      case 'dist_market_appl': 
        if(value !== undefined && value.length > 0) { ferrors = ""; }
        else { ferrors = translate.distributor_create.error3; }
        break;
      case 'dist_product_appl': 
        if(value !== undefined && value.length > 0) { ferrors = ""; }
        else { ferrors = translate.distributor_create.error4; }
        break;
      case "dist_region_name":
        if (value.trim().length > 0) {
          ferrors = "";
        } else {
          ferrors = translate.distributor_create.error5;
        }
        break;
      case 'dist_teritory': 
        if(value !== undefined && value.length > 0) { ferrors = ""; }
        else { ferrors = translate.distributor_create.error6; }
        break;
      default:
        break;
    }
    setFormErrors((prevState) => ({ ...prevState, [fieldName]: ferrors }));
  };

  const onChangeHandler = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };

  const onChangeSelect = (e, sel) => {
    // var name = e.target.name;
    var name = sel.name;
    var value = e.value; if(value === undefined) { value = e; }
    setSelected((prevState) => ({
      ...prevState,
      [name]: e,
    }));
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };
  
  const onChangeDateHandler = (val) => {
    setDateInput(val);
    setForm((prevState) => ({
      ...prevState,
      dist_efdate_da: convertDate(val),
    }));
  };

  const toggleHandler = () => {
    if((localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2]) && formDisabled === false) {
      setForm((prevState) => ({
        ...prevState,
        dist_status: form.dist_status === 0 ? 1 : 0,
      }));
    }
  };
  const handleUploadFileChange = async (e) => {
    if(e.target !== undefined) {
      var fieldname = e.target.name;
      var loadname = "";
      const pdfFile = e.target.files[0];
      const fileName = e.target.files[0].name;
      if(fieldname === "dist_aggrement_eng") {
        loadname = "da_eng";
      } else if(fieldname === "dist_aggrement_local") {
        loadname = "da_loc";
      } else if(fieldname === "dist_nda_eng") {
        loadname = "nda_eng";
      } else if(fieldname === "dist_nda_local") {
        loadname = "nda_loc";
      } 
      
      if (!e) return;
      setLoading((prev) => ({ ...prev, [loadname]: true }));
      let fetchUrl = global.config.urlLink;
      headersParams.Authorization = keyAuth;
      const dataText = {
        fileName: fileName,
      };
      await fetch(fetchUrl + "/getsignedurl", {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(dataText),
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then(async (res) => {
          if (res.status === "200") {
            const urlUploadFile = res.values;
            await fetch(urlUploadFile, {
              method: "PUT",
              headers: {
                "content-type": "application/octet-stream",
              },
              body: pdfFile,
            }).then(async (res) => {
              var urlPDFFile = res.url.split("?")[0];
              var urlPDFFileSign = await handleOpenFile(res.url.split("?")[0],"upload");
              pdfFile.url = urlPDFFileSign;
              if(fieldname === "dist_aggrement_eng") {
                setDAEngFile(pdfFile);
              } else if(fieldname === "dist_aggrement_local") {
                setDALocFile(pdfFile);
              } else if(fieldname === "dist_nda_eng") {
                setNDAEngFile(pdfFile);
              } else if(fieldname === "dist_nda_local") {
                setNDALocFile(pdfFile);
              } 
              setForm((prevState) => ({
                ...prevState,
                [fieldname]: urlPDFFile,
              }));
              setLoading((prev) => ({ ...prev, [loadname]: false }));
              
            });
          }
        });
        
    }
  };

  const removeFile = (type) => {
    if (type === "da_eng") {
      hiddenPDFInp_dist_agree_eng.current.value = null;
      setDAEngFile({});
      setForm((prevState) => ({
        ...prevState,
        dist_aggrement_eng: "",
      }));
    } else if (type === "da_loc") {
      hiddenPDFInp_dist_agree_loc.current.value = null;
      setDALocFile({});
      setForm((prevState) => ({
        ...prevState,
        dist_aggrement_local: "",
      }));
    } else if (type === "nda_eng") {
      hiddenPDFInp_nda_eng.current.value = null;
      setNDAEngFile({});
      setForm((prevState) => ({
        ...prevState,
        dist_nda_eng: "",
      }));
    } else if (type === "nda_loc") {
      hiddenPDFInp_nda_loc.current.value = null;
      setNDALocFile({});
      setForm((prevState) => ({
        ...prevState,
        dist_nda_local: "",
      }));
    }
  };

  const handleClickUpload = (cat) => {
    if (cat === "da_eng") {
      hiddenPDFInp_dist_agree_eng.current.click();
    } else if (cat === "da_loc") {
      hiddenPDFInp_dist_agree_loc.current.click();
    } else if (cat === "nda_eng") {
      hiddenPDFInp_nda_eng.current.click();
    } else if (cat === "nda_loc") {
      hiddenPDFInp_nda_loc.current.click();
    } 
  };

  const insDatatoDB = (sendText) => {
      var dataJson = JSON.parse(sendText);      
        var urlFetch = global.config.urlLink;
        if(type==="edit") { 
          urlFetch += "/editdist";
          dataJson.from_id="edit-distributor";
        } else if(type==="create") {
          urlFetch += "/adddist";
          dataJson.from_id="add-distributor";
        }
        dataJson.lang = chooseLang; 
        headersParams.Authorization = keyAuth;
        fetch(urlFetch, {
          method: 'POST',
          headers:headersParams, 
          body : JSON.stringify(dataJson)
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJson => {
          document.getElementById("loading-screen").style.display = "none";
          // window.location.href='/admin/distributor';
          navigate("/admin/distributor", {
            state: { dataref: paramsLoc.state.filter },
          })
          return resJson;
        }).catch((err) => {
          document.getElementById("loading-screen").style.display = "none";
          return "Error";
        });
    
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if(form.dist_name !== "" && form.dist_country_id !== "" && form.dist_market_appl.length > 0 && form.dist_product_appl.length > 0 && form.dist_region_name !== ""  && form.dist_teritory.length > 0) {
      document.getElementById("loading-screen").style.display = "flex";
      form.dist_market_appl = JSON.stringify(form.dist_market_appl);
      form.dist_product_appl = JSON.stringify(form.dist_product_appl);
      form.dist_product_appl_old = JSON.stringify(form.dist_product_appl_old);
      form.dist_teritory = JSON.stringify(form.dist_teritory)
      insDatatoDB(JSON.stringify(form));
    } else {
      var formField = [];
      if(form.dist_name === "") { formField.push("dist_name"); }
      if(form.dist_country_id === "") { formField.push("dist_country_id"); }
      if(form.dist_market_appl.length === 0) { formField.push("dist_market_appl"); }
      if(form.dist_product_appl.length === 0) { formField.push("dist_product_appl"); }
      if(form.dist_region_name === "") { formField.push("dist_region_name"); }
      if(form.dist_teritory.length === 0) { formField.push("dist_teritory"); }
      
      for ( var i = 0; i < formField.length; i++ ) { 
        var fieldname = formField[i];
        var fieldvalue = form[fieldname];
        validateField(fieldname, fieldvalue); 
      }
    }
    
  };
  
  const getRegionId = (list,rid) => {
    let updRegion = list?.filter(
      (ter) => ter?.value === rid
    );
    setSelected((prevState) => ({
      ...prevState,
      dist_region_name: updRegion[0],
    }));
  };
  const getCountryId = (list,tid) => {
    let updIdCountry = list?.filter(
      (ter) => ter?.value === tid
    );
    // setSelectedCountry(updIdCountry[0]);
    setSelected((prevState) => ({
      ...prevState,
      dist_country_id: updIdCountry[0],
    }));
  };
  const loadListCountry = (pgtype) => {
    return new Promise((resolve) => {
      let datatext = '{';
      datatext += '"from_id":"list-country"';
      if(pgtype !== "edit" && pgtype !== "create") { datatext += ',"lang":"' + chooseLang + '"'; }
      datatext += '}';      
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth2;
      var fetchUrl = global.config.urlLink + "/listcountry";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            var updCountry = { };
            if(resJson.values.length > 0) {
              updCountry = resJson.values.map((country) => ({
                value: country.country_id,
                label: country.country_name,
              }));
            } 
            resolve(updCountry);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };

  const getListObjTarget = (prod,year,list) => {
    return new Promise((resolve) => {
      
      var uniqueProducts = prod;
      if(uniqueProducts.length > 0) {
        uniqueProducts = uniqueProducts.filter((c, index) => {
          return uniqueProducts.indexOf(c) === index;
        });
      } 
      var uniqueYears = year;
      if(uniqueYears.length > 0) {
        uniqueYears = [...new Map(uniqueYears.map((m) => [m.startDate, m])).values()];
      }
      var itemSend = [];
      var itemTarget = [];
      if(list.length > 0) {
        var sortproduct = uniqueProducts.sort((a, b) => a > b ? 1 : -1);
        // var sortyear = uniqueYears.sort((a, b) => a > b ? 1 : -1);
        var sortyear = uniqueYears.sort((a, b) => a.startDate > b.startDate ? 1 : -1);
        for(var t=0;t<sortproduct.length; t++) {
          var prodName = sortproduct[t];
          var itemTargetCol = {prodName: prodName,tid:[],target_qty:[]};
          for(var u=0; u<sortyear.length; u++) {
            var currSDate = sortyear[u].startDate;
            var targetQty = 0, targetId=""; 
            for(var v=0; v<list.length; v++) {
              var items = list[v];
              if(items.product_name === prodName && items.target_startdate === currSDate) {
                if(items.target_qty !== "" && items.target_qty !== null) { targetQty = items.target_qty; }
                if(items.target_id !== "" && items.target_id !== null) { targetId = items.target_id; }
                break;
              }
            }
            itemTargetCol.tid.push(targetId);
            itemTargetCol.target_qty.push(targetQty);
          }
          itemTarget.push(itemTargetCol);
          if(t === (sortproduct.length-1)) {
            itemSend.push(sortproduct);
            itemSend.push(sortyear);
            itemSend.push(itemTarget);
            resolve(itemSend);
          }
        }
        
      } else {
        itemSend.push(uniqueProducts);
        itemSend.push(uniqueYears);
        itemSend.push(itemTarget);
        resolve(itemSend);
      }
    })
  }
  const loadListObjTarget = (id,pgtype) => {
    return new Promise((resolve) => {
      let datatext = '{';
      datatext += '"from_id":"list-objective"';
      datatext += ',"dist_id":'+id;
      datatext += ',"stat_id":1';
      if(pgtype !== "edit" && pgtype !== "create") { datatext += ',"lang":"' + chooseLang + '"'; }
      datatext += '}';
      
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth3;
      var fetchUrl = global.config.urlLink + "/listobjectivetarget";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            var list = resJson.values;
            var newList = list;
            var productArr = [];
            var yearArr = [];
            if(newList.length > 0) {
              for(var p=0; p<newList.length; p++) {
                var items = newList[p];
                productArr.push(items.product_name);
                yearArr.push({startDate:items.target_startdate,endDate:items.target_enddate});
                if(p===(newList.length-1)) {
                  resolve([{product: productArr, targetyear: yearArr, targetdata: newList}]);
                }
              }
            } else resolve([{product: productArr, targetyear:yearArr, targetdata: newList}]);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const viewListObjTarget = async (listtarget, detaildatadist, pgtype) => {
      var getResultList = await getListObjTarget(listtarget.product,listtarget.targetyear,listtarget.targetdata);
      var headerColTarget = [];
      headerColTarget.push({ name: translate.distributor_create.column_1, selector: (row) => row.prodName, grow: 2,  width: "300px" });
      getResultList[1].map((item, h) => {
        var titleHeader1 = <DateTimeFormats datez = {new Date(item.startDate)} type={"year-long"} />
        if(h<getResultList[1].length) {
          headerColTarget.push({ name: titleHeader1, cell: (row) => 
            <div className={`${
              (localStorage.getItem("dulv")!==global.config.conflevel[3] && row.tid[h] !== "") ? "cursor-pointer hover:bg-gray-100" : ""
            } flex flex-1 gap-4 items-center h-full pl-3`}
            
          onClick={() =>  (localStorage.getItem("dulv")!==global.config.conflevel[3] && row.tid[h] !== "") ? handlePopUpModal(pgtype, "target", detaildatadist, {
                target_id: row.tid[h],
                page_from: "distributor"
              }) : ''
              }
            
            > 
              {row.tid[h] !== ""? `${ row.target_qty[h] }` : `-`}
              
            </div>
          , grow: 1,  width: "160px", });
        }
        
    })
    setTargetData(getResultList[2]);
    setTargetHeader(headerColTarget);
  }
  const getProductId = (list, pid) => {
    setSelected(prevState => ({...prevState, dist_product_appl: JSON.parse(pid)}))
  }
  const getMarketId = (list,mid) => {
    setSelected((prevState) => ({
      ...prevState,
      product_multiple_select: JSON.parse(mid),
    }));
  };
  const loadListMarket = (pgtype) => {
    return new Promise((resolve) => {
      let datatext = '{';
      datatext += '"from_id":"list-market"';
      datatext += ',"stat_id":1';
      if(pgtype !== "edit" && pgtype !== "create") { datatext += ',"lang":"' + chooseLang + '"'; }
      datatext += '}';
      
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth4;
      var fetchUrl = global.config.urlLink + "/listmarket";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            var updateMarket = { };
            if(resJson.values.length > 0) {
              updateMarket = resJson?.values?.map((item) => ({
                value: item?.market_id,
                label: item?.market_name,
              }));
            } 
            resolve(updateMarket);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadListProduct = (pgtype) => {
    return new Promise((resolve) => {
      let dataText = { from_id: "list-all-product",stat_id:1 } 
      if(pgtype !== "edit" && pgtype !== "create") { dataText.lang = chooseLang; }
      headersParams.Authorization = keyAuth5;
      let fetchUrl = global.config.urlLink + "/listproduct";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(dataText),
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then((res) => {
          if (res.status === "200") {
            let updateProduct = res.values.map((item) => ({
              value: item.product_id,
              label: item.product_name,
            }));
            resolve(updateProduct);
          } else {
            resolve([]);
          }
        })
        .catch((err) => {
          resolve([]);
        });
    });
  };
  const getTeritoryId = (list, tid) => {
    setSelected(prevState => ({...prevState, dist_teritory: JSON.parse(tid)}))
  }
  const loadTerritory = (pgtype) => {
    return new Promise((resolve) => {
      let datatext = {from_id: "list-teritory"}
      if(pgtype !== "edit" && pgtype !== "create") { datatext.lang = chooseLang; }
      headersParams.Authorization = keyAuth6
      let fetchUrl = global.config.urlLink + "/listteritory"
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(datatext)
      }).then(res => {
        if (res.status === 200) return res.json();
      }).then(res => {
        if (res.status === '200') {
          let updateTeritory = res.values.map((item) => ({
            value: item.teritory_name,
            label: item.teritory_name,
          }));
          resolve(updateTeritory);
        }
      }).catch(err => {
        resolve([])
      })
    })
  }
  const loadDataDistributor = (id,pgtype) => {
    return new Promise((resolve) => {
      let datatext = '{';
      datatext += '"from_id":"detail-distributor"';
      datatext += ',"doc_id":'+id;
      if(pgtype !== "edit" && pgtype !== "create") { datatext += ',"lang":"' + chooseLang + '"'; }
      datatext += '}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth;
      var fetchUrl = global.config.urlLink + "/detaildist";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            resolve(resJson.values);
          } else if(resJson.status === global.config.errcodesess) {
            resolve([resJson.status]);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  
  const loadListRegion = (pgtype) => {
    return new Promise((resolve) => {
      var arrRegion = [];
      global.config.regions.forEach(item => {
        arrRegion.push({ value: item, label: item });
      })
      resolve(arrRegion)
    })
  }
  const loadData = async (id, detaildatadist,pgtype) => {
    document.getElementById("loading-screen").style.display = "flex";
    var countryid = "", marketval=[], productval = [], regionname = "", teritoryval = [];
    var diststatus = true;
    var datauserstr = {};
    if(id !== undefined) {
      let [result1, result2, result3, result4, result5, result6, result7] = await Promise.all([loadListCountry(pgtype), loadDataDistributor(id,pgtype), loadListObjTarget(id,pgtype),loadListMarket(pgtype),loadListProduct(pgtype), loadTerritory(pgtype), loadListRegion(pgtype)]);
      
      if(result2.length > 0) {
        if(result2[0] === global.config.errcodesess) { 
          var resultSO = await Logouts();
          if(resultSO.status === "200") {
            window.location.reload();
          }
        } else {
          if(result2[0].dist_status === null) { diststatus = false; }
          else { 
            if(result2[0].status === 1) { diststatus =true; } else { diststatus =false; } 
          }
          countryid = result2[0].id_country;
          marketval = result2[0].market_application_focus; 
          productval = result2[0].product_application
          regionname = result2[0].distributor_region; if(regionname === null || regionname === "null" || regionname === "undefined" || regionname === undefined) { regionname = ""; }
          teritoryval = result2[0].distributor_teritory;
          if(result2[0].market_application_focus === null || result2[0].market_application_focus === "") { marketval = []; } else { marketval = JSON.parse(result2[0].market_application_focus); }
          if(result2[0].product_application === null || result2[0].product_application === "") { productval = []; } else { productval = JSON.parse(result2[0].product_application); }
          if(result2[0].distributor_teritory === null || result2[0].distributor_teritory === "") { teritoryval = []; } else { teritoryval = JSON.parse(result2[0].distributor_teritory); }
          var case_remarks = result2[0].distribution_case_remarks;
          if(case_remarks === null || case_remarks === "null" || case_remarks === "undefined" || case_remarks === undefined) { case_remarks = ""; }
          var distributoraddress = result2[0].distributor_address; if(distributoraddress === null || distributoraddress === "null" || distributoraddress === "undefined" || distributoraddress === undefined) { distributoraddress = ""; }
          
          var filecomplianceurl = result2[0].compliance_doc_url; 
          if(filecomplianceurl !== null && filecomplianceurl !== "null" && filecomplianceurl !== "undefined" && filecomplianceurl !== undefined) { 
            filecomplianceurl = filecomplianceurl;
          } else { filecomplianceurl = ""; }
          
          datauserstr = {
            doc_id: result2[0].distributor_id,
            dist_name: result2[0].distributor_name,
            dist_teritory: teritoryval,
            dist_country_id: countryid,
            dist_region_name: regionname,
            dist_website: result2[0].distributor_website,
            dist_contactname: result2[0].distributor_contactname,
            dist_contactemail: result2[0].distributor_contactemail,
            dist_contactphone: result2[0].distributor_contactphone,
            dist_contactposition: result2[0].distributor_contactposition,
            dist_lead: result2[0].distributor_lead,
            dist_case: result2[0].distribution_case,
            dist_case_remarks: case_remarks,
            dist_aggrement_eng: result2[0].distribution_aggrement_english,
            dist_aggrement_local: result2[0].distribution_aggrement_local,
            dist_efdate_da: result2[0].efective_date_of_da,
            dist_nda_eng: result2[0].nda_english,
            dist_nda_local: result2[0].nda_local,
            dist_market_appl: marketval,
            dist_product_appl: productval,
            dist_product_appl_old: productval,
            dist_status: diststatus,
            dist_address: distributoraddress,
            file_compliance: filecomplianceurl
          };
          detaildatadist = {
            doc_id: result2[0].distributor_id,
            dist_name: result2[0].distributor_name,
            date_da: result2[0].efective_date_of_da,
            lproduct_id: result2[0].product_application,
            page_from: "distributor"
          }
          setDAEngFile((prev) => ({ ...prev, name: result2[0].distribution_aggrement_english }));
          setDALocFile((prev) => ({ ...prev, name: result2[0].distribution_aggrement_local }));
          setNDAEngFile((prev) => ({ ...prev, name: result2[0].nda_english }));
          setNDALocFile((prev) => ({ ...prev, name: result2[0].nda_local }));
          
          setDateInput(new Date(result2[0].efective_date_of_da));
          setDetailDist(detaildatadist);
          setSelected((prevState) => ({
            ...prevState,
            dist_market_appl: marketval,
            dist_product_appl: productval,
            dist_teritory: teritoryval
          }));
          document.getElementById("loading-screen").style.display = "none";
        }
      } else {
        datauserstr = {
          docid: 0,
          dist_name: "",
          dist_teritory: teritoryval,
          dist_country_id: "",
          dist_region_name: "",
          dist_website: "",
          dist_contactname: "",
          dist_contactemail: "",
          dist_contactphone: "",
          dist_contactposition: "",
          dist_lead: "",
          dist_case: "",
          dist_case_remarks: "",
          dist_aggrement_eng: "",
          dist_aggrement_local: "",
          dist_efdate_da: convertDate(new Date()),
          dist_nda_eng: "",
          dist_nda_local: "",
          dist_market_appl: marketval,
          dist_product_appl: productval,
          dist_product_appl_old: productval,
          dist_status: diststatus,
          dist_address: "",
          file_compliance: ""
        };
        document.getElementById("loading-screen").style.display = "none";
      
      }
      setForm(datauserstr);  
      setListRegion(result7);
      getRegionId(result7,regionname)    
      setListCountry(result1);
      getCountryId(result1,countryid);
      setMarketDropdown(result4);
      setProductDropdown(result5);
      setTeritoryDropdown(result6);
      
      viewListObjTarget(result3[0], detaildatadist, pgtype);
      
      if(marketval !== null && marketval.length > 0) {
        getMarketId(result4,JSON.stringify(marketval));
      }
      if (productval !== null && productval.length > 0) {
        getProductId(result5, JSON.stringify(productval)) 
      }
      if (teritoryval !== null && teritoryval.length > 0) {
        getTeritoryId(result6, JSON.stringify(teritoryval)) 
      }
      
    } else {
      let [result1,result4,result5, result6, result7] = await Promise.all([loadListCountry(pgtype),loadListMarket(pgtype),loadListProduct(pgtype), loadTerritory(pgtype), loadListRegion(pgtype)]);
      setListRegion(result7);
      setListCountry(result1);
      setMarketDropdown(result4);
      setProductDropdown(result5);
      setTeritoryDropdown(result6);
      document.getElementById("loading-screen").style.display = "none";
    }
  }
  const createTeritory = (val) => {
    return new Promise((resolve) => {
      var statAdd = false;
      if(teritoryDropdown.length>0) {
        for(var d=0; d<teritoryDropdown.length; d++) {
          if(teritoryDropdown[d].value.toLowerCase() === val.toLowerCase()) {
            statAdd = false;
            break;
          } else {
            statAdd = true;
          }
        }
      } else { statAdd = true; }
      if(statAdd === true) {
        let datatext = {teritory_name: val, status: true, from_id: "add-teritory",lang : chooseLang}
        headersParams.Authorization = keyAuth6
        let fetchUrl = global.config.urlLink + "/addteritory"
        fetch(fetchUrl, {
          method: "POST",
          headers: headersParams,
          body: JSON.stringify(datatext)
        }).then(res => {
          if (res.status === 200) return res.json();
        }).then(res => {
          if (res.status === '200') {
            resolve(res.values)
          }
        }).catch(err => {
          resolve([])
        })
      }
    })
  }
  

  // const handleCreateOption = useCallback(inputValue => {setSelected(prev => ({...prev, dist_teritory: inputValue}))}, [])
  const handleCreate = useCallback(inputValue => {
      const newValue = { value: inputValue.toLowerCase(), label: inputValue };
      setTeritoryDropdown([...teritoryDropdown, newValue]);
      setForm(prev => ({...prev, dist_teritory: [...isSelected.dist_teritory, newValue]}))
      setSelected((e) => ({
        ...e,
        dist_teritory: [...isSelected.dist_teritory, newValue],
      }));
      createTeritory(inputValue);
  }, [isSelected])

  useEffect(() => {
    var docId = "",distName="", efDateDA=new Date(), lproductId= "", pageType="detail",pageRef="distributor";
    if(localStorage.getItem("dulv")===global.config.conflevel[3]) {
      docId = localStorage.getItem("dudst");
    } else {
      if(paramsLoc.state !== null) {
        docId = paramsLoc.state.data?.distributor_id;
        distName = paramsLoc.state.data?.distributor_name;
        efDateDA = paramsLoc.state.data?.efective_date_of_da;
        lproductId = paramsLoc.state.data?.product_application;
        pageType = paramsLoc.state?.type;
        pageRef = paramsLoc.state?.ref;
      } 
    }
    if(docId !== "") {
      var detaildatadist = {
        doc_id: docId,
        dist_name: distName,
        date_da: efDateDA,
        lproduct_id: lproductId,
        page_from: "distributor"
      }
      setDetailDist(detaildatadist);
      setType(pageType);
      setReff(pageRef);
      loadData(docId,detaildatadist,pageType);
      var inpdisabled = true;
      if(pageType === "edit" || pageType === "create") {   
        if(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2]) { 
          inpdisabled = false;
        } 
      }
      setFormDisabled(inpdisabled);
     
    } else {
      window.location.href = "/admin/distributor";
    }
  }, []);

  useEffect(() => {
    setForm((prevState) => ({
      ...prevState,
      dist_efdate_da: convertDate(dateInput),
    }));
  }, [dateInput]);
  return (
    <div className=" bg-gray-100 h-screen flex-1 p-8 overflow-y-auto">
      <div className="flex items-center gap-10 mb-8">
        <div className="flex items-center">
          {localStorage.getItem("dulv")!==global.config.conflevel[3] && 
          <Button
            variant="icon"
            className="mr-5"
            icon="left"
            onClick={() => handleBack(reff)}
            // onClick={() => navigate(-1)}
          />
          }
          {type === "create" ? (
            <h2 className=" text-2xl">{translate.distributor_create.title}</h2>
          ) : (
            <h2 className=" text-2xl">{detaildist.dist_name}</h2>
          )}
        </div>
        {(localStorage.getItem("dulv")!==global.config.conflevel[3])?
        <div className="flex items-center gap-x-4">
          <label
            className={`text-sm text-gray-400 ${
              !form.dist_status && "text-red-500"
            }`}
          >
            {global.config.generalstatus[1]}
          </label>
          <ToggleSwitch
            id="dist_status"
            checked={form.dist_status}
            onClick={toggleHandler}
          />
          <label
            className={`text-sm text-gray-400 ${
              form.dist_status && "text-red-500"
            }`}
          >
            {global.config.generalstatus[0]}
          </label>
        </div>
        : '' }
      </div>

      <form onSubmit={handleSubmit}>
        <div className="flex flex-1 justify-end gap-4 mb-8">
          {formDisabled === false && (
            <Button
              type="submit"
              variant="base"
              title={type === "create" ? translate.distributor_create.button1 : translate.general.button_save}
              className="bg-red-500 text-white"
            />
          )}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 px-10 gap-10 ">
          <div>
            <TextInput
              variant="base"
              type="text"
              // title="Distributor Name"
              title={translate.distributor_create.text_input1}
              name="dist_name"
              id="dist_name"
              value={form.dist_name}
              onChange={onChangeHandler}
              disabled={formDisabled}
              errmsg={formErrors.dist_name}
            />
            <div className="grid grid-cols-2 gap-4">
              <CustomSelect
                // title="Country"
                title={translate.distributor_create.text_input2}
                name="dist_country_id"
                value={isSelected.dist_country_id}
                options={listCountry}
                onChange={onChangeSelect}
                defaultValue={form.dist_country_id}
                disabled={formDisabled}
                errmsg={formErrors.dist_country_id}
              />
              <CustomSelect
                // title="Region"
                title={translate.distributor_create.text_input14}
                name="dist_region_name"
                value={isSelected.dist_region_name}
                options={listRegion}
                onChange={onChangeSelect}
                defaultValue={form.dist_region_name}
                disabled={formDisabled}
                errmsg={formErrors.dist_region_name}
              />
              
            </div>
            <CreatableSelect 
                 isMulti
                 onCreateOption={handleCreate}
                //  title="Territory"
                 title={translate.distributor_create.text_input3}
                 name="dist_teritory"
                 id="dist_teritory"
                 options={teritoryDropdown}
                 value={isSelected.dist_teritory}
                 onChange={onChangeSelect}
                 disabled={formDisabled}
                 errmsg={formErrors.dist_teritory}
              />
            <CustomSelect
              isMulti
              // title="Market(s)"
              title={translate.distributor_create.text_input4}
              name="dist_market_appl"
              value={isSelected.dist_market_appl}
              options={marketDropdown}
              onChange={onChangeSelect}
              defaultValue={form.dist_market_appl}
              disabled={formDisabled}
              errmsg={formErrors.dist_market_appl}
            />
            <CustomSelect
              isMulti
              // title="Product(s)"
              title={translate.distributor_create.text_input5}
              name="dist_product_appl"
              value={isSelected.dist_product_appl}
              options={productDropdown}
              onChange={onChangeSelect}
              defaultValue={form.dist_product_appl}
              disabled={formDisabled}
              errmsg={formErrors.dist_product_appl}
            />
            <TextInput
              type="text"
              variant="textarea"
              // title="Address"
              title={translate.distributor_create.text_input19}
              name="dist_address"
              id="dist_address"
              value={form.dist_address}
              onChange={onChangeHandler}
              disabled={formDisabled}
              height="100px"
            />
            <TextInput
              type="text"
              variant="base"
              // title="Website"
              title={translate.distributor_create.text_input10}
              name="dist_website"
              id="dist_website"
              value={form.dist_website}
              onChange={onChangeHandler}
              disabled={formDisabled}
            />
            <div className="p-1 text-gray-500 border-x-0 border-t-0 border-b-2 mb-5">{translate.distributor_create.text_input18}</div>
            <TextInput
              type="text"
              variant="base"
              // title="Contact Name"
              title={translate.distributor_create.text_input6}
              name="dist_contactname"
              id="dist_contactname"
              value={form.dist_contactname}
              onChange={onChangeHandler}
              disabled={formDisabled}
              className="ml-5"
            />
            <TextInput
              type="text"
              variant="base"
              // title="Contact Email"
              title={translate.distributor_create.text_input7}
              name="dist_contactemail"
              id="dist_contactemail"
              value={form.dist_contactemail}
              onChange={onChangeHandler}
              disabled={formDisabled}
              className="ml-5"
            />
            <TextInput
              type="text"
              variant="base"
              // title="Position"
              title={translate.distributor_create.text_input8}
              name="dist_contactposition"
              id="dist_contactposition"
              value={form.dist_contactposition}
              onChange={onChangeHandler}
              disabled={formDisabled}
              className="ml-5"
            />
            <TextInput
              type="text"
              variant="base"
              // title="Contact Number"
              title={translate.distributor_create.text_input9}
              name="dist_contactphone"
              id="dist_contactphone"
              value={form.dist_contactphone}
              onChange={onChangeHandler}
              disabled={formDisabled}
              className="ml-5"
            />
            
          </div>

          <div>
            <div className="flex flex-col mb-4">
              <label className="text-gray-500 mb-2">
                {translate.distributor_create.text_input15}
              </label>
              <div className="flex items-center">
                <div className="flex px-4 gap-4">
                  {fileDAEng.name ? (
                    <div className="bg-white shadow-3xl rounded-lg p-8  text-center w-50">
                      <div className="flex flex-col justify-center">
                        {loading.da_eng === true ? (
                          <Loading className="w-10 h-10 animate-spin " />
                        ) : (
                          <>
                            <a
                              onClick={()=> handleOpenFile(type !== "create"? fileDAEng.name : fileDAEng.url, "view")}
                              target="_blank"
                              className="my-2 cursor-pointer self-center"
                              rel="noreferrer"
                            >
                              <div className="bg-black fill-white p-2.5 mb-5 rounded-xl block w-min">
                                <Document />
                              </div>
                            </a>
                            <div className="block mb-2.5 w-24">
                              <label className="text-xs block text-gray-500 italic line-clamp-1">
                                {fileDAEng.type === "application/pdf" && "PDF"}
                              </label>
                              <label className="text-xs block text-gray-500">
                                { cleaningContent(fileDAEng.name) }
                                {/* <ReplaceAllContent content={fileDAEng.name} before={["_","-","%20",global.config.asseturl+"/distributor/"]} after={[" "," "," ",""]}/> */}
                              </label>
                              {!formDisabled && (
                                <p
                                  className="text-red-500 text-xs cursor-pointer"
                                  onClick={() => removeFile("da_eng")}
                                >
                                  {translate.distributor_create.label3}
                                </p>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ) : !formDisabled? (
                    <div className="p-8  text-center w-50">
                      <div className="p-8 block w-min self-center cursor-pointer">
                        <Add
                          className="mb-5"
                          onClick={() => handleClickUpload("da_eng")}
                        />
                      </div>
                      <label className="text-sm text-gray-500 block text-center">
                        {/* Add files */}
                        {translate.distributor_create.label1}
                      </label>
                      <p>{translate.distributor_create.label4}</p>
                    </div>
                  ) : (
                    <div className="p-8  text-center w-50">
                      <div className="p-8 block w-min self-center">
                        <Minus
                          className="mb-5"
                        />
                      </div>
                     <label className="text-sm text-gray-500 block text-center">
                        &nbsp;
                      </label>
                      <p>{translate.distributor_create.label4}</p>
                    </div>
                  )
                }
                </div>
                <input
                  type="file"
                  ref={hiddenPDFInp_dist_agree_eng}
                  name="dist_aggrement_eng"
                  onChange={handleUploadFileChange}
                  style={{ display: "none" }}
                  hidden
                  accept="application/pdf"
                />
                {/* da local */}
                <div className="flex px-4 gap-4">
                  {fileDALocal.name ? (
                    <div className="bg-white shadow-3xl rounded-lg p-8  text-center w-50">
                      <div className="flex flex-col justify-center">
                        {loading.da_loc === true ? (
                          <Loading className="w-10 h-10 animate-spin " />
                        ) : (
                          <>
                            <a
                              onClick={()=> handleOpenFile(type !== "create" ? fileDALocal.name : fileDALocal.url,"view")}
                              target="_blank"
                              className="my-2 cursor-pointer self-center"
                              rel="noreferrer"
                            >
                              <div className="bg-black fill-white p-2.5 mb-5 rounded-xl block w-min">
                                <Document />
                              </div>
                            </a>
                            <div className="block mb-2.5 w-24">
                              <label className="text-xs block text-gray-500 italic line-clamp-1">
                                {fileDALocal.type === "application/pdf" &&
                                  "PDF"}
                              </label>
                              <label className="text-xs block text-gray-500 line-clamp-1">
                              <ReplaceAllContent content={fileDALocal.name} before={["_","-","%20",global.config.asseturl+"/distributor/"]} after={[" "," "," ",""]}/>
                              </label>
                              {!formDisabled && (
                                <p
                                  className="text-red-500 text-xs cursor-pointer"
                                  onClick={() => removeFile("da_loc")}
                                >
                                  {/* Remove */}
                                  {translate.distributor_create.label3}
                                </p>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ) : !formDisabled? (
                    <div className="p-8  text-center w-50">
                      <div className="p-8 block w-min self-center cursor-pointer">
                        <Add
                          className="mb-5"
                          onClick={() => handleClickUpload("da_loc")}
                        />
                      </div>
                      <label className="text-sm text-gray-500 block text-center">
                        {/* Add files */}
                        {translate.distributor_create.label1}
                      </label>
                      <p>{translate.distributor_create.label2}</p>
                    </div>
                  ) : (
                    <div className="p-8  text-center w-50">
                      <div className="p-8 block w-min self-center">
                        <Minus
                          className="mb-5"
                        />
                      </div>
                     <label className="text-sm text-gray-500 block text-center">
                        &nbsp;
                      </label>
                      <p>{translate.distributor_create.label2}</p>
                    </div>
                  )}
                </div>
                <input
                  type="file"
                  ref={hiddenPDFInp_dist_agree_loc}
                  name = "dist_aggrement_local"
                  onChange={handleUploadFileChange}
                  style={{ display: "none" }}
                  hidden
                  accept="application/pdf"
                />
              </div>
            </div>
            
            <div className="flex flex-col mb-4">
              <label className="text-gray-500 mb-2">{translate.distributor_create.text_input20 + " " + new Date().getFullYear()}</label>
              <div className="flex items-center">
                {/* COMPLIANCE FILE */}
                <div className="flex px-4 gap-4">
                  {form.file_compliance !== "" ? (
                     <div className="p-8  text-center w-50">
                        <div className="p-8 block w-min self-center">
                        <a
                              onClick={()=> handleOpenFile(form.file_compliance,"view")}
                              target="_blank"
                              className="my-2 cursor-pointer self-center"
                              rel="noreferrer"
                            >
                            <div className="bg-black fill-white p-2.5 mb-2 rounded-xl block w-min">
                              <Document />
                            </div>
                          </a>
                        </div>
                      
                      </div>
                  ) : (
                    <div className="p-8  text-center w-50">
                      <div className="p-8 block w-min self-center">
                        <Minus
                          className="mb-5"
                        />
                      </div>
                    
                    </div>
                  )}
                 
                </div>
              </div>
            </div>

            <div className="flex flex-col mb-4">
              <label className="text-gray-500 mb-2">{translate.distributor_create.text_input16}</label>
              <div className="flex items-center">
                {/* NDA Eng */}
                <div className="flex px-4 gap-4">
                  {fileNDAEng.name ? (
                    <div className="bg-white shadow-3xl rounded-lg p-8 inline-block text-center cursor-pointer w-50">
                      <div className="flex flex-col justify-center">
                        {loading.nda_eng === true ? (
                          <Loading className="w-10 h-10 animate-spin " />
                        ) : (
                          <>
                            <a
                              onClick={()=> handleOpenFile(type !== "create" ? fileNDAEng.name : fileNDAEng.url,"view")}
                              target="_blank"
                              className="my-2 cursor-pointer self-center"
                              rel="noreferrer"
                            >
                              <div className="bg-black fill-white p-2.5 mb-5 rounded-xl block w-min">
                                <Document />
                              </div>
                            </a>
                            <div className="block mb-2.5 w-24">
                              <label className="text-xs block text-gray-500 italic line-clamp-1">
                                {fileNDAEng.type === "application/pdf" && "PDF"}
                              </label>
                              <label className="text-xs block text-gray-500 line-clamp-1">
                              <ReplaceAllContent content={fileNDAEng.name} before={["_","-","%20",global.config.asseturl+"/distributor/"]} after={[" "," "," ",""]}/>
                              </label>
                              {!formDisabled && (
                                <p
                                  className="text-red-500 text-xs cursor-pointer"
                                  onClick={() => removeFile("nda_eng")}
                                >
                                  {/* Remove */}
                                  {translate.distributor_create.label3}
                                </p>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ) : !formDisabled? (
                    <div className="p-8  text-center w-50">
                      <div className="p-8 block w-min self-center cursor-pointer">
                        <Add
                          className="mb-5"
                          onClick={() => handleClickUpload("nda_eng")}
                        />  
                      </div>
                      <label className="text-sm text-gray-500 block text-center">
                        {/* Add files */}
                        {translate.distributor_create.label1}
                      </label>
                      <p>{translate.distributor_create.label4}</p>
                    </div>
                  ) : (
                    <div className="p-8  text-center w-50">
                      <div className="p-8 block w-min self-center">
                        <Minus
                          className="mb-5"
                        />
                      </div>
                     <label className="text-sm text-gray-500 block text-center">
                        &nbsp;
                      </label>
                      <p>{translate.distributor_create.label4}</p>
                    </div>
                  )}
                  <input
                    type="file"
                    ref={hiddenPDFInp_nda_eng}
                    name = "dist_nda_eng"
                    onChange={handleUploadFileChange}
                    style={{ display: "none" }}
                    hidden
                    accept="application/pdf"
                  />
                </div>
                {/* NDA Loc */}
                <div className="flex px-4 gap-4">
                  {fileNDALoc.name ? (
                    <div className="bg-white shadow-3xl rounded-lg p-8 inline-block text-center cursor-pointer">
                      <div className="flex flex-col justify-center">
                        {loading.nda_loc === true ? (
                          <Loading className="w-10 h-10 animate-spin " />
                        ) : (
                          <>
                            <a
                              onClick={()=> handleOpenFile(type !== "create" ? fileNDALoc.name : fileNDALoc.url, "view")}
                              target="_blank"
                              className="my-2 cursor-pointer self-center"
                              rel="noreferrer"
                            >
                              <div className="bg-black fill-white p-2.5 mb-5 rounded-xl block w-min">
                                <Document />
                              </div>
                            </a>
                            <div className="block mb-2.5 w-24">
                              <label className="text-xs block text-gray-500 italic line-clamp-1">
                                {fileNDALoc.type === "application/pdf" && "PDF"}
                              </label>
                              <label className="text-xs block text-gray-500 line-clamp-1">
                              <ReplaceAllContent content={fileNDALoc.name} before={["_","-","%20",global.config.asseturl+"/distributor/"]} after={[" "," "," ",""]}/>
                              </label>
                              {!formDisabled && (
                                <p
                                  className="text-red-500 text-xs cursor-pointer"
                                  onClick={() => removeFile("nda_loc")}
                                >
                                  {translate.distributor_create.label3}
                                </p>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ) : !formDisabled? (
                    <div className="p-8  text-center w-50">
                      <div className="p-8 block w-min self-center cursor-pointer">
                        <Add
                          className="mb-5"
                          onClick={() => handleClickUpload("nda_loc")}
                        />
                      </div>
                      <label className="text-sm text-gray-500 block text-center">
                        {translate.distributor_create.label1}
                      </label>
                      <p>{translate.distributor_create.label2}</p>
                    </div>
                  ) : (
                    <div className="p-8  text-center w-50">
                      <div className="p-8 block w-min self-center">
                        <Minus
                          className="mb-5"
                        />
                      </div>
                     <label className="text-sm text-gray-500 block text-center">
                        &nbsp;
                      </label>
                      <p>{translate.distributor_create.label2}</p>
                    </div>
                  )}
                  <input
                    type="file"
                    ref={hiddenPDFInp_nda_loc}
                    name = "dist_nda_local"
                    onChange={handleUploadFileChange}
                    style={{ display: "none" }}
                    hidden
                    accept="application/pdf"
                  />
                </div>
              </div>
            </div>
            <div className="mt-12 mb-4">
              <label className="block text-gray-500 mb-2">
                {/* Effective Date of DA */}
                {translate.distributor_create.text_input17}
              </label>
              <DatePicker
                wrapperClassName=" rounded-md"
                selected={"" || dateInput}
                onChange={onChangeDateHandler}
                className={`w-3/4 p-2 rounded-md border text-gray-500`}
                disabled={formDisabled}
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <TextInput
                type="text"
                variant="base"
                // title="Distribution Case"
                title={translate.distributor_create.text_input11}
                name="dist_case"
                id="dist_case"
                value={form.dist_case}
                onChange={onChangeHandler}
                disabled={formDisabled}
              />
              <TextInput
                type="text"
                variant="base"
                // title="Distributor Manager"
                title={translate.distributor_create.text_input12}
                name="dist_lead"
                id="dist_lead"
                value={form.dist_lead}
                onChange={onChangeHandler}
                disabled={formDisabled}
              />
            </div>
            <TextInput
              type="text"
              variant="textarea"
              // title="Distribution Case Remarks"
              title={translate.distributor_create.text_input13}
              name="dist_case_remarks"
              id="dist_case_remarks"
              value={form.dist_case_remarks}
              onChange={onChangeHandler}
              disabled={formDisabled}
              height="135px"
            />
          </div>
          
        </div>
      </form>

      { type !== "create" && <div className="px-10 gap-10 mb-10 mt-5">
            <div className="mb-3 flex items-center">
              <label className="text-gray-500 mr-3">{translate.distributor_create.label5}</label>
              {(formDisabled === false && localStorage.getItem("dulv")!==global.config.conflevel[3]) && 
                  <Button
                    variant="icon"
                    icon="plus"
                    onClick={() => handlePopUpModal("create", "target",detaildist,{
                      // target_year: "",
                      target_sdate: "",
                      target_edate: "",
                      target_qty: "",
                      product_id: "",
                      distributor_id: detaildist.docId,
                      status: true,
                      page_from: "distributor"
                    })
                  }
                />
              }
            </div>
            <div>
              <DataTable
                  columns={targetheader}
                  data={targetdata}
                  fixedHeader
                  noDataComponent={<NoRecords style={{minHeight: "50px"}}/>}
                  customStyles={{
                    headRow: {
                      style: {
                        borderBottomWidth: "3px",
                        borderBottomStyle: "solid",
                        fontWeight: "bold",
                        backgroundColor: "#F5F6FA",
                        color: "#8A92A6",
                      },
                      denseStyle: {
                        minHeight: "32px",
                      },
                    },
                  }}
                />
            </div>
      </div>
     }
      {isModal && (
        <ObjectiveKPIModal
          content={modalContent}
          onClick={() => setModal(!isModal)}
          onBackgroundClick={() => setModal(!isModal)}
        />
      )}
    </div>
    
  );
};

export default DistributorDetail;
