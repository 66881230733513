import React from "react";

import { ILForbidden } from "../../../assets";

const ErrorScreenForbidden = () => {
  return (
    <div className=" bg-gray-100 p-8 h-screen overflow-y-auto flex-1 justify-center items-center flex">
      <div className="md:flex self-center justify-center items-center gap-5 w-1/2">
        <img src={ILForbidden} width={150} className="mb-4 sm:mb-0 m-auto" alt=""/>
        <div className=" text-ellipsis text-center sm:text-justify">
          <h2 className=" text-3xl mb-2">Not Authorized</h2>
          <p className=" text-gray-400">
            You are not authorized to view this page. If you think this is a
            mistake, please contact your PIC.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ErrorScreenForbidden;
