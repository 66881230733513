import React from "react";
import { Button, Modal } from "../../../../components";

import { BsTranslate } from "react-icons/bs";
import { ChinaFlag, EnglishFlag } from "../../../../assets";
import { useDispatch, useSelector } from "react-redux";
import {
  cnLang,
  enLang,
  translatingToEn,
} from "../../../../features/translate/translateSlice";

const PopupTranslate = ({ title, onBackgroundClick, onClick }) => {
  const chooseLang = useSelector((state) => state.translate.lang);
  const dispatch = useDispatch();

  const handleChooseLanguage = (e) => {
    if (chooseLang === "en") {
      dispatch(cnLang());
    } else {
      if (chooseLang === "cn") dispatch(enLang());
    }
  };

  return (
    <div className="absolute flex -top-1 gap-x-5 right-20 mb-5 bg-white border-stone-300 rounded-br-md rounded-bl-md border-2 overflow-hidden">
      <div className=" bg-red-500 flex items-center justify-center p-1 flex-1">
        <BsTranslate color="white" size={25} />
      </div>
      <div className=" text-sm bg-white p-2 flex items-center">
        <Button
          variant="radio"
          src={EnglishFlag}
          width={20}
          height={20}
          checked={chooseLang === "en"}
          onChange={() => handleChooseLanguage("en")}
          className={`${chooseLang === "en" && "bg-red-200"}`}
        />
        <Button
          variant="radio"
          src={ChinaFlag}
          width={20}
          height={20}
          checked={chooseLang === "cn"}
          onChange={() => handleChooseLanguage("cn")}
          className={`${chooseLang === "cn" && "bg-red-200"}`}
        />
      </div>
    </div>
  );
};

export default PopupTranslate;
