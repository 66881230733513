const NumbersFormat = ({num,type}) => {
    var numzone = 'en-US';
    var numoption = '{}';
    if(type === "floats") { 
        numoption = '{"style": "decimal"}';
    } else if(type === "integers") { 
        numoption = '{}';
    } 
    var num_ob = num;
    var resNumberFormat = new Intl.NumberFormat(numzone, JSON.parse(numoption)).format(num_ob);
    return resNumberFormat;
}
export default NumbersFormat;