import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, ToggleSwitch, DateTimeFormats, Loading } from "../../../components";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../features/translate/translateSlice";
import Logouts from "../../../components/logout"; 

const keyAuth = global.config.typecode[11] + global.config.authBearerKey;
const keyAuth2 = global.config.typecode[3] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const BusinessPlanList = () => {
  const translate = useSelector(staticTranslate);
  const chooseLang = useSelector((state) => state.translate.lang)
  const navigate = useNavigate();
  const [isChecked, setChecked] = useState([]);
  // const [pending, setPending] = useState(false);
  const [listBusinessPlan, setListBusinessPlan] = useState([]);
  const [listDistributor, setListDistributor] = useState([]);
  const [filterData, setFilterData] = useState({statid:"",distributorid:"",countryid:"",rowpage:0});
  const [loading, setLoading] = useState(false);
  const [defRowPage, setDefRowPage] = useState(0);
  const paramsLoc = useLocation();
  if(paramsLoc.state !== null) { var { dataref } = paramsLoc.state; }

  const handleRowsPerPageChange = (val) => {
    setFilterData({ ...filterData, rowpage: val });
    setDefRowPage(val);
  }
  const updtlistStat = (row) => {
    let checkarr = {};
    row.forEach((records) => {
      checkarr[records.bplan_id] = records.status;
    });
    setChecked(checkarr);
  };

  const handleToggle = (id, stat) => {
    let statcheck = 1,
      statsend = true;
    if (stat === 1) {
      statcheck = 0;
      statsend = false;
    } else {
      statcheck = 1;
      statsend = true;
    }
    setChecked((prevState) => ({ ...prevState, [id]: statcheck }));
    let datatext =
      '{"doc_id":"' +
      id +
      '","status": "' +
      statsend +
      '","from_id":"edit-status-businessplan","lang":"'+chooseLang+'"}';
    let data = JSON.parse(datatext);
    let fetchUrl = global.config.urlLink + "/editbusiness";
    headersParams.Authorization = keyAuth;
    fetch(fetchUrl, {
      method: "POST",
      headers: headersParams,
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
      })
      .then((resJson) => {
        return false;
      })
      .catch((err) => {
        return false;
      });
  };
  const removeBusinessPlan = (id) => {
    if (window.confirm(`${translate.business_plan.alert_delete}`)) {
      document.getElementById("loading-screen").style.display = "flex";
      let fetchUrl = global.config.urlLink + "/deletebusiness/" + id;
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "GET",
        headers: headersParams,
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((res) => {
          if (res.status === "200") {
            document.getElementById("loading-screen").style.display = "none";
            window.location.href = "/businessplan";
          }
      }).catch((err) => { 
          console.log(err);
          document.getElementById("loading-screen").style.display = "none";
      });
    } 
  };
  const handleSelect = async (e) => {
    var name = e.target.name;
    var value = e.target.value;
    var result1a;
    if(name === "distributorid") {
      result1a = await listBusinessPlanData(filterData.statid,value,filterData.countryid);
    } else if(name === "statid") {
      result1a = await listBusinessPlanData(value,filterData.distributorid,filterData.countryid);
    }
    setListBusinessPlan(result1a);
    setFilterData({ ...filterData, [name]: value });

  }
  const handleDetailClick = (resList,type) => {
    navigate("/businessplan/detail", {
      state: { data: resList, type: type, filter: filterData },
    })
  }
  const loadDistributor = (country) => {
    return new Promise(resolve => {
      if(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2]) {
        var datatext ='{';
        datatext += '"from_id":"list-distributor","stat_id":1';
        datatext += ',"lang":"'+chooseLang+'"';
        if(country !== "") { datatext += ',"country_id":"'+country+'"'; }
        datatext += '}';
        var data = JSON.parse(datatext); 
        headersParams.Authorization = keyAuth2;
        
        fetch(global.config.urlLink+"/listdist", {
          method: 'POST',
          headers: headersParams, 
          body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJson => {
            if(resJson.status === "200") {
              resolve(resJson.values);
            } else {
              resolve([]);
            }
        }).catch((err) => {
            resolve([]);
        });
      } else { resolve([]); }
    });
  }
  const listBusinessPlanData = (stat,distributor,country) => {
    return new Promise(resolve => {
      var fetchUrl = global.config.urlLink + "/listbusiness";
      var dataText = '{';
      dataText += '"from_id": "list-businesspland"';
      dataText += ',"lang":"'+chooseLang+'"';
      if(stat !== "") { dataText += ',"stat_id":'+parseInt(stat); }
      if(distributor !== "") { dataText += ',"dist_id":"'+distributor+'"'; }
      if(country !== "") { dataText += ',"country_id":"'+country+'"'; }
      dataText += '}';

      let data = JSON.parse(dataText);
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((res) => {
          if (res.status === "200") {
            resolve(res.values);
          } else if(res.status === global.config.errcodesess) {
            resolve([res.status]);
          } else { resolve([])}
      }).catch((err) => {
        resolve([]);
      });
    });
  };
  const loadData = async () => {
    var idDist = "", idCntry="", idStat="", idRowPage=defRowPage;
    if(localStorage.getItem("dulv")===global.config.conflevel[3]) { idDist = localStorage.getItem("dudst"); idStat=1; } 
    else if(localStorage.getItem("dulv")===global.config.conflevel[2]) { idCntry = localStorage.getItem("ducr"); }

    if(dataref !== undefined) { idDist = dataref.distributorid; idCntry=dataref.countryid; idStat=dataref.statid; idRowPage=dataref.rowpage; }
    else { idRowPage = 10; }

    let [result1, result2] = await Promise.all([listBusinessPlanData(idStat,idDist,idCntry), loadDistributor(idCntry)]);
    if(result1) {
      if(result1.length > 0 &&  result1[0] === global.config.errcodesess) { 
        var resultSO = await Logouts();
        if(resultSO.status === "200") {
          window.location.reload();
        }
      } else {
      
        setLoading(false); 
        updtlistStat(result1);
        setListBusinessPlan(result1);
      }
    }
    setListDistributor(result2);
    // setFilterData({ ...filterData, distributorid: idDist, countryid: idCntry });
    setFilterData({ statid: idStat, distributorid: idDist, countryid: idCntry, rowpage: idRowPage });
    setDefRowPage(idRowPage);
    
  }

  var optDistributor = [];
  if(listDistributor !== undefined) {
    for(var c=0; c<listDistributor.length; c++) {
      optDistributor.push(<option value={listDistributor[c].distributor_id} key={listDistributor[c].distributor_id}>{listDistributor[c].distributor_name}</option>)
    }
  }
  useEffect(() => {
    setLoading(true); 
    loadData();
  }, []);
  return (
    <div className="flex-1 h-screen bg-gray-100 overflow-y-auto p-8">
      <div className="flex items-center mb-8">
        <h2 className="text-2xl">{translate.business_plan.title}</h2>
        {
        //(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2])? (
        <Button
          variant="icon"
          icon="plus"
          className="ml-5"
          onClick={() =>
            navigate("/businessplan/create", { state: { type: "create", filter: filterData } })
          }
        />
        //) : ''
      }
      </div>
      {(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2])? 
      <div className="bg-white shadow-3xl flex flex-1 p-4 gap-x-4 rounded-lg mb-2">
        <p className=" text-gray-400 font-bold">{translate.general.filter}: </p>
        <select name="statid" id="statid" className="bg-white text-gray-400 outline-none" defaultValue=""
          value={filterData.statid} 
          onChange={handleSelect}
        >
          <option value="">{translate.general.filter_status}</option>
          <option value="1">{global.config.generalstatus[0]}</option>
          <option value="0">{global.config.generalstatus[1]}</option>
        </select>
        <select name="distributorid" id="distributorid" className="bg-white text-gray-400 outline-none" defaultValue=""
            value={filterData.distributorid} 
            onChange={handleSelect}
            
          >
            <option value="">{translate.general.filter_distributor}</option>
            {optDistributor}
        </select>
      </div>
      : '' }
      
      {loading ? (
        <div><Loading className="h-10 w-10 mt-10"/></div>
      ) : (
        <div className="gap-2 ">
          {
          (listBusinessPlan.length > 0)?
            listBusinessPlan.map((item, _) => (
              <div key={_}>
                {item ? (
                  <Card key={_} className="p-5 mb-5">
                    <div 
                      className=" mb-4 flex flex-1 justify-between items-center cursor-pointer"
                      onClick={() => handleDetailClick(item,"detail")}  
                    >
                      <div className="items-center gap-5">
                        <h2 className="text-xl font-semibold line-clamp-1">
                          {item.bplan_title}
                        </h2>
                      </div>
                    </div>
                    <div
                      className=" cursor-pointer"
                      onClick={() => handleDetailClick(item,"detail")}
                    >
                      
                      <div className="text-sm text-gray-400 mb-5 mt-2">
                        <span>{translate.business_plan.label1} : <DateTimeFormats datez = {new Date(item.created_date)} type={"datetime-short"} /> </span>
                        <span>{translate.business_plan.label2} {item.distributor_name} </span>

                      </div>
                      <div className=" text-justify line-clamp-3 text-gray-400 text-sm mb-8">
                        {item.bplan_content.replace( /(<([^>]+)>)/ig, '').substring(0,500)}
                        {(item.bplan_content.replace( /(<([^>]+)>)/ig, '')).length > 500? '...':''}
                      </div>
                    </div>
                    <div className="flex flex-1 ">
                      <div className="flex flex-1">
                      {(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2])?    
                        <>
                        <FaEdit
                          className="hover:fill-red-500 cursor-pointer w-4 h-4 mr-5 fasize"
                          onClick={() =>
                            navigate("/businessplan/create", {
                              state: { type: "edit", data: item, filter: filterData },
                            })
                          }
                        />
                        <FaTrash
                            onClick={() => removeBusinessPlan(item.bplan_id)}
                            className="hover:fill-red-500 cursor-pointer mr-5 fasize"
                          />
                          <div style={{marginTop: "-8px"}} >
                          <ToggleSwitch
                            id={item.bplan_id}
                            checked={isChecked[item.bplan_id] || ""}
                            onClick={() => handleToggle(item.bplan_id, isChecked[item.bplan_id])} 
                          />
                          </div>
                          </>
                        : //(localStorage.getItem("dutk") === item.created_by)? 
                          <>
                          <FaEdit
                            className="hover:fill-red-500 cursor-pointer w-4 h-4 mr-5 fasize"
                            onClick={() =>
                              navigate("/businessplan/create", {
                                state: { type: "edit", data: item, filter: filterData },
                              })
                            }
                          />
                          </>
                        //: ''
                        }
                      </div>
                      <div className=" flex justify-end">
                      <Button
                        variant="base"
                        // title="Read more..."
                        title={translate.business_plan.button1}
                        className="bg-red-500 text-white" 
                        onClick={() => handleDetailClick(item,"detail")}
                        // onClick={() =>
                        //   navigate("/businessplan/detail", {
                        //     state: { data: item, filter: filterData },
                        //   })
                        // }
                      />
                      </div>
                    </div>
                  </Card>
                ) : (
                  <Card key={_} className="p-5"><p>{translate.general.label_nodata}</p></Card>
                )}
              </div>
            ))
          : (
            <Card key="0" className="p-5 text-center w-full"><p>{translate.general.label_nodata}</p></Card>
          )
        }
        </div>
      )}
    </div>
  );
};

export default BusinessPlanList;
