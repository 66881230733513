import React from "react";
import { components } from "react-select";
import CreateableSelect from 'react-select/creatable'
import { FormErrors } from "../../components";

const CreatableSelect = ({
  title = "",
  defaultValue = {},
  name = "",
  id = "",
  value = {},
  onChange = () => {},
  options = {},
  isMulti,
  onCreateOption,
  disabled,
  errmsg = "",
  className = "",
}) => {
  const Control = ({ children, ...rest }) => (
    <components.Control {...rest} className={`py-0.5 text-gray-500 ${className}`}>
      {children}
    </components.Control>
  );

  const customStyle = {
    control: (css, state) => ({
      ...css,
      borderRadius: "0.5rem",
      borderColor: "none",
      backgroundColor: state.isDisabled ? "#F9FAFB" : "#FFFFFF",
      "&:hover": { borderColor: "#9ca3af" },
    }),
    singleValue: (css) => ({ ...css, color: "#6b7280" }),
    placeholder: (css) => ({ ...css, color: "#D1D5DB",fontSize: "0.875rem" }),
  };

  return (
    <div className="mb-4 mx-1">
      <label className="block text-gray-500 mb-2">{title}</label>
      <CreateableSelect
        onCreateOption={onCreateOption}
        id={id} 
        isDisabled={disabled}
        isMulti={isMulti}
        defaultValue={defaultValue}
        styles={customStyle}
        name={name}
        options={options}
        value={value}
        onChange={onChange}
        components={{ Control }}
      />
      <div><FormErrors formErrors={errmsg}/></div>
    </div>
  );
};

export default CreatableSelect;
