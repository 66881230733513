import { combineReducers, configureStore } from "@reduxjs/toolkit";
import translateReducer from "../features/translate/translateSlice";
import utilReducer from "../features/utils/utilSlice";

import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import { thunk } from "redux-thunk";
import filterReducer from "../features/filters/filterSlice";

const reducers = combineReducers({
  translate: translateReducer,
  utils: utilReducer,
  filters: filterReducer
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return [thunk]
  },
});
