import React from "react";

const FormErrors = ({ formErrors }) => {
  return (
    <div className={`w-full text-left`}>
      { formErrors !== ''? <div className="text-xs w-full rounded mt-2" style={{color:"#FA2323"}}>{formErrors}</div> : '' }
    </div>
  );
};

export default FormErrors;