import React, { useEffect, useState } from "react";

import DataTable from "react-data-table-component";
import useScrollbarSize from "react-scrollbar-size";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Button, ButtonMailTo, Card, ToggleSwitch, Loading, NoRecords } from "../../../components";

import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../features/translate/translateSlice";
import Logouts from "../../../components/logout"; 

const keyAuth = global.config.typecode[3] + global.config.authBearerKey;
const keyAuth2 = global.config.typecode[1]+global.config.authBearerKey;
const keyAuth3 = global.config.typecode[10]+global.config.authBearerKey;
const headersParams = global.config.headersparam;

const DistributorList = () => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector((state) => state.translate.lang)
  const { height } = useScrollbarSize();
  const navigate = useNavigate();
  const [isChecked, setChecked] = useState([]);
  const [dataDistributor, setDataDistributor] = useState([]);
  const [pending, setPending] = useState(true);
  const [listCountry, setListCountry] = useState([]);
  const [listMarket, setListMarket] = useState([]);
  const [listRegion, setListRegion] = useState([]);
  const [filterData, setFilterData] = useState({statid:"",countryid:"",marketid:"",regionid:"",rowpage:0});  
  const [defRowPage, setDefRowPage] = useState(0);
  const paramsLoc = useLocation();
  if(paramsLoc.state !== null) { var { dataref } = paramsLoc.state; }

  const handleRowsPerPageChange = (val) => {
    setFilterData({ ...filterData, rowpage: val });
    setDefRowPage(val);
  }

  const updtlistStat = (row) => {
    var checkarr = {};
    row.forEach((records) => {
      checkarr["dstat_"+records.distributor_id] = records.status;
    })
    setChecked(checkarr);
  };

  const handleDeleteDistributor = (id) => {
    if (window.confirm(`${translate.distributor_list.alert_delete}`)) {
      document.getElementById("loading-screen").style.display = "flex";
      let fetchUrl = global.config.urlLink;
      fetchUrl += "/deletedist/" + id;
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "GET",
        headers: headersParams,
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then((res) => {
          if (res.status === "200") {
            document.getElementById("loading-screen").style.display = "none";
            //window.location.href = "/admin/country";
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
          document.getElementById("loading-screen").style.display = "none";
        });
    } else {
    }
  };
  const handleToggle = (id, stat) => {
    let statcheck = 1, statsend = true;
    if (stat === 1) { statcheck = 0; statsend = false; } 
    else { statcheck = 1; statsend = true; }
    setChecked(prevState => ({ ...prevState, ["dstat_"+id]: statcheck }));
    let datatext = '{"doc_id":"' + id + '","dist_status": "' + statsend + '","from_id":"edit-status-distributor"}';
    let data = JSON.parse(datatext);
    let urlFetch = global.config.urlLink + "/editdist";
    headersParams.Authorization = keyAuth;

    fetch(urlFetch, {
      method: "POST",
      headers: headersParams,
      body: JSON.stringify(data),
    }).then((res) => {
        if (res.status === 200) return res.json();
    }).then((resJson) => {
        return false;
    }).catch((err) => {
        return false;
    });
  };
  const handleSelect = async (e) => {
    setPending(true);
    var name = e.target.name;
    var value = e.target.value;
    var result1a;
    if(name === "countryid") {
      result1a = await listDistributorData(filterData.statid,value,filterData.regionid);
    } else if(name === "statid") {
      result1a = await listDistributorData(value,filterData.countryid,filterData.regionid);
    } else if(name === "marketid") {
      var result1atemp = await listDistributorData(filterData.statid,filterData.countryid,filterData.regionid);
      result1a = result1atemp?.filter(
        (markets) =>  markets.market_application_focus.includes(value) 
      );
    } else if(name === "regionid") {
      result1a = await listDistributorData(filterData.statid,filterData.countryid,value);
      // var result1btemp = await listDistributorData(filterData.statid,filterData.countryid,value);
      // if(value !== "") {
      //   result1a = result1btemp?.filter(
      //     (region) =>  region?.distributor_region === value
      //   );
      // } else {
      //   result1a = result1btemp;
      // }
    }
    if(result1a.length > 0 && result1a[0] === global.config.errcodesess) { result1a = []; }
    setDataDistributor(result1a);
    setFilterData({ ...filterData, [name]: value });
    setPending(false);
  }
  const handleDetailClick = (resList,type) => {
    navigate("/admin/distributor/detail", {
      state: { data: resList, type: type, filter: filterData, ref: "distributor" },
    })
  }
  const loadMarket = () => {
    return new Promise(resolve => {
      if(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2]) {
        var datatext ='{"from_id":"list-market","stat_id":1,"lang":"' +chooseLang+'"}';
        var data = JSON.parse(datatext); 
        headersParams.Authorization = keyAuth3;
        fetch(global.config.urlLink+"/listmarket", {
          method: 'POST',
          headers: headersParams, 
          body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJson => {
            if(resJson.status === "200") {
              resolve(resJson.values);
            } else {
              resolve([]);
            }
        }).catch((err) => {
            resolve([]);
        });
      } else { resolve([]); }
    });
  }
  const loadCountry = () => {
    return new Promise(resolve => {
      if(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1]) {
        var datatext ='{"from_id":"list-country","stat_id":1,"lang":"' +chooseLang+'"}';
        var data = JSON.parse(datatext); 
        headersParams.Authorization = keyAuth2;
        fetch(global.config.urlLink+"/listcountry", {
          method: 'POST',
          headers: headersParams, 
          body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJson => {
            if(resJson.status === "200") {
              resolve(resJson.values);
            } else {
              resolve([]);
            }
        }).catch((err) => {
            resolve([]);
        });
      } else { resolve([]); }
    });
  }
  const listDistributorData = (stat,country,region) => {
    return new Promise(resolve => {
      var fetchUrl = global.config.urlLink + "/listdist";
      var dataText = '{';
      dataText += '"from_id": "list-distributor"';
      dataText += ',"lang":"' +chooseLang+'"';
      if(localStorage.getItem("dulv") ===global.config.conflevel[0] || localStorage.getItem("dulv") ===global.config.conflevel[1] || localStorage.getItem("dulv") ===global.config.conflevel[2]) { 
        if(stat !== "") { dataText += ',"stat_id":'+parseInt(stat); }
      } else { dataText += ',"stat_id":1'}
      if(country !== "") { dataText += ',"country_id":"'+country+'"'; }
      if(region !== "") { dataText += ',"region_id":"'+region+'"'; }
      dataText += "}";
      
      let data = JSON.parse(dataText);
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((res) => {
          if (res.status === "200") {
            resolve(res.values);
          } else if(res.status === global.config.errcodesess) {
            resolve([res.status]);
          } else { resolve([]); }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadRegion = () => {
    return new Promise((resolve) => {
      var optRegion=[];
      var a=0;
      global.config.regions.forEach(item => {
        a++;
        optRegion.push(<option value={item} key={"E"+a}>{translate.general.region_list[(a-1)]}</option>)
      })
      resolve(optRegion)
    })
  }
  const loadData = async () => {
    var idCntry="", idStat="", idMarket="", idRegion="", idRowPage=defRowPage;
    if(dataref !== undefined) { idCntry=dataref.countryid; idStat=dataref.statid; idMarket=dataref.marketid; idRegion=dataref.regionid; idRowPage=dataref.rowpage; }
    else { idRowPage = 10; }
    
    let [result1, result2, result3, result4] = await Promise.all([listDistributorData(idStat,idCntry,idRegion), loadCountry(), loadMarket(), loadRegion()]);
    if(result1.length > 0 && result1[0] === global.config.errcodesess) { 
      var resultSO = await Logouts();
      if(resultSO.status === "200") {
        window.location.reload();
      }
    } else {
      if(dataref !== undefined && dataref.marketid !== undefined && dataref.marketid !== "") {
        result1 = result1?.filter(
          (markets) =>  markets.market_application_focus.includes(dataref.marketid) 
        );
      }

      setPending(false); 
      updtlistStat(result1);
      setDataDistributor(result1);
      setListCountry(result2);  
      setListMarket(result3);
      setListRegion(result4);
      setFilterData({statid: idStat, countryid:idCntry, marketid: idMarket, regionid: idRegion, rowpage: idRowPage});
      setDefRowPage(idRowPage);
    }
  }
 
  const TeritoryData = (items) => {
    var sendteritory="";
    for(var a=0; a<items.length; a++) {
      if(sendteritory !== "") { sendteritory += `, ` }
      sendteritory+= `${items[a].label}` 
    }
    return sendteritory;
  }
  const StatusCols = (row) => (
    <div>{(row.status === true || row.status === 1)? global.config.generalstatus[0] : global.config.generalstatus[1] }
      {/* <ToggleSwitch
        id={"dstat_"+row.user_id}
        checked={isChecked["dstat_"+row.distributor_id]}
        onClick={() => (localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1])? handleToggle(row.distributor_id,isChecked["dstat_"+row.distributor_id]) : ''} 
      /> */}
    </div>
  );
  const ActionCols = (row) => {
    return (
      <div className="flex flex-1 gap-4 hover:fill-red-400 items-center"> 
        {/* <FaInfo 
          onClick={() => handleDetailClick(row,"detail")}
          className="hover:fill-red-600 fasize"
        /> */}
        <FaEdit onClick={() =>  handleDetailClick(row,"edit")} className="hover:fill-red-600 fasize" />
        {((localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1]) && <FaTrash
          onClick={() => handleDeleteDistributor(row.distributor_id)}
          className="hover:fill-red-500 cursor-pointer fasize"
        /> )}
        {/* {row.distributor_contactemail !== "" && <ButtonMailTo
          label="Mail"
          icon="send"
          mailto={`mailto:${row.distributor_contactemail}`}
        />} */}
      </div>
    );
  };

  const column = [
    { name: translate.distributor_list.column_1, selector: (row) => row.distributor_name, wrap: true },
  ];
  if(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1]) {
    column.push({ name: translate.distributor_list.column_2, selector: (row) => row.country_name });
    column.push({ name: translate.distributor_list.column_4, selector: (row) => row.distributor_region });
  }
  column.push({ 
    name: translate.distributor_list.column_3, 
    cell: (row) => <p onClick={() => handleDetailClick(row,"detail")}>{`${
      row.distributor_teritory !== null && row.distributor_teritory !== "" && JSON.parse(row.distributor_teritory).length > 0 ? 
        TeritoryData(JSON.parse(row.distributor_teritory))
      : "-" 
    }`}</p>,
    grow: 1,
    // selector: (row) => row.distributor_teritory  
  });
  column.push({ name: translate.general.column_status, cell: StatusCols, width: "120px" });
  if(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1]  || localStorage.getItem("dulv")===global.config.conflevel[2]) {
    column.push({ name: translate.general.column_action, cell: ActionCols, width: "160px" });
  }
  
  var optCountry = [];
  if(listCountry !== undefined) {
    for(var c=0; c<listCountry.length; c++) {
      optCountry.push(<option value={listCountry[c].country_id} key={"C"+listCountry[c].country_id}>{listCountry[c].country_name}</option>)
    }
  }

  var optMarket = [];
  if(listMarket !== undefined) {
    for(var d=0; d<listMarket.length; d++) {
      // optMarket.push(<option value={'"value":'+listMarket[d].market_id} key={"D"+listMarket[d].market_id}>{listMarket[d].market_name}</option>)
      optMarket.push(<option value={'"label":"'+listMarket[d].market_name+'"'} key={"D"+listMarket[d].market_id}>{listMarket[d].market_name}</option>)
    }
  }
  useEffect(() => {
    loadData();
  }, [chooseLang]);
  
  return (
    <div className="bg-gray-100 h-screen overflow-y-auto flex-1 p-8">
      <div className="flex items-center mb-8 gap-4">
        <h2 className="text-2xl">{translate.distributor_list.title}</h2>
        {(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1]  || localStorage.getItem("dulv")===global.config.conflevel[2])? 
        <Button
          variant="icon"
          icon="plus"
          onClick={() => handleDetailClick("","create")}
        />
        : ''}
      </div>
      {(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2])? 
      <div className="bg-white shadow-3xl flex flex-1 p-4 gap-x-4 rounded-lg mb-2">
        <p className=" text-gray-400 font-bold">{`${translate.general.filter}: `}</p>
        <select name="statid" id="statid" className="bg-white text-gray-400 outline-none" defaultValue=""
          value={filterData.statid} 
          onChange={handleSelect}
        >
          <option value="" key="B0">{translate.general.filter_status}</option>
          <option value="1" key="B1">{global.config.generalstatus[0]}</option>
          <option value="0" key="B2">{global.config.generalstatus[1]}</option>
        </select>
        {(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1])? 
        <select name="countryid" id="countryid" className="bg-white text-gray-400 outline-none" defaultValue=""
            value={filterData.countryid} 
            onChange={handleSelect}
            
          >
            <option value="" key="C0">{translate.general.filter_country}</option>
            {optCountry}
        </select>
        : '' }
        {(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1])? 
        <select name="regionid" id="regionid" className="bg-white text-gray-400 outline-none" defaultValue=""
            value={filterData.regionid} 
            onChange={handleSelect}
            
          >
            <option value="" key="E0">{translate.general.filter_region}</option>
            {listRegion}
            {/* <option value="ANZ" key="E1">ANZ</option>
            <option value="China" key="E2">China</option>
            <option value="SEA" key="E3">SEA</option> */}
        </select>
        : '' }
        <select name="marketid" id="marketid" className="bg-white text-gray-400 outline-none" defaultValue=""
          value={filterData.marketid} 
          onChange={handleSelect}
        >
          <option value="" key="D0">{translate.general.filter_market}</option>
          {optMarket}
        </select>
      </div>
      : '' }
      <Card className={"h-auto w-auto"}>
      {(defRowPage > 0) && <DataTable
          columns={column}
          data={dataDistributor}
          fixedHeader
          highlightOnHover
          defaultSortFieldId="id"
          progressPending={pending}
          progressComponent={<Loading className="h-10 w-10 mt-10 mb-10"/>}
          pointerOnHover
          pagination
          paginationPerPage={defRowPage}
          paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
          onRowClicked={(row) => handleDetailClick(row,"detail")}
          noDataComponent={<NoRecords />}
          dense={false}
          onChangeRowsPerPage={handleRowsPerPageChange}
          customStyles={{
            headRow: {
              style: {
                borderBottomWidth: "3px",
                borderBottomStyle: "solid",
                fontWeight: "bold",
                backgroundColor: "#F5F6FA",
                color: "#8A92A6",
              },
              denseStyle: {
                minHeight: "32px",
              },
            },
            table: {
              style: {
                height: `calc(100vh - 180px - ${height}px)`,
              },
            },
          }}
        />
      }
      </Card>
    </div>
  );
};

export default DistributorList;
