import React, { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { Button, CustomSelect, Modal, TextInput } from "../../../../components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../../features/translate/translateSlice";
import Logouts from "../../../../components/logout"; 

const keyAuth = global.config.typecode[4] + global.config.authBearerKey;
const keyAuth2 = global.config.typecode[5] + global.config.authBearerKey;
const keyAuth3 = global.config.typecode[2] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const convertDate = (dt) => {
  const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
  return `${dt.getFullYear()}-${padL(dt.getMonth() + 1)}-${padL(
    dt.getDate()
  )} ${padL(dt.getHours())}:${padL(dt.getMinutes())}:${padL(
    dt.getSeconds()
  )}`;
};
const ObjectiveKPIModal = ({ onBackgroundClick, onClick, content }) => {
  const translate = useSelector(staticTranslate);
  const chooseLang = useSelector((state) => state.translate.lang);
  const { data, type, category, product } = content;
  const [selected, setSelected] = useState({
    product_id: "",
    kpi_year: ""
  });
  const [prodDropdown, setProdDropdown] = useState([]);
  const [yearDropdown, setYearDropdown] = useState([]);
  const [formKPI, setFormKPI] = useState({});
  const [formTarget, setFormTarget] = useState({});

  const [dateInput, setDateInput] = useState({
    target_sdate: new Date(),
    target_edate: new Date(), 
  });

  const [formDisabled, setFormDisabled] = useState(true);
  const [formKPIErrors, setFormKPIErrors] = useState({
    // kpi_title: "",
    kpi_description: "",
    sort_number: "", 
    kpi_year: "",
  });
  const [formTargetErrors, setFormTargetErrors] = useState({
    // target_year: "",
    target_sdate: "",
    target_edate: "",
    target_qty: "", 
    product_id: "", 
  });
 
  const handleDelete = (id) => {
    if(category === "kpi") {  }
    else {
      if (window.confirm(`${translate.objective_kpi_details.alert_delete2}`)) {
        document.getElementById("loading-screen").style.display = "flex";
        var fetchUrl = global.config.urlLink + "/deleteobjectivetarget/" + id;
        headersParams.Authorization = keyAuth2;
        fetch(fetchUrl, {
          method: "GET",
          headers: headersParams,
        })
          .then((res) => {
            if (res.status === 200) return res.json();
          })
          .then((res) => {
            if (res.status === "200") {
              document.getElementById("loading-screen").style.display = "none";
              if(data.page_from === "distributor") {
                window.location.href = "/admin/distributor/detail";
              } else { 
                window.location.href = "/objective/detail";
              }
            }
          });
      }
    }
  };
  const validateField = (fieldName, value) => {
    let ferrors = "";
    switch (fieldName) {
      // case "kpi_title":
      //   if (value.trim().length > 0) { ferrors = ""; } 
      //   else { ferrors = translate.objective_kpi_modal.error1; }
      //   break;
      case "kpi_description":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.objective_kpi_modal.error1; }
        break;
      case "sort_number":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.objective_kpi_modal.error2; }
        break;
      case "kpi_year":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.objective_kpi_modal.error7; }
        break;
      case "target_sdate":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.objective_kpi_modal.error3; }
        break;
      case "target_edate":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.objective_kpi_modal.error4; }
        break;
      case "target_qty":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.objective_kpi_modal.error5; }
        break;
      case "product_id":
        if (value  > 0) { ferrors = ""; } 
        else { ferrors = translate.objective_kpi_modal.error6; }
        break;
      default:
        break;
    }
    if(category === "kpi") { 
      setFormKPIErrors((prevState) => ({ ...prevState, [fieldName]: ferrors }));
    } else { 
      setFormTargetErrors((prevState) => ({ ...prevState, [fieldName]: ferrors }));
    }
  };
  const onChangeDateHandler = (val,name) => {
    setDateInput((prevState) => ({
      ...prevState,
      [name]: val
    }));
    setFormTarget((prevState) => ({
      ...prevState,
      [name]: val,
    }));
  };
  const onChangeHandler = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    if (category === "kpi") {
      setFormKPI((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setFormTarget((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    validateField(name, value); 
  };

  const onChangeSelect = (e, sel) => {
    var name = sel.name;
    setSelected((prev) => ({ ...prev, [name]: e }));
    if(category==="target") {
      setFormTarget((prevState) => ({
        ...prevState,
        [name]: e.value,
      }));
    } else if(category==="kpi") {
      setFormKPI((prevState) => ({
        ...prevState,
        [name]: e.value,
      }));
    }
    validateField(name, e.value); 
  };

  const submitFormObjective = (e) => {
    e.preventDefault();
    var stat=true;
    var formField=[];
    let fetchUrl = global.config.urlLink;
    let dataJson = {};
    if (category === "kpi") {
      if (type === "create") {
        fetchUrl += "/addobjectivekpi";
        formKPI.from_id = "add-objectivekpi";
      } else {
        fetchUrl += "/editobjectivekpi";
        formKPI.from_id = "edit-objectivekpi"
      }
      formKPI.distributor_id = data.doc_id;
      formKPI.lang = chooseLang;
      headersParams.Authorization = keyAuth;
      dataJson = formKPI;
      // if(formKPI.kpi_title === "") { formField.push("kpi_title"); stat=false;}
      if(formKPI.kpi_description === "") { formField.push("kpi_description"); stat=false;}
      if(formKPI.sort_number === "") { formField.push("sort_number"); stat=false; }
      if(formKPI.kpi_year === "") { formField.push("kpi_year"); stat=false;}
    } else {
      // if(formTarget.target_year === "") { formField.push("target_year"); stat=false;}
      if(formTarget.target_qty === "") { formField.push("target_qty"); stat=false; }
      if(formTarget.product_id === "") { formField.push("product_id"); stat=false; }
      if(formTarget.target_sdate === "") { formField.push("target_sdate"); stat=false;}
      else {
        var targetSDatez = new Date(formTarget.target_sdate.getFullYear(),formTarget.target_sdate.getMonth(),formTarget.target_sdate.getDate(),"10","00","01");
        formTarget.target_sdate = targetSDatez;
      }
      if(formTarget.target_edate === "") { formField.push("target_edate"); stat=false;}
      else {
        var targetEDatez = new Date(formTarget.target_edate.getFullYear(),formTarget.target_edate.getMonth(),formTarget.target_edate.getDate(),"10","00","01");
        formTarget.target_edate = targetEDatez;
      }
      
      if (type === "create") {
        fetchUrl += "/addobjectivetarget";
        formTarget.from_id = "add-objectivetarget";
      } else {
        fetchUrl += "/editobjectivetarget";
        formTarget.from_id = "edit-objectivetarget";
      }
      formTarget.distributor_id = data.doc_id;
      formTarget.lang = chooseLang;
      headersParams.Authorization = keyAuth2;
      dataJson = formTarget;
    }
    // console.log(dataJson);
    if(stat === true) {
      
      document.getElementById("loading-screen").style.display = "flex";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(dataJson),
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then((res) => {
          if (res.status === "200") {
            document.getElementById("loading-screen").style.display = "none";
            if(data.page_from === "distributor") {
              window.location.href = "/admin/distributor/detail";
            } else { 
              window.location.href = "/objective/detail";
            }
            // loadData(data.doc_id);
          }
        })
        .catch((err) => { 
          document.getElementById("loading-screen").style.display = "none";
        });
      
    } else {
      for ( var i = 0; i < formField.length; i++ ) { 
        var fieldname = formField[i];
        var fieldvalue = "";
        if (category === "kpi") { fieldvalue = formKPI[fieldname]; } 
        else { fieldvalue = formTarget[fieldname]; }
        validateField(fieldname, fieldvalue); 
      }
    }
  };
  const getListId = (list,lid,name) => {
    let updListId = list?.filter(
      (listed) => listed?.value === lid
    );
    setSelected((prevState) => ({
      ...prevState,
      [name]: updListId[0],
    }));
    if (category === "kpi") {
      setFormKPI((prevState) => ({
        ...prevState,
        [name]: lid,
      }));
    } else {
      setFormTarget((prevState) => ({
        ...prevState,
        [name]: lid,
      }));
    }
  };
  const loadListProduct = (dataprop) => {
    return new Promise((resolve) => {
      // console.log(dataprop.lproduct_id);
      var json_product = JSON.parse(dataprop.lproduct_id);
      resolve(json_product);
      // if(json_product.length > 0) {
      //   var updProduct = { };
      //   updProduct = resJson.values.map((prod) => ({
      //     value: prod.product_id,
      //     label: prod.product_name,
      //   }));
      // }
      // console.log(json_product);
      /*
      var datatext = '{';
      datatext += '"from_id":"list-product","stat_id":1';
      datatext += '}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth3;
      var fetchUrl = global.config.urlLink + "/listproduct";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            var updProduct = { };
            if(resJson.values.length > 0) {
              updProduct = resJson.values.map((prod) => ({
                value: prod.product_id,
                label: prod.product_name,
              }));
            } 
            resolve(updProduct);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
      */
    });
    
  };
  const loadDataObjective = (id,pgtype) => {
    return new Promise((resolve) => {
      var fetchUrl = global.config.urlLink;
      var datatext = '{"doc_id":'+id;
      if(category==="kpi") {
        headersParams.Authorization = keyAuth;
        fetchUrl += "/detailobjectivekpi";
        datatext += ',"from_id":"detail-objectivekpi"';
      
      } else {
        headersParams.Authorization = keyAuth2;
        fetchUrl += "/detailobjectivetarget";
        datatext += ',"from_id":"detail-objectivetarget"';      
      }
      if(pgtype !== "create" && pgtype !== "edit") { datatext += ',"lang":"'+chooseLang+'"'; }
      datatext += '}';
      let data = JSON.parse(datatext);
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            resolve(resJson.values);
          } else if(resJson.status === global.config.errcodesess) {
            resolve([resJson.status]);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadData = async (id,pgtype) => {
    var dateDA = new Date(data.date_da);
    var yearnow = new Date().getFullYear();
    
    var sTargetDate = new Date(yearnow,dateDA.getMonth(),dateDA.getDate());
    var eTargetDate = new Date(yearnow+1,dateDA.getMonth(),dateDA.getDate());

    var yearopt= []
    for(var y=2020; y<parseInt(yearnow)+5; y++) {
      yearopt.push({value: y.toString(), label: y.toString()})
    }
    var datauserstr = {};
    if(id !== undefined) {
      let [result1, result2] = await Promise.all([loadDataObjective(id,pgtype), loadListProduct(data,pgtype)]);
      if(result1.length > 0) {
        if(result1[0] === global.config.errcodesess) { 
          var resultSO = await Logouts();
          if(resultSO.status === "200") {
            window.location.reload();
          }
        } else {
          if(category==="kpi") {
            var kpiYear = result1[0].kpi_year;
            if(kpiYear === undefined || kpiYear === null || kpiYear === "null" || kpiYear === "") { kpiYear = ""; }
            datauserstr = {
              doc_id: result1[0].kpi_id,
              kpi_title: result1[0].kpi_title,
              kpi_description: result1[0].kpi_description,
              sort_number: result1[0].sort_number,
              kpi_year: kpiYear,
              distributor_id: result1[0].id_distributor,
              status: true,
            };
            setFormKPI(datauserstr);
            setYearDropdown(yearopt);
            if(kpiYear !== "") { getListId(yearopt,result1[0].kpi_year,"kpi_year"); }
          } else {
            sTargetDate = new Date(result1[0].target_startdate);
            eTargetDate = new Date(result1[0].target_enddate);
            datauserstr = {
              doc_id: result1[0].target_id,
              // target_year: result1[0].target_years,
              target_sdate: sTargetDate,
              target_edate: eTargetDate,
              target_qty: result1[0].target_qty,
              product_id: result1[0].id_product,
              distributor_id: result1[0].id_distributor,
              status: true,
            };
            setFormTarget(datauserstr);
            setProdDropdown(result2);
            getListId(result2,result1[0].id_product,"product_id");
            setDateInput({
              target_sdate: sTargetDate,
              target_edate: eTargetDate,
            });
          }
        }
      } 
    } else {
      if(category==="kpi") {
        datauserstr = {
          doc_id: 0,
          kpi_title: "",
          kpi_description: "",
          sort_number: parseInt(data.kpi_lastnum)+1,
          kpi_year: new Date().getFullYear(),
          distributor_id: data.doc_id,
          status: true,
        };
        setFormKPI(datauserstr);
        setYearDropdown(yearopt);
        getListId(yearopt,yearnow.toString(),"kpi_year");
      } else {
        let [result2] = await Promise.all([loadListProduct(data,pgtype)]);
        datauserstr = {
          doc_id: 0,
          // target_year: "",
          target_sdate: sTargetDate,
          target_edate: eTargetDate,
          target_qty: "",
          product_id: "",
          distributor_id: data.doc_id,
          status: true,
        };
        setFormTarget(datauserstr);
        setProdDropdown(result2);
        setDateInput({
          target_sdate: sTargetDate,
          target_edate: eTargetDate, 
        });
      }
    }    
  }
  useEffect(() => {
    var docid = 0;
    if(category==="kpi") { docid = product.kpi_id; }
    else {  docid = product.target_id; }
    var inpdisabled = true;
    if(type === "edit" || type === "create") {   
        inpdisabled = false;
    }
    setFormDisabled(inpdisabled);
    loadData(docid,type);
    
  }, []);
  // useEffect(() => {
  //   setFormTarget((prevState) => ({
  //     ...prevState,
  //     target_sdate: convertDate(dateInput.target_sdate),
  //     target_edate: convertDate(dateInput.target_edate),
      
  //   }));
  // }, [dateInput]);
  // console.log("Data");
  // console.log(data);
  
  return (
    <Modal
      title={
        category === "target"
          ? type === "create"
            ? `${translate.objective_kpi_modal.title_target1} ${data.dist_name}`
            : type === "edit" 
              ? `${translate.objective_kpi_modal.title_target2} ${data.dist_name}`
              : `${translate.objective_kpi_modal.label_tag1} ${data.dist_name}`
          : type === "create"
          ? `${translate.objective_kpi_modal.title_kpi1} ${data.dist_name}`
            : type === "edit" 
            ? `${translate.objective_kpi_modal.title_kpi2} ${data.dist_name}`
            : `${translate.objective_kpi_modal.label_tag2} ${data.dist_name}`
      }
      // tags={`${
      //   category !== "kpi"
      //     ? category.charAt(0).toUpperCase() + category.slice(1)
      //     : category.toUpperCase()
      // }`}
      tags={`${
        category === "kpi"
          ? translate.objective_kpi_modal.label_tag2
          : translate.objective_kpi_modal.label_tag1
      }`}
      onClick={onClick}
      onBackgroundClick={onBackgroundClick}
    >
      <div className=" h-96">
        {category === "kpi" ? (
          <form
            className="h-full justify-between flex flex-col"
            onSubmit={submitFormObjective}
          >
            <div>
              <CustomSelect
                // title="Product"
                title={translate.objective_kpi_modal.text_input5}
                name="kpi_year"
                value={selected.kpi_year}
                options={yearDropdown}
                onChange={onChangeSelect}
                disabled={formDisabled}
                errmsg={formKPIErrors.kpi_year}
              />
              {/* <TextInput
                variant="base"
                // title="Title KPI"
                title={translate.objective_kpi_modal.text_input1}
                name="kpi_title"
                value={formKPI.kpi_title}
                onChange={onChangeHandler}
                disabled={formDisabled}
                errmsg={formKPIErrors.kpi_title}
              /> */}
              <TextInput
                variant="textarea"
                // title="Description"
                title={translate.objective_kpi_modal.text_input2}
                name="kpi_description"
                value={formKPI.kpi_description}
                onChange={onChangeHandler}
                disabled={formDisabled}
                height="80px"
                errmsg={formKPIErrors.kpi_description}
              />
              <TextInput
                variant="base"
                type="number"
                // title="Sort Number"
                title={translate.objective_kpi_modal.text_input3}
                name="sort_number"
                value={formKPI.sort_number}
                onChange={onChangeHandler}
                disabled={formDisabled}
                errmsg={formKPIErrors.sort_number}
              />
            </div>
            <div className="flex justify-end">
            {formDisabled === false && <Button
                type="button"
                variant="base"
                title={type === "edit" ? translate.general.button_save : translate.objective_kpi_modal.button2}
                className="bg-red-500 text-white"
                onClick={submitFormObjective}
              /> }
            </div>
          </form>
        ) : (
          <form>
            <CustomSelect
              // title="Product"
              title={translate.objective_kpi_modal.text_input4}
              name="product_id"
              value={selected.product_id}
              options={prodDropdown}
              onChange={onChangeSelect}
              disabled={formDisabled}
              errmsg={formTargetErrors.product_id}
            />
            {/* <TextInput
              variant="base"
              title="Year"
              name="target_year"
              value={formTarget.target_year}
              onChange={onChangeHandler}
              disabled={formDisabled}
              errmsg={formTargetErrors.target_year}
            /> */}
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div className="ml-1">
                <label className="block text-gray-500 mb-2">
                {translate.objective_kpi_modal.text_input7}
                </label>
                <DatePicker
                  wrapperClassName=" rounded-md" 
                  name="target_sdate"
                  selected={"" || dateInput.target_sdate}
                  onChange={(date) => onChangeDateHandler(date,"target_sdate") }
                  className={`w-1/2 p-2 rounded-md border text-gray-500`}
                  disabled={formDisabled}
                />
              </div>
              
              <div className="ml-1">
                <label className="block text-gray-500 mb-2">
                  {translate.objective_kpi_modal.text_input8}
                </label>
                <DatePicker
                  wrapperClassName=" rounded-md" 
                  name="target_edate"
                  selected={"" || dateInput.target_edate}
                  // onChange={onChangeDateHandler}
                  onChange={(date) => onChangeDateHandler(date,"target_edate") }
                  className={`w-1/2 p-2 rounded-md border text-gray-500`}
                  disabled={formDisabled}
                />
              </div>

            </div>
            <TextInput
              variant="base"
              // title="Target Unit"
              type="number"
              title={translate.objective_kpi_modal.text_input6}
              name="target_qty"
              value={formTarget.target_qty}
              onChange={onChangeHandler}
              disabled={formDisabled}
              errmsg={formTargetErrors.target_qty}
            />
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-10 mt-10">
              <div>
                {formDisabled === false && type === "edit" && <FaTrash
                    onClick={() => handleDelete(formTarget.doc_id)}
                    className="hover:fill-red-500 cursor-pointer fasize"
                  />
                }
              </div>
              <div className="flex justify-end">
                {formDisabled === false && <Button
                    type="button"
                    variant="base"
                    title={type === "edit" ? translate.general.button_save : translate.objective_kpi_modal.button1}
                    className="bg-red-500 text-white"
                    onClick={submitFormObjective}
                  />
                }
              </div>
            </div>
            
          </form>
        )}
      </div>
    </Modal>
  );
};

export default ObjectiveKPIModal;
