import parse from 'html-react-parser';
const ReplaceAllContent = ({before, after, content, parsetag}) => {
    var resContent = '';
    if(before.length > 0) {
        for(var ar=0; ar<before.length; ar++) {
            if(resContent !== "") { content = resContent; resContent = ""; }
            var arrContent = content.split(before[ar]);
            for(var ac=0; ac<arrContent.length; ac++) {
                if(ac > 0) { resContent += after[ar]; }
                resContent += arrContent[ac];
            }
        }
    }
    if(parsetag === true) { resContent = parse(resContent); }
    return (resContent);
}
export default ReplaceAllContent;