import React, { useEffect, useRef, useState } from "react";
import { Button, CustomSelect, ToggleSwitch, FormErrors, ReplaceAllContent } from "../../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Loading } from "../../../assets/svg/loading.svg";
import { ReactComponent as NA } from "../../../assets/svg/not-applicable.svg";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../features/translate/translateSlice";
import Logouts from "../../../components/logout"; 

const keyAuth = global.config.typecode[8] + global.config.authBearerKey;
const keyAuth2 = global.config.typecode[2] + global.config.authBearerKey;
const keyAuth3 = global.config.typecode[3] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

// const dataCategory = [
//   { value: "Logo", label: "Logo" },
//   // { value: "Starter Kit", label: "Starter Kit" },
//   { value: "Datasheet", label: "Datasheet" },
//   { value: "Brochure", label: "Brochure" },
//   { value: "Case Studies", label: "Case Studies" },
//   { value: "Video", label: "Video" },
//   { value: "Presentation", label: "Presentation" },
//   { value: "Webinar", label: "Webinar" },
//   { value: "Kit Aquavista", label: "Kit Aquavista" },
//   { value: "Hydrex", label: "Hydrex" },
//   { value: "Photo", label: "Photo" },
//   { value: "Questionnaires", label: "Questionnaires" },
//   { value: "Reference Lists", label: "Reference Lists" },
//   { value: "Service Package", label: "Service Package" },
// ];

const MarketingCreate = () => {
  const translate = useSelector(staticTranslate);
  const chooseLang = useSelector((state) => state.translate.lang);
  const navigate = useNavigate();
  const paramsLoc = useLocation();
  const [type, setType] = useState("");
  const [detaildata, setDetailData] = useState({});
  const [listcategory, setListCategory] = useState([]);
  const [productOption, setProductOption] = useState([]);
  const [distributorOption, setDistributorOption] = useState([]);
  const [formDisabled, setFormDisabled] = useState(true);

  const hiddenImageInput = useRef(null);
  const [isImg, setImg] = useState({});
  const [preview, setPreview] = useState({
    image: "",
  });

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({
    product_id: "",
    material_cath: "",
    distributor_id: ""
  });
  const [formErrors, setFormErrors] = useState({
    material_cath: "",
    product_id: "",
    material_url: "",
    distributor_id: "",
  });
  const [form, setForm] = useState({
    material_cath: "",
    material_filename: "",
    material_url: "",
    material_filetype: "",
    product_id: "",
    distributor_id: localStorage.getItem("dudst"),
    status: true,
    distributor_id_bf: localStorage.getItem("dudst")
  });

  const handleUploadImgChange = async (e) => {
    const imageFile = e.target.files[0];
    const fileName = e.target.files[0].name;
    setImg(imageFile);
    if (!e) return;
    setLoading(true);
    let fetchUrl = global.config.urlLink;
    headersParams.Authorization = keyAuth;
    const dataText = {
      fileName: fileName,
    };
    await fetch(fetchUrl + "/getsignedurl", {
      method: "POST",
      headers: headersParams,
      body: JSON.stringify(dataText),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
      })
      .then(async (res) => {
        if (res.status === "200") {
          const urlUploadFile = res.values;
          await fetch(urlUploadFile, {
            method: "PUT",
            headers: {
              "content-type": "application/octet-stream",
            },
            body: imageFile,
          }).then(async (res) => {
            let urlImgPicture = res.url.split("?")[0];
            setForm((prevState) => ({
              ...prevState,
              material_url: urlImgPicture,
              material_filetype: imageFile.type,
              material_filename: fileName,
            }));
            validateField("material_url", urlImgPicture);
            setLoading(false);
            setPreview((prevState) => ({
              ...prevState,
              image: window.URL.createObjectURL(imageFile),
            }));
          });
        }
      });
  };

  const handleClickUpload = (cat) => {
    if (cat === "image") {
      hiddenImageInput.current.click();
    }
  };
  const removeFile = (type) => {
    if (type === "image") {
      hiddenImageInput.current.value = null;
      setPreview((prevState) => ({ ...prevState, image: "" }));
      setForm((prevState) => ({ ...prevState, material_url: "",material_filename: "", material_filetype: "",  }));
      setImg({});
    }
  };

  const validateField = (fieldName, value) => {
    let ferrors = "";
    switch (fieldName) {
      case "material_cath":
        if (value.trim().length > 0) { ferrors = ""; } 
        // if (value > -1) { ferrors = ""; } 
        else { ferrors = "category need to be select"; }
        break;
      case "product_id":
        if (value > 0) { ferrors = ""; } 
        else { ferrors = "product need to be select"; }
        break;
      case "material_url":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = "file need to be upload"; }
        break;
      case "distributor_id":
        if (value > 0) { ferrors = ""; } 
        else { ferrors = "distributor need to be select"; }
        break;
      default:
        break;
    }
    setFormErrors((prevState) => ({ ...prevState, [fieldName]: ferrors }));
  };

  const toggleHandler = () => {
    setForm((prevState) => ({
      ...prevState,
      status: form.status === 0 ? 1 : 0,
    }));
  };

  const onChangeSelect = (e, sel) => {
    var name = sel.name;
    var value = e.value;
    if(name === "material_cath") {

      if(value === global.config.cathmaterial[0]) {
        loadDataDistributor(form.distributor_id);
      } else {
        document.getElementById("sel-distributor").style.display = "none";
        setSelected((prevState) => ({ ...prevState, distributor_id: "" }));
        setForm((prevState) => ({ ...prevState, distributor_id: form.distributor_id_bf }));
      }
    } else if(name === "distributor_id"){ 
      setForm((prevState) => ({ ...prevState, distributor_id_bf: value }));
      
      
    } else {
      if(form.material_cath !== global.config.cathmaterial[0]) {
        document.getElementById("sel-distributor").style.display = "none";
        setSelected((prevState) => ({ ...prevState, distributor_id: "" }));
        setForm((prevState) => ({ ...prevState, distributor_id: form.distributor_id_bf }));
      }
    }

    setSelected((prevState) => ({
      ...prevState,
      [name]: e,
    }));
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    var formField = [];
    if(form.material_cath === "") { formField.push("material_cath"); }
    if(form.product_id === "") { formField.push("product_id"); }
    if(form.material_url === "") { formField.push("material_url"); }
    if(form.material_cath === global.config.cathmaterial[0] && (form.distributor_id === "0" || form.distributor_id === "")) {
      formField.push("distributor_id");
    }

    // if(form.material_cath !== "" && form.product_id !== "" && form.material_url !== "") {
    if(formField.length > 0) {
      for ( var i = 0; i < formField.length; i++ ) { 
        var fieldname = formField[i];
        var fieldvalue = form[fieldname];
        validateField(fieldname, fieldvalue); 
      }
    } else {
      document.getElementById("loading-screen").style.display = "flex";
      
      var dataJson = form;
      var fetchUrl = global.config.urlLink;
      if (type === "create") {
        fetchUrl += "/addmarketing";
        dataJson.from_id="add-marketing";
      } else if (type === "edit") {
        fetchUrl += "/editmarketing";
        dataJson.from_id="edit-marketing";
      }
      if(form.material_cath !== global.config.cathmaterial[0]) { dataJson.distributor_id = localStorage.getItem("dudst"); }
      headersParams.Authorization = keyAuth;
      await fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(dataJson),
      })
        .then((res) => {
          if (res.status === 200) res.json();
        })
        .then(() => {
          document.getElementById("loading-screen").style.display = "none";
          window.location.href = "/marketing";
        })
        .catch((err) => { 
          console.log(err);
          document.getElementById("loading-screen").style.display = "none";
        });
    }
      
  };
  const validateFilePreview = () => {                    
    var imageSrc = '';
    var fileType = form.material_filetype
    if (form.material_url) {
      if (fileType === 'application/pdf') {
        imageSrc = "/images/icons/pdf-icon.png"
      } else if(fileType.split("/")[0]=== "image") {
        imageSrc = form.material_url
      }  else {
        imageSrc = "/images/icons/document-default.png"
      }
      return <img src={imageSrc} className="max-w-md max-h-64 rounded-lg" alt="material_photo" /> 
    } else {
      return <NA className="w-24 h-24" />
    }
  }
  const loadListCategory = () => {
    return new Promise((resolve) => {
      var arrMaterialCath = [];
      for(var c=(global.config.cathmaterial.length-1); c>=0; c--) {
        arrMaterialCath.push({ value: global.config.cathmaterial[c], label: translate.marketing_material_create.cathMaterial[c] });
      }
      resolve(arrMaterialCath);
    })
  }
  const getListId = (list,lid,name) => {
    let updListId = list?.filter(
      (listed) => listed?.value === lid
    );
    setSelected((prevState) => ({
      ...prevState,
      [name]: updListId[0],
    }));
    setForm((prevState) => ({
      ...prevState,
      [name]: lid,
    }));
  };
  const loadListDistributor = (country,pgtype) => {
    return new Promise((resolve) => {
      var datatext = '{';
      datatext += '"from_id":"list-distributor", "stat_id":1';
      if(pgtype === "detail") { datatext += ',"lang":"'+chooseLang+'"'; }
      if(country !== "") { datatext += ',"country_id":"'+country+'"'; }
      datatext += '}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth3;
      var fetchUrl = global.config.urlLink + "/listdist";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            var updDistributor = { };
            if(resJson.values.length > 0) {
              setDistributorOption(resJson.values);
              updDistributor = resJson.values.map((dist) => ({
                value: dist.distributor_id,
                label: dist.distributor_name,
              }));
            } 
            resolve(updDistributor);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadDataDistributor = async (idDist) => {
    document.getElementById("loading-screen").style.display = "flex";
    var idCntry = "";
    if(localStorage.getItem("dulv")===global.config.conflevel[2]) { idCntry = localStorage.getItem("ducr"); }
    let [result4] = await Promise.all([loadListDistributor(idCntry,type)]);
    if(result4.length > 0) {
      setDistributorOption(result4);
      if(idDist !== "" && idDist !== null && idDist !== undefined && idDist !== "0" && idDist !== 0) { 
        getListId(result4,idDist,"distributor_id"); 
      }
    }  
    document.getElementById("sel-distributor").style.display = "block";
    document.getElementById("loading-screen").style.display = "none";
  }
  const loadListProduct = (country) => {
    return new Promise((resolve) => {
      var datatext = '{';
      datatext += '"from_id":"list-all-product","stat_id":1';
      if(country !== "") { datatext += ',"country_id":"'+country+'"'; }
      datatext += '}';
      let data = JSON.parse(datatext);

      headersParams.Authorization = keyAuth2;
      var fetchUrl = global.config.urlLink + "/listproduct";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            var updProduct = { };
            if(resJson.values.length > 0) {
              updProduct = resJson.values.map((prod) => ({
                value: prod.product_id,
                label: prod.product_name,
              }));
            } 
            resolve(updProduct);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadDataMarketing = (id) => {
    return new Promise((resolve) => {
      let datatext = '{"from_id":"detail-marketing","doc_id":'+id+'}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth;
      var fetchUrl = global.config.urlLink + "/detailmarketing";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            resolve(resJson.values);
          } else if(resJson.status === global.config.errcodesess) {
            resolve([resJson.status]);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadData = async (id,countryid,distributorid) => {
    document.getElementById("loading-screen").style.display = "flex";
    var marketingstatus = true;
    var datauserstr = {};
    if(id !== undefined) {
      var productid = "", materialcath = "";
      let [result1, result2, result3] = await Promise.all([loadListProduct(countryid), loadDataMarketing(id), loadListCategory()]);
      if(result2.length > 0) {
        if(result2[0] === global.config.errcodesess) { 
          var resultSO = await Logouts();
          if(resultSO.status === "200") {
            window.location.reload();
          }
        } else {
          if(result2[0].status === 1) { marketingstatus =true; } else { marketingstatus =false; } 
          
          productid = result2[0].id_product;
          materialcath = result2[0].material_cath;
          datauserstr = {
            doc_id: result2[0].marketing_id,
            material_cath: materialcath,
            material_filename: result2[0].material_filename,
            material_url: result2[0].material_url,
            material_filetype: result2[0].material_filetype,
            product_id: productid,
            distributor_id: result2[0].id_distributor,
            status: marketingstatus,
            distributor_id_bf: result2[0].id_distributor,
          };
          setDetailData((prevState) => ({
            ...prevState,
            doc_id: result2[0].marketing_id,
            productName: result2[0].product_name,
          }));
          if(materialcath === global.config.cathmaterial[0]) {
            loadDataDistributor(result2[0].id_distributor);
          }
        }
      } else {
        datauserstr = {
          doc_id: 0,
          material_cath: "",
          material_filename: "",
          material_url: "",
          material_filetype: "",
          product_id: "",
          distributor_id: distributorid,
          status: marketingstatus,
          distributor_id_bf: distributorid
        };
      }
      setListCategory(result3);
      setForm(datauserstr);      
      setProductOption(result1);
      getListId(result1,productid,"product_id");
      getListId(result3,materialcath,"material_cath");
    } else {
      let [result1, result3] = await Promise.all([loadListProduct(countryid), loadListCategory()]);
      setProductOption(result1);
      setListCategory(result3);
    }
    document.getElementById("loading-screen").style.display = "none";
    
  }
  useEffect(() => {
    var docId = "",productName="",pageType="detail",idDist="",idCntry="";
    if(localStorage.getItem("dulv")===global.config.conflevel[3]) { idDist = localStorage.getItem("dudst"); } 
    else if(localStorage.getItem("dulv")===global.config.conflevel[2]) { idCntry = localStorage.getItem("ducr"); }

    if(paramsLoc.state !== null) {
      docId = paramsLoc.state.data.marketing_id;
      productName = paramsLoc.state.data.product_name;
      pageType = paramsLoc.state.type;
    } 
    if(docId !== "") {
      setDetailData({
        doc_id: docId,
        product_name: productName
      });
      setType(pageType);
      loadData(docId,idCntry,idDist);
      var inpdisabled = true;
      if(pageType === "edit" || pageType === "create") {   
          inpdisabled = false;
      }
      setFormDisabled(inpdisabled);
    } else {
      window.location.href = "/marketing";
    }

  }, []);
  
  return (
    <div className=" bg-gray-100 p-8 h-screen overflow-y-auto">
      <div className="flex items-center mb-8 gap-10">
        <div className=" flex items-center">
          <Button
            variant="icon"
            icon="left"
            className="mr-5"
            onClick={() => navigate(-1)}
          />
          <h2 className=" text-2xl">
            {type !== "create" ? detaildata.product_name : `${translate.marketing_material_create.title}`}
          </h2>
        </div>

        <div className="flex items-center gap-x-4">
          <label
            className={`text-sm text-gray-400 ${
              !form.status && "text-red-500"
            }`}
          >
            {global.config.generalstatus[1]}
          </label>
          <ToggleSwitch
            id="status"
            checked={form.status}
            onClick={formDisabled ? null : toggleHandler}
          />
          <label
            className={`text-sm text-gray-400 ${form.status && "text-red-500"}`}
          >
            {global.config.generalstatus[0]}
          </label>
        </div>
      </div>
      <form>
        <div className="flex flex-1 justify-end gap-4 mb-8 px-10">
          {!formDisabled && (
            <Button
              type="submit"
              variant="base"
              title={type === "edit" ? translate.general.button_save : translate.marketing_material_create.button1}
              className="bg-red-500 text-white"
              onClick={handleSubmit}
            />
          )}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="row-span-2">
            <div className="mx-1">
              <div className="flex items-center mb-2">
                <h2 className=" text-gray-500">{translate.marketing_material_create.text_input1}</h2>
              </div>
              <div className="flex mb-2 md:mb-0">
              <div className="flex flex-1">
                { type === 'create'?
                      <>
                        <div className="flex w-64">
                          { loading ? (
                              <div className="flex justify-center items-center w-48 h-48"><Loading /></div>
                            ) : (form.material_url !== "" )?
                                <div className="flex justify-center items-center w-full h-auto">{validateFilePreview()}</div>
                              : 
                                <div className="flex justify-center items-center w-48 h-48 border-gray-300 rounded-md border">
                                  <NA className="w-24" />
                                </div>
                          
                          }
                          
                        </div>
                        <div className="ml-5 justify-center items-center flex flex-col">
                          <div className="flex items-center mb-3 gap-3 justify-center">
                            <h2 className=" text-xs text-gray-500 italic">
                              {translate.marketing_material_create.text_input2}
                            </h2>
                          </div>
                          <Button
                            type="button"
                            variant="base"
                            // title="Upload File"
                            title={translate.marketing_material_create.button2}
                            className=" bg-red-500 text-white"
                            onClick={() => handleClickUpload("image")}
                          />
                        
                        </div>
                      </>
                    : <div className="flex justify-center items-center w-full">{validateFilePreview()}</div>
                  
                }
                  {/* {loading ? (
                    <Loading />
                  ) : (
                    <div className="flex justify-center items-center">
                      <div className="inline-block  w-50 rounded-lg shadow-3xl">
                      {validateFilePreview()}
                      </div>
                    </div>
                  )}
                    {(!isImg.name && type === 'create') && (
                  
                    )} */}
                  <input
                    type="file"
                    ref={hiddenImageInput}
                    onChange={handleUploadImgChange}
                    style={{ display: "none" }}
                    hidden
                  />
                </div>
                {/* <div className=" flex flex-1 justify-center">
                  {loading ? (
                    <Loading />
                  ) : (
                    <div className="flex items-center">
                      <div className="inline-block bg-[#D8DDFA] w-50 rounded-lg shadow-3xl">
                      
                        {(form.material_url !== "" && form.material_url !== null)? (
                          <img
                            src={form.material_url}
                            className="w-50 rounded-lg"
                            alt=""
                          />
                        ) : (
                     
                          isImg?.name ? (
                            <img
                              src={
                                isImg?.type !== "application/pdf"
                                  ? preview?.image
                                  : "/images/icons/pdf-icon.png"
                              }
                              className="w-24 h-24 rounded-lg"
                              alt=""
                            />
                          ) : (
                            <img
                              src={"/images/icons/cone-min.png"}
                              className="w-24 h-24"
                              alt=""
                            />
                          )
                      
                      )}
                      </div>
                    </div>
                  )}
                  <div>
                    {type === "create"? 
                    <>
                    <div className="flex items-center mb-3 gap-3 justify-center">
                      <h2 className=" text-xs text-gray-500 italic">
                        {isImg.name ? isImg.name : "Upload your file "}
                      </h2>
                    </div>
                    {!isImg.name && (
                      <Button
                        type="button"
                        variant="base"
                        title="Upload File"
                        className=" bg-red-500 text-white"
                        onClick={() => handleClickUpload("image")}
                      />
                    )}
                    </>
                    : <div></div>
                  }
                  </div>
                  <input
                    type="file"
                    ref={hiddenImageInput}
                    onChange={handleUploadImgChange}
                    style={{ display: "none" }}
                    hidden
                  />
                </div> */}
              </div>
              {(form.material_filename !== "" && form.material_filename !== null)? (
                <div className=" text-gray-500 mt-3  text-center italic">
                  <ReplaceAllContent content={form.material_filename} before={["_","-"]} after={[" "," "]}/>
                </div>
              ) : (<div></div>)
              }
              <div><FormErrors formErrors={formErrors.material_url}/></div>
            </div>
          </div>
          <div>
          <CustomSelect
            // title="Product Name"
            title={translate.marketing_material_create.text_input3}
            name="product_id"
            value={selected.product_id}
            options={productOption}
            onChange={onChangeSelect}
            disabled={formDisabled}
            errmsg={formErrors.product_id}
          />
          <CustomSelect
            // title="Category"
            title={translate.marketing_material_create.text_input4}
            name="material_cath"
            value={selected.material_cath}
            options={listcategory}
            onChange={onChangeSelect}
            disabled={formDisabled}
            errmsg={formErrors.material_cath}
          />
          <div id="sel-distributor" className="hidden">
          <CustomSelect
                // title="Distributor"
                title={translate.marketing_material_create.text_input5}
                name="distributor_id"
                value={selected.distributor_id}
                options={distributorOption}
                onChange={onChangeSelect}
                // onChange={(e) => onChangeSelectHandler(e,"distributor_id",listDistributor)}
                // defaultValue={form.distributor_id}
                disabled={formDisabled}
                errmsg={formErrors.distributor_id}
              />
          </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MarketingCreate;
