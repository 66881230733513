import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useSelector } from "react-redux";
import useScrollbarSize from "react-scrollbar-size";

import { Button, ToggleSwitch, Loading, NoRecords } from "../../../components";
import { staticTranslate } from "../../../features/translate/translateSlice";
import { MarketModal } from "../Components";
import Logouts from "../../../components/logout"; 

const keyAuth = global.config.typecode[10] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const MarketList = () => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector(state => state.translate.lang)
  const { height } = useScrollbarSize();
  const [isChecked, setChecked] = useState([]);
  const [pending, setPending] = useState(true);
  const [listMarket, setListMarket] = useState([]);
  const [isModal, setModal] = useState(false);
  const [popContent, setPopContent] = useState([]);

  useEffect(() => {
    getMarketList();
  }, []);

  const handlePopModal = (dataParams, type) => {
    let sendParams = {};
    sendParams.type = type;
    sendParams.data = dataParams;
    setPopContent(sendParams);
    setModal(!isModal);
  };

  const updtlistStat = (row) => {
    let checkarr = {};
    row.forEach((records) => {
      checkarr[records.market_id] = records.status;
    });
    setChecked(checkarr);
  };

  const getMarketList = () => {
    let fetchUrl = global.config.urlLink + "/listmarket";
    headersParams.Authorization = keyAuth;
    const dataText = { from_id: "list-market", lang: chooseLang };
    fetch(fetchUrl, {
      method: "POST",
      headers: headersParams,
      body: JSON.stringify(dataText),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
      })
      .then(async (res) => {
        if (res.status === "200") {
          setListMarket(res.values);
          updtlistStat(res.values);
        } else if(res.status === global.config.errcodesess) {
          var resultSO = await Logouts();
          if(resultSO.status === "200") {
            window.location.reload();
          }
        }
        setPending(false);
      });
  };

  const handleDeletMarket = (id) => {
    if (window.confirm(`${translate.market_list.alert_delete}`)) {
      document.getElementById("loading-screen").style.display = "flex";
      let fetchUrl = global.config.urlLink;
      fetchUrl += "/deletemarket/" + id;
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "GET",
        headers: headersParams,
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then((res) => {
          if (res.status === "200") {
            document.getElementById("loading-screen").style.display = "none";
            window.location.href = "/admin/market";
          }
        })
        .catch((err) => {
          console.log(err);
          document.getElementById("loading-screen").style.display = "none";
        });
    } else {
    }
  };

  const handleToggle = (id, stat) => {
    let statcheck = 1,
      statsend = true;
    if (stat === 1) {
      statcheck = 0;
      statsend = false;
    } else {
      statcheck = 1;
      statsend = true;
    }
    setChecked((prevState) => ({ ...prevState, [id]: statcheck }));

    let dataText =
      '{"doc_id":"' +
      id +
      '","status": "' +
      statsend +
      '","lang": "' +
      chooseLang +
      '","from_id":"edit-status-market"}';

    let data = JSON.parse(dataText);
    let urlFetch = global.config.urlLink + "/editmarket";
    headersParams.Authorization = keyAuth;
    fetch(urlFetch, {
      method: "POST",
      headers: headersParams,
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
      })
      .then((resJson) => {
        return false;
      })
      .catch((err) => {
        return false;
      });
  };

  const ActionCols = (row) => (
    <div className="flex flex-1 gap-4 hover:fill-red-500">
      {/* <FaInfo
        onClick={() => handlePopModal(row, "detail")}
        className="hover:fill-red-500 cursor-pointer fasize"
      /> */}
      <FaEdit
        onClick={() => handlePopModal(row, "edit")}
        className="hover:fill-red-500 cursor-pointer fasize"
      />
      {(localStorage.getItem("dulv")===global.config.conflevel[0] && <FaTrash
        onClick={() => handleDeletMarket(row.market_id)}
        className="hover:fill-red-500 cursor-pointer fasize"
      /> )}
    </div>
  );

  const StatusCols = (row) => (
    <div className="flex gap-x-10 items-center">
      {(row.status === true || row.status === 1)? global.config.generalstatus[0] : global.config.generalstatus[1] }
      {/* <ToggleSwitch
        id={row.market_id}
        checked={isChecked[row.market_id] || ""}
        onClick={() => handleToggle(row.market_id, isChecked[row.market_id])}
      /> */}
    </div>
  );

  const columns = [
    {
      name: translate.market_list.column_1,
      selector: (row) => row.market_name,
    },
    {
      name: translate.general.column_status,
      width: "180px",
      cell: StatusCols,
    }
  ];
  if(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1]) {
    columns.push({name: translate.general.column_action, width: "180px", cell: ActionCols});
  }
  return (
    <>
      <div className=" bg-gray-100 h-screen flex-1 p-8 overflow-y-auto">
        <div className="flex items-center mb-8">
          <h2 className=" text-2xl">{translate.market_list.title}</h2>
          {(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1]) &&
          <Button
            variant="icon"
            icon="plus"
            className="ml-5"
            onClick={() => handlePopModal(popContent, "create")}
          />
          }
        </div>
        <div>
          <DataTable
            columns={columns}
            data={listMarket}
            fixedHeader
            highlightOnHover
            defaultSortFieldId="id"
            progressPending={pending}
            progressComponent={<Loading className="h-10 w-10 mt-10 mb-10"/>}
            pointerOnHover
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
            onRowClicked={(row) => handlePopModal(row,"detail")}
            noDataComponent={<NoRecords />}
            customStyles={{
              headRow: {
                style: {
                  borderBottomWidth: "3px",
                  borderBottomStyle: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#F5F6FA",
                  color: "#8A92A6",
                },
                denseStyle: {
                  minHeight: "32px",
                },
              },
              table: {
                style: {
                  height: `calc(100vh - 180px - ${height}px)`,
                },
              },
            }}
          />
        </div>
      </div>
      {isModal && (
        <MarketModal
          marketData={popContent}
          onClick={() => setModal(false)}
          onBackgroundClick={() => setModal(false)}
        />
      )}
    </>
  );
};

export default MarketList;
