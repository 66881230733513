import React from "react";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../features/translate/translateSlice";

const NoRecords = ({ id = "", className = "", style = {} }) => {
  const translate = useSelector(staticTranslate)
  return (
    <div 
        id={id}
        className="w-full flex items-center justify-center" 
        style={style}>
        <span className="text-sm text-gray-500">{translate.general.label_nodata}</span>
      </div>
  );
};

export default NoRecords;