import React from 'react'

const TrainingParticipant = () => {
  return (
    <div className="bg-gray-100 p-8 h-screen overflow-y-auto">
        <iframe
          width="100%"
          height="1068"
          src="https://lookerstudio.google.com/embed/reporting/8dfb2611-e14a-4a5a-aa7d-c1386ef8545e/page/mWFbC"
          frameborder="0"
          // style="border:0"
          allowfullscreen
        ></iframe>
    </div>
  )
}

export default TrainingParticipant