import React, { useState }  from "react";
import { useDispatch, useSelector } from "react-redux";
import { VeoliaLogo } from "../../../assets";
import { LoginPicture } from "../../../assets/illustration";
import { ReactComponent as ICUser } from "../../../assets/svg/1-user.svg";
import { TextInput } from "../../../components";
import '../../../components/config';
import ForgotPasswordModal from "./Modal";
import PopupTranslate from "./Modal/PopupTranslate";
import {
  cnLang,
  enLang,
  staticTranslate,
  // translatingToEn,
  // translatingWords,
} from "../../../features/translate/translateSlice";

const keyAuth = global.config.typecode[0]+global.config.authBearerKey;
const headersParams = global.config.headersparam;
const Login = () => {
  const translate = useSelector(staticTranslate);
  const chooseLang = useSelector((state) => state.translate.lang)
  const wordtranslate = useSelector((state) => state.translate.wordtranslate);
  const cntranslate = useSelector((state) => state.translate.cnTranslate);
  const dispatch = useDispatch();
  
  const [user_email, setuemail] = useState('');
  const [user_password, setupass] = useState('');
  const [verification_code, setverfcode] = useState('');
  const [isModal, setModal] = useState(false);
  const [isTranslateModal, setTranslateModal] = useState(true);
  const [formErrors, setferrors] = useState({
    user_email: "",
    user_password: "",
    verification_code: ""
  });

 const validateField = (fieldName, value) => {
    var ferrors = "";
    switch(fieldName) {
        case 'user_email': 
            if(value.trim().length > 0) { ferrors = ""; }
            else { ferrors = translate.login.label_error1; }
            break;
        case 'user_password': 
            if(value.trim().length > 0) { ferrors = ""; }
            else { ferrors = translate.login.label_error2; }
            break;
        case 'verification_code': 
            if(value.trim().length > 0) { ferrors = ""; }
            else { ferrors = translate.login.label_error3; }
            break;
        default:
            break;
    }
    setferrors(prevState => ({ ...prevState, [fieldName]: ferrors }));
  }
  const handleUserInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    if(name==="user_email") { setuemail(value); }
    else if(name==="user_password") { setupass(value); }
    else if(name==="verification_code") { setverfcode(value); }
    validateField(name, value);
  }
  const cancelSignin = () => {
    setuemail("");
    setupass("");
    setverfcode("");
    setferrors({
      user_email: "",
      user_password: "",
      verification_code: ""
    })
    document.getElementById("errormsg2").style.display = "none"; 
    document.getElementById("verf-step").style.display = "none";
    document.getElementById("first-step").style.display = "block";
    document.getElementById("loading-screen").style.display = "none";
  }
  const continueSignIn = (uemail,upassw,errdiv,resultUser) => {
    var lv = "", lvname = "", userPhoto="", userCountry="", userDistributor="";
    if(resultUser.user_level === "sa") { lv = global.config.conflevel[0]; lvname = translate.login.userlevel_name[0]; }
    else if(resultUser.user_level === "ad") { lv = global.config.conflevel[1]; lvname = translate.login.userlevel_name[1]; }
    else if(resultUser.user_level === "cm") { lv = global.config.conflevel[2]; lvname = translate.login.userlevel_name[2]; }
    else if(resultUser.user_level === "dt") { lv = global.config.conflevel[3]; lvname = translate.login.userlevel_name[3];}
    
    if(resultUser.user_photo !== null && resultUser.user_photo !== undefined) { userPhoto = resultUser.user_photo; }
    if(resultUser.user_country !== null && resultUser.user_country !== undefined) { userCountry = resultUser.user_country; }
    if(resultUser.user_distributor !== null && resultUser.user_distributor !== undefined) { userDistributor = resultUser.user_distributor; }
    
    
    localStorage.setItem('duid', resultUser.user_keyid);
    localStorage.setItem('dutk', resultUser.user_keyid);
    localStorage.setItem('dulv', lv);
    localStorage.setItem('dufname', resultUser.user_fullname);
    localStorage.setItem('duphoto', userPhoto);
    localStorage.setItem('ducr', userCountry);
    localStorage.setItem('dulvname', lvname); 
    localStorage.setItem('dudst', userDistributor);
    document.getElementById("loading-screen").style.display = "none"; 
    window.location.reload(); 
  }
  const handleSubmit2 = (e) => {
    e.preventDefault();
    document.getElementById("loading-screen").style.display = "flex";
    if(user_email !== "" && user_password !== "" && verification_code !== "") {
      var datatext2 ='{';
      datatext2 += '"useremail":"'+user_email+'"';
      datatext2 += ',"userpassword":"'+user_password+'"';
      datatext2 += ',"verfcode":"'+verification_code+'"';
      datatext2 += ',"from_id":"verification"';
      datatext2 += ',"lang": "'+chooseLang+'"'
      datatext2 += '}';
      var data2 = JSON.parse(datatext2); 
      headersParams.Authorization = keyAuth;//+user.uid;
      fetch(global.config.urlLink+"/signin", {
          method: 'POST',
          headers: headersParams, 
          body : JSON.stringify(data2)
      }).then(res => {
          if(res.status === 200) 
              return res.json() 
      }).then( resJson => {
        // console.log(resJson);
        if(resJson.status === "200" && resJson.values.length > 0) {
          // var resultUser = resJson.values[0];
          continueSignIn(user_email,user_password,"errormsg2",resJson.values[0]);
        } else {
          document.getElementById("errormsg2").style.display = "block"; 
          document.getElementById("loading-screen").style.display = "none";
        } 
      }).catch((err) => {
        document.getElementById("errormsg2").style.display = "block"; 
        document.getElementById("loading-screen").style.display = "none";
      });
    } else { 
      var formField = ["verification_code"];
      for ( var i = 0; i < formField.length; i++ ) { 
        var fieldname = formField[i];
        var fieldvalue = document.getElementById(fieldname).value;
        validateField(fieldname, fieldvalue); 
        document.getElementById("loading-screen").style.display = "none";
      }
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    document.getElementById("loading-screen").style.display = "flex";
    var userEmail = document.getElementById("user_email").value;
    var userPassword = document.getElementById("user_password").value;
   
    if(userEmail !== "" && userPassword !== "") {
        var datatext ='{';
        datatext += '"useremail":"'+userEmail+'"';
        datatext += ',"userpassword":"'+userPassword+'"';
        datatext += ',"from_id":"signin"'; 
        datatext += ',"lang": "'+chooseLang+'"';
        datatext += '}';
        // console.log(datatext);
        var data = JSON.parse(datatext); 
        headersParams.Authorization = keyAuth;//+user.uid;
        fetch(global.config.urlLink+"/signin", {
            method: 'POST',
            headers: headersParams, 
            body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJson => {
          console.log(resJson);
          if(resJson.status === "200" && resJson.values.length > 0) {
            
            var resUser = resJson.values[0];
            if(resUser.verification_codes !== "" && resUser.verification_codes !== null) {
              document.getElementById("verf-step").style.display = "block";
              document.getElementById("first-step").style.display = "none";
              document.getElementById("loading-screen").style.display = "none";
            } else {
              continueSignIn(userEmail,userPassword,"errormsg",resUser);
            }
          } else {
            document.getElementById("errormsg").style.display = "block"; 
            document.getElementById("loading-screen").style.display = "none";
          } 
        }).catch((err) => {
          document.getElementById("errormsg").style.display = "block"; 
          document.getElementById("loading-screen").style.display = "none";
        });
        
    } else { 
      var formField = ["user_email", "user_password"];
      for ( var i = 0; i < formField.length; i++ ) { 
        var fieldname = formField[i];
        var fieldvalue = document.getElementById(fieldname).value;
        validateField(fieldname, fieldvalue); 
        document.getElementById("loading-screen").style.display = "none";
      }
    }
  };

  
  return (
    <>
    <div className="grid grid-cols-1 lg:grid-cols-3 h-screen w-full p-8">
      <div className="hidden lg:block col-start-1 col-end-3 self-center pl-10 pr-10">
        <img
          src={LoginPicture}
          alt="Veolia_1"
          className="rounded-tl-3xl rounded-br-3xl"
        />
      </div>
      <div className="flex flex-col justify-center items-center self-center">
        <div id="verf-step"   className="hidden">
          <form id="frm-verified" className="max-w-[500px] w-full p-8 px-8 rounded-lg " onSubmit={handleSubmit2}>
          <div className="flex justify-center items-center">
              <img src={VeoliaLogo} className="w-36 mb-4" alt="veolia_logo" />
            </div>
            <h2 className="text-2xl font-bold text-center mb-3">
            {translate.login.label1}
            </h2>
            <p className="text-center text-gray-400 mb-5">
            {translate.login.label2}
            {/* <br/>{translate.login.label3} */}
            {/* To make sure it's really you who's trying to sign in, a verification code was just sent<br/>to the email bellow  */}
            </p>
            <div className="text-center errText">
              <div id="errormsg2" className="hidden" style={{color: "#611A15", fontSize: "14px", backgroundColor: "#FDECEA", padding: "6px 16px"}}>
              {translate.login.label4}<br/>{translate.login.label5}
              {/* You are not successfully login.<br/>Please contact administrator for more information */}
              </div>
            </div>
            <div className="flex justify-center items-center mb-5">
              <div className="flex border border-1 rounded-full p-1">
                <div className="p-1" style={{width:"40px"}}><ICUser className="mr-1" width="20"  /></div>
                <div className="text-gray-400 mr-3 place-content-center">{user_email}</div>
              </div>
            </div>
            
            <div className="flex flex-col text-gray-400 py-2">
                <TextInput
                  // title="Verification code"
                  title={translate.login.label_textInput3}
                  variant="base"
                  type="text"
                  name="verification_code"
                  id="verification_code"
                  value={verification_code}
                  onChange={handleUserInput}
                  errmsg={formErrors.verification_code}
                />
            </div>
            <div className="flex justify-between text-gray-400 py-2">
              <div 
                className="flex items-center  cursor-pointer hover:text-red-500" 
                onClick={cancelSignin}
              >
                {translate.general.button_cancel}
              </div>
              <div
                className="hover:text-red-500 cursor-pointer text-sm"
              >
                <button type="submit" 
                  className="w-24 my-5 py-2 bg-red-600 rounded-lg font-semibold text-white shadow-md duration-300 hover:shadow-red-500/50">
                  {translate.login.button2}
                </button>
              </div>
            </div>

          </form>
        </div>
        <div id="first-step" >
          <form id="frm" className="max-w-[500px] w-full p-8 px-8 rounded-lg " onSubmit={handleSubmit}>
            <div className="flex justify-center items-center">
              <img src={VeoliaLogo} className="w-36 mb-4" alt="veolia_logo" />
            </div>
            <h2 className="text-2xl font-bold text-center mb-3">
              {translate?.login?.title}
              {/* Sign In to Distributor Panel */}
            </h2>
            <p className="text-center text-gray-400 mb-5">
              {translate?.login?.subtitle}
              {/* Sign in to stay connected. */}
            </p>
            <div className="text-center errText">
              <div id="errormsg" className="hidden" style={{color: "#611A15", fontSize: "14px", backgroundColor: "#FDECEA", padding: "6px 16px"}}>
                {translate.login.label4}<br />{translate.login.label5}
                {/* You are not successfully login.<br/>Please contact administrator for more information */}
              </div>
            </div>
            <div className="flex flex-col text-gray-400 py-2">
              <TextInput
                  type="text"
                  // title="Email"
                  title={translate?.login?.label_textInput1}
                  variant="base"
                  name="user_email"
                  id="user_email"
                  value={user_email}
                  onChange={handleUserInput}
                  errmsg={formErrors.user_email}
                />
            </div>
            <div className="flex flex-col text-gray-400 py-2">
                <TextInput
                  // title="Password"
                  title={translate?.login?.label_textInput2}
                  variant="base"
                  type="password"
                  name="user_password"
                  id="user_password"
                  placeholder={user_password}
                  value={user_password}
                  onChange={handleUserInput}
                  errmsg={formErrors.user_password}
                />
            </div>
            <div className="flex justify-between text-gray-400 py-2">
              <p className="flex items-center  cursor-pointer">
              </p>
              <p
                className="hover:text-red-500 cursor-pointer text-sm"
                onClick={() => setModal(!isModal)}
              >
                {translate?.login?.forgot_password}
              </p>
            </div>
            <div className="flex w-full justify-center">
              <button type="submit" className="w-1/2 my-5 py-2 bg-red-600 rounded-lg font-semibold text-white shadow-md duration-300 hover:shadow-red-500/50">
              {translate?.login?.button1}
              </button>
            </div>
          </form>
        </div>
        <p className="text-gray-400 italic text-right col-start-1 col-end-4 text-xs">
          Copyright Veolia ©{(new Date().getFullYear().toString()==="2022")? "2022" : "2022-"+new Date().getFullYear().toString() }
        </p>
      </div>
    </div>
    {isTranslateModal && (
        <PopupTranslate onClick={() => setTranslateModal(false)} />
      )}
    {isModal && (
      <ForgotPasswordModal
        onClick={() => setModal(!isModal)}
        onBackgroundClick={() => setModal(!isModal)}
      />
    )}
    </>
  );
};

export default Login;
