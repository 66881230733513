import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useScrollbarSize from "react-scrollbar-size";
import { FaFolderPlus } from "react-icons/fa";
import DataTable from "react-data-table-component";
import { Loading, Card, NoRecords } from "../../../components";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../features/translate/translateSlice";
import Logouts from "../../../components/logout"; 

const keyAuth2 = global.config.typecode[3] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const ListObjective = () => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector((state) => state.translate.lang)
  const navigate = useNavigate();
  const { height } = useScrollbarSize();
  const [isChecked, setChecked] = useState([]);
  const [dataDistributor, setDataDistributor] = useState([]);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    listDistributorData();
  }, []);
  const handleDetailClick = (resList,type) => {
    navigate("/objective/detail", {
      state: { data: resList, type: type },
    })
  }
  const listDistributorData = () => {
    const dataText = { from_id: "list-distributor", stat_id:1, lang:chooseLang };
    const fetchUrl = global.config.urlLink + "/listdist";
    headersParams.Authorization = keyAuth2;
    fetch(fetchUrl, {
      method: "POST",
      headers: headersParams,
      body: JSON.stringify(dataText),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
      })
      .then(async (res) => {
        setPending(false); 
        if (res.status === "200" && res.values.length > 0) {
          setDataDistributor(res.values);
          updtlistStat(res.values);
        } else if(res.status === global.config.errcodesess) {
          var resultSO = await Logouts();
          if(resultSO.status === "200") {
            window.location.reload();
          }
        
        }
      })
      .catch((err) => console.log(err));
  };

  const updtlistStat = (row) => {
    let checkarr = {};
    row.forEach((records) => {
      checkarr[records.distributor_id] = records.status;
    });
    setChecked(checkarr);
  };

  const ActionCols = (row) => (
    <div className="flex flex-1 gap-6 hover:fill-red-600">
      <FaFolderPlus
        size={20} 
        onClick={() => handleDetailClick(row,"detail")}
        className="hover:fill-red-600 cursor-pointer"
      />
    </div>
  );

  const columns = [
    {
      name: translate.objective_kpi.column_1,
      selector: (row) => row.distributor_name,
    },

    {
      name: translate.general.column_action,
      width: "150px",
      cell: ActionCols,
    },
  ];

  return (
    <>
      <div className=" bg-gray-100 flex-1 h-screen p-8 overflow-y-scroll">
        <div className="flex  gap-x-4 mb-8">
          <h2 className=" text-2xl">{translate.objective_kpi.title}</h2>
        </div>
        <div className="mb-2">
          <p className=" text-xl">{translate.objective_kpi.subtitle}</p>
        </div>
        <div>
        <Card className={"h-auto w-auto"}>
          <DataTable
            columns={columns}
            data={dataDistributor}
            fixedHeader
            highlightOnHover
            defaultSortFieldId="id"
            progressPending={pending}
            progressComponent={<Loading className="h-10 w-10 mt-10 mb-10"/>}
            pointerOnHover
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
            onRowClicked={(row) => handleDetailClick(row,"detail")}
            noDataComponent={<NoRecords />}
            customStyles={{
              headRow: {
                style: {
                  borderBottomWidth: "3px",
                  borderBottomStyle: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#F5F6FA",
                  color: "#8A92A6",
                },
                denseStyle: {
                  minHeight: "32px",
                },
              },
              table: {
                style: {
                  height: `calc(100vh - 180px - ${height}px)`,
                },
              },
            }}
          />
        </Card>
        </div>
      </div>
    </>
  );
};

export default ListObjective;
