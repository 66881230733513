import React, { useEffect, useState } from "react";
import { Button, Card, Loading, NoRecords, DateTimeFormats, ReplaceAllContent } from "../../../components";

import DataTable from "react-data-table-component";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { ReactComponent as TickSquare } from "../../../assets/svg/tick-square.svg";
import { useLocation, useNavigate } from "react-router-dom";
import ObjectiveKPIModal from "../Components/Modal";

import { FaEdit, FaTrash } from "react-icons/fa";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../features/translate/translateSlice";
import Logouts from "../../../components/logout"; 

const keyAuth = global.config.typecode[4] + global.config.authBearerKey;
const keyAuth2 = global.config.typecode[5] + global.config.authBearerKey;
const keyAuth3 = global.config.typecode[3] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const DetailObjective = () => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector(state => state.translate.lang)
  const navigate = useNavigate();
  const paramsLoc = useLocation();
  const [type, setType] = useState("");
  const [detaildata, setDetailData] = useState({});
  const [isModal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalContent, setModalContent] = useState({});
  // const [isKPIList, setKPIList] = useState([]);
  // const [isTargetList, setTargetList] = useState([]);
  const [targetdata, setTargetData] = useState([]);
  const [targetheader, setTargetHeader] = useState([]);
  
  
  const [kpidata, setKPIData] = useState([]);
  const [kpiheader, setKPIHeader] = useState([]);
  const [defindex, setDefIndex] = useState(0);

  const handlePopUpModal = (typeModal, category, details, product) => {
    let contentParams = {};
    if (category === "kpi") {
      contentParams.product = product;
      contentParams.data = details;
      contentParams.type = typeModal;
      contentParams.category = category;
      setModalContent(contentParams);
    } else if (category === "target") {
      contentParams.product = product;
      contentParams.data = details;
      contentParams.category = category;
      contentParams.type = typeModal;
      setModalContent(contentParams);
    }
    setModal(!isModal);
  };

  const handleDelete = (id,section) => {
    var confirmmsg = "";
    var fetchUrl = global.config.urlLink;
    if(section === "target") { 
      confirmmsg = `${translate.objective_kpi_details.alert_delete2}`;
      fetchUrl += "/deleteobjectivetarget/" + id;
      headersParams.Authorization = keyAuth2;
    } else { 
      confirmmsg = `${translate.objective_kpi_details.alert_delete1}`;
      fetchUrl += "/deleteobjectivekpi/" + id;
      headersParams.Authorization = keyAuth;
    }
    if (window.confirm(confirmmsg)) {
      fetch(fetchUrl, {
        method: "GET",
        headers: headersParams,
      }).then((res) => {
        if (res.status === 200) return res.json();
      }).then((res) => {
        if (res.status === "200") {
          if(localStorage.getItem("dulv")===global.config.conflevel[3]) {
            window.location.href = "/objective";
          } else {
            navigate("/objective/detail", {
              state: { data: detaildata, type: type },
            });
            loadData(detaildata.doc_id);
          }
        }
      }).catch((err) => {
          console.log(err);
      });
    } else {
    }
  };
  const handleSelectTab = (idx) => {
    // console.log(idx);
    // var used = "library";
    // if(idx===1) { used = "course"; }
    // this.setState( prevState => {
    //   return {  
    //       ...prevState.usageFor, usageFor: used
    //   }
    // })
    // this.loadData(used);
  }

/* ===== START OBJECTIVE KPI =======  */
// const getListObjKPI = (year,list) => {
//     return new Promise((resolve) => {
//       var headerTab="", headerPanel="";
//       if(year.length > 0) {
//         for(var ky=0; ky<year.length; ky++) {
//           headerTab += <Tab className="tabs-class__tab" disabledClassName="tabs-class__tab--disabled" selectedtabclassname="tabs-class__tab--selected">Title 1</Tab>
//         }
//       }
//       resolve(headerTab);
//     })
// }  
/* ===== START OBJECTIVE TARGET =======  */
const getListObjTarget = (prod,year,list) => {
  return new Promise((resolve) => {
    
    var uniqueProducts = prod;
    if(uniqueProducts.length > 0) {
      uniqueProducts = uniqueProducts.filter((c, index) => {
        return uniqueProducts.indexOf(c) === index;
      });
    } 
    var uniqueYears = year;
    if(uniqueYears.length > 0) {
      // uniqueYears = uniqueYears[0].filter((d, index2) => {
      //   return uniqueYears[0].startDate.indexOf(d) === index2;
      // });
      uniqueYears = [...new Map(uniqueYears.map((m) => [m.startDate, m])).values()];
    }
    var itemSend = [];
    var itemTarget = [];
    if(list.length > 0) {
      var sortproduct = uniqueProducts.sort((a, b) => a > b ? 1 : -1);
      // var sortyear = uniqueYears.sort((a, b) => a > b ? 1 : -1);
      var sortyear = uniqueYears.sort((a, b) => a.startDate > b.startDate ? 1 : -1);
      for(var t=0;t<sortproduct.length; t++) {
        var prodName = sortproduct[t];
        var itemTargetCol = {prodName: prodName,tid:[],target_qty:[],periode:[]};
        for(var u=0; u<sortyear.length; u++) {
          var currSDate = sortyear[u].startDate;
          var targetQty = 0, targetId="",sDate="", eDate=""; 
          for(var v=0; v<list.length; v++) {
            var items = list[v];
            if(items.product_name === prodName && items.target_startdate === currSDate) {
              sDate = items.target_startdate;
              eDate = items.target_enddate;
              if(items.target_qty !== "" && items.target_qty !== null) { targetQty = items.target_qty; }
              if(items.target_id !== "" && items.target_id !== null) { targetId = items.target_id; }
              break;
            }
          }
          // itemTargetCol['id_'+u] = targetId;
          // itemTargetCol['product_name_'+u] = items.product_name;
          // itemTargetCol['target_startdate_'+u] = items.target_startdate;
          // itemTargetCol['target_enddate_'+u] = items.target_enddate;
          // itemTargetCol['target_qty_'+u] = targetQty;
          // itemTargetCol['target_idproduct_'+u] = items.id_product;
          // itemTargetCol['target_status_'+u] = items.status;
          itemTargetCol.tid.push(targetId);
          itemTargetCol.periode.push({startdate: sDate, enddate: eDate});
          // itemTargetCol.product_name.push(items.product_name);
          itemTargetCol.target_qty.push(targetQty);
        }
        itemTarget.push(itemTargetCol);
        if(t === (sortproduct.length-1)) {
          itemSend.push(sortproduct);
          itemSend.push(sortyear);
          itemSend.push(itemTarget);
          resolve(itemSend);
        }
      }
      
    } else {
      itemSend.push(uniqueProducts);
      itemSend.push(uniqueYears);
      itemSend.push(itemTarget);
      resolve(itemSend);
    }
  })
}

/* ===== END OBJECTIVE TARGET =======  */
  
  const loadDataDistributor = (id) => {
    return new Promise((resolve) => {
      let datatext = '{"from_id":"detail-distributor","doc_id":'+id+',"lang":"'+chooseLang+'"}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth3;
      var fetchUrl = global.config.urlLink + "/detaildist";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            resolve(resJson.values);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  
  const loadDataObjective = (id) => {
    return new Promise((resolve) => {
      let datatext = '{"from_id":"detail-objective","dist_id":'+id+',"stat_id":1,"lang":"'+chooseLang+'"}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth;
      var fetchUrl = global.config.urlLink + "/detailobjective";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            // OBJECTIVE TARGET //
            var newList = resJson.values.objectivetarget;
            var targetList = {};
            var productArr = [];
            var yearArr = [];
            if(newList.length > 0) {
              for(var p=0; p<newList.length; p++) {
                var items = newList[p];
                productArr.push(items.product_name);
                yearArr.push({startDate:items.target_startdate,endDate:items.target_enddate});
                if(p===(newList.length-1)) {
                  targetList= {product: productArr, targetyear: yearArr, targetdata: newList};
                }
              }
            } else targetList= {product: productArr, targetyear:yearArr, targetdata: newList};
            // OBJECTIVE KPI //
            var new2List = resJson.values.objectivekpi;
            var kpiList = {};
            var yearKPIArr = [];
            var saveKPIYear = "";
            var contentKPI = [];
            var detContentKPI = [];
            if(new2List.length > 0) {
              for(var q=0; q<new2List.length; q++) {
                var itemKPIYear = new2List[q].kpi_year;
                if(saveKPIYear === "") {
                  yearKPIArr.push(itemKPIYear); 
                  saveKPIYear = itemKPIYear;
                  
                } else if(itemKPIYear !== saveKPIYear) { 
                  yearKPIArr.push(itemKPIYear); 
                  saveKPIYear = itemKPIYear;
                  contentKPI.push(detContentKPI);
                  detContentKPI = [];
                } 
                detContentKPI.push(new2List[q]);
                if(q===(new2List.length-1)) {
                  contentKPI.push(detContentKPI);
                  kpiList= {kpiyear: yearKPIArr, kpidata: contentKPI};
                  // console.log(kpiList);
                }
              }
            } else kpiList= {kpiyear:yearKPIArr, kpidata: contentKPI};
            resolve([kpiList,targetList]);
          } else if(resJson.status === global.config.errcodesess) {
            resolve([resJson.status]);
          
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  
  const loadData = async (id,detaildatadist) => {
    let [result1] = await Promise.all([loadDataObjective(id)]);
    if(result1 !== undefined && result1.length > 0) {
      if(result1[0] === global.config.errcodesess) { 
        var resultSO = await Logouts();
        if(resultSO.status === "200") {
          window.location.reload();
        }
      } else {
        // OBJECTIVE - TARGET - VIEW //
        var getResultList = await getListObjTarget(result1[1].product,result1[1].targetyear,result1[1].targetdata);
        var headerColTarget = [];
        headerColTarget.push({ name: `${translate.objective_kpi_details.label3}`, selector: (row) => row.prodName, grow: 2,  width: "300px" });
        getResultList[1].map((item, h) => {
          var titleHeader1 = <DateTimeFormats datez = {new Date(item.startDate)} type={"year-long"} />
          if(h<=getResultList[1].length) {
            headerColTarget.push({ name: titleHeader1, cell: (row) => 
              <div className={`${
                (localStorage.getItem("dulv")!==global.config.conflevel[3] && row.tid[h] !== "") ? "cursor-pointer hover:bg-gray-100" : ""
              } flex flex-1 gap-4 items-center h-full pl-3`}
              
              onClick={() =>  (localStorage.getItem("dulv")!==global.config.conflevel[3] && row.tid[h] !== "") ? handlePopUpModal("edit", "target", detaildatadist, {
                    target_id: row.tid[h],
                    page_from: "distributor"
                  }) : ''
                  }
                  > 
                    {row.tid[h] !== ""? `${ row.target_qty[h] }` : `-`}
                    
                  </div>
                , grow: 1,  width: "160px"});
            
          }
        })
        setTargetData(getResultList[2]);
        setTargetHeader(headerColTarget);
        setKPIHeader(result1[0].kpiyear);
        setKPIData(result1[0].kpidata);
        setLoading(false);
        var distId = id, distName="", last_sortnum=0;
        if(result1[0].kpiyear.length > 0) { 
          distId = result1[0].kpidata[0][0].id_distributor; 
          distName = result1[0].kpidata[0][0].distributor_name;
          last_sortnum = result1[0].kpidata[0].length; if(last_sortnum === null) { last_sortnum = 0; }
        } else { 
          let [result2] = await Promise.all([loadDataDistributor(id)]);
          distId = result2[0].distributor_id; 
          distName = result2[0].distributor_name;
        }
        setDetailData((prevState) => ({
          ...prevState,
          doc_id: distId,
          dist_name: distName,
          kpi_lastnum: last_sortnum,
        }));
      }
    } else {
      setLoading(false);
    }
  }
  useEffect(() => {
    var docId = "",distName="", efDateDA=new Date(), lproductId= "", pageType="detail";
    if(localStorage.getItem("dulv")===global.config.conflevel[3]) {
      docId = localStorage.getItem("dudst");
    } else {
      if(paramsLoc.state !== null) {
        docId = paramsLoc.state.data?.distributor_id;
        distName = paramsLoc.state.data?.distributor_name;
        efDateDA = paramsLoc.state.data?.efective_date_of_da;
        lproductId = paramsLoc.state.data?.product_application;
        pageType = paramsLoc.state.type;
      } 
    }
    if(docId !== "") {
      var detaildatadist = {
        doc_id: docId,
        dist_name: distName,
        date_da: efDateDA,
        lproduct_id: lproductId,
        kpi_lastnum: 0,
        page_from: "objective"
      }
      setLoading(true);
      setDetailData(detaildatadist);
      setType(pageType);
      loadData(docId,detaildatadist);
      setDefIndex(0);
    } else {
      if(localStorage.getItem("dulv")!==global.config.conflevel[3]) {
        window.location.href = "/objective";
      }
    }
  }, []);

  return (
    <>
      <div className=" bg-gray-100 flex-1 p-8 h-screen overflow-y-scroll no-scrollbar">
        <div className="flex items-center mb-5">
        {localStorage.getItem("dulv")!==global.config.conflevel[3] && 
          <Button
            variant="icon"
            icon="left"
            className="mr-5"
            onClick={() => navigate(-1)}
          />
        }
          <h2 className="text-2xl">{detaildata.dist_name}</h2>
        </div>
        <div className=" mb-8">
          <Card className="p-5">
            <div className="mb-3 flex items-center gap-3 mb-8">
              <h2>{translate.objective_kpi_details.label1}</h2>
              {(localStorage.getItem("dulv")!==global.config.conflevel[3]) && 
                <Button
                  variant="icon"
                  icon="plus"
                  onClick={() => handlePopUpModal("create", "kpi", detaildata,{})}
                />
              }
            </div>
            {loading ? (
              <div><Loading className="h-10 w-10 mt-10 mb-10"/></div>
            ) : (
              <>
              <div>
              {(kpiheader.length>0)?
                <Tabs defaultIndex={defindex} onSelect={() => handleSelectTab("create", "kpi", detaildata,{})}>
                  <TabList className="rtabs-class__tab-list">
                    {kpiheader.map((itemYear, idy) => (
                        <Tab className="rtabs-class__tab" disabledClassName="rtabs-class__tab--disabled" selectedtabclassname="rtabs-class__tab--selected">{itemYear}</Tab>
                      ))
                    }
                    {/* <Tab className="tabs-class__tab" disabledClassName="tabs-class__tab--disabled" selectedtabclassname="tabs-class__tab--selected">Title 1</Tab>
                    <Tab  className="tabs-class__tab" disabledClassName="tabs-class__tab--disabled" selectedtabclassname="tabs-class__tab--selected">Title 2</Tab> */}
                  </TabList>
                    {kpidata.map((itemData, idc) => (
                        <TabPanel className="rtabs-class__tab-panel" selectedClassName="rtabs-class__tab-panel--selected">
                          {(itemData.length > 0)?
                            itemData.map((item, index) => (
                              <div
                                key={index}
                                className={`flex flex-1 items-center mb-5 p-1 hover:bg-gray-100 rounded-lg cursor-pointer`}
                              >
                                <div
                                  className="flex flex-1 itemscenter gap-4 "
                                  onClick={() => {if (localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1]) { handlePopUpModal("detail", "kpi", detaildata, item); }}}
                                >
                                  <div className="w-10">
                                    <TickSquare style={{width:"35px"}}
                                      className={` ${
                                        item.status === "done"
                                          ? "fill-red-600"
                                          : " fill-gray-500"
                                      }`}
                                    />
                                  </div>
                                  <div className="text-sm" style={{justifyContent:"center", display:"flex", alignItems: "center"}}>
                                  <ReplaceAllContent content={item.kpi_description} before={["\n"]} after={["<br/>"]} parsetag={true}/>
                                  
                                  </div>
                                  {/* <div className=" text-ellipsis overflow-hidden">
                                    <p className="">{item.kpi_title}</p>
                                    <p className="text-xs text-gray-400">
                                      {item.kpi_description}
                                    </p>
                                  </div> */}
                                </div>
                                {(localStorage.getItem("dulv")!==global.config.conflevel[3])?
                                <div className="flex items-center relative gap-5 ml-5">
                                  <FaEdit
                                    onClick={() => handlePopUpModal("edit", "kpi", detaildata, item)}
                                    className="hover:fill-red-500 cursor-pointer fasize"
                                  />
                                  <FaTrash 
                                    onClick={() => handleDelete(item.kpi_id,"kpi")}
                                    className="hover:fill-red-500 cursor-pointer fasize" 
                                  />
                                </div>
                                : '' }
                              </div>
                            ))
                            : <div className="text-center">{translate.general.label_nodata}</div>
                          }
                        </TabPanel>
                      ))
                    }
                </Tabs>
                : <div className="text-center">{translate.general.label_nodata}</div>
              }
              </div>
              {/* <div> 
                { 
                (isKPIList.length > 0)?
                  isKPIList.map((item, index) => (
                    <div
                      key={index}
                      className={`flex flex-1 items-center mb-5 p-1 hover:bg-gray-100 rounded-lg cursor-pointer`}
                    >
                      <div
                        className="flex flex-1 itemscenter gap-4 "
                        onClick={() => {if (localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1]) { handlePopUpModal("detail", "kpi", detaildata, item); }}}
                      >
                        <div className="w-10">
                          <TickSquare style={{width:"35px"}}
                            className={` ${
                              item.status === "done"
                                ? "fill-red-600"
                                : " fill-gray-500"
                            }`}
                          />
                        </div>
                        <div className="text-sm" style={{justifyContent:"center", display:"flex", alignItems: "center"}}>
                        <ReplaceAllContent content={item.kpi_description} before={["\n"]} after={["<br/>"]} parsetag={true}/>
                         
                        </div>
                        {// <div className=" text-ellipsis overflow-hidden">
                         // <p className="">{item.kpi_title}</p>
                         // <p className="text-xs text-gray-400">
                         //   {item.kpi_description}
                         // </p>
                        // </div> 
                        }
                      </div>
                      {(localStorage.getItem("dulv")!==global.config.conflevel[3])?
                      <div className="flex items-center relative gap-5 ml-5">
                        <FaEdit
                          onClick={() => handlePopUpModal("edit", "kpi", detaildata, item)}
                          className="hover:fill-red-500 cursor-pointer fasize"
                        />
                        <FaTrash 
                          onClick={() => handleDelete(item.kpi_id,"kpi")}
                          className="hover:fill-red-500 cursor-pointer fasize" 
                        />
                      </div>
                      : '' }
                    </div>
                  ))
                  : <div className="text-center">{translate.general.label_nodata}</div>
                }
              </div> */}
              </>
            )}
          </Card>
        </div>
        <div>
          <Card className={`p-5`}>
            <div className="mb-3 items-center flex gap-3 mb-8">
              <h2 className="text-md">{translate.objective_kpi_details.label2}</h2>
              {(localStorage.getItem("dulv")!==global.config.conflevel[3]) && 
                  <Button
                    variant="icon"
                    icon="plus"
                    onClick={() => handlePopUpModal("create", "target", detaildata,{
                      // target_year: "",
                      target_sdate: "",
                      target_edate: "",
                      target_qty: "",
                      product_id: "",
                      distributor_id: detaildata.docId,
                      status: true,
                      page_from: "objective"
                    })
                  }
                />
              }
            </div>
            <div>
              <DataTable
                // columns={ObjectiveTargetColumn}
                // data={isTargetList}
                columns={targetheader}
                data={targetdata}
                fixedHeader
                progressPending={loading}
                progressComponent={<Loading className="h-10 w-10 mt-10 mb-10"/>}
                // pagination
                // paginationPerPage={10}
                // paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
                // pointerOnHover
                // highlightOnHover
                // onRowClicked={(row) =>
                //   handlePopUpModal("detail", "target", row)
                // }
                noDataComponent={<NoRecords />}
                customStyles={{
                  headRow: {
                    style: {
                      color: "grey",
                      backgroundColor: "#F5F6FA",
                      borderBottomStyle: "solid",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      textAlign: "center",
                    },
                    denseStyle: {
                      minHeight: "32px",
                    },
                  },
                  table: {
                    style: {
                      height: "370px",
                    },
                  },
                }}
              />
            </div>
          </Card>
        </div>
      </div>
      {isModal && (
        <ObjectiveKPIModal
          content={modalContent}
          onClick={() => setModal(!isModal)}
          onBackgroundClick={() => setModal(!isModal)}
        />
      )}
    </>
  );
};

export default DetailObjective;
