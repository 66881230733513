import { Login } from "./Auth";
import { UsersList } from "./Users";
import { DistributorList, DistributorDetail } from "./Distributor";
import { ProductList, CreateProduct } from "./Product";
import { MarketList } from "./Market";
import DataAnalysis from "./DataAnalysis";
import Profile from "./Profile";
import { PipelineDetail, PipelineList } from "./Pipeline";
import { MarketingList, MarketingCreate, MarketingDetail } from "./Marketing";
import { DetailObjective, ListObjective } from "./Objective";
import KeyContact from "./KeyContact";
import {TrainingList, TrainingDetail} from "./Training";
import { BusinessPlanList, BusinessPlanDetail, CreateBusinessPlan } from "./BusinessPlan";
import {ErrorScreen404, ErrorScreenForbidden} from "./Error";
import { CreateMessage, DistributorMessageList } from "./ContactUs";
import { CountryList } from "./Country";
import { PromotionalList, PromotionalView, CreatePromotional } from "./Promotional";
import DistributorMap from "./DistributorMap";
import TrainingParticipant from "./TrainingParticipant";
import UserLoginReport from "./UserLoginReport";
import { CampaignList, CampaignDetail } from "./Campaign";
import { ComplianceView, ComplianceList } from "./Compliance";
import { TestPage } from "./Test";

export {
  Login,
  DistributorList,
  DistributorDetail,
  UsersList,
  CreateProduct,
  ProductList,
  MarketList,
  DetailObjective,
  ListObjective,
  DataAnalysis,
  Profile,
  PipelineDetail,
  PipelineList,
  MarketingDetail,
  MarketingCreate,
  MarketingList,
  KeyContact,
  TrainingList,
  TrainingDetail,
  BusinessPlanList,
  BusinessPlanDetail,
  CreateBusinessPlan,
  ErrorScreen404,
  ErrorScreenForbidden,
  CreateMessage,
  DistributorMessageList,
  CountryList,
  PromotionalList,
  PromotionalView,
  CreatePromotional,
  DistributorMap,
  TrainingParticipant,
  UserLoginReport,
  CampaignList,
  CampaignDetail,
  ComplianceView,
  ComplianceList,
  TestPage
};
