import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Send } from "../../assets/svg/send.svg";

const ButtonMailTo = ({ label, mailto, icon }) => {
  return (
    <Link
      to="#"
      target={"_blank"}
      rel="noopener noreferrer"
      onClick={(e) => {
        // window.location.href = mailto;
        window.open(mailto, "_blank");
        e.preventDefault();
      }}
      className="bg-blue-500 p-2 rounded-lg px-4 text-white flex items-center gap-2 "
    >
      {icon === "send" && <Send className="fill-white" width={13} />}
      {label}
    </Link>
  );
};

export default ButtonMailTo;
