import { useDispatch } from "react-redux";
import { enLang, translatingToEn, clearTranslateCN } from "../features/translate/translateSlice";

async function Logouts() {

  return new Promise(resolve => {
    const dispatch = (useDispatch);
    var resSend = {};
  
    try{
      localStorage.removeItem("logged_in");
      localStorage.removeItem("duid");
      localStorage.removeItem("dutk");
      localStorage.removeItem("dulv");
      localStorage.removeItem("dufname");
      localStorage.removeItem("duphoto");
      localStorage.removeItem("ducr");
      localStorage.removeItem("dutr");
      localStorage.removeItem("dulvname");
      localStorage.removeItem("dudst");
      localStorage.removeItem("dstatc");
      
      dispatch(translatingToEn());
      dispatch(enLang());
      dispatch(clearTranslateCN());

      resSend.status = "200";
      resSend.message = "Success";
      resolve(resSend);
      
    } catch(e) {
      console.log(e);
      resSend.status = "444";
      resSend.message = "Failed";
      resolve(resSend);
    }
  })
}


export default Logouts;