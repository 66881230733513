import React from 'react'

const DistributorMap = () => {
  return (
    <div className="bg-gray-100 p-8 h-screen overflow-y-auto">
         <iframe
          width="100%"
          height="1068"
          src="https://lookerstudio.google.com/embed/reporting/441bf25e-33d5-431a-95f1-8f9934334cbf/page/mWFbC"
          frameborder="0"
          // style="border:0"
          allowfullscreen
        ></iframe>
    </div>
  )
}

export default DistributorMap