import React from "react";
import { FaBars } from "react-icons/fa";

const Navbar = ({ onClickSideBar }) => {
  const handleSideBar = () => {
    document.getElementById("sidebar").style.display = "flex";
    document.getElementById("navbar-logo").style.display = "none";
  };

  return (
    <>
      <div
        id="navbar"
        className={`flex flex-1 sticky top-0 bg-white md:hidden p-3 z-0 justify-between`}
      >
        <button onClick={handleSideBar}>
          <FaBars className="fill-red-500 w-16" />
        </button>
        <img
          id="navbar-logo"
          src="/images/main-logo.png"
          alt="main-logo"
          className="w-20"
        />
        <div></div>
      </div>
    </>
  );
};

export default Navbar;
