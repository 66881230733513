import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, CustomSelect, TextInput, ToggleSwitch } from "../../../components";
import { staticTranslate } from "../../../features/translate/translateSlice";
import Logouts from "../../../components/logout"; 

const keyAuth = global.config.typecode[11] + global.config.authBearerKey;
const keyAuth2 = global.config.typecode[3] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const CreateBusinessPlan = () => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector((state) => state.translate.lang)
  const navigate = useNavigate();
  const paramsLoc = useLocation();
  const [type, setType] = useState("");
  const [detaildata, setDetailData] = useState({});
  const [listDistributor, setListDistributor] = useState([]);
  const [selected, setSelected] = useState({
    distributor_id: "",
  });
  const [formDisabled, setFormDisabled] = useState(true);
  const [formErrors, setFormErrors] = useState({
    businessplan_title: "",
    distributor_id: "", 
    businessplan_content: ""
  });

  const [form, setForm] = useState({
    businessplan_title: "",
    distributor_id: "",
    businessplan_content: "",
    status: true,
  });
  const handleBack = () => {
    navigate("/businessplan", {
      state: { dataref: paramsLoc.state.filter },
    })
  }
  const validateField = (fieldName, value) => {
    let ferrors = "";
    switch (fieldName) {
      case "businessplan_title":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.business_plan_create.error1; }
        break;
      case "businessplan_content":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.business_plan_create.error2; }
        break;
      case "distributor_id":
        if (value > 0) { ferrors = ""; } 
        else { ferrors = translate.business_plan_create.error3; }
        break;
      default:
        break;
    }
    setFormErrors((prevState) => ({ ...prevState, [fieldName]: ferrors }));
  };

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };

  const onChangeSelect = (e, sel) => {
    var name = sel.name;
    var value = e.value;
    setSelected((prev) => ({ ...prev, [name]: e }));
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };

  const onChangeCKEditor = (e, editor) => {
    const dataCKE = editor.getData();
    setForm((prevState) => ({ ...prevState, businessplan_content: dataCKE }));
    validateField("businessplan_content", dataCKE);
  };
  const toggleHandler = () => {
    setForm((prevState) => ({
      ...prevState,
      status: form.status === 0 ? 1 : 0,
    }));
  };
  const submitHandler = (e) => {
    e.preventDefault();
    
    if(form.businessplan_title !== "" && form.businessplan_content !== "" && form.distributor_id !== "") {
      document.getElementById("loading-screen").style.display = "flex";
      var dataJson = form;
      let fetchUrl = global.config.urlLink;
      if (type === "create") {
        fetchUrl += "/addbusiness";
        dataJson.from_id="add-businessplan";
      } else {
        fetchUrl += "/editbusiness";
        dataJson.from_id="edit-businessplan";
      }
      dataJson.lang=chooseLang;
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(dataJson),
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then((res) => {
          if (res.status === "200") {
            document.getElementById("loading-screen").style.display = "none";
            // window.location.href = "/businessplan";
            navigate("/businessplan", {
              state: { dataref: paramsLoc.state.filter },
            })
          }
        })
        .catch((err) => {
          console.log(err);
          document.getElementById("loading-screen").style.display = "none";
        
        });
    } else {
      var formField = [];
      if(form.businessplan_title === "") { formField.push("businessplan_title"); }
      if(form.businessplan_content === "") { formField.push("businessplan_content"); }
      if(form.distributor_id === "") { formField.push("distributor_id"); }
      for ( var i = 0; i < formField.length; i++ ) { 
        var fieldname = formField[i];
        var fieldvalue = form[fieldname];
        validateField(fieldname, fieldvalue); 
      }
    }
  };

  const getListId = (list,lid,name) => {
    let updListId = list?.filter(
      (listed) => listed?.value === lid
    );
    setSelected((prevState) => ({
      ...prevState,
      [name]: updListId[0],
    }));
    setForm((prevState) => ({
      ...prevState,
      [name]: lid,
    }));
  };

  const loadListDistributor = (country,pgtype) => {
    return new Promise((resolve) => {
      var datatext = '{';
      datatext += '"from_id":"list-distributor","stat_id":1';
      if(pgtype !== "edit" && pgtype !== "create") { datatext += ',"lang":"'+chooseLang+'"'; }
      if(country !== "") { datatext += ',"country_id":"'+country+'"'; }
      datatext += '}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth2;
      var fetchUrl = global.config.urlLink + "/listdist";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            var updDistributor = { };
            if(resJson.values.length > 0) {
              updDistributor = resJson.values.map((dist) => ({
                value: dist.distributor_id,
                label: dist.distributor_name,
              }));
            } 
            resolve(updDistributor);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadDataBusinessPlan = (id,pgtype) => {
    return new Promise((resolve) => {
      // console.log("detail: "+id);
      // let datatext = '{"from_id":"detail-businessplan","doc_id":'+id+',"lang": "' +chooseLang + '"}';
      let datatext = '{';
      datatext += '"from_id":"detail-businessplan"';
      datatext += ',"doc_id":'+id;
      if(pgtype !== "edit" && pgtype !== "create") { datatext += ',"lang": "' +chooseLang + '"'; }
      datatext += '}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth;
      var fetchUrl = global.config.urlLink + "/detailbusiness";
      
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            resolve(resJson.values);
          } else if(resJson.status === global.config.errcodesess) {
            resolve([resJson.status]);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadData = async (id,countryid,distributorid,pgtype) => {
    // console.log("aaaaaaa;" + id);
    var businessplanstatus = true;
    var datauserstr = {};
    if(id !== undefined) {
      let [result1, result2] = await Promise.all([loadListDistributor(countryid,pgtype), loadDataBusinessPlan(id,pgtype)]);
      if(result2.length > 0) {
        if(result2[0] === global.config.errcodesess) { 
          var resultSO = await Logouts();
          if(resultSO.status === "200") {
            window.location.reload();
          }
        } else {
          if(result2[0].status === 1) { businessplanstatus =true; } else { businessplanstatus =false; } 
          
          distributorid = result2[0].id_distributor;
          datauserstr = {
            doc_id: result2[0].bplan_id,
            businessplan_title: result2[0].bplan_title,
            businessplan_content: result2[0].bplan_content,
            status: businessplanstatus,
            distributor_id: result2[0].id_distributor,
          };
          setDetailData((prevState) => ({
            ...prevState,
            doc_id: result2[0].bplan_id,
            businessplan_title: result2[0].bplan_title,
          }));
          document.getElementById("loading-screen").style.display = "none";
        }
      } else {
        datauserstr = {
          doc_id: 0,
          businessplan_title: "",
          businessplan_content: "",
          status: true,
          distributor_id: distributorid,
        };
        document.getElementById("loading-screen").style.display = "none";
      
      }
      setForm(datauserstr);      
      setListDistributor(result1);
      if(distributorid !== "") { getListId(result1,distributorid,"distributor_id"); }
      
    } else {
      let [result1] = await Promise.all([loadListDistributor(countryid,pgtype)]);
      setListDistributor(result1);
      if(distributorid !== "") { getListId(result1,distributorid,"distributor_id"); }
      document.getElementById("loading-screen").style.display = "none";
      
    }
  }
  
  useEffect(() => {
    
    var docId = "",businessplanTitle="",pageType="detail",idDist="",idCntry="";
    if(localStorage.getItem("dulv")===global.config.conflevel[3]) { idDist = localStorage.getItem("dudst"); } 
    else if(localStorage.getItem("dulv")===global.config.conflevel[2]) { idCntry = localStorage.getItem("ducr"); }

    // console.log(paramsLoc.state.data);
    if(paramsLoc.state !== null) {
      docId = paramsLoc.state.data?.bplan_id;
      businessplanTitle = paramsLoc.state.data?.bplan_title;
      pageType = paramsLoc.state.type;
    } 
    if(docId !== "") {
      document.getElementById("loading-screen").style.display = "flex";
      setDetailData({
        doc_id: docId,
        businessplan_title: businessplanTitle
      });
      setType(pageType);
      loadData(docId,idCntry,idDist,pageType);
      var inpdisabled = true;
      if(pageType === "edit" || pageType === "create") {   
          inpdisabled = false;
      }
      setFormDisabled(inpdisabled);
    } else {
      window.location.href = "/businessplan";
    }

  }, []);



  return (
    <div className="bg-gray-100 p-8 h-screen overflow-y-auto">
      <div className="flex items-center mb-8">
        <Button
          variant="icon"
          icon="left"
          className="mr-5"
          onClick={() => handleBack()}
          // onClick={() => navigate(-1)}
        />
        <h2 className=" text-2xl">
          {type === "edit"
            ? `${translate.business_plan_create.title2} ${detaildata.businessplan_title}`
            : `${translate.business_plan_create.title1}`}
        </h2>
        <div className="flex items-center gap-x-4 ml-5">
          <label
            className={`text-sm text-gray-400 ${
              !form.status && "text-red-500"
            }`}
          >
            {global.config.generalstatus[1]}
          </label>
          <ToggleSwitch
            id="status"
            checked={form.status}
            onClick={type === 'detail' ? null : toggleHandler}
          />
          <label
            className={`text-sm text-gray-400 ${form.status && "text-red-500"}`}
          >
            {global.config.generalstatus[0]}
          </label>
        </div>
      </div>
      {formDisabled === false && (<div className="flex justify-end mb-8">
        <Button
          type="button"
          variant="base"
          title={type === "edit" ? translate.general.button_save : translate.business_plan_create.button1}
          className="bg-red-500 text-white"
          onClick={submitHandler}
        />
      </div>)}
      
      <div className=" gap-1 md:gap-5 md:grid-cols-2 content-start">
        {(localStorage.getItem("dulv")!==global.config.conflevel[3])? 
          <CustomSelect
            // title="Distributor"
            title={translate.business_plan_create.text_input1}
            name="distributor_id"
            defaultValue={selected.distributor_id}
            options={listDistributor}
            value={selected.distributor_id}
            onChange={onChangeSelect}
            disabled={formDisabled}
            errmsg={formErrors.distributor_id}
          />
          : '' }
          <TextInput
            variant="base"
            // title="Title"
            title={translate.business_plan_create.text_input2}
            id="businessplan_title"
            name="businessplan_title"
            value={form.businessplan_title}
            onChange={onChangeHandler}
            disabled={formDisabled}
            errmsg={formErrors.businessplan_title}
          />
          
          <div className="grid col-span-2">
            <TextInput
              variant="ckeditor"
              // title="Content"
              title={translate.business_plan_create.text_input3}
              value={form.businessplan_content}
              onChange={onChangeCKEditor}
              disabled={formDisabled}
              errmsg={formErrors.businessplan_content}
            />
          </div>
      </div>
      
    </div>
  );
};

export default CreateBusinessPlan;
