import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, TextInput, ToggleSwitch } from "../../../components";
import { ReactComponent as ThumbnailIcon } from "../../../assets/svg/thumb-icon.svg";
import { ReactComponent as Loading } from "../../../assets/svg/loading.svg";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../features/translate/translateSlice";
import Logouts from "../../../components/logout"; 

const keyAuth = global.config.typecode[2] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const CreateProduct = () => {
  const translate = useSelector(staticTranslate);
  const chooseLang = useSelector(state => state.translate.lang);
  const navigate = useNavigate();
  const paramsLoc = useLocation();
  const [type, setType] = useState("");
  const [detaildata, setDetailData] = useState({});
  const [formDisabled, setFormDisabled] = useState(true);
  const hiddenThumbInput = useRef(null);
  const [isThumb, setIsThumb] = useState({});
  const [preview, setPreview] = useState({
    previewThumb: "",
  });
  const [loading, setLoading] = useState({
    thumbnail: false,
  });
  const [formErrors, setFormErrors] = useState({
    product_name: ""
  });
  const [form, setForm] = useState({
    product_name: "",
    product_desc: "",
    product_thumb: "",
    product_status: 1,
  });

  const handleUploadThumbChange = async (e) => {
    if (e.target.files.length !== 0) {
      const thumbUpload = e?.target?.files[0];
      const fileName = e.target.files[0].name;
      setIsThumb({ thumbUpload, fileName });
      if (!e) return;
      setLoading((prev) => ({ ...prev, thumbnail: true }));
      let fetchUrl = global.config.urlLink;
      headersParams.Authorization = keyAuth;
      const dataText = {
        fileName: fileName,
      };
      await fetch(fetchUrl + "/getsignedurl", {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(dataText),
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then(async (res) => {
          if (res.status === "200") {
            const urlUploadFile = res.values;
            await fetch(urlUploadFile, {
              method: "PUT",
              headers: {
                "content-type": "application/octet-stream",
              },
              body: thumbUpload,
            }).then(async (res) => {
              let urlThumbPicture = res.url.split("?")[0];
              setForm((prevState) => ({
                ...prevState,
                product_thumb: urlThumbPicture,
              }));
              await fetch(fetchUrl + "/makefilepublic", {
                method: "POST",
                headers: headersParams,
                body: JSON.stringify(dataText),
              }).then((res) => {
                if (res.status === 200) {
                  setLoading((prev) => ({ ...prev, thumbnail: false }));
                  setPreview((prevState) => ({
                    ...prevState,
                    previewThumb: window.URL.createObjectURL(thumbUpload),
                  }));
                }
              });
            });
          }
        });
    }
  };

  const handleClickUploadImage = (type) => {
    if (type === "thumbnail") {
      hiddenThumbInput.current.click();
    } 
  };

  const removeFile = (type) => {
    if (type === "thumbnail") {
      hiddenThumbInput.current.value = null;
      setPreview((prevState) => ({ ...prevState, previewThumb: null }));
      setIsThumb((prev) => ({ ...prev, fileName: "" }));
      setForm((prevState) => ({ ...prevState, product_thumb: "" }));
    } 
  };

  const validateField = (fieldName, value) => {
    let ferrors = "";
    switch (fieldName) {
      case "product_name":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.product_detail.error1; }
        break;
      default:
        break;
    }
    setFormErrors((prevState) => ({ ...prevState, [fieldName]: ferrors }));
  };
  const toggleHandler = () => {
    setForm((prevState) => ({
      ...prevState,
      product_status: form.product_status === 0 ? 1 : 0,
    }));
  };
  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(form.product_name !== "") {
      document.getElementById("loading-screen").style.display = "flex";
      let fetchUrl = global.config.urlLink;
      var dataJson = form;
      if (type === "create") {
        fetchUrl += "/addproduct";
        dataJson.from_id="add-product";
      } else {
        fetchUrl += "/editproduct";
        dataJson.from_id="edit-product";
      }
      dataJson.lang = chooseLang;
      headersParams.Authorization = keyAuth;
      await fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(dataJson),
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then((res) => {
          document.getElementById("loading-screen").style.display = "none";
          if (res.status === "200") {
            window.location.href = "/admin/product";
          }
        })
        .catch((err) => { 
          console.log(err);
          document.getElementById("loading-screen").style.display = "none";
        });
    } else {
      var formField = [];
      if(form.product_name === "") { formField.push("product_name"); }
      for ( var i = 0; i < formField.length; i++ ) { 
        var fieldname = formField[i];
        var fieldvalue = form[fieldname];
        validateField(fieldname, fieldvalue); 
      }
    }
  };
  const loadDataProduct = (id,pgtype) => {
    return new Promise((resolve) => {
      let datatext = '{';
      datatext += '"from_id":"detail-product"';
      datatext += ',"doc_id":'+id;
      if(pgtype !== "edit" && pgtype !== "create") { datatext += ',"lang": "'+chooseLang+'"'; }
      datatext += '}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth;
      var fetchUrl = global.config.urlLink + "/detailproduct";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            resolve(resJson.values);
          } else if(resJson.status === global.config.errcodesess) {
            resolve([resJson.status]);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadData = async (id,pgtype) => {
    var productstatus = true;
    var datauserstr = {};
    if(id !== undefined) {
      let [result2] = await Promise.all([loadDataProduct(id,pgtype)]);
      if(result2.length > 0) {
        if(result2[0] === global.config.errcodesess) { 
          var resultSO = await Logouts();
          if(resultSO.status === "200") {
            window.location.reload();
          }
        } else {
          if(result2[0].status === 1) { productstatus =true; } else { productstatus =false; } 
          
          datauserstr = {
            doc_id: result2[0].product_id,
            product_name: result2[0].product_name,
            product_desc: result2[0].product_description,
            product_thumb: result2[0].pic_thumbnail,
            product_status: productstatus,
          };
          setIsThumb((prev) => ({ ...prev, fileName: result2[0].pic_thumbnail }));
          setDetailData((prevState) => ({
            ...prevState,
            doc_id: result2[0].marketing_id,
            productName: result2[0].product_name,
          }));
        }
      } else {
        datauserstr = {
          doc_id: 0,
          product_name: "",
          product_desc: "",
          product_thumb: "",
          product_status: productstatus,
        };
      }
      setForm(datauserstr);      
    }
  }
  useEffect(() => {
    var docId = "",productName="",pageType="detail";
    
    if(paramsLoc.state !== null) {
      docId = paramsLoc.state.data?.product_id;
      productName = paramsLoc.state.data?.product_name;
      pageType = paramsLoc.state.type;
    } 
    if(docId !== "") {
      document.getElementById("loading-screen").style.display = "flex";
      setDetailData({
        doc_id: docId,
        productName: productName
      });
      setType(pageType);
      loadData(docId,pageType);
      var inpdisabled = true;
      if(pageType === "edit" || pageType === "create") {   
          inpdisabled = false;
      }
      setFormDisabled(inpdisabled);
      document.getElementById("loading-screen").style.display = "none";
    
    } else {
      window.location.href = "/admin/product";
    }

  }, []);
  return (
    <div className=" bg-gray-100 h-screen flex-1 p-8 overflow-y-auto relative">
      <div className="flex items-center gap-10 mb-8">
        <div className="flex items-center">
          <Button
            variant="icon"
            icon="left"
            className="mr-5"
            onClick={() => navigate(-1)}
          />
          <h2 className=" text-2xl">{`${
            type === "create" ? translate.product_detail.title1 : type === "edit" ? translate.product_detail.title2 : translate.product_detail.title3
          }`}</h2>
        </div>
        <div className="flex items-center gap-x-4">
          <label
            className={`text-sm text-gray-400 ${
              !form.product_status && "text-red-500"
            }`}
          >
            {global.config.generalstatus[1]}
          </label>
          { formDisabled? 
            <ToggleSwitch id="product_status" checked={form.product_status} />
          : <ToggleSwitch id="product_status" checked={form.product_status} onClick={toggleHandler} />
          }
          <label
            className={`text-sm text-gray-400 ${
              form.product_status && "text-red-500"
            }`}
          >
            {global.config.generalstatus[0]}
          </label>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-1 justify-end gap-4 mb-8 px-10">
          {!formDisabled && (
            <Button
              type="submit"
              variant="base"
              title={type === "edit" ? translate.general.button_save : translate.product_detail.button1}
              className="bg-red-500 text-white"
            />
          )}
        </div>
        <div className="px-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            <TextInput
              variant="base"
              // title="Product Name"
              title={translate.product_detail.text_input1}
              name="product_name"
              value={form.product_name}
              onChange={onChangeHandler}
              disabled={formDisabled}
              errmsg={formErrors.product_name}
            />
            {/*UPLOAD THUMBNAIL*/}
            <div className="row-span-2 ">
              <label className="text-gray-500 mb-2 block">
                {/* Product Thumbnail */}
                {translate.product_detail.label1}
              </label>
              <div className="flex flex-col items-center bg-red-50 rounded-lg border border-red-300 justify-around w-full h-72">
                <div className="w-50 h-50 rounded-lg self-center flex items-center justify-center">
                  {!isThumb?.fileName ? (
                    <div className="flex flex-col justify-center items-center">
                      {loading.thumbnail ? (
                        <Loading />
                      ) : (
                        <>
                          <ThumbnailIcon className=" w-32 h-32" />
                          {type !== "detail" && (
                            <>
                              <p className="text-xs text-gray-500 text-center italic mb-8">
                                {/* Browse your files .jpeg / .jpg / .png / .gif
                                extensions. */}
                                {translate.general.label_allow_img}
                              </p>
                              <Button
                                type="button"
                                variant="base"
                                // title="Upload Product Image"
                                title={translate.product_detail.button2}
                                className="bg-red-500 text-white mx-10"
                                onClick={() =>
                                  handleClickUploadImage("thumbnail")
                                }
                              />
                            </>
                          )}
                        </>
                      )}
                    </div>
                  ) : isThumb?.fileName ? (
                    <div>
                      {loading.thumbnail ? (
                        <Loading />
                      ) : (
                        <>
                          <Button
                            type="button"
                            variant="icon"
                            icon="close"
                            onClick={() => removeFile("thumbnail")}
                            className=" absolute z-[999999] ml-1 mt-1"
                            style={
                              type === "detail" ? { display: "none" } : null
                            }
                          />
                          <div className="flex flex-col justify-center items-center">
                            <img
                              src={preview?.previewThumb || isThumb.fileName}
                              alt="img-product.jpg"
                              className=" w-52 h-52 rounded-lg -z-0 self-center mb-5"
                            />
                            {/* <p className="text-xs text-gray-400 line-clamp-1 text-center w-32">
                              {isThumb?.fileName}
                            </p> */}
                          </div>
                        </>
                      )}
                    </div>
                  ) : null}
                </div>
                <input
                  type="file"
                  ref={hiddenThumbInput}
                  onChange={handleUploadThumbChange}
                  style={{ display: "none" }}
                  accept="image/png, image/gif, image/jpeg, image/jpg"
                  hidden
                />
              </div>
            </div>
            
            <TextInput
              variant="textarea"
              // title="Product Description"
              // placeholder="Specification Product"
              title={translate.product_detail.text_input2}
              placeholder={translate.product_detail.placeholder2}
              name="product_desc"
              value={form.product_desc}
              onChange={onChangeHandler}
              disabled={formDisabled}
            />
            
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateProduct;
