// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-content {
  height: 25vw;
}

.ck.ck-content ul,
.ck.ck-content ul li {
  list-style-type: inherit;
}
.ck.ck-content ul {
  padding-left: 40px;
}

.ck.ck-content ol,
.ck.ck-content ol li {
  list-style-type: decimal;
}
.ck.ck-content ol {
  padding-left: 40px;
}
.ck-editor__editable_inline {
  padding: 0 30px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/TextInput/ckeditor.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;;EAEE,wBAAwB;AAC1B;AACA;EACE,kBAAkB;AACpB;;AAEA;;EAEE,wBAAwB;AAC1B;AACA;EACE,kBAAkB;AACpB;AACA;EACE,0BAA0B;AAC5B","sourcesContent":[".ck-content {\n  height: 25vw;\n}\n\n.ck.ck-content ul,\n.ck.ck-content ul li {\n  list-style-type: inherit;\n}\n.ck.ck-content ul {\n  padding-left: 40px;\n}\n\n.ck.ck-content ol,\n.ck.ck-content ol li {\n  list-style-type: decimal;\n}\n.ck.ck-content ol {\n  padding-left: 40px;\n}\n.ck-editor__editable_inline {\n  padding: 0 30px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
