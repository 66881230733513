import React from "react";
import DataTable from "react-data-table-component";
import { Modal, NoRecords } from "../../../components";

const ModalPipelineDetails = ({ onClick, onBackgroundClick, content }) => {
  const { index, params, data } = content;
  const columns = [
    { name: "Product Name", selector: (row) => row.product_name, grow: 2 }
  ];
  if(index !== undefined) {
    if(index === 0) {
      columns.push({ name: "Project Won", selector: (row) => row.totalProject })
    } else if(index === 1) {
      columns.push({ name: "Remaining Target Unit", selector: (row) => row.totalRemain })
    } else if(index === -2) {
      columns.push({ name: "Unit Product(s)", selector: (row) => row.totalUnits })
    } else {
      columns.push({ name: "Target Unit", selector: (row) => row.totalTarget })
    }
  }
  return (
    <Modal
      title={`${
        (index === -2 && params !== "") ? params + " Projects" : "Product(s)"
      }`}
      onClick={onClick}
      onBackgroundClick={onBackgroundClick}
      className={{width:"200px"}}
    >
      <div>
      <DataTable
        columns={columns}
        data={data}
        fixedHeader
        noDataComponent={<NoRecords style={{minHeight: "50px"}}/>}
        customStyles={{
          headRow: {
            style: {
              borderBottomWidth: "3px",
              borderBottomStyle: "solid",
              fontWeight: "bold",
              backgroundColor: "#F5F6FA",
              color: "#8A92A6",
            },
            denseStyle: {
              minHeight: "32px",
            },
          },
          table: {
            style: {
              height: `300px`,
            },
          },
        }}
      />
      
      </div>
    </Modal>
  );
};

export default ModalPipelineDetails;
