import React from "react";
import { useSelector } from "react-redux";
import { Modal } from "../../../components";
import { staticTranslate } from "../../../features/translate/translateSlice";

const VContactModal = ({ onBackgrounClick, onClick, contentData }) => {
  const translate = useSelector(staticTranslate)
  return (
    <Modal
      // title="Message"
      title={translate.contact_us.modal_title_1}
      onBackgroundClick={onBackgrounClick}
      onClick={onClick}
    >
      <div>
        {contentData.distributor_name && (<div className="flex w-full py-1">
          <div className="w-32 text-gray-500">{translate.contact_us.modal_label_1}</div>
          <div className="flex-auto text-gray-500">{contentData.distributor_name || "-"}</div>
        </div>)}
        <div className="flex w-full py-1">
          <div className="w-32 text-gray-500">{translate.contact_us.modal_label_2}</div>
          <div className="flex-auto text-gray-500">{contentData.contact_name || "-"}</div>
        </div>
        <div className="flex w-full py-1">
          <div className="w-32 text-gray-500">{translate.contact_us.modal_label_3}</div>
          <div className="flex-auto text-gray-500">{contentData.contact_email || "-"}</div>
        </div>
        <div className="flex w-full py-1">
          <div className="w-32 text-gray-500">{translate.contact_us.modal_label_4}</div>
          <div className="flex-auto text-gray-500">{contentData.contact_phone || "-"}</div>
        </div>
        <div className="flex w-full py-1 mt-5 mb-10">
          <div className="w-32 text-gray-500">{translate.contact_us.modal_label_5}</div>
          <div className="flex-auto text-gray-500">{contentData.contact_message || "-"}</div>
        </div>
      </div>    
      
    </Modal>
  );
};

export default VContactModal;
