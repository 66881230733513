import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useScrollbarSize from "react-scrollbar-size";
import { Button, Card, ToggleSwitch, Loading, NoRecords } from "../../../components";
import { staticTranslate } from "../../../features/translate/translateSlice";
import Logouts from "../../../components/logout"; 

const keyAuth = global.config.typecode[16] + global.config.authBearerKey;
const keyAuth2 = global.config.typecode[3] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const CampaignList = () => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector((state) => state.translate.lang)
  const navigate = useNavigate();
  const { height } = useScrollbarSize();
  const [isChecked, setChecked] = useState([]);
  const [pending, setPending] = useState(true);
  const [listCampaign, setListCampaign] = useState([]);
  const [listDistributor, setListDistributor] = useState([]);
  const [filterData, setFilterData] = useState({statid:"",distributorid:"",countryid:"",rowpage:0,statcampaignid:""});
  const [defRowPage, setDefRowPage] = useState(0);
  const paramsLoc = useLocation();
  if(paramsLoc.state !== null) { var { dataref } = paramsLoc.state; }

  const handleRowsPerPageChange = (val) => {
    setFilterData({ ...filterData, rowpage: val });
    setDefRowPage(val);
  }
  const handleDelete = (id) => {
    if (window.confirm(`${translate.marketing_campaign.alert_delete}`)) {
      document.getElementById("loading-screen").style.display = "flex";
      var fetchUrl = global.config.urlLink + "/deletecampaign/" + id;
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "GET",
        headers: headersParams,
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then((res) => {
          if (res.status === "200") {
            document.getElementById("loading-screen").style.display = "none";
            window.location.href = "/campaign";
          }
        });
    }
  };

  const handleToggle = (id, stat) => {
    let statcheck = 1,
      statsend = true;
    if (stat === 1) {
      statcheck = 0;
      statsend = false;
    } else {
      statcheck = 1;
      statsend = true;
    }
    setChecked((prevState) => ({ ...prevState, [id]: statcheck }));
    let datatext =
      '{"doc_id":"' +
      id +
      '","status": "' +
      statsend +
      '","lang": "' +
      chooseLang +
      '","from_id":"edit-status-campaign"}';

    let data = JSON.parse(datatext);
    let fetchUrl = global.config.urlLink + "/editcampaign";
    headersParams.Authorization = keyAuth;
    fetch(fetchUrl, {
      method: "POST",
      headers: headersParams,
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
      })
      .then((resJson) => {
        return false;
      })
      .catch((err) => {
        return false;
      });
  };
  const handleSelect = async (e) => {
    document.getElementById("loading-screen").style.display = "flex";
    var name = e.target.name;
    var value = e.target.value;
    var result1a;
    if(name === "distributorid") {
      result1a = await listCampaignData(filterData.statid,value,filterData.countryid,filterData.statcampaignid);
    } else if(name === "statid") {
      result1a = await listCampaignData(value,filterData.distributorid,filterData.countryid,filterData.statcampaignid);
    } else if(name === "statcampaignid") {
      result1a = await listCampaignData(filterData.statid,filterData.distributorid,filterData.countryid,value);
    }
    setListCampaign(result1a);
    setFilterData({ ...filterData, [name]: value });
    document.getElementById("loading-screen").style.display = "none";
  }
  const handleDetailClick = (resList,type) => {
    navigate("/campaign/detail", {
      state: { data: resList, type: type, filter: filterData },
    })
  }
  const loadDistributor = (country) => {
    return new Promise(resolve => {
      if(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2]) {
        var datatext ='{';
        datatext += '"from_id":"list-distributor","stat_id":1';
        datatext += ',"lang": "'+chooseLang+'"';
        if(country !== "") { datatext += ',"country_id":"'+country+'"'; }
        datatext += '}';
        var data = JSON.parse(datatext); 
        headersParams.Authorization = keyAuth2;
        
        fetch(global.config.urlLink+"/listdist", {
          method: 'POST',
          headers: headersParams, 
          body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJson => {
            if(resJson.status === "200") {
              resolve(resJson.values);
            } else {
              resolve([]);
            }
        }).catch((err) => {
            resolve([]);
        });
      } else { resolve([]); }
    });
  }
  const listCampaignData = (stat,distributor,country,campaignstat) => {
    return new Promise(resolve => {
      var fetchUrl = global.config.urlLink + "/listcampaign";
      var dataText = '{';
      dataText += '"from_id": "list-campaign"';
      dataText += ',"lang": "'+chooseLang+'"';
      if(stat !== "") { dataText += ',"stat_id":'+parseInt(stat); }
      if(distributor !== "") { dataText += ',"dist_id":"'+distributor+'"'; }
      if(country !== "") { dataText += ',"country_id":"'+country+'"'; }
      if(campaignstat !== "") { dataText += ',"campaign_stat_id":"'+campaignstat+'"'; }
      dataText += '}';
      let data = JSON.parse(dataText);
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((res) => {
          if (res.status === "200") {
            resolve(res.values);
          } else if(res.status === global.config.errcodesess) {
            resolve([res.status]);
          } else { resolve([])}
      }).catch((err) => {
        resolve([]);
      });
    });
  };
  const loadData = async () => {
    var idDist = "", idCntry="", idStat="", idRowPage=defRowPage, idCampaignStat="";
    if(localStorage.getItem("dulv")===global.config.conflevel[3]) { idDist = localStorage.getItem("dudst"); } 
    else if(localStorage.getItem("dulv")===global.config.conflevel[2]) { idCntry = localStorage.getItem("ducr"); }

    if(dataref !== undefined) { idDist = dataref.distributorid; idCntry=dataref.countryid; idStat=dataref.statid; idRowPage=dataref.rowpage; idCampaignStat=dataref.statcampaignid; }
    else { idRowPage = 10; }

    let [result1, result2] = await Promise.all([listCampaignData(idStat,idDist,idCntry,idCampaignStat), loadDistributor(idCntry)]);
    if(result1.length > 0 && result1[0] === global.config.errcodesess) {
      var resultSO = await Logouts();
      if(resultSO.status === "200") {
        window.location.reload();
      }
    } else {
      setPending(false); 
      // updtlistStat(result1);
      setListCampaign(result1);
      setListDistributor(result2);
      // setFilterData({ ...filterData, distributorid: idDist, countryid: idCntry });
      setFilterData({statid:idStat, distributorid: idDist, countryid: idCntry, rowpage: idRowPage, statcampaignid: idCampaignStat});
      setDefRowPage(idRowPage);
    }
  }

  var optDistributor = [];
  if(listDistributor !== undefined) {
    for(var c=0; c<listDistributor.length; c++) {
      optDistributor.push(<option value={listDistributor[c].distributor_id} key={listDistributor[c].distributor_id}>{listDistributor[c].distributor_name}</option>)
    }
  }

  const ActionCols = (row) => (
    <div className="flex flex-1 gap-4 hover:fill-red-500">
      {/* <FaInfo 
        onClick={() => handleDetailClick(row,"detail")}
        className="hover:fill-red-500 cursor-pointer fasize"
      /> */}
    {((localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2]) && row.campaign_status !== global.config.campaignstatus[2]) && 
      <FaEdit 
          onClick={() => handleDetailClick(row,"edit")}
          className="hover:fill-red-500 cursor-pointer fasize"
      />
    } 
      {(localStorage.getItem("dulv")===global.config.conflevel[0] && row.campaign_status !== global.config.campaignstatus[2]) && 
      <FaTrash
          onClick={() => handleDelete(row.campaign_id)}
          className="hover:fill-red-500 cursor-pointer fasize"
        />
      }
    </div>
  );

  const column = [
    { name: translate.marketing_campaign.column_1, selector: (row) => row.campaign_name },
    { name: translate.marketing_campaign.column_2, selector: (row) => row.product_name },
    { name: translate.marketing_campaign.column_3, selector: (row) => row.campaign_type_of_content },
  ];
  if(localStorage.getItem("dulv")!==global.config.conflevel[3]) {
    column.push({ name: translate.marketing_campaign.column_4, selector: (row) => row.distributor_name });
  }
  column.push({ name: translate.general.column_status, selector: (row) => row.campaign_status });

  if(localStorage.getItem("dulv")!==global.config.conflevel[3]) {
    column.push({ name: translate.general.column_action, cell: ActionCols,  width: "120px"  });
  }
  
  
  useEffect(() => {
    loadData();
  }, []);
  return (
    <div className="flex-1 p-8 h-screen bg-gray-100 overflow-y-auto">
      <div className="flex items-center mb-8">
        <h2 className="text-2xl">{translate.marketing_campaign.title}</h2>
        {(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[3]) && 
        <Button
          variant="icon"
          icon="plus"
          className="ml-5" 
          onClick={() => handleDetailClick(filterData.distributorid,"create")}
        />
      }
      </div>
      <div className="bg-white shadow-3xl flex flex-1 p-4 gap-x-4 rounded-lg mb-2">
        <p className=" text-gray-400 font-bold">{translate.general.filter}: </p>
        <select name="statcampaignid" id="statcampaignid" className="bg-white text-gray-400 outline-none" defaultValue=""
          value={filterData.statcampaignid} 
          onChange={handleSelect}
        >
          <option value="">{translate.general.filter_status}</option>
          <option value={global.config.campaignstatus[0]}>{global.config.campaignstatus[0]}</option>
          {(localStorage.getItem("dulv")===global.config.conflevel[0]) &&  <option value={global.config.campaignstatus[1]}>{global.config.campaignstatus[1]}</option> }
          <option value={global.config.campaignstatus[2]}>{global.config.campaignstatus[2]}</option>
        </select>
        {(localStorage.getItem("dulv")!==global.config.conflevel[3])?
        <>
        <select name="distributorid" id="distributorid" className="bg-white text-gray-400 outline-none" defaultValue=""
            value={filterData.distributorid} 
            onChange={handleSelect}
            
          >
            <option value="">{translate.general.filter_distributor}</option>
            {optDistributor}
        </select>
        </> : <></>
        }
      </div>
      
      
      <Card className={"h-auto w-auto"}>
      {(defRowPage > 0) && <DataTable
          columns={column}
          data={listCampaign}
          fixedHeader
          highlightOnHover
          defaultSortFieldId="id"
          progressPending={pending}
          progressComponent={<Loading className="h-10 w-10 mt-10 mb-10"/>}
          pointerOnHover
          pagination
          paginationPerPage={defRowPage}
          paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
          onRowClicked={(row) => handleDetailClick(row,"detail")}
          noDataComponent={<NoRecords />}
          onChangeRowsPerPage={handleRowsPerPageChange}
          customStyles={{
            headRow: {
              style: {
                borderBottomWidth: "3px",
                borderBottomStyle: "solid",
                fontWeight: "bold",
                backgroundColor: "#F5F6FA",
                color: "#8A92A6",
              },
              denseStyle: {
                minHeight: "32px",
              },
            },
            table: {
              style: {
                height: `calc(100vh - 180px - ${height}px)`,
              },
            },
          }}
        />
      }
      </Card>
    </div>
  );
};

export default CampaignList;
