import React from "react";

const ToggleSwitch = ({ id, checked, onClick }) => {
  return (
    <div className="relative flex flex-col">
      <div className="mt-1">
        <label className="inline-flex relative items-center cursor-pointer">
          <input 
            id={id}
            name={id}
            type="checkbox"
            className="sr-only peer"
            checked={checked}
            readOnly
          />
          <div
            onClick={onClick}
            className="w-8 h-3 bg-gray-300 
            rounded-full 
            peer peer-focus:ring-red-300 
            peer-checked:after:translate-x-full after:content-[''] duration-1000 after:absolute after:-top-0.5 after:left-[0px] after:bg-gray-200 
            peer-checked:after:bg-red-400 after:border-gray-500 after:rounded-full after:h-4 after:w-4 after:transition-all 
            peer-checked:bg-red-300"
          ></div>
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
