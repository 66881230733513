import React, { useEffect, useState } from "react";
// import useScrollbarSize from "react-scrollbar-size";
// import Carousel from 'react-bootstrap/Carousel';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { FaFacebookSquare, FaYoutubeSquare,FaLinkedin } from "react-icons/fa";
import Logouts from "../../../components/logout";


const keyAuth = global.config.typecode[14] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const PromotionalView = () => {
  const [listBanner, setListBanner] = useState([]);
  const [viewBanner, setViewBanner] = useState([]);
  
  const handleClick = (link,target) => {
    window.open(link , target)
  }
  const handleBannClick = (idx,resList) => {
    if(resList[idx].banner_linkurl.indexOf("http://") > -1 || resList[idx].banner_linkurl.indexOf("https://") > -1) {
      window.open(resList[idx].banner_linkurl , resList[idx].banner_targeturl)
    }
  };
  const getBannerList = () => {
    document.getElementById("loading-screen").style.display = "flex";
    let fetchUrl = global.config.urlLink + "/listbanner";
    headersParams.Authorization = keyAuth;
    const dataText = { from_id: "list-banner",stat_id:1 };
    fetch(fetchUrl, {
      method: "POST",
      headers: headersParams,
      body: JSON.stringify(dataText),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
      })
      .then(async (res) => {
        if (res.status === "200") {
          var lbanner = [];
          var result = res.values;
          if(result.length > 0) {
            for(var b=0; b<result.length; b++) {
              lbanner.push(
                <div key={b}>
                    <img src={result[b].banner_fileurl} alt="" />
                    {/*<p className="legend">Legend 1</p> */}
                </div>
              )
            }
          }
          setViewBanner(lbanner);
          setListBanner(result);
          
        } else {
          var resultSO = await Logouts();
          if(resultSO.status === "200") {
            window.location.reload();
          }
        }
        document.getElementById("loading-screen").style.display = "none";
      });
  };
  useEffect(() => {
    getBannerList();
  }, []);

  return (
    <div className="flex-1 p-8 bg-gray-100 h-screen overflow-y-scroll no-scrollbar">
      <div className="mb-8">
        <div className="flex justify-center">
        {listBanner.length > 0 && (<Carousel 
                autoPlay={true}
                interval={5000}
                showThumbs={false}
                infiniteLoop={true}
                showStatus={false}
                onClickItem={(row) => handleBannClick(row,listBanner)}
                // labels={listBanner}
                // stopOnHover={true}
              >
                {viewBanner}
                
            </Carousel>
        )}
        </div>
      </div> 
      <div className="mb-8 flex">
        <div  className="flex flex-1 rounded-lg justify-end align-middle mr-3">
          <FaFacebookSquare className="fasosmed mr-3" onClick={() => handleClick("https://www.facebook.com/veoliawatertechnologiesasiapac","_blank")}/>
          <FaLinkedin className="fasosmed mr-3 " onClick={() => handleClick("https://www.linkedin.com/company/veolia-water-technologies-asia-pacific/?viewAsMember=true","_blank")}/>
          <FaYoutubeSquare className="fasosmed align-middle" style={{fill: "red"}} onClick={() => handleClick("https://www.youtube.com/@veoliawatertechnologiesasi6630","_blank")}/>
          
        </div>
      </div>
    </div>
  );
};

export default PromotionalView;
