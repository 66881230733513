import React from "react";
import { FormErrors } from "../../components";

const CustomCheckbox = ({ 
  id = "",
  name = "",
  label = "",
  disabled,
  errmsg = "",
  className = "",
  onChange = () => {},
  value = {},
  checked,
}) => {
  // ...
  return (
    <div className={`${className} checkbox-wrapper`}>
      <label className="flex flex-row justify-center items-center gap-2">
        <input 
          id={id}
          name={name}
          type="checkbox"
          checked={checked}
          onChange={onChange} 
          disabled={disabled}
          value={value}
        />
        <span>{label}</span>
      </label>
      <div><FormErrors formErrors={errmsg}/></div>
    </div>
  );
}

export default CustomCheckbox;
