import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../../features/translate/translateSlice";
import { Button, Modal, TextInput } from "../../../../components";

const headersParams = global.config.headersparam;
const keyAuth = global.config.typecode[9] + global.config.authBearerKey;

const ModalTraining = ({ onClick, onBackgroundClick, content }) => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector((state) => state.translate.lang)
  const { type, data } = content;
  const [formDisabled, setFormDisabled] = useState(true);
  const [form, setForm] = useState({
    doc_id: "",
    distributor_id: "",
    participant_name: "",
    participant_email: "",
    participant_position: "",
    status: true,
  });
  const [formErrors, setFormErrors] = useState({
    participant_name: "",
    participant_email: "",
  });

  useEffect(() => {
    // console.log("data", data);
    // console.log("data", type);
    // setForm((prevState) => ({ ...prevState, doc_id: data.participant_id }));
    if (type === "edit" || type === "detail") {
      setForm((prev) => ({
        ...prev,
        doc_id: data.doc_id,
        distributor_id: data.training_dist_id,
        participant_name: data.participant_name,
        participant_email: data.participant_email,
        participant_position: data.participant_position
      }));
    } else {
      setForm((prev) => ({ ...prev, doc_id: data.doc_id, distributor_id: data.training_dist_id }));
    }
    if (type === "edit" || type === "create") {
      setFormDisabled(false);
    } else {
      setFormDisabled(true);
    }
  }, []);

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.participant_email !== "" && form.participant_name !== "") {
      document.getElementById("loading-screen").style.display = "flex";
      if(form.participant_position === undefined || form.participant_position === null) { form.participant_position = ""; }
      let fetchUrl = global.config.urlLink;
      if (type === "create") {
        fetchUrl += "/addtraininguser";
        form.from_id = "add-training-user";
      } else {
        fetchUrl += "/edittraininguser";
        form.from_id = "edit-training-user";
      }
      form.lang=chooseLang;
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(form),
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then((res) => {
          if (res.status === "200") {
            // window.location.href = "/training/detail";
            window.location.href = "/training";
            document.getElementById("loading-screen").style.display = "none";
          }
        });
      } else {
        var formField = [];
        if(form.participant_name === "") { formField.push("participant_name"); }
        if(form.participant_email === "") { formField.push("participant_email"); }
        for ( var i = 0; i < formField.length; i++ ) { 
          var fieldname = formField[i];
          var fieldvalue = form[fieldname];
          validateField(fieldname, fieldvalue); 
        }
    }
  };
  const validateField = (fieldName, value) => {
    let ferrors = "";
    switch (fieldName) {
      case "participant_name":
        if (value.trim().length > 0) {
          ferrors = "";
        } else {
          ferrors = translate.training_participant.error1;
        }
        break;
      case "participant_email":
        if (value.trim().length > 0) {
          ferrors = "";
        } else {
          ferrors = translate.training_participant.error2;
        }
        break;

      default:
        break;
    }
    setFormErrors((prevState) => ({ ...prevState, [fieldName]: ferrors }));
  };

  return (
    <Modal
      title={
        type === "create"
          ? translate.training_participant.title1
          : type === "edit"
          ? translate.training_participant.title2
          : translate.training_participant.title3
      }
      onClick={onClick}
      onBackgroundClick={onBackgroundClick}
    >
      <TextInput
        variant="base"
        // title="Name"
        title={translate.training_participant.text_input1}
        id="participant_name"
        name="participant_name"
        value={form.participant_name}
        onChange={onChangeHandler}
        errmsg={formErrors.participant_name}
        disabled={formDisabled}
      />
      <TextInput
        variant="base"
        // title=" Email"
        title={translate.training_participant.text_input2}
        id="participant_email"
        name="participant_email"
        value={form.participant_email}
        onChange={onChangeHandler}
        errmsg={formErrors.participant_email}
        disabled={formDisabled}
      />
      <TextInput
        variant="base"
        // title=" Position"
        title={translate.training_participant.text_input3}
        id="participant_position"
        name="participant_position"
        value={form.participant_position}
        onChange={onChangeHandler}
        errmsg={formErrors.participant_position}
        disabled={formDisabled}
      />
      
      {type !== "detail" && (
        <div className="flex justify-end">
          <Button
            variant="base"
            title={type === "create" ? translate.training_participant.button1 : translate.general.button_save}
            className="bg-red-500 text-white"
            onClick={handleSubmit}
          />
        </div>
      )}
    </Modal>
  );
};

export default ModalTraining;
