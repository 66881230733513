import React from "react";

const UserLoginReport = () => {
  return (
    <div className="bg-gray-100 p-8 h-screen overflow-y-auto">
      <iframe
       width="100%"
       height="1068"
        src="https://lookerstudio.google.com/embed/reporting/e6f0edc1-7537-410d-acde-c154f072424c/page/mWFbC"
        frameborder="0"
        // style="border:0"
        allowfullscreen
      ></iframe>
    </div>
  );
};

export default UserLoginReport;
