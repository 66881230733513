import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, TextInput, ToggleSwitch, FormErrors } from "../../../components";
import { ReactComponent as ThumbnailIcon } from "../../../assets/svg/thumb-icon.svg";
import { ReactComponent as Loading } from "../../../assets/svg/loading.svg";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../features/translate/translateSlice";
import Logouts from "../../../components/logout"; 

const keyAuth = global.config.typecode[14] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const dataTarget = [
  { value: "_blank", label: "Open New Tab" },
  { value: "_self", label: "Open in the same page" },
];
const CreatePromotional = () => {
  const translate = useSelector(staticTranslate);
  const chooseLang = useSelector((state) => state.translate.lang);
  const navigate = useNavigate();
  const paramsLoc = useLocation();
  const [type, setType] = useState("");
  const [detaildata, setDetailData] = useState({});
  const [formDisabled, setFormDisabled] = useState(true);
  const hiddenThumbInput = useRef(null);
  const [isThumb, setIsThumb] = useState({});
  const [preview, setPreview] = useState({
    previewThumb: "",
  });
  const [selected, setSelected] = useState({
    link_target: "_blank"
  });

  const [loading, setLoading] = useState({
    thumbnail: false,
  });
  const [formErrors, setFormErrors] = useState({
    file_url: "",
    file_link: "",
    link_target: "",
    sort_number: ""
  });
  const [form, setForm] = useState({
    file_name: "",
    file_type: "",
    file_url: "",
    file_link: "",
    link_target: "_blank",
    sort_number: "",
    status: 1
  });

  const handleUploadThumbChange = async (e) => {
    if (e.target.files.length !== 0) {
      const thumbUpload = e?.target?.files[0]; 
      const fileName = e.target.files[0].name;
      const fileType = e.target.files[0].type;
      setIsThumb({ thumbUpload, fileName });
      if (!e) return;
      setLoading((prev) => ({ ...prev, thumbnail: true }));
      setFormErrors((prevState) => ({ ...prevState, "file_url": "" }));
      let fetchUrl = global.config.urlLink;
      headersParams.Authorization = keyAuth;
      const dataText = {
        fileName: fileName,
      };
      await fetch(fetchUrl + "/getsignedurl", {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(dataText),
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then(async (res) => {
          if (res.status === "200") {
            const urlUploadFile = res.values;
            await fetch(urlUploadFile, {
              method: "PUT",
              headers: {
                "content-type": "application/octet-stream",
              },
              body: thumbUpload,
            }).then(async (res) => {
              let urlThumbPicture = res.url.split("?")[0];
              setForm((prevState) => ({
                ...prevState,
                file_url: urlThumbPicture,
                file_name: fileName,
                file_type: fileType,
              }));
              await fetch(fetchUrl + "/makefilepublic", {
                method: "POST",
                headers: headersParams,
                body: JSON.stringify(dataText),
              }).then((res) => {
                if (res.status === 200) {
                  setLoading((prev) => ({ ...prev, thumbnail: false }));
                  setPreview((prevState) => ({
                    ...prevState,
                    previewThumb: window.URL.createObjectURL(thumbUpload),
                  }));
                }
              });
            });
          }
        });
    }
  };

  const handleClickUploadImage = (type) => {
    if (type === "thumbnail") {
      hiddenThumbInput.current.click();
    } 
  };

  const removeFile = (type) => {
    if (type === "thumbnail") {
      hiddenThumbInput.current.value = null;
      setPreview((prevState) => ({ ...prevState, previewThumb: null }));
      setIsThumb((prev) => ({ ...prev, fileName: "" }));
      setForm((prevState) => ({ ...prevState, file_url: "" }));
    } 
  };

  const validateField = (fieldName, value) => {
    let ferrors = "";
    switch (fieldName) {
      case "file_link":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.banner_detail.error1; }
        break;
      case "link_target":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.banner_detail.error2; }
        break;
      case "sort_number":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.banner_detail.error3; }
        break;
      case "file_url":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.banner_detail.error4; }
        break;
      default:
        break;
    }
    setFormErrors((prevState) => ({ ...prevState, [fieldName]: ferrors }));
  };
  const toggleHandler = () => {
    setForm((prevState) => ({
      ...prevState,
      status: form.status === 0 ? 1 : 0,
    }));
  };
  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };
  const onChangeSelect = (e, sel) => {
    var name = sel.name;
    var value = e.value;
    setSelected((prevState) => ({
      ...prevState,
      [name]: e,
    }));
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // if(form.file_url !== "" && form.file_link !== "" && form.link_target !== "" && form.sort_number !== "") {
    if(form.file_url !== "" && form.link_target !== "" && form.sort_number !== "") {
      document.getElementById("loading-screen").style.display = "flex";
      let fetchUrl = global.config.urlLink;
      var dataJson = form;
      if (type === "create") {
        fetchUrl += "/addbanner";
        dataJson.from_id="add-banner";
      } else {
        fetchUrl += "/editbanner";
        dataJson.from_id="edit-banner";
      }
      dataJson.lang = chooseLang;
      headersParams.Authorization = keyAuth;
      await fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(dataJson),
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then((res) => {
          document.getElementById("loading-screen").style.display = "none";
          if (res.status === "200") {
            window.location.href = "/admin/promo";
          }
        })
        .catch((err) => { 
          console.log(err);
          document.getElementById("loading-screen").style.display = "none";
        });
    } else {
      var formField = [];
      if(form.file_url === "") { formField.push("file_url"); }
      // if(form.file_link === "") { formField.push("file_link"); }
      if(form.link_target === "") { formField.push("link_target"); }
      if(form.sort_number === "") { formField.push("sort_number"); }
      for ( var i = 0; i < formField.length; i++ ) { 
        var fieldname = formField[i];
        var fieldvalue = form[fieldname];
        validateField(fieldname, fieldvalue); 
      }
    }
  };
  const getListId = (list,lid,name) => {
    let updListId = list?.filter(
      (listed) => listed?.value === lid
    );
    setSelected((prevState) => ({
      ...prevState,
      [name]: updListId[0],
    }));
    setForm((prevState) => ({
      ...prevState,
      [name]: lid,
    }));
  };
  const loadDataBanner = (id,pgtype) => {
    return new Promise((resolve) => {
      let datatext = '{';
      datatext += '"from_id":"detail-banner"';
      datatext += ',"doc_id":'+id;
      if(pgtype !== "create" && pgtype !== "edit") { datatext +=',"lang":"'+chooseLang+'"'; }
      datatext += '}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth;
      var fetchUrl = global.config.urlLink + "/detailbanner";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            resolve(resJson.values);
          } else if(resJson.status === global.config.errcodesess) {
            resolve([resJson.status]);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadData = async (id,pgtype) => {
    var bannerstatus = true;
    var datauserstr = {};
    if(id !== undefined) {
      let [result2] = await Promise.all([loadDataBanner(id,pgtype)]);
      var targetLink = "";
      if(result2.length > 0) {
        if(result2[0] === global.config.errcodesess) { 
          var resultSO = await Logouts();
          if(resultSO.status === "200") {
            window.location.reload();
          }
        } else {
          if(result2[0].status === 1) { bannerstatus =true; } else { bannerstatus =false; } 
          targetLink = result2[0].banner_targeturl;
          datauserstr = {
            doc_id: result2[0].banner_id,
            file_name: result2[0].banner_filename,
            file_type: result2[0].banner_filetype,
            file_url: result2[0].banner_fileurl,
            file_link: result2[0].banner_linkurl,
            link_target: result2[0].banner_targeturl,
            sort_number: result2[0].banner_sortid,
            status: bannerstatus
          };
          setIsThumb((prev) => ({ ...prev, fileName: result2[0].banner_fileurl }));
          setDetailData((prevState) => ({
            ...prevState,
            doc_id: result2[0].banner_id,
            file_name: result2[0].banner_filename,
            file_type: result2[0].banner_filetype,
            file_url: result2[0].banner_fileurl,
            file_link: result2[0].banner_linkurl,
            link_target: result2[0].banner_targeturl,
            sort_number: result2[0].banner_sortid,
          }));
        }
      } else {
        datauserstr = {
          doc_id: 0,
          file_name: "",
          file_type: "",
          file_url: "",
          file_link: "",
          link_target: "_blank",
          sort_number: "",
          status: bannerstatus
        };
      }
      setForm(datauserstr);     
      getListId(dataTarget,targetLink,"link_target");
    }
  }
  useEffect(() => {
    var docId = "",fileName="",pageType="detail";
    
    if(paramsLoc.state !== null) {
      docId = paramsLoc.state.data?.banner_id;
      fileName = paramsLoc.state.data?.banner_filename;
      pageType = paramsLoc.state.type;
    } 
    if(docId !== "") {
      document.getElementById("loading-screen").style.display = "flex";
      setDetailData({
        doc_id: docId,
        file_name: fileName
      });
      setType(pageType);
      loadData(docId,pageType);
      var inpdisabled = true;
      if(pageType === "edit" || pageType === "create") {   
          inpdisabled = false;
      }
      setFormDisabled(inpdisabled);
      document.getElementById("loading-screen").style.display = "none";
    
    } 

  }, []);
  return (
    <div className=" bg-gray-100 h-screen flex-1 p-8 overflow-y-auto relative">
      <div className="flex items-center gap-10 mb-8">
        <div className="flex items-center">
          <Button
            variant="icon"
            icon="left"
            className="mr-5"
            onClick={() => navigate(-1)}
          />
          <h2 className=" text-2xl">{`${
            type === "create" ? translate.banner_detail.label1 : type === "edit" ? translate.banner_detail.label2 : translate.banner_detail.label3
          }`}</h2>
        </div>
        <div className="flex items-center gap-x-4">
          <label
            className={`text-sm text-gray-400 ${
              !form.status && "text-red-500"
            }`}
          >
            {global.config.generalstatus[1]}
          </label>
          { formDisabled? 
            <ToggleSwitch id="file_status" checked={form.status} />
          : <ToggleSwitch id="file_status" checked={form.status} onClick={toggleHandler} />
          }
          <label
            className={`text-sm text-gray-400 ${
              form.status && "text-red-500"
            }`}
          >
            {global.config.generalstatus[0]}
          </label>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-1 justify-end gap-4 mb-8 px-10">
          {!formDisabled && (
            <Button
              type="submit"
              variant="base"
              title={type === "edit" ? translate.general.button_save : translate.banner_detail.button1}
              className="bg-red-500 text-white"
            />
          )}
        </div>
        <div className="px-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            {/*UPLOAD THUMBNAIL*/}
            <div>
              <label className="text-gray-500 mb-2 block">
                {translate.banner_detail.text_input1}
              </label>
              <div className="flex flex-col items-center bg-red-50 rounded-lg border border-red-300 justify-around w-full h-72">
                <div className="w-50 h-50 rounded-lg self-center flex items-center justify-center">
                  {!isThumb?.fileName ? (
                    <div className="flex flex-col justify-center items-center">
                      {loading.thumbnail ? (
                        <Loading />
                      ) : (
                        <>
                          <ThumbnailIcon className=" w-32 h-32" />
                          {type !== "detail" && (
                            <>
                              <p className="text-xs text-gray-500 text-center italic mb-8">
                                {translate.general.label_allow_img}<br/>
                                {translate.banner_detail.label4}
                                
                              </p>
                              <Button
                                type="button"
                                variant="base"
                                // title="Upload Image"
                                title={translate.banner_detail.text_input2}
                                className="bg-red-500 text-white mx-10"
                                onClick={() =>
                                  handleClickUploadImage("thumbnail")
                                }
                              />
                            </>
                          )}
                        </>
                      )}
                    </div>
                  ) : isThumb?.fileName ? (
                    <div>
                      {loading.thumbnail ? (
                        <Loading />
                      ) : (
                        <>
                          <Button
                            type="button"
                            variant="icon"
                            icon="close"
                            onClick={() => removeFile("thumbnail")}
                            className=" absolute z-[999999] ml-1 mt-1"
                            style={
                              type === "detail" ? { display: "none" } : null
                            }
                          />
                          <div className="flex flex-col justify-center items-center">
                            <img
                              src={preview?.previewThumb || isThumb.fileName}
                              alt="img product"
                              className=" h-72 rounded-lg -z-0 self-center"
                            />
                            {/* <p className="text-xs text-gray-400 line-clamp-1 text-center w-32">
                              {isThumb?.fileName}
                            </p> */}
                          </div>
                        </>
                      )}
                    </div>
                  ) : null}
                </div>
                <div><FormErrors formErrors={formErrors.file_url}/></div>
                <input
                  type="file"
                  ref={hiddenThumbInput}
                  onChange={handleUploadThumbChange}
                  style={{ display: "none" }}
                  accept="image/png, image/gif, image/jpeg, image/jpg"
                  hidden
                />
              </div>
            </div>
            <div>
            <TextInput
              variant="base"
              // title="Link Url"
              title={translate.banner_detail.text_input3}
              name="file_link"
              value={form.file_link}
              onChange={onChangeHandler}
              disabled={formDisabled}
              errmsg={formErrors.file_link}
            />
            <TextInput
              type="number"
              variant="base"
              // title="Sort Number"
              title={translate.banner_detail.text_input4}
              id="sort_number"
              name="sort_number"
              value={form.sort_number}
              onChange={onChangeHandler}
              disabled={formDisabled}
              errmsg={formErrors.sort_number}
            />
            </div>
            
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreatePromotional;
