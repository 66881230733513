import React, { useEffect, useState } from "react";
import { Button, Modal, TextInput, ToggleSwitch, CustomSelect } from "../../../../components";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../../features/translate/translateSlice";
import Logouts from "../../../../components/logout"; 

const keyAuth = global.config.typecode[1] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const CountryModal = ({ onBackgroundClick, onClick, countryData }) => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector((state) => state.translate.lang)
  const { type, data } = countryData;
  const [formDisabled, setFormDisabled] = useState(true);
  const [listRegion, setListRegion] = useState([]);
  const [formErrors, setFormErrors] = useState({
    country_name: "",
    region_name: "",
    currency: "",
    symbols: "",
    company_entity: "",
    company_address: "",
  });
  const [form, setForm] = useState({
    country_name: "",
    region_name: "",
    currency: "",
    symbols: "",
    status: true,
    company_entity: "",
    company_address: "",
  });
  const [isSelected, setSelected] = useState({
    region_name: ""
  });

  const validateField = (fieldName, value) => {
    let ferrors = "";
    switch (fieldName) {
      case "country_name":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.country_detail.error1; }
        break;
      case "region_name":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.country_detail.error2; }
        break;
      case "currency":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.country_detail.error3; }
        break;
      case "symbols":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.country_detail.error4; }
        break;
      case "company_entity":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.country_detail.error5; }
        break;
      case "company_address":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.country_detail.error6; }
        break;
      default:
        break;
    }
    setFormErrors((prevState) => ({ ...prevState, [fieldName]: ferrors }));
  };
  const onChangeSelect = (e, sel) => {
    var name = sel.name;
    var value = e.value; if(value === undefined) { value = e; }
    setSelected((prevState) => ({
      ...prevState,
      [name]: e,
    }));
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };
  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };
  const toggleHandler = () => {
    setForm((prevState) => ({
      ...prevState,
      status: form.status === 0 ? 1 : 0,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if(form.country_name !== "" && form.region_name !== "" && form.currency !== "" && form.symbols !== "" && form.company_entity !== "" && form.company_address !== "") {
      document.getElementById("loading-screen").style.display = "flex";
      var fetchUrl = global.config.urlLink;
      var dataJson = form;
      if (type === "add") {
        fetchUrl += "/addcountry";
        dataJson.from_id="add-country";
      } else {
        fetchUrl += "/editcountry";
        dataJson.from_id="edit-country";
      }
      dataJson.lang=chooseLang;
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(dataJson),
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then((res) => {
          if (res.status === "200") {
            document.getElementById("loading-screen").style.display = "none";
            window.location.href = "/admin/country";
          }
        })
        .catch((err) => { 
          console.log(err);
          document.getElementById("loading-screen").style.display = "none";
        });
    } else {
      var formField = ["country_name","region_name","currency","symbols","company_entity","company_address"];
      for ( var i = 0; i < formField.length; i++ ) { 
        var fieldname = formField[i];
        var fieldvalue = form[fieldname];
        validateField(fieldname, fieldvalue); 
      }
    }
  };
  const getRegionId = (list,rid,langz,typez) => {
    var el = "label";
    if(typez === "edit" && langz === "cn") { el = "value"; }
    let updRegion = list?.filter(
      (ter) => ter?.[el] === rid
    );
    setSelected((prevState) => ({
      ...prevState,
      region_name: updRegion[0],
    }));
  };
  const loadListRegion = () => {
    return new Promise((resolve) => {
      var arrRegion = [];
      // global.config.regions.forEach(item => {
      //   arrRegion.push({ value: item, label: item });
      // })
      var a=0;
      global.config.regions.forEach(item => {
          arrRegion.push({ value: item, label: translate.general.region_list[a] });
          a++;
      })
      resolve(arrRegion)
    })
  }
  const getCountry = (id) => {
    return new Promise((resolve) => {
      let datatext = '{';
      datatext += '"from_id":"detail-country"';
      datatext += ',"doc_id":'+id;
      datatext += '}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth;
      var fetchUrl = global.config.urlLink + "/detailcountry";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            resolve(resJson.values);
          } else if(resJson.status === global.config.errcodesess) {
            resolve([resJson.status]);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  
  const loadData = async (dataSend,langz,typez) => {
    if(typez === "edit" && langz === "cn") {
      let [result1,result2] = await Promise.all([loadListRegion(),getCountry(dataSend.country_id)]);
      setListRegion(result1);
      if(result2.length > 0 && result2[0] === global.config.errcodesess) { 
        var resultSO = await Logouts();
        if(resultSO.status === "200") {
          window.location.reload();
        }
      } else {
        getRegionId(result1,result2[0].region_name,langz,typez);
        setForm((prevState) => ({
          ...prevState,
          doc_id: dataSend.country_id,
          country_name: result2[0].country_name,
          region_name: result2[0].region_name,
          currency: result2[0].country_currency,
          symbols: result2[0].country_currency_symbol,
          status: result2[0].status,
          company_entity: result2[0].country_entity !== undefined && result2[0].country_entity !== null? result2[0].country_entity : "",
          company_address: result2[0].country_address !== undefined && result2[0].country_address !== null? result2[0].country_address : "",
        }));
      }
    } else {
      let [result1] = await Promise.all([loadListRegion()]); 
      setListRegion(result1);
      if(dataSend.region_name !== "" && dataSend.region_name !== undefined) { getRegionId(result1,dataSend.region_name,langz,typez); }
    }
  }
  useEffect(() => {
    // var regName = "";
    var inpdisabled = true;
    if(type === "edit" || type === "add") { inpdisabled = false; }
    setFormDisabled(inpdisabled);

    if(type === "edit" && chooseLang === "cn") {
      loadData(data,chooseLang,type);
    } else if(type !== "add") {
      setForm((prevState) => ({
        ...prevState,
        doc_id: data.country_id,
        country_name: data.country_name,
        region_name: data.region_name,
        currency: data.country_currency,
        symbols: data.country_currency_symbol,
        status: data.status,
        company_entity: data.country_entity !== undefined && data.country_entity !== null? data.country_entity : "",
        company_address: data.country_address !== undefined  && data.country_address !== null? data.country_address : "",
      }));
      loadData(data,chooseLang,type);
    } else {
      loadData(data,chooseLang,type);
    }

  }, []);

  return (
    <Modal
      title={
        type === "add"
          ? translate.country_detail.title1
          : type === "detail"
          ? translate.country_detail.title3
          : translate.country_detail.title2
      }
      onBackgroundClick={onBackgroundClick}
      onClick={onClick}
    >
      <div className=" flex flex-col overflow-y-auto justify-between">
        <div className=" gap-y-2 gap-x-8 mx-1 mb-8">
          <div>
            <TextInput
              variant="base"
              // title="Country Name"
              title={translate.country_detail.text_input1}
              name="country_name"
              value={form.country_name}
              onChange={onChangeHandler}
              disabled={formDisabled}
              errmsg={formErrors.country_name}
            />
            <CustomSelect
                // title="Region"
                title={translate.country_detail.text_input2}
                name="region_name"
                value={isSelected.region_name}
                options={listRegion}
                onChange={onChangeSelect}
                defaultValue={form.region_name}
                disabled={formDisabled}
                errmsg={formErrors.region_name}
              />
            <div className="grid grid-cols-1 sm:grid-cols-2 ">
            <TextInput
              variant="base"
              // title="Currency"
              title={translate.country_detail.text_input3}
              name="currency"
              value={form.currency}
              onChange={onChangeHandler}
              disabled={formDisabled}
              errmsg={formErrors.currency}
            />
            <TextInput
              variant="base"
              // title="Symbol Currency"
              title={translate.country_detail.text_input4}
              name="symbols"
              value={form.symbols}
              onChange={onChangeHandler}
              disabled={formDisabled}
              errmsg={formErrors.symbols}
            />
            </div>
            <TextInput
              variant="base"
              // title="Country Name"
              title={translate.country_detail.text_input5}
              name="company_entity"
              value={form.company_entity}
              onChange={onChangeHandler}
              disabled={formDisabled}
              errmsg={formErrors.company_entity}
            />
            <TextInput
              type="text"
              variant="textarea"
              // title="Address"
              title={translate.country_detail.text_input6}
              name="company_address"
              id="company_address"
              value={form.company_address}
              onChange={onChangeHandler}
              disabled={formDisabled}
              height="100px" 
              errmsg={formErrors.company_address}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2">
          <div className="flex items-center gap-x-4">
            <label
              className={`text-sm text-gray-400 ${
                !form.status && "text-red-500"
              }`}
            >
              {global.config.generalstatus[1]}
            </label>
            <ToggleSwitch
              id="status"
              checked={form.status}
              onClick={formDisabled ? null : toggleHandler}
            />
            <label
              className={`text-sm text-gray-400 ${form.status && "text-red-500"}`}
            >
               {global.config.generalstatus[0]}
            </label>
          </div>
          <div className="flex justify-end">
          {!formDisabled && (
            <Button
              type="button"
              variant="base"
              className="bg-red-500 text-white"
              title={type === "add" ? translate.country_detail.button1 : translate.general.button_save}
              onClick={handleSubmit}
            />
          )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CountryModal;
