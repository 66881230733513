import React from "react";

const LoadingScreen = ({ id = "", className = "", style = {} }) => {
  return (
    <div
      id={id}
      className={`absolute bg-white bg-opacity-60 z-[999999] h-full w-full flex items-center justify-center ${className}`}
      style={style}
    >
      <div className="flex items-center">
        <svg
          className="animate-spin h-20 w-20 text-gray-300"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="white"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="#ED1C24"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default LoadingScreen;
