import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, CustomSelect, TextInput, FormErrors } from "../../../components";
import { FaExternalLinkAlt } from "react-icons/fa";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../features/translate/translateSlice";
import Logouts from "../../../components/logout"; 

const keyAuth = global.config.typecode[7] + global.config.authBearerKey;
const keyAuth2 = global.config.typecode[3] + global.config.authBearerKey;
const keyAuth3 = global.config.typecode[10] + global.config.authBearerKey;
const keyAuth4 = global.config.typecode[2] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

// var dropDownStatus = [];
// for(var d=0; d<global.config.pipelinestatus.length; d++) {
//   dropDownStatus.push({value: global.config.pipelinestatus[d], label: translate.pipeline.pipeline_status[d]})
// }

const PipelineDetail = () => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector((state) => state.translate.lang)
  const navigate = useNavigate();
  const paramsLoc = useLocation();
  const [type, setType] = useState("");
  const [detaildata, setDetailData] = useState({});
  const [optDistributor, setOptDistributor] = useState([]);
  const [listDistributor, setListDistributor] = useState([]);
  const [listMarket, setListMarket] = useState([]);
  const [listAllMarket, setListAllMarket] = useState([]);
  const [listProduct, setListProduct] = useState([]);
  const [listAllProduct, setListAllProduct] = useState([]);
  const [listProjectStatus, setListProjectStatus] = useState([]);
  const [formDisabled, setFormDisabled] = useState(true);
  const [dateInput, setDateInput] = useState(new Date());
  const [date2Input, setDate2Input] = useState(new Date());
  const [selected, setSelected] = useState({
    distributor_id: "",
    pipeline_market: "",
    project_status: "",
    pipeline_product: ""
  });
  const [formErrors, setFormErrors] = useState({
    project_name: "",
    project_country: "", 
    pipeline_market: "",
    pipeline_product: "",
    project_status: "",
    distributor_id: "",
    project_expect_closedate: ""
  });
  const [form, setForm] = useState({
    doc_id: 0,
    project_name: "",
    project_country: "",
    pipeline_market: "",
    pipeline_client: "",
    pipeline_product: "",
    pipeline_model: "",
    pipeline_application: "",
    project_qty: 0, //Number
    project_currency: "",
    project_sell_price: 0, //Number
    project_prob: 0, //Number
    project_volume: 0, //Number
    project_expect_podate: "",
    project_expect_closedate: "",
    // project_po_date: new Date(),
    project_po_date: "",
    project_status: "",
    project_status_bf: "",
    project_remarks: "",
    project_skywater_link: "",
    project_comment: "",
    distributor_id: "",
    distributor_name:"",
    status: true,
  });
  const handleBack = () => {
    navigate("/pipeline", {
      state: { dataref: paramsLoc.state.filter },
    })
  }
  const handleUrlClick = (url) => {
    window.open(url,"_blank");
  }
  const validateField = (fieldName, value) => {
    let ferrors = "";
    switch (fieldName) {
      case "project_name":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.pipeline_detail.error1; }
        break;
      case "project_country":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.pipeline_detail.error2; }
        break;
      case "pipeline_market":
        if (value > 0) { ferrors = ""; } 
        else { ferrors = translate.pipeline_detail.error3; }
        break;
      case "pipeline_product":
        if (value > 0) { ferrors = ""; } 
        else { ferrors = translate.pipeline_detail.error4; }
        break;
      case "project_status":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.pipeline_detail.error5; }
        break;
      case "distributor_id":
        if (value > 0) { ferrors = ""; } 
        else { ferrors = translate.pipeline_detail.error6; }
        break;
      case "project_expect_closedate":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.pipeline_detail.error7; }
        break;
      default:
        break;
    }
    setFormErrors((prevState) => ({ ...prevState, [fieldName]: ferrors }));
  };
  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if(name === "project_qty" || name === "project_prob") {
      var projQty = 0, projProb = 0;
      if(form.project_qty !== undefined && form.project_qty !== "") { projQty = form.project_qty; } 
      if(form.project_prob !== undefined && form.project_prob !== "") { projProb = form.project_prob; } 
      if(name === "project_qty") { projQty = value; }
      else if(name === "project_prob") { projProb = value; }
      var projWeight = projQty * (projProb/100);
      setForm((prevState) => ({
        ...prevState,
        [name]: value,
        project_volume: projWeight
      }));
    } else {
      setForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    validateField(name, value);
  };
  const onChangeSelectHandler= (e,name,list) => {
    var value = e.value;
    setSelected((prevState) => ({
      ...prevState,
      [name]: e,
    }));
    if(name=== "distributor_id") { 
      let getDistributor = list?.filter(
        (dist) => dist?.distributor_id === value
      );
      var projectCountry = getDistributor[0].country_name;
      var projectCurrency = getDistributor[0].country_currency;
      // if(form.project_country !== "") { projectCountry = form.project_country; } else { projectCountry =  getDistributor[0].country_name; }
      // if(form.project_currency !== "") { projectCurrency = form.project_currency; } else { projectCurrency =  getDistributor[0].country_currency; }
      // setListMarket(JSON.parse(getDistributor[0].market_application_focus));
      // setListProduct(JSON.parse(getDistributor[0].product_application));
      setForm((prevState) => ({
        ...prevState,
        [name]: value,
        distributor_name: e.label,
        project_country: projectCountry,
        project_currency: projectCurrency
      }));
      if(projectCountry !== "" && projectCountry !== null) { setFormErrors((prevState) => ({ ...prevState, project_country: "" })); }

    } else {
      setForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    validateField(name, value);
  };
  const convertDate = (dt) => {
    const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
    return `${dt.getFullYear()}-${padL(dt.getMonth() + 1)}-${padL(
      dt.getDate()
    )} ${padL(dt.getHours())}:${padL(dt.getMinutes())}:${padL(
      dt.getSeconds()
    )}`;
  };
  const onChangeDateHandler = (val,name) => {
    if(name=== "project_po_date") { setDateInput(val); }
    else if(name=== "project_expect_closedate") { 
      setDate2Input(val); 
      if(val !== "" && val !== null) { setFormErrors((prevState) => ({ ...prevState, [name]: "" })); }
    }
    if(val !== null && val !== "") { 
      var sendDate = convertDate(val);
      setForm((prevState) => ({
        ...prevState,
        [name]: sendDate,
      }));  
    } else {
      setForm((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if(form.project_name !== "" && form.project_country !== "" && form.pipeline_market !== "" && form.project_status !== "" && form.distributor_id !== "" && form.pipeline_product !== "") {
      document.getElementById("loading-screen").style.display = "flex";
      var dataJson = form;
      var fetchUrl = global.config.urlLink;
      if (type === "create") {
        fetchUrl += "/addpipe";
        dataJson.from_id="add-pipeline";
      } else {
        fetchUrl += "/editpipe";
        dataJson.from_id="edit-pipeline";
      }
      dataJson.lang = chooseLang;
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(dataJson),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((res) => {

          document.getElementById("loading-screen").style.display = "none";
          if (res.status === "200") {
            // navigate("/pipeline");
            navigate("/pipeline", {
              state: { dataref: paramsLoc.state.filter },
            })
          }
      }).catch((err) => { 
        document.getElementById("loading-screen").style.display = "none";
      });
    } else {
      var formField = [];
      if(form.project_name === "") { formField.push("project_name"); }
      if(form.project_country === "") { formField.push("project_country"); }
      if(form.pipeline_market === "") { formField.push("pipeline_market"); }
      if(form.pipeline_product === "") { formField.push("pipeline_product"); }
      if(form.project_status === "") { formField.push("project_status"); }
      if(form.distributor_id === "") { formField.push("distributor_id"); }
      if(form.project_expect_closedate === "") { formField.push("project_expect_closedate"); }
      for ( var i = 0; i < formField.length; i++ ) { 
        var fieldname = formField[i];
        var fieldvalue = form[fieldname];
        validateField(fieldname, fieldvalue); 
      }
    }
    
  };
  const getListId = (list,lid,name) => {
    let updListId = list?.filter(
      (listed) => listed?.value === lid
    );
    setSelected((prevState) => ({
      ...prevState,
      [name]: updListId[0],
    }));
    setForm((prevState) => ({
      ...prevState,
      [name]: lid,
    }));
  };
  const loadListProjectStatus = () => {
    return new Promise((resolve) => {
      var s=0;
      var arrProjectStat = [];
      global.config.pipelinestatus.forEach(item => {
        // arrMaterialCath.push({ value: item, label: item });
        arrProjectStat.push({ value: item, label: translate.pipeline.pipeline_status[s] });
        s++;
      })
      resolve(arrProjectStat);
    })
  }
  const loadDetailDistributor = (distid,pgtype) => {
    return new Promise((resolve) => {
      let datatext = '{';
      datatext += '"from_id":"detail-distributor"';
      datatext += ',"doc_id":'+distid;
      if(pgtype === "detail") { datatext +=',"lang":"'+chooseLang+'"'; }
      datatext += '}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth2;
      var fetchUrl = global.config.urlLink + "/detaildist";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            resolve(resJson.values);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadListDistributor = (country,pgtype) => {
    return new Promise((resolve) => {
      var datatext = '{';
      datatext += '"from_id":"list-distributor","stat_id":1';
      if(pgtype === "detail") { datatext += ',"lang":"'+chooseLang+'"'; }
      if(country !== "") { datatext += ',"country_id":"'+country+'"'; }
      datatext += '}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth2;
      var fetchUrl = global.config.urlLink + "/listdist";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            var updDistributor = { };
            if(resJson.values.length > 0) {
              setListDistributor(resJson.values);
              updDistributor = resJson.values.map((dist) => ({
                value: dist.distributor_id,
                label: dist.distributor_name,
              }));
            } 
            resolve([updDistributor,resJson.values]);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadListMarket = (pgtype) => {
    return new Promise((resolve) => {
      var datatext = '{';
      datatext += '"from_id":"list-market","stat_id":1';
      if(pgtype === "detail") { datatext += ',"lang":"'+chooseLang+'"'; } 
      datatext += '}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth3;
      var fetchUrl = global.config.urlLink + "/listmarket";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            var updMarket = { };
            var updMarketOthers = { };
            if(resJson.values.length > 0) {
              updMarket = resJson.values.map((mark) => ({
                value: mark.market_id,
                label: mark.market_name,
              }));
              var filterMarketOthers = resJson.values.filter(
                (mr) => mr.market_name === "Others"
              );
              if(filterMarketOthers.length > 0) {
                updMarketOthers = {
                  value: filterMarketOthers[0].market_id,
                  label: filterMarketOthers[0].market_name,
                }
              }
            } 
            resolve([updMarket,updMarketOthers]);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadListProduct = (pgtype) => {
    return new Promise((resolve) => {
      var datatext = '{';
      datatext += '"from_id":"list-all-product","stat_id":1';
      if(pgtype === "detail") { datatext += ',"lang":"'+chooseLang+'"'; }
      datatext += '}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth4;
      var fetchUrl = global.config.urlLink + "/listproduct";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            var updProduct = { };
            var updProductOthers = { };
            if(resJson.values.length > 0) {
              updProduct = resJson.values.map((prod) => ({
                value: prod.product_id,
                label: prod.product_name,
              }));
              var filterProductOthers = resJson.values.filter(
                (pr) => pr.product_name === "Others"
              );
              if(filterProductOthers.length > 0) {
                updProductOthers = {
                  value: filterProductOthers[0].product_id,
                  label: filterProductOthers[0].product_name,
                }
              }
            } 
            resolve([updProduct,updProductOthers]);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadDataPipeline = (id,pgtype) => {
    return new Promise((resolve) => {
      let datatext = '{';
      datatext += '"from_id":"detail-pipeline"';
      datatext += ',"doc_id":'+id;
      if(pgtype === "detail") {  datatext += ',"lang":"'+chooseLang+'"'; }
      datatext += '}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth;
      var fetchUrl = global.config.urlLink + "/detailpipe";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            resolve(resJson.values);
          } else if(resJson.status === global.config.errcodesess) {
            resolve([resJson.status]);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadData = async (id,countryid,distributorid,pgtype) => {
    document.getElementById("loading-screen").style.display = "flex";
    var pipelinestatus = true;
    var datauserstr = {};
    var marketid = "",productid="",projectstatus="", projectpodate="", projectclosedate="";
    if(id !== undefined) {
      let [result1, result2, result3, result4, result5] = await Promise.all([loadListDistributor(countryid,pgtype), loadDataPipeline(id,pgtype),loadListMarket(pgtype),loadListProduct(pgtype),loadListProjectStatus(pgtype)]);
      if(result2.length > 0) { 
        if(result2[0] === global.config.errcodesess) { 
          var resultSO = await Logouts();
          if(resultSO.status === "200") {
            window.location.reload();
          }
        } else {
          if(result2[0].status === 1) { pipelinestatus =true; } else { pipelinestatus =false; } 
          
          distributorid = result2[0].id_distributor;
          marketid = result2[0].project_market_id;
          productid= result2[0].project_product_id;
          projectstatus = result2[0].project_status;
          if(result2[0].project_po_date !== null && result2[0].project_po_date !== "") { projectpodate = result2[0].project_po_date; }
          if(result2[0].project_expected_close_date !== null && result2[0].project_expected_close_date !== "") { projectclosedate = result2[0].project_expected_close_date; }
          datauserstr = {
            doc_id: result2[0].pipeline_id,
            project_name: result2[0].project_name,
            project_country: result2[0].project_country,
            pipeline_market: marketid,
            pipeline_client: result2[0].project_client,
            pipeline_product: productid,
            pipeline_model: result2[0].project_model,
            pipeline_application: result2[0].project_application,
            project_qty: result2[0].project_qty,
            project_currency: result2[0].project_currency,
            project_sell_price: result2[0].project_selling_price,
            project_prob: result2[0].project_probability,
            project_volume: result2[0].project_weight_volume,
            project_expect_podate: result2[0].project_expected_po_date,
            project_expect_closedate: projectclosedate,
            project_po_date: projectpodate,
            project_status: projectstatus,
            project_status_bf: projectstatus,
            project_remarks: result2[0].project_status_remarks,
            project_skywater_link: result2[0].project_skywater_link,
            project_comment: result2[0].project_comment,
            distributor_id: distributorid,
            distributor_name: result2[0].distributor_name,
            status: pipelinestatus,
          };
          if(projectpodate !== "") { setDateInput(new Date(projectpodate)); } else { setDateInput(""); }
          if(projectclosedate !== "") { setDate2Input(new Date(projectclosedate)); } else { setDate2Input(""); }
          setForm(datauserstr);      
          setDetailData((prevState) => ({
            ...prevState,
            doc_id: result2[0].pipeline_id,
            project_name: result2[0].project_name,
          }));
        }
      } 
      
      var listOptMarket=[], listOptProduct=[];
      if(localStorage.getItem("dulv")===global.config.conflevel[3]) {
        if(result1[1].length > 0) {
          var sel_distributor = result1[1].filter(
            (dist) => dist.distributor_id === distributorid
          );
          listOptMarket = JSON.parse(sel_distributor[0].market_application_focus);
          listOptProduct = JSON.parse(sel_distributor[0].product_application);

          listOptMarket.push(result3[1]);
          listOptProduct.push(result4[1]);
        } else {
          listOptMarket = result3[0];
          listOptProduct = result4[0];
        }
      } else {
        listOptMarket = result3[0];
        listOptProduct = result4[0];
      }
      
      setListAllMarket(result3[0]);
      setListAllProduct(result4[0]);
      setListProjectStatus(result5);
      setOptDistributor(result1[0]);
      setListMarket(listOptMarket);
      setListProduct(listOptProduct);
      
      if(distributorid !== "") { getListId(result1[0],distributorid,"distributor_id"); }
      if(marketid !== "") { getListId(listOptMarket,marketid,"pipeline_market"); }
      if(productid !== "") { getListId(listOptProduct,productid,"pipeline_product"); }
      if(projectstatus !== "") { getListId(result5,projectstatus,"project_status"); }
      document.getElementById("loading-screen").style.display = "none";
    } else {
      if(distributorid !== "") {
        let [result1a,result3a,result4a,result5a] = await Promise.all([loadDetailDistributor(distributorid,pgtype),loadListMarket(pgtype),loadListProduct(pgtype),loadListProjectStatus(pgtype)]);
        setListDistributor(result1a);
        setListAllMarket(result3a[0]);
        setListAllProduct(result4a[0]);
        setListProjectStatus(result5a);
        
        if(result1a.length > 0) {
          var lsDistributor = result1a.map((dist) => ({
            value: dist.distributor_id,
            label: dist.distributor_name,
          }));
          setOptDistributor(lsDistributor);
          
          var lsOptMarket=[], lsOptProduct=[];
          if(localStorage.getItem("dulv")===global.config.conflevel[3]) {
            lsOptMarket = JSON.parse(result1a[0].market_application_focus);
            lsOptProduct = JSON.parse(result1a[0].product_application);

            lsOptMarket.push(result3a[1]);
            lsOptProduct.push(result4a[1]);
          } else {
            lsOptMarket = result3a[0];
            lsOptProduct = result4a[0];
          }
          setListMarket(lsOptMarket);
          setListProduct(lsOptProduct);
          
          datauserstr = {
            doc_id: "",
            project_name: "",
            project_country: result1a[0].country_name,
            pipeline_market: marketid,
            pipeline_client: "",
            pipeline_product: productid,
            pipeline_model: "",
            pipeline_application: "",
            project_qty: 0,
            project_currency: result1a[0].country_currency,
            project_sell_price: 0,
            project_prob: 0,
            project_volume: 0,
            project_expect_podate: "",
            project_expect_closedate: "",
            // project_po_date: new Date(),
            project_po_date: "",
            project_status: projectstatus,
            project_status_bf: projectstatus,
            project_remarks: "",
            project_skywater_link: "",
            project_comment: "",
            distributor_id: distributorid,
            distributor_name: result1a[0].distributor_name,
            status: pipelinestatus,
          };
          setForm(datauserstr);  
          setDateInput("");
          setDate2Input("");
        } 
        
      } else {
        let [result1,result3,result4,result5] = await Promise.all([loadListDistributor(countryid,pgtype),loadListMarket(pgtype),loadListProduct(pgtype),loadListProjectStatus(pgtype)]);
        setOptDistributor(result1[0]);
        // getListId(result1,distributorid,"distributor_id");
        setListMarket(result3[0]);
        setListAllMarket(result3[0]);
        // getListId(result3,marketid,"pipeline_market");
        setListProduct(result4[0]);
        setListAllProduct(result4[0]);
        // getListId(result4,productid,"pipeline_product");
        // getListId(dropDownStatus,projectstatus,"project_status");
        setListProjectStatus(result5);
        setDateInput("");
        setDate2Input("");
      }
      document.getElementById("loading-screen").style.display = "none";
    }
  }
  
  useEffect(() => {
    var docId = "",projectName="",pageType="detail",idDist="",idCntry="";
    if(localStorage.getItem("dulv")===global.config.conflevel[3]) { idDist = localStorage.getItem("dudst"); } 
    else if(localStorage.getItem("dulv")===global.config.conflevel[2]) { idCntry = localStorage.getItem("ducr"); }

    if(paramsLoc.state !== null) {
      docId = paramsLoc.state.data.pipeline_id;
      projectName = paramsLoc.state.data.project_name;
      pageType = paramsLoc.state.type;
    } 
    
    if(docId !== "") {
      setDetailData({
        doc_id: docId,
        project_name: projectName
      });
      setType(pageType);
      var inpdisabled = true;
      if(pageType === "edit" || pageType === "create") {   
          inpdisabled = false;
      }
      setFormDisabled(inpdisabled);
      loadData(docId,idCntry,idDist,pageType);
      
    } else {
      window.location.href = "/pipeline";
    }  
  }, []);
  return (
    <div className=" bg-gray-100 h-screen flex-1 overflow-y-auto p-8">
      <div className="flex justify-start items-center mb-8 ">
        <Button
          variant="icon"
          icon="left"
          className="mr-5"
          onClick={() => handleBack()}
          // onClick={() => navigate(-1)}
          // onClick={() => document.history.go(-1)}
        />
        <h2 className="text-2xl">{`${
          type === "detail"
            ? detaildata.project_name
            : type === "edit"
            ? detaildata.project_name
            : translate.pipeline_detail.title
        }`}</h2>
      </div>
      <form>
        <div className="flex flex-1 justify-end gap-4 mb-8 px-10">
          {formDisabled === false && (
            <Button
              type="submit"
              variant="base"
              title={type === "edit" ? translate.general.button_save : translate.pipeline_detail.button1}
              className="bg-red-500 text-white"
              onClick={handleSubmit}
            />
          )}
        </div>
        {localStorage.getItem("dulv")!==global.config.conflevel[3] && 
        (<div>
            <CustomSelect
                // title="Distributor"
                title={translate.pipeline_detail.text_input1}
                name="distributor_id"
                value={selected.distributor_id}
                options={optDistributor}
                onChange={(e) => onChangeSelectHandler(e,"distributor_id",listDistributor)}
                defaultValue={form.distributor_id}
                disabled={formDisabled}
                errmsg={formErrors.distributor_id}
              />
        
        </div>)
        }
        <div className="grid grid-cols-1 gap-5 md:gap-20 md:grid-cols-2 content-start">
          <div className="flex flex-col content-start">
            <TextInput
              variant="base"
              // title="Project Name"
              title={translate.pipeline_detail.text_input2}
              id="project_name"
              name="project_name"
              value={form.project_name}
              onChange={onChangeHandler}
              disabled={formDisabled}
              errmsg={formErrors.project_name}
            />
            <TextInput
              variant="base"
              // title="Project Country"
              title={translate.pipeline_detail.text_input3}
              id="project_country"
              name="project_country"
              value={form.project_country}
              onChange={onChangeHandler}
              disabled={formDisabled}
              errmsg={formErrors.project_country}
            />
            <CustomSelect
              // title="Market"
              title={translate.pipeline_detail.text_input4}
              name="pipeline_market"
              value={selected.pipeline_market}
              options={listMarket}
              onChange={(e) => onChangeSelectHandler(e,"pipeline_market",listMarket)}
              disabled={formDisabled}
              errmsg={formErrors.pipeline_market}
            />
            <TextInput
              variant="base"
              // title="Client"
              title={translate.pipeline_detail.text_input5}
              id="pipeline_client"
              name="pipeline_client"
              value={form.pipeline_client}
              onChange={onChangeHandler}
              disabled={formDisabled}
            />
            <CustomSelect
              // title="Product"
              title={translate.pipeline_detail.text_input6}
              name="pipeline_product"
              value={selected.pipeline_product}
              options={listProduct}
              onChange={(e) => onChangeSelectHandler(e,"pipeline_product",listProduct)}
              disabled={formDisabled}
              errmsg={formErrors.pipeline_product}
            />
            <TextInput
              variant="base"
              // title="Model"
              title={translate.pipeline_detail.text_input7}
              id="pipeline_model"
              name="pipeline_model"
              value={form.pipeline_model}
              onChange={onChangeHandler}
              disabled={formDisabled}
            />
              <TextInput
                type="number"
                variant="base"
                // title="Quantity"
                title={translate.pipeline_detail.text_input8}
                id="project_qty"
                name="project_qty"
                value={form.project_qty}
                onChange={onChangeHandler}
                disabled={formDisabled}
              />
            <div className="grid grid-cols-3 gap-x-2">
              
              <TextInput
                id="project_currency"
                name="project_currency"
                value={form.project_currency}
                onChange={onChangeHandler}
                variant="base"
                // title="Currency"
                title={translate.pipeline_detail.text_input19}
                disabled={formDisabled}
              />
              <div className="col-start-2 col-end-4">
              <TextInput
                type="number"
                variant="base"
                // title="Selling Price"
                title={translate.pipeline_detail.text_input9}
                id="project_sell_price"
                name="project_sell_price"
                value={form.project_sell_price}
                onChange={onChangeHandler}
                disabled={formDisabled}
              />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-x-2">
              <TextInput
                type="number"
                variant="base"
                // title="Probability"
                title={translate.pipeline_detail.text_input10}
                id="project_prob"
                name="project_prob"
                value={form.project_prob}
                onChange={onChangeHandler}
                disabled={formDisabled}
              />
              <div className="col-start-2 col-end-4">
                <TextInput
                  type="number"
                  variant="base"
                  // title="Weighed Volume"
                  title={translate.pipeline_detail.text_input11}
                  id="project_volume"
                  name="project_volume"
                  value={form.project_volume}
                  onChange={onChangeHandler}
                  disabled={formDisabled}
                />
              </div>
            </div>
          </div>
          <div className="mb-4">
            {/* <TextInput
              variant="base"
              // title="Expect PO Date"
              title={translate.pipeline_detail.text_input12}
              id="project_expect_podate"
              name="project_expect_podate"
              value={form.project_expect_podate}
              onChange={onChangeHandler}
              disabled={formDisabled}
            /> */}
            <div className="mb-4">
              <label className="block text-gray-500 mb-2">{translate.pipeline_detail.text_input20}</label>
              <DatePicker
                wrapperClassName="rounded-md"
                selected={"" || date2Input}
                onChange={(e) => onChangeDateHandler(e,"project_expect_closedate")}
                disabled={formDisabled}
                className={`${
                  type === "detail"
                    ? "bg-gray-50 border w-3/4 p-2 rounded-md text-gray-500"
                    : "w-3/4 p-2 rounded-md border text-gray-500"
                }`}
              />
              <div className="w-52"><FormErrors formErrors={formErrors.project_expect_closedate}/></div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-500 mb-2">{translate.pipeline_detail.text_input13}</label>
              <DatePicker
                wrapperClassName="rounded-md"
                selected={"" || dateInput}
                // onChange={onChangeDateHandler}
                onChange={(e) => onChangeDateHandler(e,"project_po_date")}
                disabled={formDisabled}
                className={`${
                  type === "detail"
                    ? "bg-gray-50 border w-3/4 p-2 rounded-md text-gray-500"
                    : "w-3/4 p-2 rounded-md border text-gray-500"
                }`}
              />
            </div>
            <TextInput
              variant="base"
              // title="Application"
              title={translate.pipeline_detail.text_input14}
              id="pipeline_application"
              name="pipeline_application"
              value={form.pipeline_application}
              onChange={onChangeHandler}
              disabled={formDisabled}
            />
            <CustomSelect
              // title="Status"
              title={translate.pipeline_detail.text_input15}
              name="project_status"
              value={selected.project_status}
              options={listProjectStatus}
              onChange={(e) => onChangeSelectHandler(e,"project_status",listProjectStatus)}
              disabled={formDisabled}
              errmsg={formErrors.project_status}
            />
            <TextInput
              variant="textarea"
              // title="Remarks"
              title={translate.pipeline_detail.text_input16}
              id="project_remarks"
              name="project_remarks"
              value={form.project_remarks}
              onChange={onChangeHandler}
              disabled={formDisabled}
              height="130px"
            />
            <div>
            <TextInput
              variant="base"
              // title="URL Skywater"
              title={translate.pipeline_detail.text_input17}
              id="project_skywater_link"
              name="project_skywater_link"
              value={form.project_skywater_link}
              onChange={onChangeHandler}
              disabled={formDisabled}
              addon={(form.project_skywater_link !== "") && <FaExternalLinkAlt onClick={() => handleUrlClick(form.project_skywater_link)} className="hover:fill-red-500 cursor-pointer fasize"/>}
            />
            </div>
            <TextInput
              variant="textarea"
              // title="Comment"
              title={translate.pipeline_detail.text_input18}
              id="project_comment"
              name="project_comment"
              value={form.project_comment}
              onChange={onChangeHandler}
              disabled={formDisabled}
              height="130px"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default PipelineDetail;
