import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { LoadingScreen, ComplianceScreen } from "./components";
import { store } from "./redux/store";
import { Provider, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
// import { staticLoading } from "./features/translate/translateSlice";
import { isLoading } from "./features/utils/utilSlice";

const root = ReactDOM.createRoot(document.getElementById("root"));
let persistor = persistStore(store);
const MainApp = () => {
  const loading = useSelector(isLoading);
  return (
    <>
      {loading && <LoadingScreen />}
      <App />
    </>
  );
};
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor} />
      <LoadingScreen id="loading-screen" style={{ display: "none" }} />
      <ComplianceScreen id="compliance-screen" style={{ display: "none" }} />
      <MainApp />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
