import React, { useEffect, useState, useRef } from "react";
import { Button, TextInput, LoadingScreen } from "../../components";

import { ReactComponent as Loading } from "../../assets/svg/loading.svg";
import { useDispatch, useSelector } from "react-redux";
// import { staticTranslate } from "../../features/translate/translateSlice";
import { enLang, cnLang, staticTranslate, translatingToEn, clearTranslateCN } from "../../features/translate/translateSlice";
import { clearFilters } from "../../features/filters/filterSlice";
import Logouts from "../../components/logout"; 

const keyAuth = global.config.typecode[0] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const Profile = () => {
  const translate = useSelector(staticTranslate);
  const chooseLang = useSelector((state) => state.translate.lang);
  const dispatch = useDispatch();
  const hiddenImageInput = useRef(null);
  const [type, setType] = useState("detail");
  const [loading, setLoading] = useState(false);
  const [loadingScr, setLoadingScr] = useState(false);
  const [profPic, setProfPic] = useState({});
  const [preview, setPreview] = useState({
    image: "",
  });
  const [formErrors, setFormErrors] = useState({
    profile_fullname: ""
  });
  const [form, setForm] = useState({
    doc_id: localStorage.getItem("dutk"),
    profile_fullname: "",
    profile_phone: "",
    profile_position: "",
    profile_photo: ""
  });
  const [formErrors2, setFormErrors2] = useState({
    current_password: "",
    new_password: "",
    confirm_password: ""
  });
  const [form2, setForm2] = useState({
    doc_id: localStorage.getItem("dutk"),
    current_password: "",
    new_password: "",
    confirm_password: ""
  });
  const [detaildata, setDetailData] = useState({
    doc_id: localStorage.getItem("dutk"),
    profile_fullname: '',
    // profile_email: '',
    profile_phone: '',
    profile_position: '',
    profile_distributor: '',
    profile_country: '',
    profile_photo: ''
  });
  
  const HandleLogout = () => {
    // const auth = callAuth();
    document.getElementById("loading-screen").style.display = "flex";
    localStorage.removeItem("logged_in");
    localStorage.removeItem("duid");
    localStorage.removeItem("dutk");
    localStorage.removeItem("dulv");
    localStorage.removeItem("dufname");
    localStorage.removeItem("duphoto");
    localStorage.removeItem("ducr");
    localStorage.removeItem("dutr");
    localStorage.removeItem("dulvname");
    localStorage.removeItem("dudst");
    localStorage.removeItem("dstatc");

    dispatch(translatingToEn());
    dispatch(enLang());
    dispatch(clearTranslateCN());
    dispatch(clearFilters())

    document.getElementById("loading-screen").style.display = "none";
    window.location.href = "/";
  };

  const handleUploadImgChange = async (e) => {
    setLoading(true);
    var imageFile = e.target.files[0];
    var fileName = e.target.files[0].name;
    setProfPic(imageFile);
    if (!e) return;
    let fetchUrl = global.config.urlLink;
    headersParams.Authorization = keyAuth;
    var dataText = {
      fileName: fileName,
    };
    await fetch(fetchUrl + "/getsignedurl", {
      method: "POST",
      headers: headersParams,
      body: JSON.stringify(dataText),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
      })
      .then(async (res) => {
        if (res.status === "200") {
          const urlUploadFile = res.values;
          await fetch(urlUploadFile, {
            method: "PUT",
            headers: {
              "content-type": "application/octet-stream",
            },
            body: imageFile,
          }).then(async (res) => {
            let urlImgPicture = res.url.split("?")[0];
            setForm((prevState) => ({
              ...prevState,
              profile_photo: urlImgPicture,
            }));
            await fetch(fetchUrl + "/makefilepublic", {
              method: "POST",
              headers: headersParams,
              body: JSON.stringify(dataText),
            }).then((res) => {
              if (res.status === 200) {
                setPreview((prevState) => ({
                  ...prevState,
                  image: window.URL.createObjectURL(imageFile),
                }));
                setLoading(false);
              }
            });
          });
        }
      });
  };

  const handleClickUpload = () => {
    hiddenImageInput.current.click();
  };

  const removeImg = (type) => {
    hiddenImageInput.current.value = null;
    setPreview((prevState) => ({ ...prevState, image: null }));
    setForm((prevState) => ({ ...prevState, profile_photo: "" }));
    setProfPic({});
  };

  const validateField = (fieldName, value) => {
    let ferrors = "";
    switch (fieldName) {
      case "profile_fullname":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = "full name need to be filled"; }
        break;
      default:
        break;
    }
    setFormErrors((prevState) => ({ ...prevState, [fieldName]: ferrors }));
  };
  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };
  const updateProfile = (e) => {
    e.preventDefault();
    console.log(form);
    console.log(e);
    if(form.profile_fullname !== "") {
      document.getElementById("loading-screen").style.display = "flex";
      let fetchUrl = global.config.urlLink + "/editusers";
      headersParams.Authorization = keyAuth;
      var dataJson = form;
      dataJson.from_id = "edit-profile";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(dataJson),
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then((res) => {
          localStorage.setItem("dufname", form.profile_fullname);
          localStorage.setItem("duphoto", form.profile_photo);
          document.getElementById("loading-screen").style.display = "none";
          window.location.href = "/profile";
        });
    } else {
      var formField = [];
      if(form.profile_fullname === "") { formField.push("profile_fullname"); }
      for ( var i = 0; i < formField.length; i++ ) { 
        var fieldname = formField[i];
        var fieldvalue = form[fieldname];
        validateField(fieldname, fieldvalue); 
      }
    }
  };

  const validateField2 = (fieldName2, value2, errmsg) => {
    let ferrors2 = "";
    switch (fieldName2) {
      case "current_password":
        if (value2.trim().length > 0) { ferrors2 = ""; } 
        else { 
          if(errmsg !== "") { ferrors2 = errmsg; }
          else { ferrors2 = translate.profile.error1; }
        }
        break;
      case "new_password":
        if (value2.trim().length > 0) { 
          if(errmsg !== "") { ferrors2 = errmsg; } 
          else { ferrors2 = "";  } 
        } else { 
          if(errmsg !== "") { ferrors2 = errmsg;  }
          else { ferrors2 = translate.profile.error2; }
        }
        break;
      case "confirm_password":
        if (value2.trim().length > 0) { 
          if(errmsg !== "") { ferrors2 = errmsg; }
          else { ferrors2 = ""; } 
        } else { 
          if(errmsg !== "") { ferrors2 = errmsg; }
          else { ferrors2 = translate.profile.error3; }
        }
        break;
      default:
        break;
    }
    // if(errmsg !== "") { ferrors2 = errmsg; }
    setFormErrors2((prevState) => ({ ...prevState, [fieldName2]: ferrors2 }));
  };
  const onChangeHandler2 = (e) => {
    var name2 = e.target.name;
    var value2 = e.target.value;
    setForm2((prevState) => ({
      ...prevState,
      [name2]: value2,
    }));
    var errmsg = "";
    if(name2 === "new_password" & value2 !== "") {
      if(form2.confirm_password !== value2) { errmsg = translate.profile.error4; }
      else { validateField2("confirm_password", form2.confirm_password,""); }
    } else if(name2 === "confirm_password" & value2 !== "") {
      if(form2.new_password !== value2) { errmsg = translate.profile.error5; }
      else { validateField2("new_password", form2.new_password,""); }
    }
    validateField2(name2, value2,errmsg);
  };
  const revertPassword = (newpassword,oldpassword) => {
    return new Promise((resolve) => {
      let fetchUrl3 = global.config.urlLink + "/editpass";
      headersParams.Authorization = keyAuth;
      var dataJson3 = {
        doc_id: localStorage.getItem("dutk"),
        current_password: oldpassword,
        new_password: newpassword,
        confirm_password: newpassword
      };
      dataJson3.from_id = "edit-profile-password";
      fetch(fetchUrl3, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(dataJson3),
      }).then((res3) => {
          if (res3.status === 200) return res3.json();
      }).then((resJson3) => {
          resolve(resJson3);
          // window.location.href = "/profile";
      });
    });
  }
  const callrevert = async (newpassword,oldpassword) => {
    let resultback = await revertPassword(newpassword,oldpassword);
    return resultback;
  }
  
  const updateMyPassword = (e) => {
    e.preventDefault();
    console.log(form2);
    if(form2.current_password !== "" || form2.new_password !== "" || form2.confirm_password !== "") {
      if(form2.new_password.length > 5 || form2.confirm_password.length > 5) {
        document.getElementById("loading-screen").style.display = "flex";
        let fetchUrl2 = global.config.urlLink + "/editpass";
        headersParams.Authorization = keyAuth;
        var dataJson2 = form2;
        dataJson2.from_id = "edit-profile-password";
        fetch(fetchUrl2, {
          method: "POST",
          headers: headersParams,
          body: JSON.stringify(dataJson2),
        }).then((res2) => {
            if (res2.status === 200) return res2.json();
        }).then((resJson2) => {
            if(resJson2.status === "200") {
              window.location.href = "/profile";
            } else {
              console.log(resJson2);
              document.getElementById("loading-screen").style.display = "none";
              validateField2("current_password", "",translate.profile.error7); 
            }
        });
      } else {
        
        var formField3 = [];
        if(form2.new_password.length < 6) { formField3.push("new_password"); }
        if(form2.confirm_password.length < 6) { formField3.push("confirm_password"); }
        for ( var k = 0; k < formField3.length; k++ ) { 
          var fieldname3 = formField3[k];
          var fieldvalue3 = form2[fieldname3];
          validateField2(fieldname3, fieldvalue3, translate.profile.error6); 
        }
      }
    } else {
      var formField2 = [];
      if(form2.current_password === "") { formField2.push("current_password"); }
      if(form2.new_password === "") { formField2.push("new_password"); }
      if(form2.confirm_password === "") { formField2.push("confirm_password"); }
      for ( var j = 0; j < formField2.length; j++ ) { 
        var fieldname2 = formField2[j];
        var fieldvalue2 = form2[fieldname2];
        validateField2(fieldname2, fieldvalue2, ""); 
      }
    }
  };
  
  const loadDataProfile = () => {
    return new Promise((resolve) => {
      let datatext = '{"from_id":"detail-profile","doc_id":"'+localStorage.getItem("dutk")+'","lang":"'+chooseLang+'"}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth;
      var fetchUrl = global.config.urlLink + "/detailusers";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            // var result = resJson.values;
            resolve(resJson.values);
          } else if(resJson.status === global.config.errcodesess) {
            resolve([resJson.status]);
          } 
      }).catch((err) => {
          console.log(err);
      });
    });
  };
  const loadData = async () => {
    document.getElementById("loading-screen").style.display = "flex";
    let [result] = await Promise.all([loadDataProfile()]);

    if(result.length > 0) {
      if(result[0] === global.config.errcodesess) { 
        var resultSO = await Logouts();
        if(resultSO.status === "200") {
          window.location.reload();
        }
      } else {
        var picProfile = result[0].user_photo;
        if(picProfile === undefined || picProfile === null || picProfile === "undefined" || picProfile === "null") { picProfile = ""; }
        
        var country_name = "";
        if(result[0].user_country !== null && result[0].user_country !== '""' && result[0].user_country !== "" && JSON.parse(result[0].user_country).length > 0) {
          var usercountry = JSON.parse(result[0].user_country);
          usercountry.forEach(country => {
            if(country_name!=="") { country_name += ", "; }
            country_name += country.label;
          })
        }
        setDetailData((prevState) => ({
          ...prevState,
          doc_id: result[0].user_keyid,
          profile_fullname: result[0].user_fullname,
          profile_email: result[0].user_email,
          profile_phone: result[0].user_phone,
          profile_position: result[0].user_position,
          profile_distributor: result[0].distributor_name,
          // profile_country: result[0].country_name,
          profile_country: country_name,
          profile_photo: picProfile,
          profile_level: result[0].user_level
        }));
        setForm((prevState) => ({
          ...prevState,
          doc_id: result[0].user_keyid,
          profile_fullname: result[0].user_fullname,
          profile_phone: result[0].user_phone,
          profile_position: result[0].user_position,
          profile_photo: picProfile
        }));
        setForm2((prevState) => ({
          ...prevState,
          doc_id: result[0].user_keyid,
          current_password: "",
          new_password: "",
          confirm_password: "",
        }));
      }
    }
    document.getElementById("loading-screen").style.display = "none";
  } 

  useEffect(() => {
    loadData();    
  }, []);

  console.log(form);
  return (
    
    <>
      {loadingScr && <LoadingScreen />}
      <div className="p-8 bg-gray-100 h-screen overflow-y-auto">
        <div className=" mb-8">
          <h2 className=" text-2xl">{translate.profile.title}</h2>
        </div>
        <div className="flex gap-8">
          <div>
          {loading ? (
              <div className="w-40 flex justify-center">
                <Loading />
              </div>
            ) : (
              <>
                {preview.image ? (
                  <>
                  <Button
                    type="button"
                    variant="icon"
                    icon="close"
                    onClick={removeImg}
                    className=" absolute z-[999999] ml-1 mt-1"
                  />
                  <img
                    src={preview.image}
                    alt="user_default"
                    className={`w-40 h-40 ${
                      type === "edit" // && "cursor-pointer"
                    } rounded-full object-cover object-[50%_0px]`}
                    // onClick={removeImg}
                  />
                </>
                ) : (
                  type === "edit"?
                  <>
                    <img
                      src={(form.profile_photo !== "" && form.profile_photo !== null && form.profile_photo !== undefined) ? form.profile_photo : "/images/user-df.png"}
                      alt="user_default"
                      className={`w-40 h-40 rounded-full cursor-pointer object-cover object-[50%_0px]`}
                      onClick={handleClickUpload}
                    /> 
                    <div className="bg-yellow-300 relative">
                      <Button
                        variant="icon"
                        icon={(detaildata.profile_photo !== "" && detaildata.profile_photo !== null)  ? "close" : "camera"}
                        className=" absolute left-16 -top-3"
                        onClick={
                          (detaildata.profile_photo !== "" && detaildata.profile_photo !== null)
                            ? removeImg
                            : handleClickUpload
                        }
                      />
                    </div>
                  </>
                : 
                <img
                  src={(detaildata.profile_photo !== "" && detaildata.profile_photo !== null && detaildata.profile_photo !== undefined) ? detaildata.profile_photo : "/images/user-df.png"}
                  alt="user_default"
                  className={`w-40 h-40 rounded-full object-cover object-[50%_0px]`}
                />
              )}
            </>
          )}
          </div>
          {type === "edit" ? ( 
            <div className="flex-1 mr-32 mb-8">
              <TextInput
                variant="base"
                // title="Full Name"
                title={translate.profile.label1}
                value={form.profile_fullname}
                name="profile_fullname"
                id="profile_fullname"
                onChange={onChangeHandler}
                errmsg={formErrors.profile_fullname}
              />
              {/* <TextInput
                variant="base"
                title="Email"
                value={detaildata.profile_email}
                name="profile_email"
                id="profile_email"
                onChange={onChangeHandler}
              /> */}
              <TextInput
                variant="base"
                // title="Position"
                title={translate.profile.label2}
                value={form.profile_position}
                name="profile_position"
                id="profile_position"
                onChange={onChangeHandler}
              />
              <TextInput
                variant="base"
                // title="Phone"
                title={translate.profile.label3}
                value={form.profile_phone}
                name="profile_phone"
                id="profile_phone"
                onChange={onChangeHandler}
              />
              <input
                type="file"
                ref={hiddenImageInput}
                onChange={handleUploadImgChange}
                style={{ display: "none" }}
                // hidden
              />
            </div>
          ) : 
          (type === "edit-password") ? ( 
            <div className="flex-1 mr-32 mb-8">
              <div className="text-center errText mb-3"><div id="errormsg" className="hidden" style={{color: "#611A15", fontSize: "14px", backgroundColor: "#FDECEA", padding: "6px 16px"}}>Failed to update your password</div></div>
              <TextInput
                variant="base"
                type="password"
                // title="Current Password"
                title={translate.profile.label4}
                value={form2.current_password}
                name="current_password"
                id="current_password"
                onChange={onChangeHandler2}
                errmsg={formErrors2.current_password}
              />
              <TextInput
                variant="base"
                type="password"
                // title="New Password"
                title={translate.profile.label5}
                value={form2.new_password}
                name="new_password"
                id="new_password"
                onChange={onChangeHandler2}
                errmsg={formErrors2.new_password}
              />
              <TextInput
                variant="base"
                type="password"
                // title="Confirm New Password"
                title={translate.profile.label6}
                value={form2.confirm_password}
                name="confirm_password"
                id="confirm_password"
                onChange={onChangeHandler2}
                errmsg={formErrors2.confirm_password}
              />
            </div>
          ) : (
          <div className="flex-1 ">
            <div className="mb-4">
              <label className="block text-sm text-gray-400">{translate.profile.label1}</label>
              <h2 className=" text-xl">{detaildata.profile_fullname}</h2>
            </div>
            <div className="mb-2">
              <label className="block text-sm text-gray-400">{translate.profile.label9}</label>
              <h2 className=" text-xl">{detaildata.profile_email}</h2>
            </div>
            {(detaildata.profile_level === "dt")? 
            <div className="mb-2">
              <label className="block text-sm text-gray-400">{translate.profile.label7}</label>
              <h2 className=" text-xl">{(detaildata.profile_distributor!=="")? detaildata.profile_distributor : '-'}</h2>
            </div>
            : '' }
            {(detaildata.profile_level === "cm")? 
            <div className="mb-2">
              <label className="block text-sm text-gray-400">{translate.profile.label8}</label>
              <h2 className=" text-xl">{(detaildata.profile_country!=="")? detaildata.profile_country : '-'}</h2>
            </div>
            : '' }
            <div className="mb-2">
              <label className="block text-sm text-gray-400">{translate.profile.label2}</label>
              <h2 className=" text-xl">{(detaildata.profile_position!=="")? detaildata.profile_position : '-'}</h2>
            </div>
            <div className="mb-2">
              <label className="block text-sm text-gray-400">{translate.profile.label3}</label>
              <h2 className=" text-xl">{(detaildata.profile_phone!=="")? detaildata.profile_phone : '-'}</h2>
            </div>
          </div>
          )}
        </div>
        {type === "edit" ? ( 
          <div className="flex justify-end mr-32">  
            <Button
                variant="base"
                // title="Save"
                title={translate.general.button_save}
                className=" bg-red-500 text-white border border-red-500 mr-5"
                onClick={updateProfile}
              />
              <Button
              variant="base"
              // title="Cancel"
              title={translate.general.button_cancel}
              className=" bg-red-500 text-white border border-red-500"
              onClick={() => setType("detail")}
            />
          </div>
        ) : (type === "edit-password") ? ( 
          <div className="flex justify-end mr-32">  
            <Button
                variant="base"
                // title="Save"
                title={translate.general.button_save}
                className=" bg-red-500 text-white border border-red-500 mr-5"
                onClick={updateMyPassword}
              />
              <Button
              variant="base"
              // title="Cancel"
              title={translate.general.button_cancel}
              className=" bg-red-500 text-white border border-red-500"
              onClick={() => setType("detail")}
            />
          </div>
        ) : ( 
          <div className="flex justify-end mr-32">
            <Button
              variant="base"
              // title="Edit Password"
              title={translate.profile.button1}
              className=" bg-red-500 text-white border border-red-500 mr-5"
              onClick={() => setType("edit-password")}
            />
            <Button
              variant="base"
              // title="Edit Profile"
              title={translate.profile.button2}
              className=" bg-red-500 text-white border border-red-500 mr-5"
              onClick={() => setType("edit")}
            />
            <Button
              variant="base"
              // title="Logout"
              title={translate.profile.button3}
              className=" bg-white text-red-500 border border-red-500"
              onClick={HandleLogout}
            />
          
          </div>
        )}
      </div>
    </>
  );
};

export default Profile;
