import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { Sidebar } from "./components";
import {
  // staticLoading,
  staticTranslate,
  translatingToEn,
  translatingWords,
  cnTranslateResult
} from "./features/translate/translateSlice";

import {
  DistributorList,
  DistributorDetail,
  DataAnalysis,
  Profile,
  Login,
  PipelineList,
  PipelineDetail,
  MarketingDetail,
  MarketingCreate,
  MarketingList,
  KeyContact,
  TrainingList,
  TrainingDetail,
  BusinessPlanList,
  BusinessPlanDetail,
  CreateBusinessPlan,
  UsersList,
  ProductList,
  CreateProduct,
  ListObjective,
  DetailObjective,
  MarketList,
  ErrorScreen404,
  CreateMessage,
  DistributorMessageList,
  CountryList,
  PromotionalList,
  PromotionalView,
  CreatePromotional,
  DistributorMap,
  TrainingParticipant,
  UserLoginReport,
  CampaignList,
  CampaignDetail,
  ComplianceView,
  ComplianceList,
  TestPage
} from "./pages";

const keyAuth = global.config.typecode[17] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const App = () => {
  const dispatch = useDispatch();
  const translate = useSelector(staticTranslate);
  const cnTranslate = useSelector(state => state.translate.cnTranslate)
  const chooseLang = useSelector((state) => state.translate.lang);
  const [loading, isLoading] = useState(false);

  const cekCompliance = (idDist) => {
    isLoading(true);
    let fetchUrl = global.config.urlLink + "/cekcompliance";
    headersParams.Authorization = keyAuth;
    const data = { from_id: "cek-compliance-tyear", lang: chooseLang, dist_id: idDist };
    fetch(fetchUrl, {
      method: "POST",
      headers: headersParams,
      body: JSON.stringify(data),
    }).then((res) => {
        if (res.status === 200) return res.json();
    }).then(async (res) => {
        if(res.status === "204") {
          document.getElementById("compliance-screen").style.display = "flex";
        } else if(res.status === "200") {
          if(res.values.length > 0) {
            localStorage.setItem('dstatc', res.values[0].status);
            isLoading(false);
          }
        }
    });
};

  useEffect(() => {
    if (chooseLang === "en") {
      // window.localStorage.setItem("wordtranslate", JSON.stringify(translate));
      dispatch(translatingToEn())
    } else {
      // dispatch(translatingWords(translate));
      if (cnTranslate === null) {
        dispatch(translatingWords(translate))
      } else {
        dispatch(cnTranslateResult());
      }
    }
    if(localStorage.getItem('duid') !== null) {
      if(localStorage.getItem("dulv")===global.config.conflevel[3]) { 
          localStorage.removeItem("dstatc"); 
          var thisyear = new Date().getFullYear();
          if(thisyear > 2023) {
            cekCompliance(localStorage.getItem("dudst"))
          } else {
            localStorage.setItem('dstatc', global.config.compliancestatus[2]);
          }
      } else {
        localStorage.setItem('dstatc', global.config.compliancestatus[2]);
      }
    } else {
      localStorage.removeItem("dstatc"); 
      document.getElementById("loading-screen").style.display = "none";
      document.getElementById("compliance-screen").style.display = "none";
    }
  }, [chooseLang]);
  
  var mainHome = <Login />;
  var pageObjective = <Login />;
  var pageCompliance = <Login />;  
  if(localStorage.getItem('duid') !== null) {
    if(localStorage.getItem("dulv") === global.config.conflevel[0] || localStorage.getItem("dulv") === global.config.conflevel[1]) { 
      pageCompliance = <ComplianceList />; pageObjective = <ListObjective />; mainHome = <PromotionalView />; //mainHome = <DistributorList />; 
    } else if(localStorage.getItem("dulv") === global.config.conflevel[2]) { 
      pageCompliance = <ComplianceList />; pageObjective = <ListObjective />; mainHome = <PromotionalView />; //mainHome = <DataAnalysis />; 
    } else { 
      pageCompliance = <ComplianceView />; pageObjective = <DetailObjective />; mainHome = <PromotionalView />;  //mainHome = <DataAnalysis />; 
    }
  }
  
  return (
    <>
        { (loading === false && localStorage.getItem("duid") !== null && localStorage.getItem("dstatc") === global.config.compliancestatus[2])? 
        <Router>
        <Routes>
          <Route path="/" element={<Sidebar />}>
            <Route index element={mainHome} />
            <Route path="admin">
              <Route path="distributor">
                <Route index element={<DistributorList />} />
                <Route path="detail" element={<DistributorDetail />} />
              </Route>
              <Route path="contact">
                  <Route index element={<DistributorMessageList />} />
                </Route>
              <Route path="users">
                <Route index element={<UsersList />} />
              </Route>
              <Route path="users-report">
                <Route index element={<UserLoginReport />} />
              </Route>
              <Route path="product">
                <Route index element={<ProductList />} />
                <Route path="create" element={<CreateProduct />} />
              </Route>
              <Route path="market">
                <Route index element={<MarketList />} />
              </Route>
              <Route path="country">
                <Route index element={<CountryList />} />
              </Route>
              <Route path="promo">
                <Route index element={<PromotionalList />} />
                <Route path="create" element={<CreatePromotional />} />
              </Route>
              <Route path="compliance">
                <Route index element={pageCompliance} />
              </Route>
            </Route>
            <Route path="distributor">
              <Route index element={<DistributorDetail />} />
            </Route>
            <Route path="analysis">
              <Route index element={<DataAnalysis />} />
            </Route>
            <Route path="objective">
              <Route index element={pageObjective} />
              <Route path="detail" element={<DetailObjective />} />
            </Route>
            <Route path="training">
              <Route index element={<TrainingList />} />
              <Route path="detail" element={<TrainingDetail />} />
            </Route>
            <Route path="training-participant">
              <Route index element={<TrainingParticipant />} />
            </Route>
            <Route path="marketing">
              <Route index element={<MarketingList />} />
              <Route path="create" element={<MarketingCreate />} />
              <Route path="detail" element={<MarketingDetail />} />
            </Route>
            <Route path="keycontact">
              <Route index element={<KeyContact />} />
            </Route>
            <Route path="pipeline">
              <Route index element={<PipelineList />} />
              <Route path="detail" element={<PipelineDetail />} />
            </Route>
            <Route path="businessplan">
              <Route index element={<BusinessPlanList />} />
              <Route path="detail" element={<BusinessPlanDetail />} />
              <Route path="create" element={<CreateBusinessPlan />} />
            </Route>
            <Route path="contact">
              <Route path="create" element={<CreateMessage />} />
            </Route>
            <Route path="profile">
              <Route index element={<Profile />} />
            </Route>
            <Route path="promo">
              <Route index element={<PromotionalView />} />
            </Route>
            <Route path="apac">
              <Route index element={<DistributorMap />} />
            </Route>
            <Route path="campaign">
              <Route index element={<CampaignList />} />
              <Route path="detail" element={<CampaignDetail />} />
            </Route>
            <Route path="compliance">
              <Route index element={pageCompliance} />
            </Route>
            <Route path="test">
              <Route index element={<TestPage />} />
            </Route>
            <Route path="*" element={<ErrorScreen404 />} />
          </Route>
        </Routes>
      </Router>
      : (loading === false && localStorage.getItem("duid") !== null && localStorage.getItem("dstatc") !== global.config.compliancestatus[2])? 
      <Router>
        <Routes>
          <Route path="/" element={<Sidebar />}>
            <Route index element={<ComplianceView />} />
            <Route path="admin">
              <Route path="distributor">
                <Route index element={<ComplianceView />} />
                <Route path="detail" element={<ComplianceView />} />
              </Route>
              <Route path="contact">
                  <Route index element={<ComplianceView />} />
                </Route>
              <Route path="users">
                <Route index element={<ComplianceView />} />
              </Route>
              <Route path="users-report">
                <Route index element={<ComplianceView />} />
              </Route>
              <Route path="product">
                <Route index element={<ComplianceView />} />
                <Route path="create" element={<ComplianceView />} />
              </Route>
              <Route path="market">
                <Route index element={<ComplianceView />} />
              </Route>
              <Route path="country">
                <Route index element={<ComplianceView />} />
              </Route>
              <Route path="promo">
                <Route index element={<ComplianceView />} />
                <Route path="create" element={<ComplianceView />} />
              </Route>
              <Route path="compliance">
                <Route index element={<ComplianceView />} />
              </Route>
            </Route>
            <Route path="distributor">
              <Route index element={<ComplianceView />} />
            </Route>
            <Route path="analysis">
              <Route index element={<ComplianceView />} />
            </Route>
            <Route path="objective">
              <Route index element={<ComplianceView />} />
              <Route path="detail" element={<ComplianceView />} />
            </Route>
            <Route path="training">
              <Route index element={<ComplianceView />} />
              <Route path="detail" element={<ComplianceView />} />
            </Route>
            <Route path="training-participant">
              <Route index element={<ComplianceView />} />
            </Route>
            <Route path="marketing">
              <Route index element={<ComplianceView />} />
              <Route path="create" element={<ComplianceView />} />
              <Route path="detail" element={<ComplianceView />} />
            </Route>
            <Route path="keycontact">
              <Route index element={<ComplianceView />} />
            </Route>
            <Route path="pipeline">
              <Route index element={<ComplianceView />} />
              <Route path="detail" element={<ComplianceView />} />
            </Route>
            <Route path="businessplan">
              <Route index element={<ComplianceView />} />
              <Route path="detail" element={<ComplianceView />} />
              <Route path="create" element={<ComplianceView />} />
            </Route>
            <Route path="contact">
              <Route path="create" element={<ComplianceView />} />
            </Route>
            <Route path="profile">
              <Route index element={<Profile />} />
            </Route>
            <Route path="promo">
              <Route index element={<ComplianceView />} />
            </Route>
            <Route path="apac">
              <Route index element={<ComplianceView />} />
            </Route>
            <Route path="campaign">
              <Route index element={<ComplianceView />} />
              <Route path="detail" element={<ComplianceView />} />
            </Route>
            <Route path="compliance">
              <Route index element={<ComplianceView />} />
            </Route>
            <Route path="*" element={<ErrorScreen404 />} />
          </Route>
        </Routes>
      </Router>
        : (loading === false && <Router>
            <div className="w-full">
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="distributor"><Route index element={<Login />} /></Route>
                <Route path="admin">
                  <Route path="distributor">
                    <Route index element={<Login />} />
                    <Route path="detail" element={<Login />} />
                  </Route>
                  <Route path="product">
                    <Route index element={<Login />} />
                    <Route path="create" element={<Login />} />
                  </Route>
                  <Route path="country"><Route index element={<Login />} /></Route>
                  <Route path="contact"><Route index element={<Login />} /></Route>
                  <Route path="users"><Route index element={<Login />} /></Route>
                  <Route path="users-report"><Route index element={<Login />} /></Route>
                  <Route path="market"><Route index element={<Login />} /></Route>
                  <Route path="promo">
                    <Route index element={<Login />} />
                    <Route path="create" element={<Login />} />
                  </Route>
                  <Route path="compliance"><Route index element={<Login />} /></Route>
                </Route>
                <Route path="objective">
                  <Route index element={<Login />} />
                  <Route path="detail" element={<Login />} />
                </Route>
                <Route path="training">
                  <Route index element={<Login />} />
                  <Route path="detail" element={<Login />} />
                </Route>
                <Route path="training-participant"><Route index element={<Login />} /></Route>
                <Route path="marketing">
                  <Route index element={<Login />} />
                  <Route path="create" element={<Login />} />
                  <Route path="detail" element={<Login />} />
                </Route>
                <Route path="pipeline">
                  <Route index element={<Login />} />
                  <Route path="detail" element={<Login />} />
                </Route>
                <Route path="businessplan">
                  <Route index element={<Login />} />
                  <Route path="detail" element={<Login />} />
                  <Route path="create" element={<Login />} />
                </Route>
                <Route path="analysis"><Route index element={<Login />} /></Route>
                <Route path="keycontact"><Route index element={<Login />} /></Route>
                <Route path="contact"><Route path="create" element={<Login />} /></Route>
                <Route path="profile"><Route index element={<Login />} /></Route>
                <Route path="promo"><Route index element={<Login />} /></Route>
                <Route path="apac"><Route index element={<Login />} /></Route>
                <Route path="campaign"><Route index element={<Login />} /></Route>
                <Route path="compliance"><Route index element={<Login />} /></Route>
                <Route path="*" element={<ErrorScreen404 />} />
                <Route path="test">
                  <Route index element={<TestPage />} />
                </Route>
              </Routes>
            </div>
          </Router> 
        )
      }
    </>
  );
};

export default App;
