import { DollarCoinIcon, DoughnutChartIcon, GraphIcon } from "../assets";

export const UserData = [
  {
    id: 1,
    year: 2016,
    userGain: 80,
    userLost: 823,
  },
  {
    id: 2,
    year: 2017,
    userGain: 20,
    userLost: 200,
  },
  {
    id: 3,
    year: 2018,
    userGain: 100,
    userLost: 900,
  },
  {
    id: 4,
    year: 2019,
    userGain: 90,
    userLost: 700,
  },
  {
    id: 5,
    year: 2020,
    userGain: 10,
    userLost: 900,
  },
];

export const progressData = [
  {
    id: 0,
    title: "Total Quotes",
    value: 99,
    strokeColor: `rgba(62,152,199,1)`,
  },
  { id: 1, title: "Total Units", value: 32, strokeColor: `rgba(42,150,1,1)` },
  {
    id: 2,
    title: "Total Weighted Units",
    value: 60,
    strokeColor: `rgba(150,5,160,1)`,
  },
  {
    id: 3,
    title: "Total Selling Price",
    value: 50,
    strokeColor: `rgba(200,50,5,1)`,
  },
];

export const DashboardGraphData = [
  {
    id: 0,
    iconCard: DollarCoinIcon,
    title: "Today Gains",
    value: "65M",
    percent: "20%",
    status: "Increased",
    background: `bg-blue-200`,
  },
  {
    id: 0,
    iconCard: GraphIcon,
    title: "Today Gains",
    value: "15M",
    percent: "10%",
    status: "Decreased",
    background: `bg-yellow-200`,
  },
  {
    id: 0,
    iconCard: DoughnutChartIcon,
    title: "Today Gains",
    value: "5M",
    percent: "20%",
    status: "Increased",
    background: `bg-blue-200`,
  },
];
