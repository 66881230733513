import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Button, TextInput, ToggleSwitch, CustomSelect } from "../../../components";
import ModalTraining from "../Components/Modal";
import { staticTranslate } from "../../../features/translate/translateSlice";
import Logouts from "../../../components/logout"; 

const keyAuth = global.config.typecode[9] + global.config.authBearerKey;
const keyAuth2 = global.config.typecode[3] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const TrainingDetail = () => {
  const translate = useSelector(staticTranslate);
  const chooseLang = useSelector((state) => state.translate.lang);
  const navigate = useNavigate();
  const paramsLoc = useLocation();
  const [type, setType] = useState("");
  const [isVisible, setVisible] = useState(false);
  const [detaildata, setDetailData] = useState({});
  const [listDistributor, setListDistributor] = useState([]);
  const [selDistributor, setSelectedDistributor] = useState("");
  const [listParticipant, setListParticipant] = useState([]);
  const [popContent, setPopContent] = useState([]);
  const [formDisabled, setFormDisabled] = useState(true);
  
  const [formErrors, setFormErrors] = useState({
    training_name: "",
    // training_url: "", 
    training_dist_id: ""
  });
  const [form, setForm] = useState({
    training_name: "",
    // training_url: "",
    training_desc: "",
    status: 1,
    training_dist_id: "",
    training_dist_name: ""
  });
  const handleBack = () => {
    navigate("/training", {
      state: { dataref: paramsLoc.state.filter },
    })
  }
  const validateField = (fieldName, value) => {
    let ferrors = "";
    switch (fieldName) {
      case "training_name":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.training_detail.error1; }
        break;
      // case "training_url":
      //   if (value.trim().length > 0) { ferrors = ""; } 
      //   else { ferrors = "training url need to be filled"; }
      //   break;
      case "training_dist_id":
        if (value > 0) { ferrors = ""; } 
        else { ferrors = translate.training_detail.error2; }
        break;
      default:
        break;
    }
    setFormErrors((prevState) => ({ ...prevState, [fieldName]: ferrors }));
  };
  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };
  const onChangeSelectHandler= (e) => {
    // var name = "training_dist_id";
    // var value = e.value;
    
    setSelectedDistributor(e);
    setForm((prevState) => ({
      ...prevState,
      'training_dist_id': e.value,
      'training_dist_name': e.label
    }));
    validateField('training_dist_id', e.value);
  };
  const toggleHandler = () => {
    setForm((prevState) => ({
      ...prevState,
      status: form.status === 0 ? 1 : 0,
    }));
  };
  const handleContentModal = (resList, type) => {
    let content = {};
    content.type = type;
    content.data = resList;
    setPopContent(content);
    setVisible(!isVisible);
  };
  const handleDeleteParticipant = (id_training, participant_id) => {
    if (window.confirm(`${translate.training_detail.alert_delete}`)) {
      document.getElementById("loading-screen").style.display = "flex";
      let fetchUrl =
        global.config.urlLink +
        "/deletetraininguser/" +
        id_training +
        "/" +
        participant_id;
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "GET",
        headers: headersParams,
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then((res) => {
          document.getElementById("loading-screen").style.display = "none";
          window.location.href = "/training/detail";
        });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if(form.training_name !== "" && form.training_dist_id !== "") {
      document.getElementById("loading-screen").style.display = "flex";
      var dataJson = form;
      dataJson.distributor_id = form.training_dist_id;
      dataJson.distributor_name = form.training_dist_name;
      var fetchUrl = global.config.urlLink;
      if (type === "create") {
        fetchUrl += "/addtraining";
        dataJson.from_id="add-training";
      } else {
        fetchUrl += "/edittraining";
        dataJson.from_id="edit-training";
      }
      dataJson.lang=chooseLang;
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(dataJson),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((res) => {
          document.getElementById("loading-screen").style.display = "none";
          if (res.status === "200") {
            navigate("/training", {
              state: { dataref: paramsLoc.state.filter },
            })
          }
      }).catch((err) => {
        console.log(err);
        document.getElementById("loading-screen").style.display = "none";
      });
    } else {
      var formField = [];
      if(form.training_name === "") { formField.push("training_name"); }
      // if(form.training_url === "") { formField.push("training_url"); }
      if(form.training_dist_id === "") { formField.push("training_dist_id"); }
      for ( var i = 0; i < formField.length; i++ ) { 
        var fieldname = formField[i];
        var fieldvalue = form[fieldname];
        validateField(fieldname, fieldvalue); 
      }
    }
  };

  const getDistributorId = (list,did) => {
    var updIdDistributor = list.filter(
      (dist) => parseInt(dist.value) === parseInt(did)
    );
    setSelectedDistributor(updIdDistributor[0]);
    setForm((prevState) => ({
      ...prevState,
      training_dist_id: did,
      training_dist_name: updIdDistributor[0].label,
    }));
  };
  const loadListDistributor = (country,pgtype) => {
    return new Promise((resolve) => {
      var datatext = '{';
      datatext += '"from_id":"list-distributor","stat_id":1';
      if(pgtype !== "edit" && pgtype !== "create") {  datatext += ',"lang": "'+chooseLang+'"'; }
      if(country !== "") { datatext += ',"country_id":"'+country+'"';; }
      datatext += '}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth2;
      var fetchUrl = global.config.urlLink + "/listdist";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            var updDistributor = { };
            if(resJson.values.length > 0) {
              updDistributor = resJson.values.map((dist) => ({
                value: dist.distributor_id,
                label: dist.distributor_name,
              }));
            } 
            resolve(updDistributor);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadDataTraining = (id,pgtype) => {
    return new Promise((resolve) => {
      let datatext = '{';
      datatext += '"from_id":"detail-training"'
      datatext += ',"doc_id":'+id;
      if(pgtype !== "edit" && pgtype !== "create") { datatext += ',"lang":"'+chooseLang+'"'; }
      datatext += '}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth;
      var fetchUrl = global.config.urlLink + "/detailtraining";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            resolve(resJson.values);
          } else if(resJson.status === global.config.errcodesess) {
            resolve([resJson.status]);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadDataParticipant = (id,pgtype) => {
    return new Promise((resolve) => {
      if(formDisabled === true) { 
        let datatext = {
          dist_id: localStorage.getItem("dudst"),
          from_id: "list-training-user",
          docid: id,
        };
        if(pgtype !== "edit" && pgtype !== "create") {   datatext.lang = chooseLang; }
        headersParams.Authorization = keyAuth;
        let fetchUrl = global.config.urlLink + "/listtraininguser";
        fetch(fetchUrl, {
          method: "POST",
          headers: headersParams,
          body: JSON.stringify(datatext),
        })
          .then((res) => {
            if (res.status === 200) return res.json();
          })
          .then((res) => {
            if (res.status === "200") {
              resolve(res.values);
            }
          });
      } else { resolve([]); }
    });
  };
  const loadData = async (id,countryid,distributorid,pgtype) => {
    document.getElementById("loading-screen").style.display = "flex";
    var trainingstatus = true;
    var datauserstr = {};
    if(id !== undefined) {
      let [result1, result2, result3] = await Promise.all([loadListDistributor(countryid,pgtype), loadDataTraining(id,pgtype), loadDataParticipant(id,pgtype)]);
      if(result2.length > 0) {
        if(result2[0] === global.config.errcodesess) { 
          var resultSO = await Logouts();
          if(resultSO.status === "200") {
            window.location.reload();
          }
        } else {
          if(result2[0].status === 1) { trainingstatus =true; } else { trainingstatus =false; } 
          
          distributorid = result2[0].id_distributor;
          datauserstr = {
            doc_id: result2[0].training_id,
            training_name: result2[0].training_name,
            // training_url: result2[0].training_link,
            training_desc: result2[0].training_description,
            status: trainingstatus,
            training_dist_id: result2[0].id_distributor,
          };
          setDetailData((prevState) => ({
            ...prevState,
            doc_id: result2[0].training_id,
            training_name: result2[0].training_name,
          }));
        }
        document.getElementById("loading-screen").style.display = "none";
      } else {
        datauserstr = {
          doc_id: 0,
          training_name: "",
          // training_url: "",
          training_desc: "",
          status: true,
          training_dist_id: distributorid,
        };
        document.getElementById("loading-screen").style.display = "none";
      }
      setForm(datauserstr);      
      setListDistributor(result1);
      if(distributorid !== "") { getDistributorId(result1,distributorid); }
      setListParticipant(result3);
    } else {
      let [result1] = await Promise.all([loadListDistributor(countryid,pgtype)]);
      setListDistributor(result1);
      if(distributorid !== "") { getDistributorId(result1,distributorid); }
      document.getElementById("loading-screen").style.display = "none";
    }
  }
  
  useEffect(() => {
    var docId = "",trainingName="",pageType="detail",idDist="",idCntry="";
    if(localStorage.getItem("dulv")===global.config.conflevel[3]) { idDist = localStorage.getItem("dudst"); } 
    else if(localStorage.getItem("dulv")===global.config.conflevel[2]) { idCntry = localStorage.getItem("ducr"); }

    if(paramsLoc.state !== null) {
      docId = paramsLoc.state.data.training_id;
      trainingName = paramsLoc.state.data.training_name;
      pageType = paramsLoc.state.type;
    } 
    if(docId !== "") {
      setDetailData({
        doc_id: docId,
        training_name: trainingName
      });
      setType(pageType);
      loadData(docId,idCntry,idDist,pageType);
      var inpdisabled = true;
      if(pageType === "edit" || pageType === "create") {   
          inpdisabled = false;
      }
      setFormDisabled(inpdisabled);
    } else {
      window.location.href = "/training";
    }

  }, []);
  const ActionCols = (row) => ( 
    
    <div className="flex flex-1 gap-4 hover:fill-red-500">
      <FaEdit
        onClick={() => { 
          var sendrow = row;
          sendrow.training_dist_id =  form.training_dist_id;
          sendrow.doc_id =  row.participant_id;
          handleContentModal(sendrow, "edit") 
        }}
        className="hover:fill-red-500 cursor-pointer fasize"
      />
      <FaTrash
        onClick={() =>
          handleDeleteParticipant(row.id_training, row.participant_id)
        }
        className="hover:fill-red-500 cursor-pointer fasize"
      />
    </div>
  );

  const columnsTarget = [
    { name: translate.training_detail.column_1, selector: (_, index) => index + 1, grow: 0 },
    { name: translate.training_detail.column_2, selector: (row) => row.participant_name },
    { name: translate.training_detail.column_3, selector: (row) => row.participant_email },
    { name: translate.training_detail.column_4, selector: (row) => row.participant_position },
  ];
  // if(!formDisabled && localStorage.getItem("dulv")===global.config.conflevel[3]) {
    columnsTarget.push({ name: translate.training_detail.column_5, cell: ActionCols, width: "120px" });
  // }
  // const targetdata = [{ id: 0, name: "Akun 1", email: "akun1@gmail.com" }];

  return (
    <>
    <div className="bg-gray-100 p-8 h-screen overflow-y-auto">
      <div className="flex items-center mb-8 gap-10">
        <div className=" flex items-center">
          <Button
            variant="icon"
            icon="left"
            className="mr-5"
            onClick={() => handleBack()}
            // onClick={() => navigate(-1)}
          />
          <h2 className="text-2xl">
            {type !== "create" ? detaildata.training_name : translate.training_detail.title}
          </h2>
        </div>

        <div className="flex items-center gap-x-4">
          <label
            className={`text-sm text-gray-400 ${
              !form.status && "text-red-500"
            }`}
          >
            {global.config.generalstatus[1]}
          </label>
          <ToggleSwitch
            id="status"
            checked={form.status}
            onClick={formDisabled ? null : toggleHandler}
          />
          <label
            className={`text-sm text-gray-400 ${form.status && "text-red-500"}`}
          >
            {global.config.generalstatus[0]}
          </label>
        </div>
      </div>
      <div className="flex justify-end">
        {!formDisabled && (
          <Button
            variant="base"
            title={type === "create" ? translate.training_detail.button1 : translate.general.button_save}
            className="bg-red-500 text-white"
            onClick={handleSubmit}
          />
        )}
      </div>
      <div>
        {(localStorage.getItem("dulv")!==global.config.conflevel[3])? 
        <CustomSelect
            // title="Distributor"
            title={translate.training_detail.text_input1}
            name="training_dist_id"
            value={selDistributor}
            options={listDistributor}
            onChange={(e) => onChangeSelectHandler(e)}
            defaultValue={form.training_dist_id}
            disabled={formDisabled}
            errmsg={formErrors.training_dist_id}
          />
        : ''
        }
        <TextInput
          variant="base"
          // title="Training Name"
          title={translate.training_detail.text_input2}
          id="training_name"
          name="training_name"
          value={form.training_name}
          onChange={onChangeHandler}
          disabled={formDisabled}
          errmsg={formErrors.training_name}
        />
        {/* <TextInput
          variant="base"
          title="Link URL"
          id="training_url"
          name="training_url"
          value={form.training_url}
          onChange={onChangeHandler}
          disabled={formDisabled}
          errmsg={formErrors.training_url}
        /> */}
        <TextInput
          variant="textarea"
          // title="Description"
          title={translate.training_detail.text_input4}
          id="training_desc"
          name="training_desc"
          value={form.training_desc}
          onChange={onChangeHandler}
          disabled={formDisabled}
          height={"100px"}
        />
      </div>
      <div className="mx-1">
      {formDisabled === true && 
      <>
        <div className="flex flex-row items-center gap-4 mb-2">
          <label className="text-gray-500">{translate.training_detail.label1}</label>
          {(formDisabled) && (<Button
              variant="icon"
              icon="plus"
              onClick={() => handleContentModal(form, "create")}
            />
          )}
        </div>
        <DataTable
          columns={columnsTarget}
          data={listParticipant}
          fixedHeader
          pointerOnHover
          onRowClicked={(row) => handleContentModal(row, "detail")}
          customStyles={{
            headRow: {
              style: {
                borderBottomWidth: "3px",
                borderBottomStyle: "solid",
                fontWeight: "bold",
                backgroundColor: "#F5F6FA",
                color: "#8A92A6",
              },
              denseStyle: {
                minHeight: "32px",
              },
            },
          }}
        /> 
      </>
      }
      </div>
    </div>
    {isVisible && (
      <ModalTraining
        content={popContent}
        onClick={() => setVisible(!isVisible)}
        onBackgroundClick={() => setVisible(!isVisible)}
      />
    )}
    </>
  );
};

export default TrainingDetail;
