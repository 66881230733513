import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useScrollbarSize from "react-scrollbar-size";
import { Button, Card, Loading, NoRecords } from "../../../components";
import { staticTranslate } from "../../../features/translate/translateSlice";
import { ComplianceModal } from "../Components";
import Logouts from "../../../components/logout"; 
import { ReactComponent as IconPDF } from "../../../assets/svg/icon-pdf2.svg";
import openFiles from "../../../components/downloadurl";

const keyAuth = global.config.typecode[17] + global.config.authBearerKey;
const keyAuth2 = global.config.typecode[3] + global.config.authBearerKey;
const headersParams = global.config.headersparam;
const yearnow = new Date().getFullYear();

const ComplianceList = () => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector((state) => state.translate.lang)
  const navigate = useNavigate();
  const { height } = useScrollbarSize();
  const [pending, setPending] = useState(true);
  const [listCompliance, setListCompliance] = useState([]);
  const [listDistributor, setListDistributor] = useState([]);
  const [filterData, setFilterData] = useState({statid:global.config.compliancestatus[1],distributorid:"",countryid:"",rowpage:0, yearid: yearnow});
  const [defRowPage, setDefRowPage] = useState(0);
  const [isModal, setModal] = useState(false);
  const [popContent, setPopContent] = useState([]);
  const paramsLoc = useLocation();
  if(paramsLoc.state !== null) { var { dataref } = paramsLoc.state; }

  const handlePopModal = (dataParams, type) => {
    let sendParams = {};
    sendParams.type = type;
    sendParams.data = dataParams;
    setPopContent(sendParams);
    setModal(!isModal);
  };
  const handleRowsPerPageChange = (val) => {
    setFilterData({ ...filterData, rowpage: val });
    setDefRowPage(val);
  }
  
  const handleSelect = async (e) => {
    document.getElementById("loading-screen").style.display = "flex";
    var name = e.target.name;
    var value = e.target.value;
    var sel_statid = filterData.statid;
    var sel_distid = filterData.distributorid;
    var sel_cntryid = filterData.countryid;
    var sel_yearid = filterData.yearid;

    if(name === "statid") {
      sel_statid = value;
    } else if(name === "distributorid") {
      sel_distid = value;
    } else if(name === "countryid") {
      sel_cntryid = value;
    } else if(name === "yearid") {
      sel_yearid = value;
    } 
    if(name === "distributorid" && sel_distid === "" && sel_yearid === "") {
      sel_yearid = yearnow; 
      setFilterData({ ...filterData, [name]: value, yearid: sel_yearid });
    } else {
      setFilterData({ ...filterData, [name]: value });
    }
    
    var result1a = await listComplianceData(sel_statid,sel_distid,sel_cntryid,sel_yearid);
    setListCompliance(result1a);
    document.getElementById("loading-screen").style.display = "none";
  }
  const handleOpenClick = async (url) => {
    let [resultUrl] = await Promise.all([openFiles(url,keyAuth)]);
    if(resultUrl !== "" & resultUrl !== undefined) {
      window.open(resultUrl);
    }
  }
  const handleDetailClick = (resList,type) => {
    navigate("/admin/distributor/detail", {
      state: { data: resList, type: type, filter: filterData, ref: "compliance" },
    })
  }
  const handleAction  = (id,idDist,nmDist,stat,alertmsg) => {
    if (window.confirm(alertmsg)) {
      document.getElementById("loading-screen").style.display = "flex";
      let data = {
        doc_id: id,
        status: stat,
        dist_id: idDist,
        distributor_name: nmDist,
        lang: chooseLang,
        from_id: "edit-status-compliance"
      }
      let fetchUrl = global.config.urlLink + "/editcompliance";
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then(res => {
        if (res.status === 200) return res.json();
      }).then( async resJson => { 
        var result1a = await listComplianceData("",filterData.distributorid,filterData.countryid,filterData.yearid);
        setListCompliance(result1a);
        setFilterData({ ...filterData, statid: "" });
        document.getElementById("loading-screen").style.display = "none";
        return false;
      }).catch((err) => {
        document.getElementById("loading-screen").style.display = "none";
        return false;
      });
    }
  }
  const loadDistributor = (country) => {
    return new Promise(resolve => {
      if(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2]) {
        var datatext ='{';
        datatext += '"from_id":"list-distributor","stat_id":1';
        datatext += ',"lang": "'+chooseLang+'"';
        if(country !== "") { datatext += ',"country_id":"'+country+'"'; }
        datatext += '}';
        var data = JSON.parse(datatext); 
        headersParams.Authorization = keyAuth2;
        
        fetch(global.config.urlLink+"/listdist", {
          method: 'POST',
          headers: headersParams, 
          body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJson => {
            if(resJson.status === "200") {
              resolve(resJson.values);
            } else {
              resolve([]);
            }
        }).catch((err) => {
            resolve([]);
        });
      } else { resolve([]); }
    });
  }
  const listComplianceData = (stat,distributor,country,years) => {
    return new Promise(resolve => {
      var fetchUrl = global.config.urlLink + "/listcompliance";
      var dataText = '{';
      dataText += '"from_id": "list-distributor-compliance"';
      dataText += ',"lang": "'+chooseLang+'"';
      if(stat !== "") { dataText += ',"stat_id":"'+stat+'"'; }
      if(distributor !== "") { dataText += ',"dist_id":"'+distributor+'"'; }
      if(country !== "") { dataText += ',"country_id":"'+country+'"'; }
      if(years !== "") { dataText += ',"year_id":"'+years+'"'; }
      dataText += '}';
      
      let data = JSON.parse(dataText);
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((res) => {
          if (res.status === "200") {
            resolve(res.values);
          } else if(res.status === global.config.errcodesess) {
            resolve([res.status]);
          } else { resolve([])}
      }).catch((err) => {
        resolve([]);
      });
    });
  };
  const loadData = async (idDist,idCntry,idStat,idRowPage) => {
    var idyears=new Date().getFullYear();
    let [result1, result2] = await Promise.all([listComplianceData(idStat,idDist,idCntry,idyears), loadDistributor(idCntry)]);
    if(result1.length > 0 && result1[0] === global.config.errcodesess) {
      var resultSO = await Logouts();
      if(resultSO.status === "200") {
        window.location.reload();
      }
    } else {
      setPending(false); 
      setListCompliance(result1);
      setListDistributor(result2);
      setFilterData({statid:idStat, distributorid: idDist, countryid: idCntry, rowpage: idRowPage, yearid: idyears});
      setDefRowPage(idRowPage);
    }
  }

const LinkCols = (row) => (
  
  <div className="flex flex-1 gap-4 items-center h-full pr-3">
    
    {((localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2]) 
      && (row.compliance_doc_url !== null && row.compliance_doc_url !== "")
      && (row.status === global.config.compliancestatus[1] || row.status === global.config.compliancestatus[2]))?
      <div className="flex flex-1 gap-4 items-center w-full h-full pr-3" onClick={() => handleOpenClick(row.compliance_doc_url)}><IconPDF width={25} className ="cursor-pointer" onClick={() => handleOpenClick(row.compliance_doc_url)} /></div>
      
    : <></>
    }
  </div>
);
const ActionCols = (row) => (
  <div className="flex flex-1 gap-4 hover:fill-red-500">
    
    {((localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2]) 
      && row.compliance_id !== null
      && row.status === global.config.compliancestatus[1])?
      <>
      <Button
          type="button"
          variant="icon-base"
          title={translate.compliance_list.button1}
          className="bg-green-800 text-white"
          icon="checkmark"
          onClick={() => handleAction(row.compliance_id, row.distributor_id, row.distributor_name, global.config.compliancestatus[2],translate.compliance_list.alert_approve)}
        />
      <Button
          type="button"
          variant="icon-base"
          title={translate.compliance_list.button2}
          className="bg-red-500 text-white"
          icon="close"
          onClick={() => handlePopModal(row, global.config.compliancestatus[3])}
          // onClick={() => handleAction(row.compliance_id, row.distributor_id, global.config.compliancestatus[3],translate.compliance_list.alert_reject)}
        />
      </>
    : <></>
    }
  </div>
);
  useEffect(() => {
    var idDist = "", idCntry="", idStat="", idRowPage=defRowPage;
    if(localStorage.getItem("dulv")===global.config.conflevel[3]) { idDist = localStorage.getItem("dudst"); } 
    else if(localStorage.getItem("dulv")===global.config.conflevel[2]) { idCntry = localStorage.getItem("ducr"); }

    if(dataref !== undefined) { idDist = dataref.distributorid; idCntry=dataref.countryid; idStat=dataref.statid; idRowPage=dataref.rowpage; }
    else { idRowPage = 10; }

    loadData(idDist,idCntry,idStat,idRowPage);
  }, []);

  const column = [
    { name: translate.compliance_list.column_1, selector: (row) => row.distributor_name },
    { name: translate.compliance_list.column_2, selector: (row) => (row.compliance_year !== "" && row.compliance_year !== null)? row.compliance_year : filterData.yearid,  width: "120px" },
    { name: translate.general.column_status, selector: (row) => (row.status !== "" && row.status !== null)? row.status : "-",  width: "220px" },
    { name: translate.compliance_list.column_3, cell: LinkCols,  width: "100px" },
    { name: translate.general.column_action, cell: ActionCols },
  ];
  
  var optDistributor = [];
  if(listDistributor !== undefined) {
    for(var c=0; c<listDistributor.length; c++) {
      optDistributor.push(<option value={listDistributor[c].distributor_id} key={listDistributor[c].distributor_id}>{listDistributor[c].distributor_name}</option>)
    }
  }
  var optYears = [];
  for(var e=2020; e<(parseInt(yearnow)+3); e++) {
    optYears.push(<option value={e} key={"y"+e}>{e}</option>)
  }
  
  return (
    <div className="flex-1 p-8 h-screen bg-gray-100 overflow-y-auto">
      <div className="flex items-center mb-8">
        <h2 className="text-2xl">{translate.compliance_list.title}</h2>
      </div>
      {(localStorage.getItem("dulv")!==global.config.conflevel[3])?
      <>
      <div className="bg-white shadow-3xl flex flex-1 p-4 gap-x-4 rounded-lg mb-2">
        <p className=" text-gray-400 font-bold">{translate.general.filter}: </p>
        <select name="statid" id="statid" className="bg-white text-gray-400 outline-none" defaultValue=""
          value={filterData.statid} 
          onChange={handleSelect}
        >
          <option value="">{translate.general.filter_status}</option>
          <option value={global.config.compliancestatus[0]}>{translate.compliance_list.status_compliance[0]}</option>
          <option value={global.config.compliancestatus[1]}>{translate.compliance_list.status_compliance[1]}</option>
          <option value={global.config.compliancestatus[2]}>{translate.compliance_list.status_compliance[2]}</option>
        </select>
        <select name="distributorid" id="distributorid" className="bg-white text-gray-400 outline-none" defaultValue=""
            value={filterData.distributorid} 
            onChange={handleSelect}
            
          >
            <option value="">{translate.general.filter_distributor}</option>
            {optDistributor}
        </select>
        <select name="yearid" id="yearid" className="bg-white text-gray-400 outline-none" defaultValue=""
          value={filterData.yearid} 
          onChange={handleSelect}
        >
          {filterData.distributorid !== "" && <option value="">{translate.general.filter_year}</option> }
          {optYears}
        </select>
      </div>
      </> : <></>
      }
      <Card className={"h-auto w-auto"}>
      {(defRowPage > 0) && <DataTable
          columns={column}
          data={listCompliance}
          fixedHeader
          highlightOnHover
          defaultSortFieldId="id"
          progressPending={pending}
          progressComponent={<Loading className="h-10 w-10 mt-10 mb-10"/>}
          pointerOnHover
          pagination
          paginationPerPage={defRowPage}
          paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
          onRowClicked={(row) => handleDetailClick(row,"detail")}
          noDataComponent={<NoRecords />}
          onChangeRowsPerPage={handleRowsPerPageChange}
          customStyles={{
            headRow: {
              style: {
                borderBottomWidth: "3px",
                borderBottomStyle: "solid",
                fontWeight: "bold",
                backgroundColor: "#F5F6FA",
                color: "#8A92A6",
              },
              denseStyle: {
                minHeight: "32px",
              },
            },
            table: {
              style: {
                height: `calc(100vh - 180px - ${height}px)`,
              },
            },
          }}
        />
      }
      </Card>
      {isModal && (
        <ComplianceModal
          complianceData={popContent}
          onClick={() => setModal(false)}
          onBackgroundClick={() => setModal(false)}
        />
      )}
    </div>
  );
};

export default ComplianceList;
