import React, { useEffect, useState } from "react";
import { CustomSelect, Modal, TextInput, ToggleSwitch, DateTimeFormats } from "../../../../components";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../../features/translate/translateSlice";
import Logouts from "../../../../components/logout";
import MD5 from 'crypto-js/md5';

const keyAuth = global.config.typecode[0]+global.config.authBearerKey;
const keyAuth2 = global.config.typecode[1]+global.config.authBearerKey;
const keyAuth3 = global.config.typecode[3]+global.config.authBearerKey;
const headersParams = global.config.headersparam;

function randomString(length) {
  return Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1);
}

const EditUsersModal = ({ onBackgroundClick, onClick, content }) => {
  const translate = useSelector(staticTranslate);
  const chooseLang = useSelector((state) => state.translate.lang);
  const [detaildata, setDetailData] = useState(JSON.parse(content));
  const [listcountry, setListCountry] = useState([]);
  const [listdistributor, setListDistributor] = useState([]);
  const [listalldistributor, setListAllDistributor] = useState([]);
  const [listrole, setListRole] = useState([]);
  const [formDisabled, setFormDisabled] = useState(true);
  const [act, setAct] = useState([]);
  const [isSelect, setSelected] = useState({
    ulevel: "",
    ucountry: "",
    udistributor: "",
  });
  const [formerrors, setFErrors] = useState({
    ufullname: "", 
    uemail: "", 
    ulevel: "", 
    ucountry: "", 
    udistributor: ""
  });

  const validateField = (fieldName, value, ferrors) => {
    switch(fieldName) {
        case 'ufullname': 
            if(value.trim().length > 0) { ferrors = ""; }
            else { ferrors = translate.user_detail.error1; }
            break;
        case 'uemail': 
            if(value.trim().length > 0) { 
              if(ferrors !== "") { ferrors = ferrors; } else { ferrors = "";} 
            } else { ferrors = translate.user_detail.error2; }
            break;
        case 'ulevel': 
            if(value !== undefined && value.length > 0) { ferrors = ""; }
            else { ferrors = translate.user_detail.error3; }
            break;
        case 'ucountry': 
            // if(value > 0) { ferrors = ""; }
            if(value !== undefined && value.length > 0) { ferrors = ""; }
            else { ferrors = translate.user_detail.error4; }
            break;
        case 'udistributor': 
            if(value > 0) { ferrors = ""; }
            else { ferrors = translate.user_detail.error5; }
            break;
        default:
            break;
    }
    setFErrors(prevState => ({ ...prevState, [fieldName]: ferrors }));
  }
  const handleToogle = (stat) => {
    setDetailData({ ...detaildata, ustatus: !stat });
  }
  const handleUserInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setDetailData({ ...detaildata, [name]: value });
    validateField(name, value, "");
  }
  const handleUserSelect =  (e, sel) => {
    var name = sel.name;
    var value = e.value; if(value === undefined) { value = e; }
    if(name === "ulevel") {
      if(value === "dt") {
        setDetailData({ ...detaildata, "ulevel": value, "ucountry": "", "udistributor": 0});
        setSelected({ ...isSelect, "ulevel": e , "ucountry": "", "udistributor": "" });
        document.getElementById("country-div").style.display = "block";
        document.getElementById("distributor-subdiv").style.display = "block";
      } else if(value === "cm") {
        setDetailData({ ...detaildata, "ulevel": value, "ucountry": "", "udistributor": 0});
        setSelected({ ...isSelect, "ulevel": e , "ucountry": "", "udistributor": "" });
        document.getElementById("country-div").style.display = "block";
        document.getElementById("distributor-subdiv").style.display = "none";
      } else {
        setDetailData({ ...detaildata, "ulevel": value, "ucountry": "", "udistributor": 0});
        setSelected({ ...isSelect, "ulevel": e , "ucountry": "", "udistributor": "" });
        document.getElementById("country-div").style.display = "none";
        document.getElementById("distributor-subdiv").style.display = "none";
      }
    } else if(name === "ucountry") {
      if(detaildata.ulevel === "dt") {
        // let [result3] = await Promise.all([loadDistributor(value)]);
        // setListDistributor(result3);
        if(listalldistributor.length > 0) { 
          var resultDistributor=[];
          listalldistributor.forEach(seldist => {
              var userCountryVal = value;
              for(var c=0; c<userCountryVal.length; c++) {
                if(seldist?.countryid === userCountryVal[c].value) {
                  resultDistributor.push(seldist);
                }
              }  
          })
          setListDistributor(resultDistributor);
        } else {
          setListDistributor(listalldistributor);
        }
        // if(listalldistributor.length > 0) { 
        //   let result3 = listalldistributor?.filter(
        //     (dist) =>  dist?.countryid === value
        //   );
        //   setListDistributor(result3);
        // } else {
        //   setListDistributor(listalldistributor);
        // }
        
        setDetailData({ ...detaildata, [name]: value, "udistributor": 0 });
        setSelected({ ...isSelect, [name]: e , "udistributor": "" });
        document.getElementById("distributor-subdiv").style.display = "block";
      } else {
        setDetailData({ ...detaildata, [name]: value });
        setSelected({ ...isSelect, [name]: e });
      }
    } else {
      setDetailData({ ...detaildata, [name]: value });
      setSelected({ ...isSelect, [name]: e });
    }
    
    validateField(name, value, "");
  }
  

  const insUserDB = (sendText) => {
        var urlFetch = global.config.urlLink;
        if(act==="Edit") { 
          urlFetch += "/editusers";
          sendText += ',"from_id":"edit-user"';
        } else if(act==="Add") {
          urlFetch += "/addusers";
          sendText += ',"from_id":"add-user"';
        }
        sendText += ',"lang": "'+chooseLang+'"';
        var datatext = '{'+sendText+'}';
        
        var data = JSON.parse(datatext); 
        headersParams.Authorization = keyAuth;
        fetch(urlFetch, {
          method: 'POST',
          headers:headersParams, 
          body : JSON.stringify(data)
          // body: datatext
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJson => {
          document.getElementById("loading-screen").style.display = "none";
          window.location.href='/admin/users';
          return resJson;
        }).catch((err) => {
            return "Error";
        });    
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    var stat = true;
    var userFullname = detaildata.ufullname;
    var userEmail = detaildata.uemail;
    var userPhone = detaildata.uphone;
    var userPosition = detaildata.uposition;
    var userLevel = detaildata.ulevel;
    var userCountry = detaildata.ucountry;
    var userCountryOld = detaildata.ucountry_old;
    var userDistributor = detaildata.udistributor;
    
    if(userFullname === "" || userEmail === "" || userLevel === "") { stat = false; }
    else {
      if(userLevel === "cm" && userCountry === "") { stat = false; }
      else if(userLevel === "dt" && (userCountry === "" || userDistributor === "" || userDistributor === 0)) { stat = false; }
    }
    
    var formField = ['ufullname','uemail','ulevel'];
    // if(userLevel === "sa" || userLevel === "ad") { userCountry = 0; userDistributor = 0; }
    // else if(userLevel === "cm") { userDistributor = 0; formField.push('ucountry');}
    // else if(userLevel === "dt") { formField.push('ucountry','udistributor'); }
    
    if(userLevel === "sa" || userLevel === "ad") { userCountry = ""; userDistributor = 0; }
    else if(userLevel === "cm") { userDistributor = 0; formField.push('ucountry');}
    else if(userLevel === "dt") { formField.push('ucountry','udistributor'); }
    
    if(stat === true) {
      document.getElementById("loading-screen").style.display = "flex";
      var datatext ='"doc_id":"'+detaildata.docid+'","u_fullname": "'+userFullname+'","u_email": "'+userEmail+'","u_phone": "'+userPhone+'","u_position": "'+userPosition+'","u_level": "'+userLevel+'","u_country": '+JSON.stringify(userCountry)+',"u_distributor": "'+userDistributor+'","u_status": "'+detaildata.ustatus+'"';
      if(userCountryOld === "") { datatext += ',"u_country_old": "'+userCountryOld+'"'; }
      else { datatext += ',"u_country_old": '+userCountryOld; }
    
      if(act==="Add") {
        var passcreated = randomString(10);
        var userKey = MD5(userEmail+passcreated);
        datatext += ',"u_key":"'+userKey+'","u_pass": "'+passcreated+'"';
        datatext += ',"from_id":"cek-user"';
        
        var urlFetch2 = global.config.urlLink+"/cekuser";
        headersParams.Authorization = keyAuth;
        fetch(urlFetch2, {
          method: 'POST',
          headers:headersParams, 
          body : '{'+datatext+'}'
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJson2 => {
          if(resJson2.status === "200" && resJson2.values.length === 0) {
            insUserDB(datatext);
          } else {
            var errmessage = "email has already been used";
            validateField("uemail", userEmail, errmessage); 
            document.getElementById("loading-screen").style.display = "none";
          }
        }).catch((error) => {
          var errmessage = "email has already been used";
          validateField("uemail", userEmail, errmessage); 
          document.getElementById("loading-screen").style.display = "none";
        });          

      } else if(act==="Edit") {
         insUserDB(datatext);
      }
    } else {
      for ( var i = 0; i < formField.length; i++ ) { 
        var fieldname = formField[i];
        var fieldvalue = detaildata[fieldname];
          validateField(fieldname, fieldvalue, ""); 
      }
    }
  }
  const getListId = (list,lid,name) => {
    if(list.length > 0) { 
      if(name=== "ucountry") {
        setSelected((prevState) => ({
          ...prevState,
          [name]: JSON.parse(lid),
        }));
        setDetailData((prevState) => ({
          ...prevState,
          [name]: JSON.parse(lid),
        }));
  
      } else {
        let updListId = list?.filter(
          (listed) => listed?.value === lid
        );
        setSelected((prevState) => ({
          ...prevState,
          [name]: updListId[0],
        }));
        setDetailData((prevState) => ({
          ...prevState,
          [name]: lid,
        }));  
      }

    }
  };
  const loadUser = (id,pathurl) => {
    return new Promise(resolve => {
      var datatext ='{';
      datatext += '"from_id":"detail-user"';
      datatext += ',"doc_id":"'+id+'"';
      if(pathurl !== "Edit" && pathurl !== "Add") { datatext += ',"lang":"'+chooseLang+'"'; }
      datatext +='}';
      var data = JSON.parse(datatext); 
      headersParams.Authorization = keyAuth;
      fetch(global.config.urlLink+"/detailusers", {
        method: 'POST',
        headers: headersParams, 
        body : JSON.stringify(data)
      }).then(res => {
          if(res.status === 200) 
              return res.json() 
      }).then( resJson => {
          if(resJson.status === "200") {
            resolve(resJson.values);
          } else if(resJson.status === global.config.errcodesess) {
            resolve([resJson.status]);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadCountry = (pathurl) => {
    return new Promise(resolve => {
      var datatext ='{';
      datatext += '"from_id":"list-country"';
      datatext += ',"stat_id":1';
      datatext += ',"lang":"'+chooseLang+'"';
      // if(pathurl !== "Edit" && pathurl !== "Add") {  datatext += ',"lang":"'+chooseLang+'"'; }
      datatext += '}';
      var data = JSON.parse(datatext); 
      headersParams.Authorization = keyAuth2;
      fetch(global.config.urlLink+"/listcountry", {
        method: 'POST',
        headers: headersParams, 
        body : JSON.stringify(data)
      }).then(res => {
          if(res.status === 200) 
              return res.json() 
      }).then( resJson => {
          if(resJson.status === "200") {
            var updCountry = { };
            if(resJson.values.length > 0) {
              updCountry = resJson.values.map((country) => ({
                value: country.country_id,
                label: country.country_name,
              }));
            }
            resolve(updCountry);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  }
  const loadDistributor = (countryid,pathurl) => {
    return new Promise(resolve => {
      var datatext ='{'
      datatext += '"from_id":"list-distributor"';
      datatext += ',"stat_id":1';
      if(pathurl !== "Edit" && pathurl !== "Add") {  datatext += ',"lang": "'+chooseLang+'"'; }
      if(countryid !== 0 && countryid !== "") { datatext += ',"country_id":"'+countryid+'"'; }
      datatext += "}";
      var data = JSON.parse(datatext); 
      headersParams.Authorization = keyAuth3;
      fetch(global.config.urlLink+"/listdist", {
        method: 'POST',
        headers: headersParams, 
        body : JSON.stringify(data)
      }).then(res => {
          if(res.status === 200) 
              return res.json() 
      }).then( resJson => {
          if(resJson.status === "200") {
            var updDistributor = { };
            if(resJson.values.length > 0) {
              updDistributor = resJson.values.map((dist) => ({
                value: dist.distributor_id,
                label: dist.distributor_name,
                countryid: dist.id_country
              }));
            }
            resolve(updDistributor);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  }
  const loadData = async (id,pathurl) => {
    document.getElementById("loading-screen").style.display = "flex";
    var roleList = [
      // { value: "sa", label: translate.user_role.superadmin },
      // { value: "ad", label: translate.user_role.admin },
      // { value: "cm", label: translate.user_role.manager },
      // { value: "dt", label: translate.user_role.user },
    ];
    if(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1]) {
      roleList.push({ value: "ad", label: translate.user_role.admin });
      roleList.push({ value: "cm", label: translate.user_role.manager });
    }
    roleList.push({ value: "dt", label: translate.user_role.user });
    
    var usrlevel = "",countryval="",distributorid="",joindate="",lastlogin="",createdby="",createdon="";
    var usrstatus = true;
    var datauserstr = {};
    if(id !== undefined) {
      let [result1, result2, result3] = await Promise.all([loadCountry(pathurl), loadUser(id,pathurl), loadDistributor(0,pathurl)]);
      if(result2.length > 0) {
        if(result2[0] === global.config.errcodesess) {
          var resultSO = await Logouts();
          if(resultSO.status === "200") {
            window.location.reload();
          }
        } else {
            
          usrlevel = result2[0].user_level;
          // countryid = result2[0].user_country;
          if(result2[0].user_country !== null) { countryval = JSON.parse(result2[0].user_country); }
          distributorid = result2[0].user_distributor;
          if(result2[0].user_status === null) { usrstatus = false; } else { usrstatus = result2[0].user_status; }

          if(result2[0].joined_date !== null) {  joindate = <DateTimeFormats datez = {new Date(result2[0].joined_date)} type={"datetime-short"} />; }
          if(result2[0].last_login !== null) { lastlogin = <DateTimeFormats datez = {new Date(result2[0].last_login)} type={"datetime-short"} /> }
          if(result2[0].created_date !== null) { createdon = <DateTimeFormats datez = {new Date(result2[0].created_date)} type={"date-short"} /> }
          if(result2[0].created_name !== null && result2[0].created_name !== undefined) { createdby = result2[0].created_name; }

          if(usrlevel === "dt") { 
            // let [result3a] = await Promise.all([loadDistributor(result2[0].user_country)]); 
            if(result3.length > 0) {
              // let result3a = result3?.filter(
              //   (dist) =>  dist?.countryid === countryid
              // );
              var result3a = [];
              if(countryval !== null && countryval !== "") {
                result3.forEach(dist => {
                    // var userCountryVal = JSON.parse(countryval);
                    for(var c=0; c<countryval.length; c++) {
                      if(dist?.countryid === countryval[c].value) {
                        result3a.push(dist);
                      }
                    }  
                })
              } else {
                result3a = result3;
              }
              setListDistributor(result3a);
              getListId(result3a,distributorid,"udistributor");
            } else { 
              setListDistributor(result3); 
              getListId(result3,distributorid,"udistributor");
            }
            setListAllDistributor(result3);
            
          } else {
            setListDistributor(result3);
            setListAllDistributor(result3);
            getListId(result3,distributorid,"udistributor");
          }
          datauserstr = {
            "docid": result2[0].user_id,
            "ufullname":result2[0].user_fullname,
            "uemail":result2[0].user_email,
            "uphone":result2[0].user_phone,
            "uposition":result2[0].user_position,
            "ucountry":countryval[0],
            "udistributor":distributorid,
            "ulevel":usrlevel,
            "joindate": joindate,
            "lastlogin": lastlogin,
            "ustatus": usrstatus,
            "ucountry_old": JSON.stringify(countryval),
            "createdby": createdby,
            "createdon": createdon 
          };
        }
      } else {
        datauserstr = {
          "docid": 0,
          "ufullname": "",
          "uemail": "",
          "uphone": "",
          "uposition": "",
          "ucountry": "",
          "udistributor": "",
          "ulevel":usrlevel,
          "joindate":"",
          "lastlogin":"",
          "ustatus": usrstatus,
          "ucountry_old":"",
          "createdby": "",
          "createdon": "" 
        };
        setListDistributor(result3);
        setListAllDistributor(result3);
        getListId(result3,distributorid,"udistributor");
      }
      setDetailData(datauserstr);
      setListCountry(result1);
      // getListId(result1,countryid,"ucountry");
      if (countryval !== null && countryval.length > 0) { getListId(result1,JSON.stringify(countryval),"ucountry"); }
      
    } else {
      let [result1,result3] = await Promise.all([loadCountry(pathurl), loadDistributor(0,pathurl)]);
      setListDistributor(result3);
      setListAllDistributor(result3);
      setListCountry(result1);
      if (countryval !== null && countryval.length > 0) { getListId(result1,JSON.stringify(countryval),"ucountry"); }
    }
    setListRole(roleList);
    getListId(roleList,usrlevel,"ulevel");
    
    
    if(usrlevel === "cm") {
      document.getElementById("country-div").style.display = "block";
    } else if(usrlevel === "dt") {
      document.getElementById("country-div").style.display = "block";
      document.getElementById("distributor-subdiv").style.display = "block";
    } else { 
      document.getElementById("country-div").style.display = "none";
    }
    document.getElementById("loading-screen").style.display = "none";
  }
  useEffect(() => {
    var inpdisabled = true; 
    var pathurl = detaildata.type;
    var docid = detaildata.data.user_id;
    if(pathurl === "Edit" || pathurl === "Add") {  
      inpdisabled = false; 
      document.getElementById("submit-bt").style.display = "block";
    } else {  
      inpdisabled = true; 
      document.getElementById("submit-bt").style.display = "none";
    }
    setAct(pathurl);
    setFormDisabled(inpdisabled);
    loadData(docid,pathurl);
    
  }, []);

  return (
    <Modal
      title={act === "Add" ? translate.user_detail.title1 : act === "Edit" ? translate.user_detail.title2 : translate.user_detail.title3}       
      onBackgroundClick={onBackgroundClick}
      onClick={onClick}
      content={content}
      className=" overflow-y-auto"
    >
      <form id="frm" onSubmit={handleSubmit}>
        <TextInput
            variant="base"
            // title="Full Name"
            title={translate.user_detail.text_input1}
            name="ufullname"
            id="ufullname"
            value={detaildata.ufullname}
            onChange={handleUserInput}
            disabled={formDisabled}
            errmsg={formerrors.ufullname}
          />
        <TextInput
            variant="base"
            // title="Phone"
            title={translate.user_detail.text_input2}
            name="uphone"
            id="uphone"
            value={detaildata.uphone}
            onChange={handleUserInput}
            disabled={formDisabled}
          />
        <TextInput
            variant="base"
            // title="Poisition"
            title={translate.user_detail.text_input3}
            name="uposition"
            id="uposition"
            value={detaildata.uposition}
            onChange={handleUserInput}
            disabled={formDisabled}
          />
      
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
        <TextInput
              variant="base"
              // title="Email"
              title={translate.user_detail.text_input4}
              name="uemail"
              id="uemail"
              value={detaildata.uemail}
              onChange={handleUserInput}
              disabled={(act === "Add")? false : true}
              errmsg={formerrors.uemail}
            />
          <CustomSelect
            // title="Role"
            title={translate.user_detail.text_input5}
            name="ulevel"
            id="ulevel"
            options={listrole}
            value={isSelect.ulevel}
            onChange={handleUserSelect}
            disabled={formDisabled}
            errmsg={formerrors.ulevel}
          />
      </div>
      <div id="country-div" className="hidden">
        <div  className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4"  >
          <div>
            <CustomSelect
                isMulti
                // title="Country"
                title={translate.user_detail.text_input6}
                name="ucountry"
                id="ucountry"
                options={listcountry}
                value={isSelect.ucountry}
                onChange={handleUserSelect}
                disabled={formDisabled}
                errmsg={formerrors.ucountry}
              />
          </div>
          <div id="distributor-subdiv" className="hidden">
            <CustomSelect
                // title="Distributor"
                title={translate.user_detail.text_input7}
                name="udistributor"
                options={listdistributor}
                value={isSelect.udistributor}
                onChange={handleUserSelect}
                disabled={formDisabled}
                errmsg={formerrors.udistributor}
              />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4">
        <div className="col-start-1 col-end-2">
          <div>
            <h2 className="text-sm">{translate.user_detail.label1}</h2>
            <div className="flex items-center gap-4">
              <label className="text-xs">{global.config.generalstatus[1]}</label>
              <ToggleSwitch id="ustatus"
                checked={detaildata.ustatus}
                onClick={() => handleToogle(detaildata.ustatus)} />
              <label className="text-xs">{global.config.generalstatus[0]}</label>
            </div>
          </div>
        </div>
        <div className="col-start-2 col-end-3">
          {(detaildata.createdon !== null && detaildata.createdon !== "") && 
          <div>
              <h2 className="text-sm">{translate.user_detail.label4}</h2>
              <label className="text-xs">{detaildata.createdby}</label>
          </div>
          }
        </div>
        <div className="col-start-3 col-end-4">
          {(detaildata.joindate !== null && detaildata.joindate !== "") &&
          <>
              <h2 className="text-sm">{translate.user_detail.label2}</h2>
              <label className="text-xs">{detaildata.joindate}</label>
          </>}
        </div>
        <div className="col-start-4 col-end-5">
          {(detaildata.lastlogin !== null && detaildata.lastlogin !== "") &&
          <>
              <h2 className="text-sm">{translate.user_detail.label3}</h2>
              <label className="text-xs">{detaildata.lastlogin}</label>
              
          </>
        }
        </div>
      </div>

      <div className="hidden" id="submit-bt">
        <div className="py-3 sm:flex sm:flex-row-reverse">
          <button type="submit" className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">
            {translate.general.button_save}
          </button>
        </div>
      </div>
      </form>
    </Modal>
  );
};

export default EditUsersModal;
