import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, TextInput } from "../../../../components";
import { staticTranslate } from "../../../../features/translate/translateSlice";

const keyAuth = global.config.typecode[17] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const ComplianceModal = ({ onBackgroundClick, onClick, complianceData }) => {
  const translate = useSelector(staticTranslate);
  const chooseLang = useSelector(state => state.translate.lang)
  const { type, data } = complianceData;
  const [formDisabled, setFormDisabled] = useState(true);

  const [formErrors, setFormErrors] = useState({
    remarks: ""
  });
  const [form, setForm] = useState({
    doc_id: "",
    dist_id: "",
    distributor_name: "",
    remarks: "",
    status: "",
    active_year: ""
    
  });
  
  const validateField = (fieldName, value) => {
    let ferrors = "";
    switch (fieldName) {
      case "remarks":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.compliance_list.error1; }
        break;
      default:
        break;
    }
    setFormErrors((prevState) => ({ ...prevState, [fieldName]: ferrors }));
  };
  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    
    if(form.remarks !== "") {
      document.getElementById("loading-screen").style.display = "flex";
      var dataJson = form;
      dataJson.from_id="edit-status-compliance";
      dataJson.lang=chooseLang;
      dataJson.status=type;
      var fetchUrl = global.config.urlLink + "/editcompliance";
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(dataJson),
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then((res) => {
          if (res.status === "200") {
            document.getElementById("loading-screen").style.display = "none";
            window.location.href = "/admin/compliance";
          }
        })
        .catch((err) => { 
          document.getElementById("loading-screen").style.display = "none";
        });
    } else {
      var formField = ["remarks"];
      for ( var i = 0; i < formField.length; i++ ) { 
        var fieldname = formField[i];
        var fieldvalue = form[fieldname];
        validateField(fieldname, fieldvalue); 
      }
    } 
  };
 
  useEffect(() => {
    setForm((prevState) => ({
      ...prevState,
      doc_id: data.compliance_id,
      dist_id: data.id_distributor,
      distributor_name: data.distributor_name,
      remarks: "",
      status: data.status,
      active_year: data.compliance_year
    }));  
   
    setFormDisabled(false);

  }, []);

  return (
    <Modal
      title={`${translate.compliance_list.title_pop + " " + form.active_year}`}
      onBackgroundClick={onBackgroundClick}
      onClick={onClick}
    >
      <div className=" flex flex-col overflow-y-auto justify-between">
        
        <div className=" gap-y-2 gap-x-8 mx-1 mb-8">
          <div className="block text-gray-500 mb-2 p-1">{translate.compliance_list.label1} : {form.distributor_name}</div>
          <div>
            <TextInput
              type="text"
              variant="textarea"
              // title="Remarks"
              title={translate.compliance_list.text_input1}
              name="remarks"
              id="remarks"
              value={form.remarks}
              onChange={onChangeHandler}
              disabled={formDisabled}
              height="135px"
              errmsg={formErrors.remarks}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2">
          <div className="flex items-center gap-x-4">
            
          </div>
          <div className="flex justify-end">
          {!formDisabled && (
            <Button
              type="button"
              variant="base"
              className="bg-red-500 text-white"
              title={translate.general.button_submit}
              onClick={handleSubmit}
            />
          )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ComplianceModal;
