import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, TextInput, CustomSelect } from "../../../components";
import { ICSuccess } from '../../../assets';
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../features/translate/translateSlice";
import Logouts from "../../../components/logout"; 

const keyAuth = global.config.typecode[12] + global.config.authBearerKey;
const keyAuth2 = global.config.typecode[0] + global.config.authBearerKey;
const keyAuth3 = global.config.typecode[3] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const CreateMessage = () => {
  const translate = useSelector(staticTranslate);
  const chooseLang = useSelector((state) => state.translate.lang);
  const navigate = useNavigate();
  const [listDistributor, setListDistributor] = useState([]);
  const [selDistributor, setSelectedDistributor] = useState("");
  const [formErrors, setFormErrors] = useState({
    contact_name: "", 
    contact_email: "",
    contact_message: "",
  });
  const [form, setForm] = useState({
    contact_name: "",
    contact_email: "",
    contact_phone: "",
    contact_message: "",
    distributor_id: "",
    country_id: "",
    from_id: "add-veoliacontact",
    destination_notif: []
  });
  const validateField = (fieldName, value) => {
    let ferrors = "";
    switch (fieldName) {
      case "contact_name":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.contact_us_distributor.error1; }
        break;
      case "contact_email":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.contact_us_distributor.error2; }
        break;
      case "contact_message":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.contact_us_distributor.error3; }
        break;
      default:
        break;
    }
    setFormErrors((prevState) => ({ ...prevState, [fieldName]: ferrors }));
  };
  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };
  // const onChangeSelectHandler= (e) => {
  //   var name = "distributor_id";
  //   var value = e.value;
    
  //   setSelectedDistributor(e);
  //   setForm((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  //   validateField(name, value);
  // };
  const submitMessage = (e) => {
    e.preventDefault();
    // console.log("dist");
    // console.log(form);
    // console.log(form.destination_notif);
    
    if(form.contact_name !== "" && form.contact_email !== "" && form.contact_message !== "") { 
      document.getElementById("loading-screen").style.display = "flex";
      form.from_id = "add-veoliacontact";
      let fetchUrl = global.config.urlLink + "/addvcontact";
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(form),
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then((res) => {
          document.getElementById("smessage").style.display = "block";
          document.getElementById("viewforms").style.display = "none";
          document.getElementById("loading-screen").style.display = "none";
        })
        .catch((err) => {
          console.log(err);
          document.getElementById("loading-screen").style.display = "none";
        });
    } else {
      var formField = [];
      if(form.contact_name === "") { formField.push("contact_name"); }
      if(form.contact_email === "") { formField.push("contact_email"); }
      if(form.contact_message === "") { formField.push("contact_message"); }
      for ( var i = 0; i < formField.length; i++ ) { 
        var fieldname = formField[i];
        var fieldvalue = form[fieldname];
        validateField(fieldname, fieldvalue); 
      }
    }
    
  };
  const getDistributorId = (list,did) => {
    let updIdDistributor = list?.filter(
      (dist) => dist?.value === did
    );
    setSelectedDistributor(updIdDistributor[0]);
    setForm((prevState) => ({
      ...prevState,
      distributor_id: did,
    }));
  };
  const loadListDistributor = (country) => {
    return new Promise((resolve) => {
      var datatext = '{';
      datatext += '"from_id":"list-distributor","stat_id":1';
      datatext += ',"lang": "'+chooseLang+'"';
      if(country !== "") { datatext += ',"country_id":"'+country+'"';; }
      datatext += '}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth3;
      var fetchUrl = global.config.urlLink + "/listdist";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            var resultDist = [{distributor_id: 0, distributor_name: "- All Distributor - "}];
            if(resJson.values.length > 0) {
              resJson.values.map((resdist) => (
                resultDist.push({distributor_id: resdist.distributor_id, distributor_name: resdist.distributor_name})
              ));
            }
            var updDistributor = { };
            if(resultDist.length > 0) {
              updDistributor = resultDist.map((dist) => ({
                value: dist.distributor_id,
                label: dist.distributor_name,
              }));
            } 
            resolve(updDistributor);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadDetailUser = () => {
    return new Promise((resolve) => {
      var datatext = '{"from_id":"detail-profile","doc_id":"'+localStorage.getItem("dutk")+'","lang":"'+chooseLang+'"}';
      var data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth2;
      var fetchUrl = global.config.urlLink + "/detailusers";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          document.getElementById("loading-screen").style.display = "none";
          if (resJson.status === "200") {
            resolve(resJson.values);
          } else if(resJson.status === global.config.errcodesess) {
            resolve([resJson.status]);
          }  else { resolve([]); }
      }).catch((err) => {
          console.log(err);
          // document.getElementById("loading-screen").style.display = "none";
          resolve([]);
      });
    });
  };
  const loadData = async (countryid,distributorid) => {
    document.getElementById("loading-screen").style.display = "flex";
    var datauserstr = {};
      let [result1, result2] = await Promise.all([loadListDistributor(countryid), loadDetailUser()]);
      if(result2.length > 0) {
        if(result2[0] === global.config.errcodesess) { 
          var resultSO = await Logouts();
          if(resultSO.status === "200") {
            window.location.reload();
          }
        } else {
          distributorid = result2[0].user_distributor;
          // countryid = result2[0].user_country;
          // var listCountry = "";
          if(countryid === "") {
            if(result2[0].user_country !== null && result2[0].user_country !== '""' && result2[0].user_country !== "" && JSON.parse(result2[0].user_country).length > 0) {
              var usercountry = JSON.parse(result2[0].user_country);
              usercountry.forEach(country => {
                if(countryid!=="") { countryid += ","; }
                countryid += country.value;
              })
            } else { countryid = result2[0].user_country; }
          }
          
          datauserstr = {
            doc_id: result2[0].user_keyid,
            contact_name: result2[0].user_fullname,
            contact_phone: result2[0].user_phone,
            contact_email: result2[0].user_email,
            contact_message: "",
            // distributor_id: distributorid,
          };
        }
      } else {
        datauserstr = {
          doc_id: 0,
          contact_name: "",
          contact_email: "",
          contact_phone: "",
          contact_message: "",
          // distributor_id: distributorid,
        };
      }
      datauserstr.distributor_id = distributorid;
      datauserstr.country_id = countryid;
      if(localStorage.getItem("dulv")===global.config.conflevel[3]) { 
        datauserstr.destination_notif = ["cm",countryid];
      } else { 
        datauserstr.destination_notif = ["ad",countryid];
      }
      // console.log("datauserstr");
      // console.log(datauserstr);
      setForm(datauserstr);      
      setListDistributor(result1);
      if(distributorid !== "" && distributorid !== 0) { getDistributorId(result1,distributorid); }
      document.getElementById("loading-screen").style.display = "none";
      
  }
  useEffect(() => {
    document.getElementById("smessage").style.display = "none";
    document.getElementById("viewforms").style.display = "block";
    
    var idDist="",idCntry="";
    if(localStorage.getItem("dulv")===global.config.conflevel[3]) { idDist = localStorage.getItem("dudst"); } 
    else if(localStorage.getItem("dulv")===global.config.conflevel[2]) { idCntry = localStorage.getItem("ducr"); }
    loadData(idCntry,idDist);
  }, []);
  return (
    <div className="bg-gray-100 h-screen overflow-y-auto flex-1 p-8">
      <div className="mb-8">
        <p className="text-2xl">{translate.contact_us_distributor.title}</p>
        {/* <p className="text-gray-500 text-sm">
          Contact us to get solution from your problem
        </p> */}
      </div>
      {/* <Card className={"p-8"}> */}
      <div id="smessage"  className="hidden">
        <div className=" p-5 overflow-y-auto flex-1 justify-center items-center flex">
          <div className="md:flex self-center justify-center items-center">
            <img src={ICSuccess} width={80} className="m-auto" alt=""/>
            <div className=" text-ellipsis text-center sm:text-justify ml-8">
              <h2 className=" text-3xl mb-2">{translate.contact_us_distributor.label1}</h2>
              <p className=" text-gray-400">
              {translate.contact_us_distributor.label2}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="viewforms">
        <form id="frm">
          <div className="flex flex-1 justify-end gap-4 mb-8">
            <Button
                type="submit"
                variant="base"
                // title="Send Message"
                title={translate.contact_us_distributor.button1}
                className="bg-red-500 text-white"
                onClick={submitMessage}
              />            
          </div>
          <div>
            <TextInput
              type="text"
              variant="base"
              // title="Name"
              title={translate.contact_us_distributor.text_input1}
              name="contact_name"
              id="contact_name"
              value={form.contact_name}
              onChange={onChangeHandler}
              errmsg={formErrors.contact_name}
            />
            <TextInput
              type="text"
              variant="base"
              // title="Email"
              title={translate.contact_us_distributor.text_input2}
              name="contact_email"
              id="contact_email"
              value={form.contact_email}
              onChange={onChangeHandler}
              errmsg={formErrors.contact_email}
            />
            <TextInput
              type="text"
              variant="base"
              // title="Phone Number"
              title={translate.contact_us_distributor.text_input3}
              name="contact_phone"
              id="contact_phone"
              value={form.contact_phone}
              onChange={onChangeHandler}
            />
            {/* {(localStorage.getItem("dulv")!==global.config.conflevel[3])? 
            <CustomSelect
                // title="Distributor"
                title={translate.contact_us_distributor.text_input5}
                name="distributor_id"
                value={selDistributor}
                options={listDistributor}
                onChange={(e) => onChangeSelectHandler(e)}
                defaultValue={form.distributor_id}
                errmsg={formErrors.distributor_id}
              />
            : ''
            } */}
            <TextInput
              type="text"
              variant="textarea"
              // title="Message"
              title={translate.contact_us_distributor.text_input4}
              name="contact_message"
              id="contact_message"
              value={form.contact_message}
              onChange={onChangeHandler}
              errmsg={formErrors.contact_message}
            />
          </div>
          {/* <div className=" flex justify-end">
            <Button
              variant="base"
              title="Submit"
              onClick={submitMessage}
              className="bg-red-500 text-white"
            />
          </div> */}
        </form>
      </div>
      {/* </Card> */}
    </div>
  );
};

export default CreateMessage;
