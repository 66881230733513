import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
// import { FaEdit, FaTrash, FaInfoCircle } from "react-icons/fa";
import { FaEdit, FaTrash } from "react-icons/fa";
import { AiFillInfoCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useScrollbarSize from "react-scrollbar-size";
import { Button, Card, DateTimeFormats, NumbersFormat, Loading, NoRecords } from "../../../components";
import PieChart from "../../../components/Chart/Pie";
import { staticTranslate } from "../../../features/translate/translateSlice";
import ModalPipelineDetails from "./ModalPipelineDetails";
import Logouts from "../../../components/logout"; 

const keyAuth = global.config.typecode[7] + global.config.authBearerKey;
const keyAuth2 = global.config.typecode[3] + global.config.authBearerKey;
const keyAuth3 = global.config.typecode[10] + global.config.authBearerKey;
const keyAuth4 = global.config.typecode[1] + global.config.authBearerKey;
const keyAuth5 = global.config.typecode[2] + global.config.authBearerKey;
const headersParams = global.config.headersparam;
const yearnow = new Date().getFullYear();

const bgPieCols = [
  "rgba(200,32,38, 1)", // "#C82026", // "rgba(255, 99, 132, 0.2)", // Red (#ff6384)
  "rgba(41,134,204, 1)", // "#2986CC", // "rgba(54, 162, 235, 0.2)", // Blue (#36a2eb)
  "rgba(255,217,102, 1)", // "#FFD966", // "rgba(255, 206, 86, 0.2)", // Yellow (#ffce38)
  "rgba(71,148,37, 1)", // "#479425", // "rgba(75, 192, 192, 0.2)", // Green (#4bc0c0)
  "rgba(142,124,195, 1)", // "#8e7cc3", // "rgba(153, 102, 255, 0.2)", // Purple (#9966ff)
  "rgba(247,194,138, 1)", // "#F7C28A", // "rgba(255, 159, 64, 0.2)", // Orange (#ff9840)
  "rgba(206,126,0, 1)", // "#b45f06", // "rgba(223, 184, 144, 0.2)", // Brown (#dfb890)
  "rgba(188,188,188,1)", // "#bcbcbc", // "rgba(210, 210, 210, 0.2)", // Grey (#d2d2d2)
  
];
const borderPieCols = [
  "rgba(175,28,33, 1)", // "#AF1C21", //"rgba(255, 99, 132, 1)", // Red (#ff6384)
  "rgba(33,104,157, 1)", // "#21689D", // "rgba(54, 162, 235, 1)", // Blue (#36a2eb)
  "rgba(224,191,90, 1)", // "#E0BF5A", // "rgba(255, 206, 86, 1)", // Yellow (#ffce56)
  "rgba(42,87,22, 1)", // "#2a5716", // "rgba(75, 192, 192, 1)", // Green (#4bc0c0)
  "rgba(114,98,162, 1)", // "#7262a2", // "rgba(153, 102, 255, 1)", // Purple (#9966ff)
  "rgba(237,170,99, 1)", // "#edaa63", // "rgba(255, 159, 64, 1)", // Orange (#ff9f40)
  "rgba(148,78,5, 1)", // "#944e05", // "rgba(209, 166, 121, 1)", // Brown (#d1a679)
  "rgba(165,165,165, 1)", // "#A5A5A5", // "rgba(192, 192, 192, 1)", // Grey (#c0c0c0)
];
const bgPieCols2 = [
  "rgba(239,150,189, 1)", // "#ef96bd",  // "rgba(242, 212, 238, 0.2)", // Pink (#f2d4ee)
  "rgba(150,215,239, 1)", // "#96d7ef", // "rgba(121, 196, 209, 0.2)", // Light Blue (#79c4d1)
  "rgba(188,188,188,1)", // "#bcbcbc", // "rgba(210, 210, 210, 0.2)", // Grey (#d2d2d2)
];
const borderPieCols2 = [
  "rgba(247,128,180, 1)", // "#F780B4", // "rgba(231, 178, 224, 1)", // Pink (#e7b2e0)
  "rgba(117,212,247, 1)", // "#75d4f7", // "rgba(123, 200, 214, 1)", // Light Blue (#7bc8d6)
  "rgba(165,165,165, 1)", // "#A5A5A5", // "rgba(192, 192, 192, 1)", // Grey (#c0c0c0)
];
const PipelineList = () => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector((state) => state.translate.lang)
  const navigate = useNavigate();
  const { height } = useScrollbarSize();
  const [pending, setPending] = useState(true);
  const [listPiePipeline, setListPiePipeline] = useState({pipetotaltarget: 0, pipetotal: 0, piperemain: 0, pipepiestatus: {}, pipepietarget: {}, pipepiestatuslegend: {}});
  const [listPipeline, setListPipeline] = useState([]);
  const [listDistributor, setListDistributor] = useState([]);
  const [listMarket, setListMarket] = useState([]);
  const [listProduct, setListProduct] = useState([]);
  const [listCountry, setListCountry] = useState([]);
  const [listProductProject, setListProductProject] = useState([]);
  const [listProductTarget, setListProductTarget] = useState([]);
  const [listProductStatus, setListProductStatus] = useState([]);
  const [filterData, setFilterData] = useState({statid:"",distributorid:"",countryid:"",marketid:"",yearid:"",year2id:"",prjstat:"",productid:"",rowpage:0});
  const [isModal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [defRowPage, setDefRowPage] = useState(0);
  const paramsLoc = useLocation();
  if(paramsLoc.state !== null) { var { dataref } = paramsLoc.state; }

  const handleRowsPerPageChange = (val) => {
    setFilterData({ ...filterData, rowpage: val });
    setDefRowPage(val);
  }
  const handleDelete = (id) => {
    if (window.confirm(`${translate.pipeline.alert_delete}`) === true) {
      document.getElementById("loading-screen").style.display = "flex";
      let fetchUrl = `${global.config.urlLink}/deletepipe/${id}`;
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "GET",
        headers: headersParams,
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then((res) => {
          if (res.status === "200") {
            document.getElementById("loading-screen").style.display = "none";
            window.location.href = "/pipeline";
          }
        })
        .catch((err) => { 
          console.log(err);
          document.getElementById("loading-screen").style.display = "none";
        });
    }
  };
  const handleDetailClick = (resList,type) => {
    navigate("/pipeline/detail", {
      state: { data: resList, type: type, filter: filterData },
    })
  }
  const handlePopUpModal = (listdata,idx,params) => {
    if(idx === 1) {
      var arrListRemain = [];
      var arrListProductProject = listProductProject;
      if(listProductTarget.length > 0) {
        listProductTarget.forEach(lstarget => {
          var cekRemain = listProductProject.find(({ product_id }) => product_id === lstarget.product_id);
          if(cekRemain !== undefined) {
            arrListRemain.push({product_id: lstarget.product_id, product_name: lstarget.product_name, totalRemain: (lstarget.totalTarget - cekRemain.totalProject)});
            arrListProductProject = arrListProductProject.filter(
              (listp) =>  listp.product_id !== lstarget.product_id
            );

          } else {
            arrListRemain.push({product_id: lstarget.product_id, product_name: lstarget.product_name, totalRemain: lstarget.totalTarget});
          }
       });
      }
      if(arrListProductProject.length > 0) {
        arrListProductProject.forEach(lstarget2 => {
          arrListRemain.push({product_id: lstarget2.product_id, product_name: lstarget2.product_name, totalRemain: (0-lstarget2.totalProject)});
        })
      }
      listdata = arrListRemain;
    } else if(idx === -2) {
      var arrListProductStat = [];
      if(listProductStatus.length > 0) {
        arrListProductStat = listProductStatus.filter(
              (listp) =>  listp.project_status === params
            );
      }
      listdata = arrListProductStat;
    }
    let contentParams = {
      index: idx,
      params: params
    };
    contentParams.data = listdata;
    setModalContent(contentParams);
    setModal(!isModal);
  };
  const handleSelect = async (e) => {
    var name = e.target.name;
    var value = e.target.value;
    var sel_statid = filterData.statid;
    var sel_distid = filterData.distributorid;
    var sel_cntryid = filterData.countryid;
    var sel_marketid =filterData.marketid;
    var sel_yearid = filterData.yearid;
    var sel_year2id = filterData.year2id;
    var sel_prjstat=filterData.prjstat;
    var sel_productid =filterData.productid;
    
    if(name === "distributorid") {
      sel_distid = value;
    } else if(name === "marketid") {
      sel_marketid = value;
    } else if(name === "yearid") {
      sel_yearid = value;
    } else if(name === "year2id") {
      sel_year2id = value;
    } else if(name === "prjstat") {
      sel_prjstat = value;
    } else if(name === "productid") {
      sel_productid = value;
    } else if(name === "countryid") {
      sel_cntryid = value;
    }
    let [result1a, /*result2a,*/ result4a, result5a, result6a, result9a] = await Promise.all([
      listPipelineData(sel_statid,sel_distid,sel_cntryid,sel_marketid,sel_yearid,sel_prjstat,sel_productid,sel_year2id),
      // loadDistributor(sel_distid,sel_cntryid), 
      loadPipelinePie(sel_statid,sel_distid,sel_cntryid,sel_marketid,sel_yearid,"pie-in-pipeline",sel_productid,sel_year2id),
      loadPipelinePie(sel_statid,sel_distid,sel_cntryid,sel_marketid,sel_yearid,"pie-in-pipeline-product",sel_productid,sel_year2id),
      loadPipelinePie(sel_statid,sel_distid,sel_cntryid,sel_marketid,sel_yearid,"pie-in-pipeline-product-target",sel_productid,sel_year2id),
      loadPipelinePie(sel_statid,sel_distid,sel_cntryid,sel_marketid,sel_yearid,"pie-in-pipeline-status-product",sel_productid,sel_year2id)
    ]);
    if(result1a.length > 0 && result1a[0] === global.config.errcodesess) { result1a = []; }
    // setListDistributor(result2a);
    pieResult(result4a);
    setListProductProject(result5a);
    setListProductTarget(result6a);
    setListProductStatus(result9a);
    setListPipeline(result1a);
    setFilterData({ ...filterData, [name]: value });
  }

  const pieResultStatus = (res4a) => {
    return new Promise(resolve => {
      var resLabels = [];
      var resDatas = [];
      var resBgCols = [];
      var resBorderCols = [];
      var resLegends = [];
      if(res4a.pipestatus.length > 0) {
        for(var p=0; p<res4a.pipestatus.length; p++) {
          resLabels.push(res4a.pipestatus[p].project_status);
          resDatas.push(res4a.pipestatus[p].alltotalUnits);
          resBgCols.push(bgPieCols[p]);
          resBorderCols.push(borderPieCols[p]);
          resLegends.push({labels: res4a.pipestatus[p].project_status, value: res4a.pipestatus[p].alltotalUnits});
        }
      } else {
        resLabels = [""];
        resDatas = [1];
        resBgCols = [bgPieCols[7]];
        resBorderCols = [borderPieCols[7]];
        resLegends = [
          {labels: global.config.pipelinestatus[0], value: 0},
          {labels: global.config.pipelinestatus[1], value: 0},
          {labels: global.config.pipelinestatus[2], value: 0},
        ];
      }
      var resPieDataStatus = {
        labels: resLabels,
        datasets: [{
          label: translate.pipeline.graph_label5,
          data: resDatas,
          backgroundColor: resBgCols,
          hoverBackgroundColor: resBgCols,
          borderColor: resBorderCols,
          hoverBorderColor: resBorderCols,
          borderWidth: 1
        }]
      };
      resolve([resPieDataStatus,resLegends]);
    })
  }
  const pieResultTarget = (res4b) => {
    return new Promise(resolve => {
      var pipeTotal = 0;
      if(res4b.pipestatus.length > 0) {
        for(var p=0; p<res4b.pipestatus.length; p++) {
          if(res4b.pipestatus[p].project_status === global.config.pipelinestatus[0]) { 
            pipeTotal += res4b.pipestatus[p].alltotalUnits;
          }
        }
      }

      var pipeTotalTarget = 0;
      if(res4b.pipetotaltarget.length > 0 && res4b.pipetotaltarget[0].totalTarget !== null) {
        pipeTotalTarget = res4b.pipetotaltarget[0].totalTarget;
      } 
      var pipeRemain = pipeTotalTarget - pipeTotal;
      var resPieDataTarget = {labels: [], datasets: []};
      if(pipeTotal === 0 && pipeRemain === 0) {
        if(res4b.pipetotaltarget.length > 0 && res4b.pipetotaltarget[0].totalTarget !== null) {
          resPieDataTarget = {
            labels: [translate.pipeline.graph_label2,translate.pipeline.graph_label3],
            datasets: [{
              label: translate.pipeline.graph_label4,
              data: [pipeTotal,1],
              backgroundColor: [bgPieCols2[2]],
              hoverBackgroundColor: [bgPieCols2[2]],
              borderColor: [borderPieCols2[2]],
              hoverBorderColor: [borderPieCols2[2]],
              borderWidth: 1
            }]
          };
        } else {
          resPieDataTarget = {
            labels: [""],
            datasets: [{
              label: [""],
              data: [1],
              backgroundColor: [bgPieCols2[2]],
              hoverBackgroundColor: [bgPieCols2[2]],
              borderColor: [borderPieCols2[2]],
              hoverBorderColor: [borderPieCols2[2]],
              borderWidth: 1
            }]
          };
        }
      } else {
        resPieDataTarget = {
          labels: [translate.pipeline.graph_label2,translate.pipeline.graph_label3],
          datasets: [{
            label: translate.pipeline.graph_label4,
            data: [pipeTotal,pipeRemain],
            backgroundColor: [bgPieCols2[0],bgPieCols2[1]],
            hoverBackgroundColor: [bgPieCols2[0],bgPieCols2[1]],
            borderColor: [borderPieCols2[0],borderPieCols2[1]],
            hoverBorderColor: [borderPieCols2[0],borderPieCols2[1]],
            borderWidth: 1
          }]
        };
      }
      resolve([resPieDataTarget,pipeTotalTarget,pipeTotal,pipeRemain]);
    })
  }
  const pieResult = async (result4) => {
    
    let [respie4a, respie4b] = await Promise.all([pieResultStatus(result4), pieResultTarget(result4)]);
    setListPiePipeline({
      pipetotaltarget: respie4b[1], 
      pipetotal: respie4b[2],
      piperemain: respie4b[3],
      pipepiestatus: respie4a[0],
      pipepietarget: respie4b[0],
      pipepiestatuslegend: respie4a[1],
    });
  }
  const loadPipelinePie = (stat,distributor,country,market,years,fromid,product,years2) => {
    return new Promise(resolve => {
      var datatext ='{';
      // datatext += '"from_id":"pie-in-pipeline"';
      datatext += '"from_id":"'+fromid+'"';
      datatext += ',"lang":"'+chooseLang+'"';
      if(stat !== "") { datatext += ',"status_id":'+parseInt(stat); }
      if(distributor !== "") { datatext += ',"dist_id":"'+distributor+'"'; }
      if(country !== "") { datatext += ',"country_id":"'+country+'"'; }
      if(market !== "") { datatext += ',"market_id":"'+market+'"'; }
      if(years !== "") { datatext += ',"year_id":"'+years+'"'; }
      if(years2 !== "") { datatext += ',"year2_id":"'+years2+'"'; }
      if(product !== "") { datatext += ',"product_id":"'+product+'"'; }
      datatext += '}';
      var data = JSON.parse(datatext); 
      headersParams.Authorization = keyAuth;
      fetch(global.config.urlLink+"/listanalysis", {
        method: 'POST',
        headers: headersParams, 
        body : JSON.stringify(data)
      }).then(res => {
          if(res.status === 200) 
              return res.json() 
      }).then( resJson => {
          if(resJson.status === "200") {
            resolve(resJson.values);
          } else {
            console.log('Something happened wrong 2');
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  }
  const loadCountry = (distributor) => {
    return new Promise(resolve => {
        var datatext ='{';
        datatext += '"from_id":"list-country","stat_id":1';
        if(distributor !== "") { datatext += ',"dist_id":"'+distributor+'"'; }
        datatext += ',"lang":"'+chooseLang+'"';
        datatext += '}';
        var data = JSON.parse(datatext); 
        headersParams.Authorization = keyAuth4;
        fetch(global.config.urlLink+"/listcountry", {
          method: 'POST',
          headers: headersParams, 
          body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJson => {
            if(resJson.status === "200") {
              resolve(resJson.values);
            } else {
              resolve([]);
            }
        }).catch((err) => {
            resolve([]);
        });
    });
  }
  const loadProduct = () => {
    return new Promise(resolve => {
        var datatext ='{';
        datatext += '"from_id":"list-all-product","stat_id":1';
        datatext += ',"lang":"'+chooseLang+'"';
        datatext += '}';
        var data = JSON.parse(datatext); 
        headersParams.Authorization = keyAuth5;
        fetch(global.config.urlLink+"/listproduct", {
          method: 'POST',
          headers: headersParams, 
          body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJson => {
            if(resJson.status === "200") {
              resolve(resJson.values);
            } else {
              resolve([]);
            }
        }).catch((err) => {
            resolve([]);
        });
    });
  }
  const loadMarket = () => {
    return new Promise(resolve => {
        var datatext ='{';
        datatext += '"from_id":"list-market","stat_id":1';
        datatext += ',"lang":"'+chooseLang+'"';
        datatext += '}';
        var data = JSON.parse(datatext); 
        headersParams.Authorization = keyAuth3;
        fetch(global.config.urlLink+"/listmarket", {
          method: 'POST',
          headers: headersParams, 
          body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJson => {
            if(resJson.status === "200") {
              resolve(resJson.values);
            } else {
              resolve([]);
            }
        }).catch((err) => {
            resolve([]);
        });
    });
  }
  const loadDistributor = (distributor,country) => {
    return new Promise(resolve => {
      if(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2]) {
        var datatext ='{';
        datatext += '"from_id":"list-distributor","stat_id":1';
        datatext += ',"lang":"'+chooseLang+'"';
        if(country !== "") { datatext += ',"country_id":"'+country+'"'; }
        datatext += '}';
        var data = JSON.parse(datatext); 
        headersParams.Authorization = keyAuth2;
        fetch(global.config.urlLink+"/listdist", {
          method: 'POST',
          headers: headersParams, 
          body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJson => {
            if(resJson.status === "200") {
              resolve(resJson.values);
            } else {
              resolve([]);
            }
        }).catch((err) => {
            resolve([]);
        });
      } else { 
        if(distributor !== "") {
          // var datatext2 = '{"from_id":"detail-distributor","doc_id":'+distributor+',"lang":"'+chooseLang+'"}';
          // var data2 = JSON.parse(datatext2);
          var data2 = {
            from_id:"detail-distributor",
            doc_id: distributor,
            lang: chooseLang
          }
          headersParams.Authorization = keyAuth2;
          var fetchUrl2 = global.config.urlLink + "/detaildist";
          fetch(fetchUrl2, {
            method: "POST",
            headers: headersParams,
            body: JSON.stringify(data2),
          }).then((res2) => {
              if (res2.status === 200) return res2.json();
          }).then((resJson2) => {
              if (resJson2.status === "200") {
                resolve(resJson2.values);
              } else {
                resolve([]);
              }
          }).catch((err) => {
              resolve([]);
          });
        } else { resolve([]); }
      }
    });
  }
  const listPipelineData = (stat,distributor,country,market,years,prjstat,product,years2) => {
    return new Promise(resolve => {
      var fetchUrl = global.config.urlLink + "/listpipe";
      var dataText = '{';
      dataText += '"from_id": "list-pipeline"';
      dataText += ',"lang":"'+chooseLang+'"';
      if(stat !== "") { dataText += ',"stat_id":'+parseInt(stat); }
      if(distributor !== "") { dataText += ',"dist_id":"'+distributor+'"'; }
      if(country !== "") { dataText += ',"country_id":"'+country+'"'; }
      if(market !== "") { dataText += ',"market_id":"'+market+'"'; }
      if(years !== "") { dataText += ',"year_id":"'+years+'"'; }
      if(years2 !== "") { dataText += ',"year2_id":"'+years2+'"'; }
      if(prjstat !== "") { dataText += ',"prjstat_id":"'+prjstat+'"'; }
      if(product !== "") { dataText += ',"product_id":"'+product+'"'; }
      dataText += '}';
      let data = JSON.parse(dataText);
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((res) => {
          if (res.status === "200") {
            resolve(res.values);
          } else if(res.status === global.config.errcodesess) {
            resolve([res.status]);
          } else { resolve([])}
      }).catch((err) => {
        resolve([]);
      });
    });
  };
  const loadData = async () => {
    var idDist = "", idCntry="",idyears="",id2years="", idPrjStat="", idMarket="", idStat="",idProduct="", idRowPage=defRowPage;
    if(localStorage.getItem("dulv")===global.config.conflevel[3]) { idDist = localStorage.getItem("dudst"); } 
    else if(localStorage.getItem("dulv")===global.config.conflevel[2]) { idCntry = localStorage.getItem("ducr"); }
    if(dataref !== undefined) { 
      idDist = dataref.distributorid; 
      idCntry=dataref.countryid; 
      idyears=dataref.yearid; 
      id2years=dataref.year2id; 
      idPrjStat=dataref.prjstat; 
      idMarket=dataref.marketid; 
      idStat=dataref.statid; 
      idProduct=dataref.productid; 
      idRowPage=dataref.rowpage;
    } else {
      idRowPage = 10;
    }
    
    let [result1, result2, result3, result4, result5, result6, result7, result8, result9] = await Promise.all([
      listPipelineData(idStat,idDist,idCntry,idMarket,idyears,idPrjStat,idProduct,id2years), 
      loadDistributor(idDist,idCntry), 
      loadMarket(), 
      loadPipelinePie(idStat,idDist,idCntry,idMarket,idyears,"pie-in-pipeline",idProduct,id2years), 
      loadPipelinePie(idStat,idDist,idCntry,idMarket,idyears,"pie-in-pipeline-product",idProduct,id2years),
      loadPipelinePie(idStat,idDist,idCntry,idMarket,idyears,"pie-in-pipeline-product-target",idProduct,id2years),
      loadProduct(),
      loadCountry(idDist),
      loadPipelinePie(idStat,idDist,idCntry,idMarket,idyears,"pie-in-pipeline-status-product",idProduct,id2years)
    ]);
    if(result1.length > 0 && result1[0] === global.config.errcodesess) { 
      var resultSO = await Logouts();
      if(resultSO.status === "200") {
        window.location.reload();
      }
    } else {
      if(localStorage.getItem("dulv")===global.config.conflevel[3]) { 
        if(result2.length > 0) {
          var market_application_focus = JSON.parse(result2[0].market_application_focus);
          var listOptMarket = [];
          if(market_application_focus.length > 0) {
            market_application_focus.forEach(item => {
              listOptMarket.push({ market_id: item.value, market_name: item.label });
              
            })
          }
          var filterMarketOthers = result3.filter(
            (mr) => mr.market_name === "Others"
          );
          if(filterMarketOthers.length > 0) {
            listOptMarket.push({
              market_id: filterMarketOthers[0].market_id,
              market_name: filterMarketOthers[0].market_name,
            })
          }
          result3 = listOptMarket;
          /* ===================== */
          var product_application = JSON.parse(result2[0].product_application);
          var listOptProduct = [];
          if(product_application.length > 0) {
            product_application.forEach(item2 => {
              listOptProduct.push({ product_id: item2.value, product_name: item2.label });
              
            })
          }
          var filterProductOthers = result7.filter(
            (pr) => pr.product_name === "Others"
          );
          if(filterProductOthers.length > 0) {
            listOptProduct.push({
              product_id: filterProductOthers[0].product_id,
              product_name: filterProductOthers[0].product_name,
            })
          }
          result7 = listOptProduct;
        }
      } 
      setListMarket(result3);
      setListProduct(result7);
      setListCountry(result8);
      setListPipeline(result1);
      setListDistributor(result2);
      // setFilterData({ ...filterData, distributorid: idDist, countryid: idCntry, yearid: idyears });
      setFilterData({statid: idStat, distributorid: idDist, countryid: idCntry, marketid: idMarket, yearid: idyears, year2id: id2years, prjstat: idPrjStat, productid: idProduct, rowpage: idRowPage});
      pieResult(result4);
      setListProductProject(result5);
      setListProductTarget(result6);
      setListProductStatus(result9);
      setPending(false); 
      setDefRowPage(idRowPage);
    }
  }
  const PriceCols = (row) => (
    <div className="flex flex-1">
      <div className="mr-2">{row.project_currency}</div>
      <div><NumbersFormat num={row.project_selling_price} type={"floats"} /></div>
    </div>
  );
  const ActionCols = (row) => (
    <div className="flex flex-1 gap-4 hover:fill-red-500">
      {/* <FaInfo 
        onClick={() => handleDetailClick(row,"detail")}
        className="hover:fill-red-500 cursor-pointer fasize"
      /> */}
      {(localStorage.getItem("dulv")===global.config.conflevel[0] || 
        localStorage.getItem("dulv")===global.config.conflevel[1] || 
        localStorage.getItem("dulv")===global.config.conflevel[2] || 
        (localStorage.getItem("dulv")===global.config.conflevel[3] /*&& localStorage.getItem("dutk") === row.created_by*/)
       )?
      <div className="flex flex-1 gap-4 hover:fill-red-500">
        <FaEdit
          onClick={() => handleDetailClick(row,"edit")}
          className="hover:fill-red-500 cursor-pointer fasize"
        />
        <FaTrash
          onClick={() => handleDelete(row.pipeline_id)}
          className="hover:fill-red-500 cursor-pointer fasize"
        />
      </div>
      : ''
      }
    </div>
  );
  // const poDate = (row) => (
  //   <div>
  //     {
  //       (row.project_po_date !== null)? <DateTimeFormats datez = {new Date(row.project_po_date)} type={"date-short"} /> : ''
  //     }
  //   </div>
  // );
  const columns = [
    { name: translate.pipeline.column_1, selector: (row) => row.project_name, grow: 2, wrap: true },
    { name: translate.pipeline.column_2, selector: (row) => row.project_client, grow: 2, wrap: true },
    { name: translate.pipeline.column_3, selector: (row) => row.market_name, grow: 2, wrap: true  },
    { name: translate.pipeline.column_4, selector: (row) => row.product_name, wrap: true },
    // { name: translate.pipeline.column_4, selector: (row) => row.project_application, wrap: true },
    // { name: translate.pipeline.column_5, selector: (row) => row.project_model, wrap: true },
    { name: translate.pipeline.column_9, width: "70px", selector: (row) => <NumbersFormat num={row.project_qty} type={"floats"} />, wrap: true },
    { name: translate.pipeline.column_6, width: "140px", cell: PriceCols },
    // { name: translate.pipeline.column_6, width: "70px", selector: (row) => row.project_currency, wrap: true },
    // { name: "", width: "120px", selector: (row) => <NumbersFormat num={row.project_selling_price} type={"floats"} /> },
    // { name: translate.pipeline.column_7, width: "120px", selector: (row) =>  <DateTimeFormats datez = {new Date(row.created_date)} type={"date-short"} />},
    // { name: translate.pipeline.column_8, cell: poDate},
    { name: translate.pipeline.column_7,  width: "100px", selector: (row) => row.project_probability, wrap: true },
    { name: translate.pipeline.column_10,  width: "120px", selector: (row) => ((row.project_expected_close_date !== null && row.project_expected_close_date !== "") && <DateTimeFormats datez = {new Date(row.project_expected_close_date)} type={"yearmonth-short"} />), wrap: true },
    { name: translate.pipeline.column_8, width: "100px", selector: (row) => row.project_status },
  ];
  // if(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[3]) {
  columns.push({ name: translate.general.column_action, width: "100px", cell: ActionCols })
  // }

  useEffect(() => {
    loadData();
  }, []);

  var optDistributor = [];
  if(listDistributor !== undefined) {
    for(var c=0; c<listDistributor.length; c++) {
      optDistributor.push(<option value={listDistributor[c].distributor_id} key={listDistributor[c].distributor_id}>{listDistributor[c].distributor_name}</option>)
    }
  }
  var optMarket = [];
  if(listMarket !== undefined) {
    for(var d=0; d<listMarket.length; d++) {
      optMarket.push(<option value={listMarket[d].market_id} key={listMarket[d].market_id}>{listMarket[d].market_name}</option>)
    }
  }
  var optYears = [];
  for(var e=(parseInt(yearnow)-3); e<(parseInt(yearnow)+3); e++) {
    optYears.push(<option value={e} key={"y"+e}>{e}</option>)
  }
  var optProjectStatus = [];
  if(global.config.pipelinestatus?.length > 0) {
    for(var f=0; f<global.config.pipelinestatus.length; f++) {
      if(f<3) {
      optProjectStatus.push(<option value={global.config.pipelinestatus[f]} key={global.config.pipelinestatus[f]}>{translate.pipeline.pipeline_status[f]}</option>)
      }
    }
  }
  var optProduct = [];
  if(listProduct !== undefined) {
    for(var g=0; g<listProduct.length; g++) {
      optProduct.push(<option value={listProduct[g].product_id} key={listProduct[g].product_id}>{listProduct[g].product_name}</option>)
    }
  }
  var optCountry = [];
  if(listCountry !== undefined) {
    for(var h=0; h<listCountry.length; h++) {
      optCountry.push(<option value={listCountry[h].country_id} key={listCountry[h].country_id}>{listCountry[h].country_name}</option>)
    }
  }
  return (
  <>
    <div className="bg-gray-100 p-8 h-screen overflow-y-auto">
      <div className=" flex items-center mb-8">
        <h2 className=" text-2xl">{translate.pipeline.title1}</h2>
        {//(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[3])? 
        <Button
          variant="icon"
          icon="plus"
          className="ml-5"
          onClick={() => handleDetailClick({},"create")}
        />
        //: '' 
       } 
      </div>
      <div className="bg-white shadow-3xl flex flex-1 p-4 gap-x-4 rounded-lg mb-2">
        <p className=" text-gray-400 font-bold">{translate.general.filter}: </p>
        
        {(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2])? 
        <>
        <select name="distributorid" id="distributorid" className="bg-white text-gray-400 outline-none" defaultValue="" style={{maxWidth: "185px"}}
            value={filterData.distributorid} 
            onChange={handleSelect}
            
          >
            <option value="">{translate.general.filter_distributor}</option>
            {optDistributor}
        </select>
        <select name="countryid" id="countryid" className="bg-white text-gray-400 outline-none" defaultValue="" style={{maxWidth:"130px"}}
          value={filterData.countryid} 
          onChange={handleSelect}
        >
          <option value="">{translate.general.filter_country}</option>
          {optCountry}
        </select>
        </>
        : <></> }
        <select name="marketid" id="marketid" className="bg-white text-gray-400 outline-none" defaultValue="" style={{maxWidth:"130px"}} 
          value={filterData.marketid} 
          onChange={handleSelect}
        >
          <option value="">{translate.general.filter_market}</option>
          {optMarket}
        </select>
        <select name="productid" id="productid" className="bg-white text-gray-400 outline-none" defaultValue=""  style={{maxWidth:"130px"}}
          value={filterData.productid} 
          onChange={handleSelect}
        >
          <option value="">{translate.general.filter_product}</option>
          {optProduct}
        </select>
        <select name="yearid" id="yearid" className="bg-white text-gray-400 outline-none" defaultValue="" style={{maxWidth:"140px"}}
          value={filterData.yearid} 
          onChange={handleSelect}
        >
          <option value="">{translate.pipeline.filter_year_create}</option>
          {optYears}
        </select>
        <select name="year2id" id="year2id" className="bg-white text-gray-400 outline-none" defaultValue="" style={{maxWidth:"120px"}}
          value={filterData.year2id} 
          onChange={handleSelect}
        >
          <option value="">{translate.pipeline.filter_year_close}</option>
          {optYears}
        </select>
        <select name="prjstat" id="prjstat" className="bg-white text-gray-400 outline-none" defaultValue="" style={{maxWidth:"130px"}}
          value={filterData.prjstat} 
          onChange={handleSelect}
        >
          <option value="">{translate.general.filter_status}</option>
          {optProjectStatus}
        </select>
      </div>
      
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
      {(listPiePipeline.pipepietarget.labels !== undefined /*&& listPiePipeline.pipepietarget.datasets[0].data[0] > 0*/)? (
        <Card className={`p-5 flex flex-col  mb-5`}>
          <div className="w-full mb-3">
            <h4 className="text-center text-gray-500 text-sm">
            {(filterData.year2id !== "")? (
                <div>{translate.pipeline.label1} <span className="font-bold">{filterData.year2id}</span></div>
              ) : (
                <div>{translate.pipeline.label5} <span className="font-bold">{translate.pipeline.label2}</span> {translate.pipeline.label3}</div>
              )
            } 
            </h4>
          </div>
          <div className="flex gap-8 justify-center items-center h-full">
            <div className="col-md-4">
            <PieChart 
              chartData={listPiePipeline.pipepietarget} 
            //   onElementsClick={elems => {
            //     // if required to build the URL, you can 
            //     // get datasetIndex and value index from an `elem`:
            //     console.log(elems[0]._datasetIndex + ', ' + elems[0]._index);
            //     // and then redirect to the target page:
            //     // window.location = "https://example.com";
            // }}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                    position: 'right',
                  }
                },
                onClick: function(evt, data) {
                  if(data[0].element.$context.index === 0) {
                    handlePopUpModal(listProductProject,data[0].element.$context.index,"")
                  } else if(data[0].element.$context.index === 1) {
                    handlePopUpModal(listProductTarget,data[0].element.$context.index,"")
                  }
                },
                onHover: (event, activeElements) => {
                  if (activeElements?.length > 0) {
                    event.native.target.style.cursor = 'pointer';
                  } else {
                    event.native.target.style.cursor = 'auto';
                  }
                },
                // onClick: function (e, element) { 
                  // console.log(e);
                  // console.log(element);
                  // console.log(element[0]); 
                  // if (element.length > 0) {
                  //   var ind = element[0];
                  //   alert(ind);
                  // }
                  
                  //  handlePopUpModal(listProductProject)
                // }
              }}
              width={150} height={150}
            />
            </div>
            <div >
              <div className="flex gap-x-2 w-full my-2">
                <div className="flex justify-end text-sm w-24 p-1">
                  <AiFillInfoCircle
                      size={18}
                      className="hover:fill-red-500 fill-gray-400  cursor-pointer mr-2"
                      onClick={() => handlePopUpModal(listProductTarget,-1,"")}
                    />
                  <label className="text-gray-400 text-sm">{translate.pipeline.graph_label1}</label>
                </div>
                <p className="font-bold text-3xl">{listPiePipeline.pipetotaltarget}</p>
              </div>
              <div className="flex gap-x-2 w-full my-2">
                <div className="flex justify-end text-sm w-24 p-1">
                  <AiFillInfoCircle
                      size={18}
                      className="hover:fill-red-500 fill-gray-400  cursor-pointer mr-2"
                      onClick={() => handlePopUpModal(listProductProject,0,"")}
                    />
                  <label className="text-gray-400 text-sm">{translate.pipeline.graph_label2}</label>
                </div>
                <p className="font-bold text-3xl">{listPiePipeline.pipetotal}</p>
                {/* <div><FaInfoCircle */}
                {/* <div><AiFillInfoCircle
                  size={20}
                  className="hover:fill-red-500 fill-gray-400  cursor-pointer  mt-1"
                  onClick={() => handlePopUpModal(listProductProject)}
                  // onClick={() => setModal(!isModal)}
                  // className="hover:fill-red-500 fill-gray-400  cursor-pointer fasize mt-2 ml-2" 
                /></div> */}
              </div>
              <div className="flex gap-x-2 w-full my-2">
                <label className="text-gray-400 text-sm w-24 text-right p-1">{translate.pipeline.graph_label3}</label>
                <p className="font-bold text-3xl">{listPiePipeline.piperemain}</p>
              </div>
            </div>
          </div>
        </Card>
      ) : <div></div>}
      {(listPiePipeline.pipepiestatus.labels !== undefined && listPiePipeline.pipepiestatus.labels.length > 0)? (
        <Card className={`p-5 flex flex-col justify-center items-center mb-5`}>
          <div className="w-full mb-3">
            <h4 className="text-center text-gray-500 text-sm">
            {translate.pipeline.label4}
            </h4>
          </div>
          <div className="flex gap-8">
            <div className="col-md-4">
            <PieChart 
              chartData={listPiePipeline.pipepiestatus} 
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                    position: 'right',
                  }
                }
              }}
              width={150} height={150}
            />
            </div>
            <div >
              {
                listPiePipeline.pipepiestatuslegend.map((datas, h) => {  
                  return <div className="flex gap-x-2 w-full my-2">
                    <div className="flex justify-end text-sm w28 p-1">
                      <AiFillInfoCircle
                          size={18}
                          className="hover:fill-red-500 fill-gray-400  cursor-pointer mr-2"
                          onClick={() => handlePopUpModal(listProductStatus,-2,datas.labels)}
                        />
                      <label className="text-gray-400 text-sm w-20 text-right p-1">{`${datas.labels}`}</label>
                    </div>
                    <p className="font-bold text-3xl">{`${datas.value}`}</p>
                  </div>
                })
              }
              {/* <div className="flex gap-x-2 w-full my-2">
                <label className="text-gray-400 text-sm w-24 text-right p-1">Target</label>
                <p className="font-bold text-3xl">{listPiePipeline.pipetotaltarget}</p>
              </div>
              <div className="flex gap-x-2 w-full my-2">
                <label className="text-gray-400 text-sm w-24 text-right p-1">Pipeline</label>
                <p className="font-bold text-3xl">{listPiePipeline.pipetotal}</p>
              </div>
              <div className="flex gap-x-2 w-full my-2">
                <label className="text-gray-400 text-sm w-24 text-right p-1">Remaining</label>
                <p className="font-bold text-3xl">{listPiePipeline.piperemain}</p>
              </div> */}
            </div>
          </div>

          
        </Card>
      ) : <div></div>}
      </div> 
      <div>
        {(defRowPage > 0) && <DataTable
          columns={columns}
          data={listPipeline}
          fixedHeader
          highlightOnHover
          defaultSortFieldId="id"
          progressPending={pending}
          progressComponent={<Loading className="h-10 w-10 mt-10 mb-10"/>}
          pointerOnHover
          pagination
          paginationPerPage={defRowPage}
          paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
          onRowClicked={(row) => handleDetailClick(row,"detail")}
          noDataComponent={<NoRecords />}
          dense={false}
          onChangeRowsPerPage={handleRowsPerPageChange}
          // className="w-screen"
          customStyles={{
            headRow: {
              style: {
                borderBottomWidth: "3px",
                borderBottomStyle: "solid",
                fontWeight: "bold",
                backgroundColor: "#F5F6FA",
                color: "#8A92A6",
              },
              denseStyle: {
                minHeight: "32px",
              },
            },
            table: {
              style: {
                height: `calc(100vh - 180px - ${height}px)`,
              },
            },
          }}
        />
      }
      </div>
    </div>
    {isModal && (
      <ModalPipelineDetails
        content={modalContent}
        onBackgroundClick={() => setModal(false)}
        onClick={() => setModal(false)}
      />
    )}
    </>
  );
};

export default PipelineList;
