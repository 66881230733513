import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import useScrollbarSize from "react-scrollbar-size";
import { Button, Loading, NoRecords } from "../../../components";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../features/translate/translateSlice";
import Logouts from "../../../components/logout"; 

const keyAuth = global.config.typecode[14] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const PromotionalList = () => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector((state) => state.translate.lang)
  const navigate = useNavigate();
  const { height } = useScrollbarSize();
  const [isChecked, setChecked] = useState([]);
  const [pending, setPending] = useState(true);
  const [listBanner, setListBanner] = useState([]);
  
  useEffect(() => {
    getBannerList();
  }, []);

  const handleDetailClick = (resList,type) => {
    navigate("/admin/promo/create", {
      state: { data: resList, type: type },
    })
  }
  const updtlistStat = (row) => {
    let checkarr = {};
    row.forEach((records) => {
      checkarr[records.banner_id] = records.status;
    });
    setChecked(checkarr);
  };
  const getBannerList = () => {
    let fetchUrl = global.config.urlLink + "/listbanner";
    headersParams.Authorization = keyAuth;
    const dataText = { from_id: "list-banner", lang: chooseLang };
    fetch(fetchUrl, {
      method: "POST",
      headers: headersParams,
      body: JSON.stringify(dataText),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
      })
      .then(async (res) => {
        setPending(false);
        if (res.status === "200") {
          setListBanner(res.values);
          updtlistStat(res.values);
        } else if(res.status === global.config.errcodesess) {
          var resultSO = await Logouts();
          if(resultSO.status === "200") {
            window.location.reload();
          }
        }
      });
  };

  const handleDeleteBanner = (id) => {
    if (window.confirm(`${translate.banner_list.alert_delete}`)) {
      document.getElementById("loading-screen").style.display = "flex";
      let fetchUrl = global.config.urlLink;
      fetchUrl += "/deletebanner/" + id;
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "GET",
        headers: headersParams,
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then((res) => {
          if (res.status === "200") {
            document.getElementById("loading-screen").style.display = "none";
            window.location.href = "/admin/promo";
          }
        })
        .catch((err) => {
          console.log(err);
          document.getElementById("loading-screen").style.display = "none";
        });
    } else {
    }
  };

  const handleToggle = (id, stat) => {
    let statcheck = 1,
      statsend = true;
    if (stat === 1) {
      statcheck = 0;
      statsend = false;
    } else {
      statcheck = 1;
      statsend = true;
    }
    setChecked((prevState) => ({ ...prevState, [id]: statcheck }));

    let dataText =
      '{"doc_id":"' +
      id +
      '","status": "' +
      statsend +
      '","lang": "' +
      chooseLang +
      '","from_id":"edit-status-banner"}';

    let data = JSON.parse(dataText);
    let urlFetch = global.config.urlLink + "/editbanner";
    headersParams.Authorization = keyAuth;
    fetch(urlFetch, {
      method: "POST",
      headers: headersParams,
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
      })
      .then((resJson) => {
        return false;
      })
      .catch((err) => {
        return false;
      });
  };

  const ActionCols = (row) => (
    <div className="flex flex-1 gap-4 hover:fill-red-500">
      {/* <FaInfo
        onClick={() => handlePopModal(row, "detail")}
        className="hover:fill-red-500 cursor-pointer fasize"
      /> */}
      <FaEdit
        onClick={() => handleDetailClick(row,"edit")}
        className="hover:fill-red-500 cursor-pointer fasize"
      />
      {(localStorage.getItem("dulv")===global.config.conflevel[0] && <FaTrash
        onClick={() => handleDeleteBanner(row.banner_id)}
        className="hover:fill-red-500 cursor-pointer fasize"
      /> )}
    </div>
  );

  const StatusCols = (row) => (
    <div className="flex gap-x-10 items-center">
      {(row.status === true || row.status === 1)? global.config.generalstatus[0] : global.config.generalstatus[1] }
      {/* <ToggleSwitch
        id={row.market_id}
        checked={isChecked[row.market_id] || ""}
        onClick={() => handleToggle(row.market_id, isChecked[row.market_id])}
      /> */}
    </div>
  );
  const cellLink = (row) => (
    <div className="flex gap-x-10 items-center">
      {(row.banner_linkurl.indexOf("http://") > -1 || row.banner_linkurl.indexOf("https://") > -1)? 
          <a href={`${row.banner_linkurl}`} target="_blank" className="text-blue-500 underline" rel="noreferrer">Link</a>
        : '-'
      }
    </div>
  );
  const columns = [
    {
      name: translate.banner_list.column_1,
      selector: (row) => row.banner_filename,
    },
    { name: translate.banner_list.column_2, 
      width: "180px",
      cell: cellLink,
    },
    {
      name: translate.banner_list.column_3,
      width: "100px",
      selector: (row) => row.banner_sortid,
    },
    {
      name: translate.general.column_status,
      width: "180px",
      cell: StatusCols,
    },
    {
      name: translate.general.column_action,
      width: "180px",
      cell: ActionCols,
    },
  ];

  return (
    <>
      <div className=" bg-gray-100 h-screen flex-1 p-8 overflow-y-auto">
        <div className="flex mb-8 gap-4">
          <h2 className=" text-2xl">{translate.banner_list.title}</h2>
          <Button
            variant="icon"
            icon="plus"
            onClick={() =>
              navigate("/admin/promo/create", { state: { type: "create" } })
            }
          />
        </div>
        <div>
          <DataTable
            columns={columns}
            data={listBanner}
            fixedHeader
            highlightOnHover
            defaultSortFieldId="id"
            progressPending={pending}
            progressComponent={<Loading className="h-10 w-10 mt-10 mb-10"/>}
            pointerOnHover
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
            onRowClicked={(row) => handleDetailClick(row,"detail")}
            noDataComponent={<NoRecords />}
            customStyles={{
              headRow: {
                style: {
                  borderBottomWidth: "3px",
                  borderBottomStyle: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#F5F6FA",
                  color: "#8A92A6",
                },
                denseStyle: {
                  minHeight: "32px",
                },
              },
              table: {
                style: {
                  height: `calc(100vh - 180px - ${height}px)`,
                },
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PromotionalList;
