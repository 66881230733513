import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import useScrollbarSize from "react-scrollbar-size";
import { Card, DateTimeFormats, Loading, NoRecords } from "../../../components";
import VContactModal from "../Modal";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../features/translate/translateSlice";
import Logouts from "../../../components/logout"; 

const keyAuth3 = global.config.typecode[12] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const DistributorMessageList = () => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector((state) => state.translate.lang)
  const { height } = useScrollbarSize();

  const [pending, setPending] = useState(false);
  const [isModal, setModal] = useState(false);
  const [listVContact, setListVContact] = useState([]);
  const [vContactData, setVContactData] = useState({});

  useEffect(() => {
    loadData();
  }, []);

  const handleModalVContact = (data) => {
    setVContactData(data);
    setModal(!isModal);
  };

  const listVeoliaContact = () => {
    setPending(true);
    return new Promise((resolve) => {
      let fetchUrl = global.config.urlLink + "/listvcontact";
      let dataText = {
        from_id: "list-veoliacontact",
        lang: chooseLang
      };
      headersParams.Authorization = keyAuth3;
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(dataText),
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then((res) => {
          if (res.status === "200") {
            var resultList = res.values;
            if(localStorage.getItem("dulv")===global.config.conflevel[2]) {
              resultList = resultList.filter((sel) =>  {
                return (
                  (parseInt(sel.id_country) === parseInt(localStorage.getItem("ducr"))) && 
                  (parseInt(sel.id_distributor) !== 0)
                ); 
              });
            } else if(localStorage.getItem("dulv")===global.config.conflevel[3]) {
              resultList = resultList.filter((sel) =>  {
                return parseInt(sel.id_distributor) === parseInt(localStorage.getItem("dudst")); 
              });
            }
            
            resolve(resultList);
          } else if(res.status === global.config.errcodesess) {
            resolve([res.status]);
          } else {
            resolve([]);
          }
        })
        .catch((err) => {
          resolve([]);
        });
    });
  };

  const loadData = async () => {
    let [result1] = await Promise.all([listVeoliaContact()]);
    if(result1.length > 0 && result1[0] === global.config.errcodesess) { 
      var resultSO = await Logouts();
      if(resultSO.status === "200") {
        window.location.reload();
      }
    } else {
      setPending(false);
      setListVContact(result1);
    }
  };
  const custInfo = (row) => (
    <div className="flex flex-1 flex-col gap-1 py-2 hover:fill-red-500" onClick={() => handleModalVContact(row)}>
      <p>{`${translate.contact_us.column_detail_1} : ${row.contact_name}`}</p>
      <p>{`${translate.contact_us.column_detail_2} : ${row.contact_email}`}</p>
      {row.contact_phone !== ""? <p>{`${ translate.contact_us.column_detail_1} : ${row.contact_phone}`}</p> : "" }
    </div>
  );
  const columnVeolia = [
    { name: translate.contact_us.column_1, selector: (row) => <DateTimeFormats datez = {new Date(row.created_date)} type={"datetime-short"} /> },
    { name: translate.contact_us.column_2, cell: custInfo },
  
    // { name: "Contact Information", selector: (row) => row.contact_name },
    // { name: "Customer Email", selector: (row) => row.contact_email },
    // { name: "Customer Contact", selector: (row) => row.contact_phone },
    { name: translate.contact_us.column_3, selector: (row) => row.distributor_name },
  ];

  return (
    <>
      <div className="bg-gray-100 h-screen overflow-y-auto flex-1 p-8">
        <div className="mb-8">
          <h1 className=" text-2xl">{translate.contact_us.title}</h1>
        </div>
        <Card>
          <DataTable
            columns={columnVeolia}
            data={listVContact}
            fixedHeader
            highlightOnHover
            defaultSortFieldId="id"
            progressPending={pending}
            progressComponent={<Loading className="h-10 w-10 mt-10 mb-10"/>}
            pointerOnHover
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
            onRowClicked={(row) => handleModalVContact(row)}
            noDataComponent={<NoRecords />}
            customStyles={{
              headRow: {
                style: {
                  borderBottomWidth: "3px",
                  borderBottomStyle: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#F5F6FA",
                  color: "#8A92A6",
                },
                denseStyle: {
                  minHeight: "32px",
                },
              },
              table: {
                style: {
                  height: `calc(100vh - 180px - ${height}px)`,
                },
              },
            }}
          />
        </Card>
      </div>
      {isModal && (
        <VContactModal
          contentData={vContactData}
          onBackgrounClick={handleModalVContact}
          onClick={handleModalVContact}
        />
      )}
    </>
  );
};

export default DistributorMessageList;
