import React from "react";

import { NavLink } from "react-router-dom";

import { ReactComponent as Work } from "../../assets/svg/work.svg";
import { ReactComponent as Structure } from "../../assets/svg/structure.svg";
import { ReactComponent as Discovery } from "../../assets/svg/discovery.svg";
import { ReactComponent as Messages } from "../../assets/svg/envelope.svg";
import { ReactComponent as Coupon } from "../../assets/svg/coupon.svg";
import { ReactComponent as Filter } from "../../assets/svg/filter.svg";
import { ReactComponent as Cart } from "../../assets/svg/cartbasket.svg";
import { ReactComponent as Country } from "../../assets/svg/language.svg";
import { ReactComponent as User3 } from "../../assets/svg/user-3.svg";
import { ReactComponent as Bookmark } from "../../assets/svg/bookmark.svg";
import { ReactComponent as Pipeline } from "../../assets/svg/pipeline.svg";
import { ReactComponent as TicketStar } from "../../assets/svg/ticket-star.svg";
import { ReactComponent as Copy } from "../../assets/svg/copy.svg";
import { ReactComponent as Certificate } from "../../assets/svg/certificate.svg";
import { ReactComponent as User2 } from "../../assets/svg/user-2.svg";
import { ReactComponent as Upgraph } from "../../assets/svg/upgraph.svg";
import { ReactComponent as Dart } from "../../assets/svg/dart.svg";
import { ReactComponent as User3New } from "../../assets/svg/3-user.svg";
import { ReactComponent as Megaphone } from "../../assets/svg/megaphone.svg";



export const VeoliaSideBar = ({ item, index, visible }) => {
  const activeLink =
    "hover:bg-red-500 p-2 flex items-center text-white text-xs bg-red-500 text-white rounded-md fill-white mb-2";
  const normalLink =
    "hover:bg-red-500 p-2 flex items-center hover:text-white text-xs text-gray-500 fill-gray-500 hover:fill-white rounded-md mb-2";
    // "hover:bg-red-500 p-2 flex items-center hover:text-white text-white text-xs text-gray-500 fill-gray-500 hover:fill-white rounded-md mb-2";

  return (
    <>
      <div>
        {item?.map((menus, _) => (
          <div key={_} className="gap-y-5 mb-10">
            <p
              className={`text-gray-400 text-sm mb-3 ${
                index === 0 ? "" : "pt-3"
              } ${visible && "border-b-4 truncate text-center"}`}
            >
              {`${visible ? "" : menus.category}`}
            </p>
            {menus.submenu.map((menu, index) => (
              <NavLink
                key={index}
                to={`${menu.path}`}
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                <div>
                  <li
                    className={`flex items-center gap-x-4 cursor-pointer hover:bg-red-500  hover:border-b-1 ${
                      menu.gap && "mt-2"
                    }`}
                  >
                    {/* {menu.src === "dashboard" && <Dashboard width="20" />}
                    {menu.src === "game" && <Game width="20" />}
                    {menu.src === "location" && <Location width="20" />}
                    {menu.src === "shield-done" && <ShieldDone width="20" />}
                    {menu.src === "info-circle" && <InfoCircle width="20" />}
                    {menu.src === "caution" && <Caution width="20" />}
                    {menu.src === "market" && <Market width="20" />} */}
                    
                    {menu.src === "structure" && <Structure width="20" />}
                    {menu.src === "discovery" && <Discovery width="20" />}
                    {menu.src === "messages" && <Messages width="20" />}
                    {menu.src === "coupon" && <Coupon width="20" />}
                    {menu.src === "filter" && <Filter width="20" />}
                    {menu.src === "cart" && <Cart width="20" />}
                    {menu.src === "flag" && <Country width="20" />}
                    {menu.src === "3-user" && <User3 width="20" />}
                    {menu.src === "bookmark" && <Bookmark width="20" />}
                    {menu.src === "pipeline" && <Pipeline width="20" />}
                    {menu.src === "ticket-star" && <TicketStar width="20" />}
                    {menu.src === "copy" && <Copy width="20" />}
                    {menu.src === "certificate" && <Certificate width="20" />}
                    {menu.src === "2-user" && <User2 width="20" />}
                    {menu.src === "upgraph" && <Upgraph width="20" />}
                    {menu.src === "dart" && <Dart width="20" />}
                    {menu.src === "3-user-new" && <User3New width="20" />}
                    {menu.src === "campaign" && <Megaphone width="18" />}
                    {menu.src === "work" && <Work width="20" />}
                    
                    <span
                      className={`${
                        visible && "hidden"
                      } origin-left duration-200`}
                    >
                      {menu.title}
                    </span>
                  </li>
                </div>
              </NavLink>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};
