import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, TextInput, ToggleSwitch } from "../../../../components";
import { staticTranslate } from "../../../../features/translate/translateSlice";
import Logouts from "../../../../components/logout"; 

const keyAuth = global.config.typecode[10] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const MarketModal = ({ onBackgroundClick, onClick, marketData }) => {
  const translate = useSelector(staticTranslate);
  const chooseLang = useSelector(state => state.translate.lang)
  const { type, data } = marketData;
  const [formDisabled, setFormDisabled] = useState(true);

  const [formErrors, setFormErrors] = useState({
    market_name: ""
  });
  const [form, setForm] = useState({
    market_name: "",
    status: true,
  });

  const validateField = (fieldName, value) => {
    let ferrors = "";
    switch (fieldName) {
      case "market_name":
        if (value.trim().length > 0) { ferrors = ""; } 
        else { ferrors = translate.market_detail.error1; }
        break;
      default:
        break;
    }
    setFormErrors((prevState) => ({ ...prevState, [fieldName]: ferrors }));
  };
  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };
  const toggleHandler = () => {
    setForm((prevState) => ({
      ...prevState,
      status: form.status === 0 ? 1 : 0,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    
    if(form.market_name !== "") {
      document.getElementById("loading-screen").style.display = "flex";
      var fetchUrl = global.config.urlLink;
      var dataJson = form;
      if (type === "create") {
        fetchUrl += "/addmarket";
        dataJson.from_id="add-market";
      } else {
        fetchUrl += "/editmarket";
        dataJson.from_id="edit-market";
      }
      dataJson.lang=chooseLang;
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(dataJson),
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then((res) => {
          if (res.status === "200") {
            document.getElementById("loading-screen").style.display = "none";
            window.location.href = "/admin/market";
          }
        })
        .catch((err) => { 
          console.log(err);
          document.getElementById("loading-screen").style.display = "none";
        });
    } else {
      var formField = ["market_name"];
      for ( var i = 0; i < formField.length; i++ ) { 
        var fieldname = formField[i];
        var fieldvalue = form[fieldname];
        validateField(fieldname, fieldvalue); 
      }
    } 
  };
  const getMarket = (id) => {
    return new Promise((resolve) => {
      let datatext = '{';
      datatext += '"from_id":"detail-market"';
      datatext += ',"doc_id":'+id;
      datatext += '}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth;
      var fetchUrl = global.config.urlLink + "/detailmarket";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            resolve(resJson.values);
          } else if(resJson.status === global.config.errcodesess) {
            resolve([resJson.status]);
          } else {
            resolve([]);
          }
      }).catch((err) => {
          resolve([]);
      });
    });
  };
  const loadData = async (dataSend) => {
    let [result1] = await Promise.all([getMarket(dataSend.market_id)]);
    if(result1.length > 0 && result1[0] === global.config.errcodesess) { 
      var resultSO = await Logouts();
      if(resultSO.status === "200") {
        window.location.reload();
      }
    } else {
      if(result1) {
        setForm((prevState) => ({
          ...prevState,
          doc_id: dataSend.market_id,
          market_name: result1[0].market_name,
          status: dataSend.status,
        })); 
      }
    }
  }
  useEffect(() => {
    if(type === "edit" && chooseLang === "cn") {
      loadData(data);
    } else if(type !== "create") {
      setForm((prevState) => ({
        ...prevState,
        doc_id: data.market_id,
        market_name: data.market_name,
        status: data.status,
      }));  
    }
    
    var inpdisabled = true;
    if(type === "edit" || type === "create") {   
        inpdisabled = false;
    }
    setFormDisabled(inpdisabled);

  }, []);

  return (
    <Modal
      title={
        type === "create"
          ? translate.market_detail.title1
          : type === "detail"
          ? translate.market_detail.title3
          : translate.market_detail.title2
      }
      onBackgroundClick={onBackgroundClick}
      onClick={onClick}
    >
      <div className=" flex flex-col overflow-y-auto justify-between">
        <div className=" gap-y-2 gap-x-8 mx-1 mb-8">
          <div>
            <TextInput
              variant="base"
              // title="Market Name"
              title={translate.market_detail.text_input1}
              name="market_name"
              value={form.market_name}
              onChange={onChangeHandler}
              disabled={formDisabled}
              errmsg={formErrors.market_name}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2">
          <div className="flex items-center gap-x-4">
            <label
              className={`text-sm text-gray-400 ${
                !form.status && "text-red-500"
              }`}
            >
              {global.config.generalstatus[1]}
            </label>
            <ToggleSwitch
              id="status"
              checked={form.status}
              onClick={formDisabled ? null : toggleHandler}
            />
            <label
              className={`text-sm text-gray-400 ${form.status && "text-red-500"}`}
            >
               {global.config.generalstatus[0]}
            </label>
          </div>
          <div className="flex justify-end">
          {!formDisabled && (
            <Button
              type="button"
              variant="base"
              className="bg-red-500 text-white"
              title={type === "add" ? translate.market_detail.button1 : translate.general.button_save}
              onClick={handleSubmit}
            />
          )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MarketModal;
