import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import useScrollbarSize from "react-scrollbar-size";
import { FaEdit, FaTrash } from "react-icons/fa";

import { Button, Loading, NoRecords } from "../../components";
import AddContactModal from "./Components/Modal";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../features/translate/translateSlice";
import Logouts from "../../components/logout"; 

const keyAuth = global.config.typecode[6] + global.config.authBearerKey;
const keyAuth2 = global.config.typecode[3] + global.config.authBearerKey;
const headersParams = global.config.headersparam;


const KeyContact = () => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector((state) => state.translate.lang)
  const { height } = useScrollbarSize();
  const [isMode, setMode] = useState("veolia");
  const [isModal, setModal] = useState(false);
  const [pending, setPending] = useState(true)
  const [listContact, setListContact] = useState([])
  const [popContent, setPopContent] = useState([])
  const [listDistributor, setListDistributor] = useState([]);
  const [filterData, setFilterData] = useState({statid:"",distributorid:"",countryid:""});
  
  const handlePopModal = (dataParams, type) => {
    let sendParams = {}
    sendParams.type = type
    sendParams.data = dataParams
    setPopContent(sendParams)
    setModal(!isModal)
  }

  const handleDelete = (id) => {
    if (window.confirm(`${translate.key_contact.alert_delete}`)) {
      document.getElementById("loading-screen").style.display = "flex";
      var fetchUrl = global.config.urlLink + "/deletekcp/" + id;
      headersParams.Authorization = keyAuth
      fetch(fetchUrl, {
        method: "GET",
        headers: headersParams
      })
        .then((res) => {
          if (res.status === 200) return res.json()
        })
        .then(res => {
          if (res.status === "200") {
            document.getElementById("loading-screen").style.display = "none";
            window.location.href = "/keycontact"
          }
        }).catch(err => {
          console.log(err);
          document.getElementById("loading-screen").style.display = "none";
        })
    } else {

    }
  }

  const handleSelect = async (e) => {
    document.getElementById("loading-screen").style.display = "flex";
    var name = e.target.name;
    var value = e.target.value;
    var result1a;
    if(name === "distributorid") {
      result1a = await listContactData(filterData.statid,value,filterData.countryid);
    } else if(name === "statid") {
      result1a = await listContactData(value,filterData.distributorid,filterData.countryid);
    }
    if(result1a !== undefined) {
      if(result1a.length > 0 && result1a[0] === global.config.errcodesess) { result1a = []; }
      setListContact(result1a);
      setFilterData({ ...filterData, [name]: value });
      document.getElementById("loading-screen").style.display = "none";
    }
    
  }
  const loadDistributor = (country) => {
    return new Promise(resolve => {
      if(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2]) {
        var datatext ='{';
        if(localStorage.getItem("dulv")===global.config.conflevel[3]) { datatext += '"from_id": "list-distributor"'; }
        else { datatext += '"from_id": "list-all-distributor"'; }
        datatext += ',"stat_id":1';
        datatext += ',"lang":"'+chooseLang+'"';
        if(country !== "") { datatext += ',"country_id":"'+country+'"'; }
        datatext += '}';
        var data = JSON.parse(datatext); 
        headersParams.Authorization = keyAuth2;
        fetch(global.config.urlLink+"/listdist", {
          method: 'POST',
          headers: headersParams, 
          body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJson => {
            if(resJson.status === "200") {
              resolve(resJson.values);
            } else {
              console.log('Something happened wrong 2');
              resolve([]);
            }
        }).catch((err) => {
            resolve([]);
        });
      } else { resolve([]); }
    });
  }

  const listContactData = (stat,distributor,country) => {
    return new Promise(resolve => {
      var fetchUrl = global.config.urlLink + "/listkcp";
      var dataText = '{';
      if(localStorage.getItem("dulv")===global.config.conflevel[3]) { dataText += '"from_id": "list-contactperson"'; }
      else { dataText += '"from_id": "list-all-contactperson"'; }
      dataText += ',"lang":"'+chooseLang+'"';
      if(stat !== "") { dataText += ',"stat_id":'+parseInt(stat); }
      if(distributor !== "") { dataText += ',"dist_id":"'+distributor+'"'; }
      if(country !== "") { dataText += ',"country_id":"'+country+'"'; }
      dataText += '}';
      let data = JSON.parse(dataText);
      headersParams.Authorization = keyAuth;
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((res) => {
          if (res.status === "200") {
            var updateData = res.values.filter((user, _) => user.party_side === isMode)
            resolve(updateData);
          } else if(res.status === global.config.errcodesess) {
            resolve([res.status]);
          } else { resolve([])}
      }).catch((err) => {
        resolve([]);
      });
    });
  };
  const loadData = async () => {
    var idDist = filterData.distributorid, idCntry=filterData.countryid;
    if(localStorage.getItem("dulv")===global.config.conflevel[3]) { idDist = localStorage.getItem("dudst"); } 
    else if(localStorage.getItem("dulv")===global.config.conflevel[2]) { idCntry = localStorage.getItem("ducr"); }
    let [result1, result2] = await Promise.all([listContactData("",idDist,idCntry), loadDistributor(idCntry)]);
    if(result1.length > 0 && result1[0] === global.config.errcodesess) { 
      var resultSO = await Logouts();
      if(resultSO.status === "200") {
        window.location.reload();
      }
    } else {
      setPending(false); 
      setListContact(result1);
      setListDistributor(result2);
      setFilterData({ ...filterData, distributorid: idDist, countryid: idCntry });
    }
      
  }

  var optDistributor = [];
  if(listDistributor !== undefined) {
    for(var c=0; c<listDistributor.length; c++) {
      optDistributor.push(<option value={listDistributor[c].distributor_id} key={listDistributor[c].distributor_id}>{listDistributor[c].distributor_name}</option>)
    }
  }

  const AvatarNameList = (row) => (
    <div className="flex items-center py-3" style={{minWidth: "200px"}}>
      <div className="items-center flex mr-4">
        {row.kcp_photourl ?
          <img alt=""
            src={row?.photoURL}
            className="rounded-full object-cover self-center"
          /> : <div className="w-11 h-7 text-ce flex justify-center items-center rounded-full bg-red-500 text-white"  style={{width:"35px",height:"35px"}}><p>{row?.kcp_name[0]}</p></div>
        }
      </div>
      <div className="break-all">{row?.kcp_name}</div>
    </div>
  );

  const EmailUser = (row) => (
    <a>{row.kcp_email}</a>
  );

  const ProductData = (items) => {
    var sendproduct="";
    for(var a=0; a<items.length; a++) {
      if(sendproduct !== "") { sendproduct += `, ` }
      sendproduct+= `${items[a].label}` 
    }
    return sendproduct;
  }
  
  const ActionCols = (row) => (
    <div className="flex flex-1 gap-4 hover:fill-red-500">
      {/* <FaInfo
        onClick={() => handlePopModal(row, "detail")}
        className="hover:fill-red-500 cursor-pointer fasize"
      /> */}
      {(localStorage.getItem("dulv")===global.config.conflevel[0] || 
        localStorage.getItem("dulv")===global.config.conflevel[1] || 
        localStorage.getItem("dulv")===global.config.conflevel[2] || 
        (localStorage.getItem("dulv")===global.config.conflevel[3] /*&& localStorage.getItem("dutk") === row.created_by*/)
       )?
      <div className="flex flex-1 gap-4 hover:fill-red-500">
      <FaEdit
        onClick={() => handlePopModal(row, "edit")}
        className="hover:fill-red-500 cursor-pointer fasize"
      />
      <FaTrash
        onClick={() => handleDelete(row.kcp_id)}
        className="hover:fill-red-500 cursor-pointer fasize"
      />
      </div>
      :''
      }
    </div>
  );
  const ActionCols2 = (row) => {
    var arrIdCountry = localStorage.getItem("ducr").split(",");
    for(var c=0; c<arrIdCountry.length; c++) {
      if(parseInt(arrIdCountry[c]) === parseInt(row.id_country)) { 
        return <>
          <div className="flex flex-1 gap-4 hover:fill-red-500">
            <div className="flex flex-1 gap-4 hover:fill-red-500">
            <FaEdit
              onClick={() => handlePopModal(row, "edit")}
              className="hover:fill-red-500 cursor-pointer fasize"
            />
            <FaTrash
              onClick={() => handleDelete(row.kcp_id)}
              className="hover:fill-red-500 cursor-pointer fasize"
            />
            </div>
          </div>
        </>
      }
    }
    
  };
  const KeyContactColumn = [
    {
      name: translate.key_contact.column_1,
      // sortable: true,
      fixed: true,
      selector: AvatarNameList,
      grow: 1,
      wrap: true
    },
    
    {
      name: translate.key_contact.column_2,
      selector: EmailUser,
      grow: 1,
    },
    {
      name: translate.key_contact.column_3,
      selector: (row) => row.kcp_position,
    }
  ];

  if(isMode === "distributor") {
    KeyContactColumn.push({ name: translate.key_contact.column_5, selector: (row) => row.distributor_name, grow: 1});
  } else {
    KeyContactColumn.push({
      name: translate.key_contact.column_4,
      cell: (row) => <p>{`${
        row.kcp_product !== "" && JSON.parse(row.kcp_product).length > 0 ? 
          ProductData(JSON.parse(row.kcp_product))
        : "-" 
      }`}</p>,
      grow: 1,
    });    
  }
  if(localStorage.getItem("dulv") === global.config.conflevel[0] || localStorage.getItem("dulv") === global.config.conflevel[1] || localStorage.getItem("dulv") === global.config.conflevel[2] || (localStorage.getItem("dulv") === global.config.conflevel[3] && isMode === "distributor" )) {
    if(localStorage.getItem("dulv") === global.config.conflevel[2] && localStorage.getItem("ducr") !== "") { 
      KeyContactColumn.push({ name: translate.general.column_action, cell: ActionCols2, grow: 1,  width: "150px"});
    } else {
      KeyContactColumn.push({ name: translate.general.column_action, cell: ActionCols, grow: 1,  width: "150px"});
    }
  }

  useEffect(() => {
    loadData()
  }, [isMode]);

  return (
    <>
      <div className=" bg-gray-100 p-8 h-screen overflow-y-auto">
        <div className="mb-8 flex gap-4">
          <h2 className=" text-2xl">{translate.key_contact.title}</h2>
          {(localStorage.getItem("dulv") === global.config.conflevel[0] || localStorage.getItem("dulv") === global.config.conflevel[1] || localStorage.getItem("dulv") === global.config.conflevel[2] || (localStorage.getItem("dulv") === global.config.conflevel[3] && isMode === "distributor" ))? 
            <Button variant="icon" icon="plus" onClick={() => handlePopModal({"party_side": isMode}, "add")} /> 
          : '' }
        </div>
        <div className="bg-white rounded-t-lg flex">
          <div className="flex p-2 w-64">
            <div
              className={`p-2 px-5 text-sm rounded-lg ${isMode === "veolia" && "bg-red-500 text-white "
                }`}
            >
              <h2 onClick={() => setMode("veolia")} className="cursor-pointer">
                {translate.key_contact.label2}
              </h2>
            </div>
            <div
              className={`p-2 px-5 text-sm rounded-lg ${isMode === "distributor" && "bg-red-500 text-white"
                }`}
            >
              <h2
                onClick={() => setMode("distributor")}
                className="cursor-pointer"
              >
                {translate.key_contact.label1}
              </h2>
            </div>
          </div>
          <div  className="bg-white flex flex-1 rounded-lg justify-end align-middle mr-3">
            {(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2])? 
            <select name="distributorid" id="distributorid" className="bg-white text-gray-400 outline-none" defaultValue=""
                value={filterData.distributorid} 
                onChange={handleSelect}
                
              >
                <option value="">{translate.general.filter_distributor}</option>
                {optDistributor}
            </select>
            : '' }
          </div>
        </div>
        <div>
          <DataTable
            columns={KeyContactColumn}
            data={listContact}
            pagination
            paginationPerPage={10}
            highlightOnHover
            fixedHeader
            progressPending={pending}
            progressComponent={<Loading className="h-10 w-10 mt-10 mb-10"/>}
            pointerOnHover
            onRowClicked={(row) => handlePopModal(row,"detail")}
            noDataComponent={<NoRecords />}
            customStyles={{
              headRow: {
                style: {
                  borderBottomWidth: "3px",
                  borderBottomStyle: "solid",
                  fontWeight: "bold",
                  backgroundColor: "#F5F6FA",
                  color: "#8A92A6",
                },
                denseStyle: {
                  minHeight: "32px",
                },
              },
              table: {
                style: {
                  height: `calc(100vh - 180px - ${height}px)`,
                },
              },
            }}
          />
        </div>
      </div>
      {isModal && (
        <AddContactModal
          onClick={() => setModal(false)}
          onBackgroundClick={() => setModal(false)}
          dataContact={popContent}
        />
      )}
    </>
  );
};

export default KeyContact;
