import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Loading } from "../../../components";
import { ReactComponent as ThumbnailIconGrey } from "../../../assets/svg/thumb-icon-grey.svg";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../features/translate/translateSlice";
import Logouts from "../../../components/logout"; 

const keyAuth = global.config.typecode[8] + global.config.authBearerKey;
const headersParams = global.config.headersparam;

const MarketingList = () => {
  const translate = useSelector(staticTranslate);
  const chooseLang = useSelector((state) => state.translate.lang);
  const navigate = useNavigate();
  const [marketingList, setMarketingList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    var idDist = "", idCntry="";
    if(localStorage.getItem("dulv")===global.config.conflevel[3]) { idDist = localStorage.getItem("dudst"); } 
    else if(localStorage.getItem("dulv")===global.config.conflevel[2]) { idCntry = "0"; }
    // else if(localStorage.getItem("dulv")===global.config.conflevel[2]) { idCntry = localStorage.getItem("ducr"); }
    
    getMarketingList(idDist,idCntry);
  }, []);

  const getMarketingList = (distributor,country) => {
    setLoading(true);
    let fetchUrl = global.config.urlLink + "/listmarketing";
    let dataText = {
      from_id: "list-marketing",
      lang: chooseLang
    };
    if(distributor !== "") { dataText.dist_id = distributor; }
    if(country !== "") { dataText.country_id = country; }
    if(localStorage.getItem("dulv")===global.config.conflevel[3] || localStorage.getItem("dulv")===global.config.conflevel[2]) {
      dataText.stat_id = 1;
    }
    headersParams.Authorization = keyAuth;
    fetch(fetchUrl, {
      method: "POST",
      headers: headersParams,
      body: JSON.stringify(dataText),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
      })
      .then(async (res) => {
        if (res.status === "200") {
          // const filteredMarketingList = res.values.filter((item) => item.status === 1)
          // setMarketingList(filteredMarketingList);
          setMarketingList(res.values);
        } else if(res.status === global.config.errcodesess) {
          var resultSO = await Logouts();
          if(resultSO.status === "200") {
            window.location.reload();
          }
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };


  return (
    <div className=" bg-gray-100 h-screen overflow-y-auto p-8">
      <div className="mb-8 flex items-center">
        <h2 className=" text-2xl">{translate.marketing_material.title}</h2>
        {(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1] || localStorage.getItem("dulv")===global.config.conflevel[2]) && 
          <Button
            variant="icon"
            icon="plus"
            className="ml-5"
            onClick={() =>
              navigate("/marketing/create", {
                state: { type: "create", data: {} },
              })
            }
          />
        }
      </div>
      <div>
        <div className="flex items-center mb-4">
          <h2 className=" text-xl">{translate.marketing_material.subtitle}</h2>
        </div>
        <Card className="p-8">
          <div className=" mb-5">
              <label className="text-sm text-gray-400">
              {translate.marketing_material.label1} 
                {` ${
                  marketingList.length === 0
                    ? translate.marketing_material.label4
                    : marketingList.length === 1
                    ? marketingList.length + " " + translate.marketing_material.label2
                    : marketingList.length + " " + translate.marketing_material.label3
                }`}
              </label>
          </div>
          {loading ? (
            <div><Loading className="h-10 w-10 mt-10"/></div>
          ) : (
            <div className="flex flex-1 gap-5 flex-wrap">
              {marketingList.length > 0 ? (
                marketingList.map((item, index) => (
                  <div
                    key={index}
                    className="shadow-3xl rounded-lg p-2 border border-gray-200 flex flex-col justify-between items-center  cursor-pointer" 
                    onClick={() =>
                      navigate("/marketing/detail", {
                        state: { item },
                      })
                    }
                  >
                    <div className="flex flex-col justify-between">
                      
                        {(item.pic_thumbnail !== "" && item.pic_thumbnail !== null)? 
                          <div className=" rounded-lg flex justify-center items-center" style={{width:"110px", height:"110px"}}><img src={item.pic_thumbnail} alt="" className="rounded-lg" style={{maxWidth:"110px", maxHeight:"110px"}} /></div>
                          : <div className="flex justify-center items-center w-full w-28 h-28 rounded-md"><ThumbnailIconGrey className="w-24 h-24"/></div>
                        }
                        
                        {/* <img
                          src={
                            item.pic_thumbnail || "/images/icons/cone-min.png"
                          }
                          className="w-24 rounded-lg"
                          alt=""
                        /> */}
                      
                      <div className="py-2 w-24">
                        <p className="text-center text-xs text-gray-500 italic line-clamp-1 text-bold">
                          {item.product_name}
                        </p>
                        {/* <p className="text-center text-xs text-gray-500 italic line-clamp-1">
                          {item.product_description}
                        </p> */}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center w-full"><h2>{translate.marketing_material.label_error}</h2></div>
              )}
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

export default MarketingList;
