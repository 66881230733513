import React, { useEffect, useState } from "react";

import { Button, Modal, TextInput, CustomSelect } from "../../../../components";
import { ReactComponent as CloseSquare } from "../../../../assets/svg/close-square.svg";
import { FormErrors } from "../../../../components";
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../../features/translate/translateSlice";
import Logouts from "../../../../components/logout"; 

const keyAuth = global.config.typecode[3] + global.config.authBearerKey;
const keyAuth2 = global.config.typecode[6] + global.config.authBearerKey;
const keyAuth3 = global.config.typecode[2] + global.config.authBearerKey; 

const headersParams = global.config.headersparam;

const AddContactModal = ({ onBackgroundClick, onClick, dataContact }) => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector((state) => state.translate.lang)
  const { data, type } = dataContact;
  const [isMethod, setMethod] = useState("create");
  const [listDist, setListDist] = useState([]);
  const [distDropdown, setDistDropdown] = useState([]);
  const [prodDropdown, setProdDropdown] = useState([]);
  const [isSelected, setSelected] = useState({
    distributor_select: "",
    product_multiple_select: [],
  });
  const [form, setForm] = useState({
    contact_name: "",
    contact_email: "",
    contact_phone: "",
    contact_position: "",
    contact_level: "secondary",
    contact_product: [],
    distributor_id: "",
    contact_side: data.party_side,
    from_id: "add-contactperson",
  });
  const [formerrors, setFErrors] = useState({
    contact_name: "",
    contact_email: "",
    contact_product: "",
    distributor_id: "",
  });

  const validateField = (fieldName, value) => {
    var ferrors = "";
    switch(fieldName) {
        case 'contact_name': 
            if(value.trim().length > 0) { ferrors = ""; }
            else { ferrors = translate.key_contact_modal.error1; }
            break;
        case 'contact_email': 
            if(value.trim().length > 0) { ferrors = ""; }
            else { ferrors = translate.key_contact_modal.error2; }
            break;
        case 'product_multiple_select': 
            if(value !== undefined) { ferrors = ""; }
            else { ferrors = translate.key_contact_modal.error3; }
            break;
        case 'distributor_select': 
            if(value !== undefined) { ferrors = ""; }
            else { ferrors = translate.key_contact_modal.error4; }
            break;
        default:
            break;
    }
    setFErrors(prevState => ({ ...prevState, [fieldName]: ferrors }));
  }
  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };
  const onChangeSelect = (e, sel) => {
    const name = sel.name;
    if(name === "distributor_select") {
      setSelected((prevState) => ({
        ...prevState,
        distributor_select: e,
      }));
      validateField(name, e);
    } else if(name === "product_multiple_select") {
      setSelected((prevState) => ({
        ...prevState,
        product_multiple_select: e,
      }))
      validateField(name, e);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    
    var stat = true;
    
    var contactName= form.contact_name;
    var contactEmail= form.contact_email;
    var contactProduct= JSON.parse(form.contact_product);
    var contactDistributor= form.distributor_id;
    var formField = [];
    if(contactName === "") { formField.push('contact_name'); }
    if(contactEmail === "") { formField.push('contact_email'); }
    if(contactDistributor === "" || contactDistributor === undefined) { formField.push('distributor_select'); }
    
    // if(form.contact_side === "veolia" && contactProduct.length === 0) {  stat = false; formField.push('product_multiple_select'); }
    // else {
    // if(contactName === "" || contactEmail === "" || contactDistributor === "") {  stat = false; }
    // }

    // if(stat === true) {
    if(contactName !== "" && contactEmail !== "" && contactDistributor !== "")  {
      let fetchUrl = global.config.urlLink;
      if (type === "add") {
        fetchUrl += "/addkcp";
      } else {
        fetchUrl += "/editkcp";
      }
      form.lang=chooseLang;
      headersParams.Authorization = keyAuth2;
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(form),
      })
        .then((res) => {
          if (res.status === 200) return res.json();
        })
        .then((res) => {
          if (res.status === "200") {
            window.location.href = "/keycontact";
          }
        })
        .catch((err) => console.log(err));
    } else { 
      for ( var i = 0; i < formField.length; i++ ) { 
        var fieldname = formField[i];
        var fieldvalue = form[fieldname];
        validateField(fieldname, fieldvalue); 
      }
    }
  };

  const getDistributorDetail = () => {
    let fetchUrl = global.config.urlLink + "/detaildist";
    const dataText = {
      from_id: "detail-distributor",
      doc_id: data?.id_distributor,
      lang: chooseLang
    };
    headersParams.Authorization = keyAuth;
    fetch(fetchUrl, {
      method: "POST",
      headers: headersParams,
      body: JSON.stringify(dataText),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
      })
      .then((res) => {
        setSelected((prevState) => ({
          ...prevState,
          distributor_select: {
            value: res?.values[0]?.distributor_id,
            label: res?.values[0]?.distributor_name,
          },
        }));
      });
  };

  const getProductList = () => {
    document.getElementById("loading-screen").style.display = "flex";
    let fetchUrl = global.config.urlLink + "/listproduct";
    var datas = { stat_id:1, lang: chooseLang };
    if(localStorage.getItem("dulv")===global.config.conflevel[0] || localStorage.getItem("dulv")===global.config.conflevel[1]) {
      datas.from_id = "list-all-product";
    } else {
      datas.from_id = "list-product";
      if(localStorage.getItem("dulv")===global.config.conflevel[3]) {
        datas.dist_id = localStorage.getItem("dudst");
      }
    }
    headersParams.Authorization = keyAuth3;
    fetch(fetchUrl, {
      method: "POST",
      headers: headersParams,
      body: JSON.stringify(datas),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
      })
      .then((res) => {
        if (res.status === "200") {
          const updateProduct = res?.values?.map((item) => ({
            value: item?.product_id,
            label: item?.product_name,
          }));
          setProdDropdown(updateProduct);
          document.getElementById("loading-screen").style.display = "none";
        }
      })
      .catch((err) => {
        console.log(err);
        document.getElementById("loading-screen").style.display = "none";
      });
  };

  const getDistributorList = () => {
    document.getElementById("loading-screen").style.display = "flex";
    let fetchUrl = global.config.urlLink + "/listdist";
    const dataText = { from_id: "list-distributor",stat_id:1, lang: chooseLang };
    headersParams.Authorization = keyAuth;
    fetch(fetchUrl, {
      method: "POST",
      headers: headersParams,
      body: JSON.stringify(dataText),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
      })
      .then(async (res) => {
        if (res.status === "200" && res.values.length > 0) {
          var resultList = res.values;
          if(localStorage.getItem("dulv")===global.config.conflevel[3]) {
            resultList = resultList.filter((sel) =>  {
              return parseInt(sel.distributor_id) === parseInt(localStorage.getItem("dudst")); 
              
            });
          } 
          var dropdowns = resultList.map((item) => ({
            value: item.distributor_id,
            label: item.distributor_name,
          }));
          setListDist(resultList);
          setDistDropdown(dropdowns);
          if(localStorage.getItem("dulv")===global.config.conflevel[3]) {
            setSelected((prevState) => ({
              ...prevState,
              distributor_select: dropdowns[0],
            }));
          }
          document.getElementById("loading-screen").style.display = "none";
        } else if(res.status === global.config.errcodesess) {
          var resultSO = await Logouts();
          if(resultSO.status === "200") {
            window.location.reload();
          }
        }
      })
      .catch((err) => { 
        console.log(err);
        document.getElementById("loading-screen").style.display = "none";
      });
  };

  
  
  useEffect(() => {
    getProductList();
    getDistributorList();
    if (type === "edit" || type === "detail") {
      getDistributorDetail();
      setSelected((prevState) => ({
        ...prevState,
        product_multiple_select: JSON.parse(data?.kcp_product),
      }));
      setForm((prevState) => ({
        ...prevState,
        doc_id: data?.kcp_id,
        contact_name: data?.kcp_name,
        contact_email: data?.kcp_email,
        contact_phone: data?.kcp_phone,
        contact_position: data?.kcp_position,
        contact_product: JSON.parse(data?.kcp_product),
        distributor_id: data?.id_distributor,
        contact_side: data?.party_side,
        from_id: "edit-contactperson",
      }));
    }
    
  }, []);

  useEffect(() => {
    setForm((prevState) => ({
      ...prevState,
      distributor_id: isSelected?.distributor_select?.value?.toString(),
      contact_product: JSON.stringify(isSelected?.product_multiple_select),
    }));
  }, [isSelected]);
  return (
    <Modal
      title={
        type === "add"
          ? translate.key_contact_modal.title1
          : type === "detail"
          ? translate.key_contact_modal.title2
          : translate.key_contact_modal.title3
      }
      tags={`${
        data.party_side.charAt(0).toUpperCase() + data.party_side.slice(1)
      }`}
      onBackgroundClick={onBackgroundClick}
      onClick={onClick}
    >
      <div>
        
        {isMethod === "user" ? (
          <div className="h-80 flex flex-col justify-between">
            <div>
              <div className=" mb-8">
                <label className=" text-gray-500 block mb-2">Search User</label>
                <input
                  placeholder="Type Name..."
                  className="py-2 px-4 outline-blue-500 border rounded-lg"
                />
              </div>
              <div className=" grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-20 mb-8">
                <div className="flex items-center">
                  <div className="flex items-center flex-1 gap-4">
                    <img src="/images/Profile_Default.png" width="50" alt=""/>
                    <p>John Doe</p>
                  </div>
                  <CloseSquare
                    onClick={() => console.log("delete contact")}
                    className="cursor-pointer"
                    width="30"
                  />
                </div>
                <div className="flex items-center">
                  <div className="flex items-center flex-1 gap-4">
                    <img src="/images/Profile_Default.png" width="50" alt=""/>
                    <p>John Doe</p>
                  </div>
                  <CloseSquare
                    onClick={() => console.log("delete contact")}
                    className="cursor-pointer"
                    width="30"
                  />
                </div>
                <div className="flex items-center">
                  <div className="flex items-center flex-1 gap-4">
                    <img src="/images/Profile_Default.png" width="50" alt=""/>
                    <p>John Doe</p>
                  </div>
                  <CloseSquare
                    onClick={() => console.log("delete contact")}
                    className="cursor-pointer"
                    width="30"
                  />
                </div>
                <div className="flex items-center">
                  <div className="flex items-center flex-1 gap-4">
                    <img src="/images/Profile_Default.png" width="50" alt=""/>
                    <p>John Doe</p>
                  </div>
                  <CloseSquare
                    onClick={() => console.log("delete contact")}
                    className="cursor-pointer"
                    width="30"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end items-end">
              <Button
                variant="base"
                className="bg-red-500 text-white"
                title="Insert"
              />
            </div>
          </div>
        ) : (
          <div className=" flex flex-col overflow-y-auto justify-between">
            <div className=" grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-8 mx-1 mb-8">
              <div><TextInput
                  variant="base"
                  // title="Contact Name"
                  title={translate.key_contact_modal.text_input1}
                  name="contact_name"
                  value={form.contact_name}
                  onChange={onChangeHandler}
                  disabled={type === "detail"}
                />
                <FormErrors formErrors={formerrors.contact_name}/>
              </div>
              <div id="field-distributor"><CustomSelect
                  // title="Distributor"
                  title={translate.key_contact_modal.text_input2}
                  name="distributor_select"
                  value={isSelected.distributor_select}
                  options={distDropdown}
                  onChange={onChangeSelect}
                  disabled={type === "detail"}
                />
                <FormErrors formErrors={formerrors.distributor_select}/>
              </div>
              
              <div><TextInput
                  variant="base"
                  // title="Contact Email"
                  title={translate.key_contact_modal.text_input3}
                  name="contact_email"
                  value={form.contact_email}
                  onChange={onChangeHandler}
                  disabled={type === "detail"}
                />
                <FormErrors formErrors={formerrors.contact_email}/>
              </div>
              <div><CustomSelect
                  isMulti
                  // title="Product(s)"
                  title={translate.key_contact_modal.text_input4}
                  name="product_multiple_select"
                  value={isSelected.product_multiple_select}
                  options={prodDropdown}
                  onChange={onChangeSelect}
                  disabled={type === "detail"}
                />
                <FormErrors formErrors={formerrors.product_multiple_select}/>
              </div>
              <div><TextInput
                  variant="base"
                  // title="Contact Phone"
                  title={translate.key_contact_modal.text_input5}
                  name="contact_phone"
                  value={form.contact_phone}
                  onChange={onChangeHandler}
                  disabled={type === "detail"}
                />
              </div>
              <div></div>
              
              <div><TextInput
                  variant="base"
                  // title="Role"
                  title={translate.key_contact_modal.text_input6}
                  name="contact_position"
                  value={form.contact_position}
                  onChange={onChangeHandler}
                  disabled={type === "detail"}
                />
              </div>
            </div>
            <div className="flex justify-end">
              {type !== "detail" && (
                <Button
                  type="button"
                  variant="base"
                  className="bg-red-500 text-white"
                  // title={type === "add"? "Insert" : "Save Changes"}
                  title={type === "add"? translate.key_contact_modal.button1 : translate.general.button_save}
                  onClick={handleSubmit}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AddContactModal;
