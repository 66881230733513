import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { FaTimes, FaEnvelope } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import { ControlChevronLeft, VeoliaLogo, ChinaFlag, EnglishFlag } from "../../assets";
import { VeoliaSideBar } from "./VeoliaSideBar";
import Navbar from "../Navbar";
import Button from "../Button";

import { ReactComponent as AccentSidebar } from "../../assets/svg/accent-sidebar.svg";
import { useDispatch, useSelector } from "react-redux";
import { enLang, cnLang, staticTranslate, translatingToEn, clearTranslateCN } from "../../features/translate/translateSlice";
import { setLoading } from "../../features/utils/utilSlice";
import { clearFilters } from "../../features/filters/filterSlice";

const keyAuth = global.config.typecode[0]+global.config.authBearerKey;
const headersParams = global.config.headersparam;

const Sidebar = () => {
  const translate = useSelector(staticTranslate)
  const chooseLang = useSelector((state) => state.translate.lang);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dispLoad, setdispLoad] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // console.log("on click navbar", openSideBarWithHamburger);
  }, [chooseLang]);
  
  const getProfiles = (langz) => {
    return new Promise((resolve) => {
      let datatext = '{"from_id":"detail-profile","doc_id":"'+localStorage.getItem("dutk")+'","lang":"'+langz+'"}';
      let data = JSON.parse(datatext);
      headersParams.Authorization = keyAuth;
      var fetchUrl = global.config.urlLink + "/detailusers";
      fetch(fetchUrl, {
        method: "POST",
        headers: headersParams,
        body: JSON.stringify(data),
      }).then((res) => {
          if (res.status === 200) return res.json();
      }).then((resJson) => {
          if (resJson.status === "200") {
            resolve(resJson.values);
          } 
      }).catch((err) => {
          console.log(err);
      });
    });
  };
  const changeLang = (langz) => {
    return new Promise((resolve) => {
      if (chooseLang === "en") { dispatch(cnLang()); }
      else { dispatch(enLang()); }
      resolve("ok");
    });
  }
  const HandleChooseLanguage = async (e) => {
    let [result1, result2] = await Promise.all([getProfiles(e), changeLang(e)]);
    if(result1 && result2) {
      var lvname_idx;
      var resultUser = result1[0]; 
      if(resultUser.user_level === "sa") { lvname_idx = 0; }
      else if(resultUser.user_level === "ad") { lvname_idx = 1; }
      else if(resultUser.user_level === "cm") { lvname_idx = 2; }
      else if(resultUser.user_level === "dt") { lvname_idx = 3;}
      
      setdispLoad([resultUser.user_fullname,lvname_idx]);
      window.location.reload();
    }
  };

  const HandleLogout = () => {
    dispatch(setLoading(true));
    localStorage.removeItem("logged_in");
    localStorage.removeItem("duid");
    localStorage.removeItem("dutk");
    localStorage.removeItem("dulv");
    localStorage.removeItem("dufname");
    localStorage.removeItem("duphoto");
    localStorage.removeItem("ducr");
    localStorage.removeItem("dutr");
    localStorage.removeItem("dulvname");
    localStorage.removeItem("dudst");
    localStorage.removeItem("dstatc");

    dispatch(translatingToEn());
    dispatch(enLang());
    dispatch(clearTranslateCN());
    dispatch(clearFilters())
    dispatch(setLoading(false));
    window.location.href = "/";
  };

  const handleSideBar = () => {
    document.getElementById("sidebar").style.display = "none";
    document.getElementById("navbar-logo").style.display = "flex";
  };
  if(translate === undefined || translate.compliance === undefined || translate.compliance_list === undefined) {
    HandleLogout();
  }
  var viewData = { category: translate.sidebar.category1, submenu: [] };
  var viewData2 = { category: translate.sidebar.category2, submenu: [] };
  
  // viewData.category = "Access Management";
  if (
    localStorage.getItem("dulv") === global.config.conflevel[0] ||
    localStorage.getItem("dulv") === global.config.conflevel[1] ||
    localStorage.getItem("dulv") === global.config.conflevel[2]
  ) {
    viewData.submenu.push({
      // title: "Distributor List",
      title: translate.sidebar.submenu1_1,
      // src: "work",
      src: "structure",
      path: "/admin/distributor",
    });
  } else {
    viewData.submenu.push({
      // title: "Distributor ID",
      title: translate.sidebar.submenu1_Id1,
      // src: "work",
      src: "structure",
      path: "/distributor",
    });
  }
  viewData.submenu.push({
    // title: "Key Contact",
    title: translate.sidebar.submenu2_8,
    src: "discovery",
    path: "/apac",
  });
  if(localStorage.getItem("dulv") === global.config.conflevel[0] || 
     localStorage.getItem("dulv") === global.config.conflevel[1] || 
     localStorage.getItem("dulv") === global.config.conflevel[2]
  ) {
    viewData.submenu.push({
      // title: "Users List",
      title: translate.sidebar.submenu1_9,
      src: "work",
      path: "/admin/compliance",
    });
    viewData.submenu.push({
      // title: "Messages From Distributor",
      title: translate.sidebar.submenu1_2,
      src: "messages",
      path: "/admin/contact",
    });
  }
  if(localStorage.getItem("dulv") === global.config.conflevel[0] || 
      localStorage.getItem("dulv") === global.config.conflevel[1]
  ) {
    viewData.submenu.push({
      // title: "Banner List",
      title: translate.sidebar.submenu1_6,
      src: "coupon",
      path: "/admin/promo",
    });
  }
  if(localStorage.getItem("dulv") === global.config.conflevel[0] || 
     localStorage.getItem("dulv") === global.config.conflevel[1] || 
     localStorage.getItem("dulv") === global.config.conflevel[2]
  ) {
    viewData.submenu.push({
      // title: "Product List",
      title: translate.sidebar.submenu1_3,
      src: "filter",
      path: "/admin/product",
    });
    viewData.submenu.push({
      // title: "Market List",
      title: translate.sidebar.submenu1_4,
      // src: "market",
      src: "cart",
      path: "/admin/market",
    });
    viewData.submenu.push({
      // title: "Country List",
      title: translate.sidebar.submenu1_7,
      src: "flag",
      path: "/admin/country",
    });
    viewData.submenu.push({
      // title: "Users List",
      title: translate.sidebar.submenu1_5,
      src: "3-user",
      path: "/admin/users",
    });
    viewData.submenu.push({
      // title: "Users List",
      title: translate.sidebar.submenu1_8,
      src: "bookmark",
      path: "/admin/users-report",
    });
  }
  
    // viewData2.category = "Distributor Management";
    viewData2.submenu.push({
      // title: "Pipeline Review",
      title: translate.sidebar.submenu2_6,
      // src: "info-circle",
      src: "pipeline",
      path: "/pipeline",
    });
    viewData2.submenu.push({
      // title: "Objective & KPIs",
      title: translate.sidebar.submenu2_2,
      src: "ticket-star",
      path: "/objective",
    });
    viewData2.submenu.push({
      // title: "Marketing Material",
      title: translate.sidebar.submenu2_3,
      // src: "shield-done",
      src: "copy",
      path: "/marketing",
    });
    viewData2.submenu.push({
      // title: "Trainings",
      title: translate.sidebar.submenu2_4,
      // src: "location",
      src: "certificate",
      path: "/training",
    });
    if (localStorage.getItem("dulv") === global.config.conflevel[0] ||
       localStorage.getItem("dulv") === global.config.conflevel[1] ||
       localStorage.getItem("dulv") === global.config.conflevel[2]) {
      viewData2.submenu.push({
        // title: "Trainings",
        title: translate.sidebar.submenu2_9,
        src: "2-user",
        path: "/training-participant",
      });
    }
    viewData2.submenu.push({ 
      // title: "Data Analysis", 
      title: translate.sidebar.submenu2_1,
      // src: "game", 
      src: "upgraph",
      path: "/analysis" 
    });
    viewData2.submenu.push({
      // title: "Business Plan",
      title: translate.sidebar.submenu2_7,
      // src: "bookmark",
      src: "dart",
      path: "/businessplan",
    });
    viewData2.submenu.push({
      // title: "Key Contact",
      title: translate.sidebar.submenu2_5,
      src: "3-user-new",
      path: "/keycontact",
    });
    viewData2.submenu.push({
      // title: "Marketing Campaign,
      title: translate.sidebar.submenu2_10,
      src: "campaign",
      path: "/campaign",
    });
  
    
    
  const Veolia_Menu = [viewData, viewData2];
  if(dispLoad.length > 0 && dispLoad[1] !== undefined) {
    localStorage.setItem('dufname', dispLoad[0]);
    localStorage.setItem('dulvname', translate.login.userlevel_name[dispLoad[1]]); 
  }
  return (
    <div className="flex overflow-y-auto">
      <div
        id="sidebar"
        className={`${
          !open ? " w-60 min-w-80" : " w-28 p-5 "
        } hidden md:flex flex-col duration-500 transition-all bg-white border-r h-screen sticky top-0 pt-1 z-[1]`}
      >
        <div
          className="w-6 h-6 justify-center items-center flex md:hidden rounded-full absolute cursor-pointer border-dark-purple border-2 -right-2.5 top-5 bg-white "
          onClick={handleSideBar}
        >
          <FaTimes />
        </div>
        <div
          className={`w-6 h-6 justify-center items-center md:flex hidden rounded-full absolute cursor-pointer border-dark-purple border-2 -right-2.5 bg-white z-[1] ${
            open ? "top-10" : "top-6"
          }`}
          onClick={() => setOpen(!open)}
        >
          <img
            src={ControlChevronLeft}
            alt="chevron_left"
            className={`${open && " rotate-180 duration-500"} w-3`}
          />
        </div>
        <div
          className={`flex items-center ${
            !open && "justify-center"
          } gap-x-4 border-b-2 py-3 cursor-pointer`}
          onClick={() => navigate("/")}
        >
          <img
            className={`w-28 ${open && "hidden"} my-1`}
            src="/images/main-logo.png"
            alt="logo_with_textname"
          />
          <img
            className={`cursor-pointer duration-300 ${!open && "hidden"}`}
            alt="only_veolia_logo"
            src={VeoliaLogo}
          />
        </div>
        <div
          className={`flex flex-col place-items-center mx-auto p-2 py-4 w-full cursor-pointer `}
          onClick={() => navigate("/profile")}
        >
          <div className="bg-red-200 rounded-full p-0.5 mb-2">
          <img
            src={(localStorage.getItem("duphoto") !== "" && localStorage.getItem("duphoto") !== null && localStorage.getItem("duphoto") !== undefined && localStorage.getItem("duphoto") !== "null") ? localStorage.getItem("duphoto") : "/images/user-df.png"}
            alt="user_default"
            className="self-center items-center justify-center w-32 h-32 rounded-full object-cover object-[50%_0px]"
          />
          </div>
          <div
            className={`justify-center items-center flex-col flex mb-2 ${
              open && "hidden"
            }`}
          >
            <p className="text-center font-semibold" id="a-ufullname">{localStorage.getItem("dufname")}</p>
            <label className="text-gray-400 text-xs" id="a-ulevelname">{localStorage.getItem("dulvname")}</label>
          </div>
          <Button
            type="base"
            size="small"
            // title="Edit Profile"
            title={translate.sidebar.button_profile}
            className={`bg-red-500 text-white ${open && "hidden"}`}
            onClick={() => navigate("/profile")}
          />
        </div>
        <ul
          className={`flex flex-col h-full pt-6 overflow-y-auto ${
            open ? "items-center" : "mx-5"
          }  bg-transparent border-b-2`}
        >
          {(localStorage.getItem("dstatc") === global.config.compliancestatus[2] && <VeoliaSideBar visible={open} item={Veolia_Menu} /> )}
        </ul>
        <div className="flex flex-col">
          <AccentSidebar
            className={`absolute overflow-hidden bottom-0 left-0 -z-50 fill-blue-50 ${
              open && "hidden"
            }`}
          />
          {(localStorage.getItem("dstatc") === global.config.compliancestatus[2]) &&
          (<button
            onClick={() => navigate("/contact/create")} 
            className={`bg-red-500 p-2 mx-3 rounded-lg text-white font-semibold mt-5 hover:shadow-3xl duration-150 ${
              open ? "flex flex-1 justify-center items-center" : ""
            } ${open ? "text-sm" : "text-xs"}`}
          >
            {open ? <FaEnvelope width="20" /> : translate.sidebar.button_mail}
          </button>)
          }
          <div className="items-center flex justify-center gap-5 mt-5">
            <Button
              variant="radio"
              src={EnglishFlag}
              checked={chooseLang === "en"}
              onChange={() => HandleChooseLanguage("en")}
              className={`${chooseLang === "en" && "bg-red-200"}`}
              width={35}
              height={35}
              />
            <Button
              variant="radio"
              src={ChinaFlag}
              checked={chooseLang === "cn"}
              onChange={() => HandleChooseLanguage("cn")}
              className={`${chooseLang === "cn" && "bg-red-200"}`}
              width={35}
              height={35}
            />
          </div>
          <button
            onClick={HandleLogout}
            className={`mx-3 p-2 rounded-lg text-red-500 font-semibold my-5 hover:shadow-xl duration-150 ${
              open ? "flex flex-1 justify-center items-center" : ""
            } ${open ? "text-sm" : "text-xs"}`}
          >
            {open ? <IoMdLogOut color="red" size={20} /> : translate.sidebar.logout}
          </button>
        </div>
      </div>
      <div className={`flex-col flex-1`}>
        <Navbar />
        <Outlet />
      </div>
    </div>
  );
};

export default Sidebar;
