import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, DateTimeFormats } from "../../../components";

import parse from 'html-react-parser';
import { useSelector } from "react-redux";
import { staticTranslate } from "../../../features/translate/translateSlice";

const BusinessPlanDetail = () => {
  const translate = useSelector(staticTranslate)
  const paramsLoc = useLocation();
  const navigate = useNavigate();
  const { data } = paramsLoc.state;
  const { filter } = paramsLoc.state;
  
  const handleBack = () => {
    navigate("/businessplan", {
      state: { dataref: filter },
    })
  }
  return (
    <div className="flex-1 h-screen bg-gray-100 overflow-y-auto p-8">
      <div className="flex items-center mb-8 gap-4">
        {/* <Button variant="icon" icon="left" onClick={() => navigate(-1)} /> */}
        <Button variant="icon" icon="left" onClick={() => handleBack()} />
        <h2 className="text-2xl">{translate.business_plan_detail.title}</h2>
      </div>
      <Card className="p-8">
        <div>
          <h2 className=" text-2xl mb-2">{data.bplan_title}</h2>
          <label className="text-sm text-gray-400 mb-2 mt-5 mr-5">
          {translate.business_plan_detail.label1} <DateTimeFormats datez = {new Date(data.created_date)} type={"datetime-short"} /> 
          </label>
          <label className="text-sm text-gray-400 mb-2 mt-5 mr-5">
          {translate.business_plan_detail.label2} {data.distributor_name}
          </label>
          
        </div>
        <div className="mt-5">{parse(data.bplan_content)}</div>
      </Card>
    </div>
  );
};

export default BusinessPlanDetail;
